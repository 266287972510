/* eslint-disable no-undef */
import firebase from "firebase/app";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/remote-config";
// import { getAnalytics } from "firebase/analytics";

// const firestore = () => firebase.firestore;
let firestoreDB;
let firebaseAuth;

const initialize = () => {
  const config = {
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  firebase.initializeApp(config);

  firestoreDB = firebase.firestore();
  if (process.env.REACT_APP_FIRESTORE_URL) {
    firestoreDB.settings({
      host: process.env.REACT_APP_FIRESTORE_URL,
      ssl: false,
    });
  }

  firebaseAuth = firebase.auth();
  if (process.env.REACT_APP_EMULATOR_AUTH_URL) {
    log.info(
      `Using emulator for auth: ${process.env.REACT_APP_EMULATOR_AUTH_URL}`
    );
    firebaseAuth.useEmulator(process.env.REACT_APP_EMULATOR_AUTH_URL);
  }
};

const firestore = () => firebase.firestore;
const db = () => firebase.firestore();
const storage = () => firebase.storage();
const remoteConfig = () => firebase.remoteConfig();
const auth = () => firebaseAuth;
// const firebaseAnalytics = getAnalytics();
// const analytics = () => firebaseAnalytics;
const functions = () => {
  const functions = firebase.functions();
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_FIREBASE_FUNCTIONS_URL) {
      functions.useFunctionsEmulator(
        process.env.REACT_APP_FIREBASE_FUNCTIONS_URL
      );
    }
  }

  return functions;
};

let api = {
  firestore,
  initialize,
  db,
  storage,
  remoteConfig,
  functions,
  auth,
  // analytics,
};

export default api;
