import React from "react";
import log from "loglevel"; // eslint-disable-line
import PropTypes from "prop-types";
import { Modal, Button, Container } from "semantic-ui-react";

import EditTeamName from "./EditTeamName";

class RegisterForHunt extends React.PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.team) {
      this.props.handleClose();
    }
  }

  render() {
    // If the user is not logged in, show a sign up screen.
    // On the signup screen, let the user switch to a log in screen.
    // On the login screen, let the user switch to a signup screen.
    // If the user is signed in (or after the user signs in), show a confirmation screen.
    // The confirmation screen should:
    //    - Let the user see their name and email address
    //    - Let the user create a team or join a team
    // After registering, the user should see a confirmation screen and get sent a confirmation email/text
    const trigger = (
      <Button
        size="large"
        positive
        style={{
          minWidth: "200px",
        }}
        disabled={
          this.props.registerLoading ||
          !!this.props.team ||
          this.props.huntIsFull
        }
        loading={this.props.registerLoading}
        onClick={this.props.handleOpen}
      >
        {this.props.team ? "You're Registered!" : "REGISTER"}
      </Button>
    );
    return (
      <Container textAlign="center">
        {this.props.huntIsFull ? (
          <div>
            {trigger}
            <br />
            <p>
              Sorry, there are no more spots available in this hunt. Please
              contact the organizer for more information.
            </p>
          </div>
        ) : (
          <Modal
            trigger={trigger}
            size="small"
            closeIcon={true}
            open={this.props.modalOpen}
            onClose={this.props.handleClose}
          >
            <Modal.Header>Register for {this.props.hunt.name}</Modal.Header>
            <Modal.Content>
              <Container textAlign="center">{this.props.children}</Container>
            </Modal.Content>
          </Modal>
        )}
        {this.props.loggedIn && !!this.props.team && (
          <div>
            <br />
            <p>
              You are registered as <EditTeamName team={this.props.team} />{" "}
            </p>
          </div>
        )}
        {this.props.loggedIn && (
          <div>
            <br />
            <a onClick={this.props.onLogout}>Sign out</a>
          </div>
        )}
      </Container>
    );
  }
}

RegisterForHunt.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  hunt: PropTypes.object.isRequired,
  team: PropTypes.object,
  huntIsFull: PropTypes.bool.isRequired,
  registerLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default RegisterForHunt;
