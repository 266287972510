import React from "react";
import PropTypes from "prop-types";
import { Header, Icon, Button } from "semantic-ui-react";
import QRCode from "qrcode.react";
import NumberFormat from "react-number-format";

import GoogleGeoPointChallengeMap from "components/GoogleMaps/GoogleGeoPointChallengeMap";
import { CHALLENGE_TYPES } from "../constants";

import log from "loglevel"; // eslint-disable-line no-unused-vars

class ViewChallengeDetails extends React.PureComponent {
  downloadQR = () => {
    // log.debug("download QR 1", button, this._qrDownloadButton);
    const href = document.querySelector(".qrcode > canvas").toDataURL();
    // const button = document.getElementById("qr-button");
    // log.debug("download QR", href, this._qrDownloadButton.ref);
    const but1 = document.querySelector("#qr-download-button");
    but1.href = href;
    but1.download = `${this.props.challenge.name}-qr.png`;
  };

  render() {
    const { challenge } = this.props;
    const challengeType = CHALLENGE_TYPES.find(c => c.value === challenge.type);
    return (
      <div>
        <Header sub>Challenge Type</Header>
        <Icon name={challengeType.icon} /> {challengeType.text}
        <Header sub>Challenge Name</Header>
        {challenge.name}
        <Header sub>Challenge Description</Header>
        {challenge.description}
        {challenge.points && [
          <Header sub key="points-header">
            Points
          </Header>,
          challenge.points
        ]}
        {challenge.type === "password" && <Header sub>Password Answer</Header>}
        {challenge.type === "password" && challenge.password}
        {challenge.type === "geopoint" && [
          <Header sub key="geopoint-header">
            Geopoint Location
          </Header>,
          <p key="geopoint-location">
            {"["}
            <NumberFormat
              value={challenge.point.latitude}
              decimalScale={5}
              fixedDecimalScale={true}
              displayType={"text"}
            />
            ,
            <NumberFormat
              value={challenge.point.longitude}
              decimalScale={5}
              fixedDecimalScale={true}
              displayType={"text"}
            />
            {"]"}
          </p>,
          <p key="geopoint-radius">
            Radius:{" "}
            <NumberFormat
              value={challenge.radius}
              decimalScale={2}
              fixedDecimalScale={false}
              displayType={"text"}
            />{" "}
            meters
          </p>,
          <GoogleGeoPointChallengeMap
            center={{
              lat: challenge.point.latitude,
              lng: challenge.point.longitude
            }}
            editable={false}
            challengeLocation={{
              lat: challenge.point.latitude,
              lng: challenge.point.longitude
            }}
            challengeRadius={challenge.radius}
            key="geopoint-map"
          />
        ]}
        {challenge.type === "qr" && [
          <Header key="qr-header" sub>
            QR Code
          </Header>,
          <div key="qr-code-canvas" className="qrcode">
            <QRCode
              size={200}
              level="Q"
              value={`https://scavr.app/qr/${challenge.objectId}`}
            />
          </div>,
          <Button
            key="qr-download-button"
            as="a"
            className="qr-button"
            id="qr-download-button"
            icon
            labelPosition="left"
            onClick={this.downloadQR}
          >
            <Icon name="download" />
            Download
          </Button>
        ]}
      </div>
    );
  }
}

ViewChallengeDetails.propTypes = {
  challenge: PropTypes.object.isRequired
};

export default ViewChallengeDetails;
