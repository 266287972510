/* eslint-disable no-case-declarations */
import { fromJS } from "immutable";
import {
  SAVE_CHALLENGE,
  SAVE_CHALLENGE_FAILURE,
  SAVE_CHALLENGE_SUCCESS,
} from "../constants";

const initialState = fromJS({
  loading: false,
  error: null,
});

function myHuntsReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_CHALLENGE:
      return state.set("loading", true);
    case SAVE_CHALLENGE_FAILURE:
      return state.set("loading", false).set("error", action.error.message);
    case SAVE_CHALLENGE_SUCCESS:
      return state.set("loading", false);
    default:
      return state;
  }
}

export default myHuntsReducer;
