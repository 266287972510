import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; //eslint-disable-line no-unused-vars
import Field from "../Field";
import { Form } from "semantic-ui-react";

const timeOptions = [
  { key: "12am", text: "12:00 AM", value: "12:00 AM" },
  { key: "1230am", text: "12:30 AM", value: "12:30 AM" },
  { key: "1am,", text: "1:00 AM", value: "1:00 AM" },
  { key: "130am,", text: "1:30 AM", value: "1:30 AM" },
  { key: "2am", text: "2:00 AM", value: "2:00 AM" },
  { key: "230am", text: "2:30 AM", value: "2:30 AM" },
  { key: "3am,", text: "3:00 AM", value: "3:00 AM" },
  { key: "330am,", text: "3:30 AM", value: "3:30 AM" },
  { key: "4am", text: "4:00 AM", value: "4:00 AM" },
  { key: "430am", text: "4:30 AM", value: "4:30 AM" },
  { key: "5am,", text: "5:00 AM", value: "5:00 AM" },
  { key: "530am,", text: "5:30 AM", value: "5:30 AM" },
  { key: "6am", text: "6:00 AM", value: "6:00 AM" },
  { key: "630am", text: "6:30 AM", value: "6:30 AM" },
  { key: "7am,", text: "7:00 AM", value: "7:00 AM" },
  { key: "730am,", text: "7:30 AM", value: "7:30 AM" },
  { key: "8am", text: "8:00 AM", value: "8:00 AM" },
  { key: "830am", text: "8:30 AM", value: "8:30 AM" },
  { key: "9am,", text: "9:00 AM", value: "9:00 AM" },
  { key: "930am,", text: "9:30 AM", value: "9:30 AM" },
  { key: "10am,", text: "10:00 AM", value: "10:00 AM" },
  { key: "1030am,", text: "10:30 AM", value: "10:30 AM" },
  { key: "11am", text: "11:00 AM", value: "11:00 AM" },
  { key: "1130am", text: "11:30 AM", value: "11:30 AM" },
  { key: "12pm", text: "12:00 PM", value: "12:00 PM" },
  { key: "1230pm", text: "12:30 PM", value: "12:30 PM" },
  { key: "1pm,", text: "1:00 PM", value: "1:00 PM" },
  { key: "130pm,", text: "1:30 PM", value: "1:30 PM" },
  { key: "2pm", text: "2:00 PM", value: "2:00 PM" },
  { key: "230pm", text: "2:30 PM", value: "2:30 PM" },
  { key: "3pm,", text: "3:00 PM", value: "3:00 PM" },
  { key: "330pm,", text: "3:30 PM", value: "3:30 PM" },
  { key: "4pm", text: "4:00 PM", value: "4:00 PM" },
  { key: "430pm", text: "4:30 PM", value: "4:30 PM" },
  { key: "5pm,", text: "5:00 PM", value: "5:00 PM" },
  { key: "530pm,", text: "5:30 PM", value: "5:30 PM" },
  { key: "6pm", text: "6:00 PM", value: "6:00 PM" },
  { key: "630pm", text: "6:30 PM", value: "6:30 PM" },
  { key: "7pm,", text: "7:00 PM", value: "7:00 PM" },
  { key: "730pm,", text: "7:30 PM", value: "7:30 PM" },
  { key: "8pm", text: "8:00 PM", value: "8:00 PM" },
  { key: "830pm", text: "8:30 PM", value: "8:30 PM" },
  { key: "9pm,", text: "9:00 PM", value: "9:00 PM" },
  { key: "930pm,", text: "9:30 PM", value: "9:30 PM" },
  { key: "10pm,", text: "10:00 PM", value: "10:00 PM" },
  { key: "1030pm,", text: "10:30 PM", value: "10:30 PM" },
  { key: "11pm", text: "11:00 PM", value: "11:00 PM" },
  { key: "1130pm", text: "11:30 PM", value: "11:30 PM" }
];

const TimeField = ({ label, name, placeholder }) => {
  return (
    <Field
      label={label}
      name={name}
      placeholder={placeholder}
      component={Form.Select}
      options={timeOptions}
      clearable
    />
  );
};

TimeField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default TimeField;
