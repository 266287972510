import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; //eslint-disable-line no-unused-vars
import moment from "moment";
import { Card, Button, Icon, Label, Image, Header } from "semantic-ui-react";

export class ChallengeAttemptCard extends React.PureComponent {
  state = {
    visible: true
  };

  onClickApprove = () => {
    this.setState({ visible: false });
  };

  render() {
    const attempt = this.props.challengeAttempt;
    const challenge = attempt.challenge;
    const team = attempt.team;
    const judging = this.props.isJudging;

    let submissionTime = "";
    if (attempt.submittedAt && attempt.submittedAt.seconds) {
      submissionTime = moment.unix(attempt.submittedAt.seconds).format("lll");
    }

    if (!challenge || !team) {
      return <div />;
    }

    let statusText = "";
    let color = "grey";
    switch (attempt.status) {
      case "completed":
        color = "green";
        statusText = "Completed";
        break;
      case "rejected":
        color = "red";
        statusText = "Rejected";
        break;
      case "attempted":
        color = "grey";
        statusText = "Submitted";
        break;
      default:
        break;
    }

    let showButtons = true;
    let hasImage = false;
    let hasVideo = false;
    let icon = "";
    switch (challenge.type) {
      case "photo":
        icon = "photo";
        showButtons = true;
        hasImage = true;
        break;
      case "video":
        icon = "video camera";
        showButtons = true;
        hasVideo = true;
        break;
      case "password":
        icon = "lightbulb";
        showButtons = true;
        break;
      case "qr":
        icon = "qrcode";
        break;
      case "geopoint":
        icon = "marker";
        break;
      default:
        break;
    }

    return (
      <Card color={color}>
        {hasImage ? (
          <Image
            src={attempt.fileUrl}
            size="large"
            centered
            as="a"
            href={attempt.fileUrl}
            target="_blank"
          />
        ) : hasVideo ? (
          <video
            controls
            style={{
              backgroundSize: "contain",
              width: "100%",
              maxWidth: "450px",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <source src={attempt.fileUrl} type="video/mp4" />
          </video>
        ) : (
          ""
        )}
        <Card.Content>
          <Card.Header>
            <Icon name={icon} />
            {challenge.name}
          </Card.Header>
          <Card.Meta>Team: {team.name}</Card.Meta>
          <Card.Description>
            <em>Challenge Description:</em> {challenge.description}
            <br />
            <em> </em>
          </Card.Description>
        </Card.Content>
        {showButtons ? (
          <Card.Content extra>
            <Button.Group fluid>
              <Button
                basic={attempt.status !== "completed"}
                disabled={attempt.status === "completed" || judging}
                color="green"
                onClick={this.props.handleAccept}
                loading={judging}
              >
                Approve
              </Button>
              <Button
                basic={attempt.status !== "rejected"}
                disabled={attempt.status === "rejected" || judging}
                color="red"
                onClick={this.props.handleReject}
                loading={judging}
              >
                Reject
              </Button>
            </Button.Group>
          </Card.Content>
        ) : (
          ""
        )}
        <Card.Content extra>
          {submissionTime && (
            <Header as="h5" floated="right" color="grey">
              {submissionTime}
            </Header>
          )}
          <Label as="a" color={color} basic>
            {statusText}
            {attempt.status === "completed" ? (
              <Label.Detail>{challenge.points} points</Label.Detail>
            ) : (
              ""
            )}
          </Label>
          {attempt.status === "rejected" ? (
            <div>
              <br />
              <em>{attempt.message}</em>
            </div>
          ) : (
            ""
          )}
        </Card.Content>
      </Card>
    );
  }
}

ChallengeAttemptCard.propTypes = {
  challengeAttempt: PropTypes.object,
  handleAccept: PropTypes.func,
  handleReject: PropTypes.func,
  isJudging: PropTypes.bool
};

ChallengeAttemptCard.defaultProps = {};

export default ChallengeAttemptCard;
