import log from "loglevel"; // eslint-disable-line no-unused-vars
import FirebaseUtils from "DataProvider/firebase/firebaseUtils";
import defaultConfig from "./defaultConfig.dev";

// const providerType = "firebase";

const instance = (function () {
  let userClient;

  return {
    initialize: async (userId) => {
      log.debug("Initializing remote config.");
      if (userClient) {
        return;
      }

      const config = FirebaseUtils.remoteConfig();
      config.settings = {
        minimumFetchIntervalMillis: 60000,
        fetchTimeoutMillis: 30000,
      };

      log.debug("Default config settings: ", defaultConfig);
      config.defaultConfig = {
        ...defaultConfig,
      };

      try {
        await config.ensureInitialized();
        log.debug("Remote Config initialized");
        await config.fetchAndActivate();
        log.debug("Remote config activated");
        userClient = config;
      } catch (err) {
        log.error("Failed to init remoteConfig", err);
      }
    },

    getJSONValue: (key, attributes) => {
      if (!userClient) {
        return defaultConfig[key];
      }
      const val = userClient.getString(key);
      log.debug(`For key (${key}) got: ${val}`);
      return JSON.parse(val);
    },

    getStringValue: (key) => {
      if (!userClient) {
        return defaultConfig[key];
      }
      const val = userClient.getString(key);
      log.debug(`For key (${key}) got: ${val}`);
      return val;
    },

    getValue: (key) => {
      log.error("Get value not implemented for Firebase Remote Config", key);
    },
  };
})();

export default instance;

// const initialize = async () => {
//   const config = remoteConfig();
//   // config.settings = {
//   //   minimumFetchIntervalMillis: 60000,
//   //   fetchTimeoutMillis: 30000,
//   // };
//
//   log.debug("Default config settings: ", defaultConfig);
//   config.defaultConfig = {
//     ...defaultConfig,
//   };
//
//   try {
//     await remoteConfig().ensureInitialized();
//     log.debug("Remote Config initialized");
//     // await remoteConfig().fetchAndActivate();
//     // log.debug("Remote config activated");
//   } catch (err) {
//     log.error("Failed to init remoteConfig", err);
//   }
// };
//
// const getValue = async (key) => {
//   const config = remoteConfig();
//   // return config().getValue(key);
//   try {
//     await config.fetchAndActivate();
//     // log.debug("fetch result: ", result);
//   } catch (e) {
//     log.error("error fetching/activating", e);
//   }
//
//   return config.getValue(key);
//   // log.debug("Got: ", val, "for key: ", key, "for user: ", user);
//   // return val;
// };
//
// export { providerType, initialize, getValue };
