import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import { Modal, Button } from "semantic-ui-react";
import CreateChallengeForm from "../CreateEditChallengeForm";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { selectErrors, selectValues, selectHasPoints } from "../selectors";
import { selectHuntCapabilityChallengeTypes } from "redux/selectors"; // eslint-disable-line no-unused-vars

class CreateChallengeModal extends React.PureComponent {
  handleClose = () => {
    this.setState({ open: false });
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleCreate = () => {
    this.props.onCreateChallenge(this.props.formData);
    this.handleClose();
  };

  constructor() {
    super();
    this.state = {
      open: false,
    };
  }

  render() {
    const { triggerElement, hasPoints, formErrors } = this.props;

    return (
      <Modal
        trigger={triggerElement}
        size="small"
        closeOnDimmerClick={false}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        open={this.state.open}
        zoom={8}
      >
        <Modal.Header>Create a Challenge</Modal.Header>
        <Modal.Content scrolling>
          <CreateChallengeForm hasPoints={hasPoints} />
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.handleClose}>
            Cancel
          </Button>
          <Button disabled={!!formErrors} primary onClick={this.handleCreate}>
            Create Challenge
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

CreateChallengeModal.propTypes = {
  triggerElement: PropTypes.node.isRequired,
  onCreateChallenge: PropTypes.func.isRequired,
  hasPoints: PropTypes.bool.isRequired,
  formData: PropTypes.object,
  formErrors: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  formData: selectValues,
  formErrors: selectErrors,
  hasPoints: selectHasPoints,
});

export default withRouter(connect(mapStateToProps)(CreateChallengeModal));
