import logger from "loglevel"; // eslint-disable-line no-unused-vars
import defaultConfig from "./defaultConfig.dev";
const log = logger.getLogger("local_remote_config");
log.setLevel("ERROR");

const providerType = "local";

const initialize = async () => {
  log.debug("Initializing Default Config: ", defaultConfig);
};

const getValue = async key => defaultConfig[key];

const getJSONValue = key => {
  log.debug("Getting value for key: ", key, ". Value is: ", defaultConfig[key]);
  return defaultConfig[key];
};

const getStringValue = key => {
  log.debug(`Getting value for key: ${key}. Value is ${defaultConfig[key]}`);
  return defaultConfig[key];
};

export { providerType, initialize, getValue, getJSONValue, getStringValue };
