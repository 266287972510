import React from "react";
import log from "loglevel";
import PropTypes from "prop-types";
import {
  Accordion,
  Header,
  Button,
  Input,
  Segment,
  Message,
  Form,
  Icon,
  Checkbox
} from "semantic-ui-react";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import prettyBytes from "pretty-bytes";
import {
  createAttemptArchive,
  listenForHuntArchive,
  stopListeningForArchive
} from "redux/actionCreators";
import { selectCurrentHuntId } from "redux/selectors";
import {
  selectArchiveDate,
  selectArchiveRequested,
  selectArchiveSize,
  selectArchiveURL
} from "./selectors";

class Download extends React.PureComponent {
  state = {
    email: "",
    includePhotos: true,
    includeVideos: true,
    accordionOpen: false,
    emailWarning: false
  };

  componentDidMount() {
    this.props.listenForArchive(this.props.huntId);
  }

  componentWillUnmount() {
    this.props.stopListeningForArchive(this.props.huntId);
  }

  onChangeEmail = evt => {
    this.setState({ email: evt.target.value });
  };

  onChangePhotos = (evt, data) => {
    this.setState({ includePhotos: data.checked });
  };

  onChangeVideos = (evt, data) => {
    log.debug("Video state change: ", evt, data, data.checked);
    this.setState({ includeVideos: data.checked });
    log.debug(this.state);
  };

  onChangeAccordion = () => {
    const { accordionOpen } = this.state;
    const newAccordion = !accordionOpen;

    this.setState({ accordionOpen: newAccordion });
  };

  onClickDownload = () => {
    this.props.createArchive(this.props.huntId, {
      completed: true,
      rejected: false,
      submitted: false,
      photos: this.state.includePhotos,
      videos: this.state.includeVideos,
      email: this.state.email
    });
  };

  showEmailWarning = () => {
    this.setState({ emailWarning: true });
  };

  render() {
    const {
      email,
      includePhotos,
      includeVideos,
      accordionOpen,
      emailWarning
    } = this.state;

    const {
      archiveRequested,
      archiveURL,
      archiveDate,
      archiveSize
    } = this.props;

    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validEmail = re.test(email);
    const enableButton =
      validEmail && (includeVideos || includePhotos) && !archiveRequested;

    return (
      <div>
        <div />
        <Header as="h2">Download</Header>
        <Segment>
          <Form warning={emailWarning}>
            <Accordion fluid styled>
              <Accordion.Title
                active={accordionOpen}
                onClick={this.onChangeAccordion}
              >
                <Icon name="dropdown" />
                Options
              </Accordion.Title>
              <Accordion.Content active={accordionOpen}>
                <Form.Field>
                  <Checkbox
                    toggle
                    label="Include Photos"
                    checked={includePhotos}
                    onChange={this.onChangePhotos}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label="Include Videos"
                    checked={includeVideos}
                    onChange={this.onChangeVideos}
                  />
                </Form.Field>
              </Accordion.Content>
            </Accordion>
            <br />
            <Form.Field>
              <Input
                placeholder="Email Address"
                value={email}
                size="large"
                fluid
                autoFocus
                onChange={this.onChangeEmail}
              />
            </Form.Field>
            <Form.Field>
              {archiveRequested ? (
                <div>
                  <Button disabled>
                    <Button.Content>Sending...</Button.Content>
                  </Button>

                  <Message
                    floating
                    positive
                    header="Sent!"
                    icon="thumbs up outline"
                    content="We're bundling up all your images and videos. Please give
                it a few minutes, then check your
                email or come back here to get your download
                link."
                  />
                </div>
              ) : enableButton ? (
                <Button primary onClick={this.onClickDownload}>
                  Send Zip File
                </Button>
              ) : (
                <div>
                  {validEmail ? null : (
                    <Message
                      floating
                      warning
                      content="Please enter a valid email address. ☝️"
                    />
                  )}
                  <Button onClick={this.showEmailWarning}>
                    <Button.Content>Send Zip File</Button.Content>
                  </Button>
                </div>
              )}
            </Form.Field>
          </Form>
        </Segment>
        {archiveURL ? (
          <Segment>
            <Header size="large">Most Recent Export</Header>
            <Header size="small">Download Link</Header>
            <a href={archiveURL}>Scavr Export.zip</a>
            <Header size="small">File Size</Header>
            <p>{prettyBytes(Number(archiveSize))}</p>
            <Header size="small">Date Created</Header>
            <p>{archiveDate}</p>
          </Segment>
        ) : null}
        <Message
          floating
          info
          icon="zip"
          header="How To Export Your Photos & Videos"
          content={
            <p>
              1. Select if you want photos, videos, or both. This will only
              export the &quot;approved&quot; photos and videos. It won&apos;t
              include any rejected or pending submissions.
              <br />
              2. Enter the email address where you&apos;d like to receive a link
              to the zip file.
              <br />
              3. Check your email in a few minutes for the link
            </p>
          }
        />
      </div>
    );
  }
}

Download.propTypes = {
  huntId: PropTypes.string.isRequired,

  archiveRequested: PropTypes.bool,
  archiveURL: PropTypes.string,
  archiveDate: PropTypes.string,
  archiveSize: PropTypes.string,

  createArchive: PropTypes.func.isRequired,
  listenForArchive: PropTypes.func.isRequired,
  stopListeningForArchive: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  huntId: selectCurrentHuntId,

  archiveRequested: selectArchiveRequested,
  archiveURL: selectArchiveURL,
  archiveDate: selectArchiveDate,
  archiveSize: selectArchiveSize
});

const mapDispatchToProps = dispatch => ({
  createArchive: (huntId, settings) =>
    dispatch(createAttemptArchive(huntId, settings)),
  listenForArchive: huntId => dispatch(listenForHuntArchive(huntId)),
  stopListeningForArchive: huntId => dispatch(stopListeningForArchive(huntId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Download)
);
