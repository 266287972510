import log from "loglevel";
import defaultConfig from "./defaultConfig.dev";

// const providerType = "split";

const instance = (function () {
  let userClient;
  // let anonymousClient;

  return {
    initialize: async (userId) => {
      if (userClient) {
        return;
      }

      const SplitFactory = require("@splitsoftware/splitio").SplitFactory;

      // Instantiate the SDK
      const factory = SplitFactory({
        core: {
          // eslint-disable-next-line no-undef
          authorizationKey: process.env.REACT_APP_SPLIT_KEY,

          // the key can be the logged in
          // user id, or the account id that
          // the logged in user belongs to.
          // The type of customer (user, account, custom)
          // is chosen during Split's sign-up process.
          key: userId,
        },
        startup: {
          readyTimeout: 1.5, // 1.5 sec
        },
      });
      // And get the client instance you'll use
      userClient = factory.client();

      // if (anonymousUserId) {
      //   anonymousClient = factory.client(anonymousUserId);
      // }

      return new Promise((resolve) => {
        userClient.once(userClient.Event.SDK_READY, () => {
          log.debug("Split Loaded successfully.");
          resolve();
        });
      });
    },

    getJSONValue: (key, attributes) => {
      if (!userClient) {
        log.warn("No user client found, using backup");
        return defaultConfig[key];
      }
      let result = userClient.getTreatmentWithConfig(key, attributes);
      log.debug("JSON result for key: ", key, result);
      return JSON.parse(result.config);
    },

    getStringValue: (key) => {
      if (!userClient) {
        log.warn("No user client found, using backup");
        return defaultConfig[key];
      }
      let result = userClient.getTreatment(key);
      if (result) {
        log.debug(`JSON result for key ${key}: ${result}`);
        return result;
      } else {
        return null;
      }
    },

    getValue: (key) => {
      log.error("Get value not implemented for Split", key);
    },
  };
})();

export default instance;
