export const CREATE_TEAM_FORM_NAME = "createTeamAndRegister";

export const CREATE_TEAM_AND_JOIN_HUNT =
  "scavr/JoinHunt/CREATE_TEAM_AND_JOIN_HUNT";
export const CREATE_TEAM_AND_JOIN_HUNT_SUCCEEDED =
  "scavr/JoinHunt/CREATE_TEAM_AND_JOIN_HUNT_SUCCEEDED";
export const CREATE_TEAM_AND_JOIN_HUNT_FAILED =
  "scavr/JoinHunt/CREATE_TEAM_AND_JOIN_HUNT_FAILED";

export const FETCH_AM_I_REGISTERED = "scavr/JoinHunt/FETCH_AM_I_REGISTERED";
export const FETCH_AM_I_REGISTERED_SUCCEEDED =
  "scavr/JoinHunt/FETCH_AM_I_REGISTERED_SUCCEEDED";
export const FETCH_AM_I_REGISTERED_FAILED =
  "scavr/JoinHunt/FETCH_AM_I_REGISTERED_FAILED";

export const FETCH_REGISTERED_TEAMS = "scavr/JoinHunt/FETCH_REGISTERED_TEAMS";
export const FETCH_REGISTERED_TEAMS_SUCCEEDED =
  "scavr/JoinHunt/FETCH_REGISTERED_TEAMS_SUCCESS";
export const FETCH_REGISTERED_TEAMS_FAILED =
  "scavr/JoinHunt/FETCH_REGISTERED_TEAMS_FAILED";

export const UPDATE_TEAM_NAME = "scavr/JoinHunt/UPDATE_TEAM_NAME";
export const UPDATE_TEAM_NAME_SUCCEEDED =
  "scavr/JoinHunt/UPDATE_TEAM_NAME_SUCCESS";
export const UPDATE_TEAM_NAME_FAILED = "scavr/JoinHunt/UPDATE_TEAM_NAME_FAILED";
