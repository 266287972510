import { UserSchema } from "./User";
import { schema } from "normalizr";

// import log from "loglevel";

export const HuntSchema = new schema.Entity(
  "hunts",
  {
    createdBy: UserSchema,
  },
  {
    idAttribute: "objectId",
  }
);
