import React from "react";
import { Button, Card, Placeholder } from "semantic-ui-react";

export default class PlaceholderAttemptCard extends React.PureComponent {
  render() {
    return (
      <Card>
        <Card.Content>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line length="very short" />
              <Placeholder.Line length="medium" />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </Card.Content>
        <Card.Content extra>
          <Button.Group fluid>
            <Button basic disabled color="green">
              Approve
            </Button>
            <Button basic disabled color="red">
              Reject
            </Button>
          </Button.Group>
        </Card.Content>
      </Card>
    );
  }
}
