import { fromJS } from "immutable";
import { LOAD_HUNT_CHALLENGES_SUCCESS } from "containers/Challenges/constants";
import {
  TEAMS_LOADED,
  CHALLENGE_ATTEMPTS_LOADED,
  CHALLENGE_ATTEMPT_DIFF_LOADED
} from "containers/ManageHunt/constants";
import { LOADING_LEADERBOARD_DATA } from "redux/constants";

const initialState = fromJS({
  loadingTeams: false,
  loadingChallenges: false,
  loadingAttempts: false
});

function leaderboardReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_HUNT_CHALLENGES_SUCCESS:
      return state.set("loadingChallenges", false);
    case TEAMS_LOADED:
      return state.set("loadingTeams", false);
    case CHALLENGE_ATTEMPTS_LOADED:
    case CHALLENGE_ATTEMPT_DIFF_LOADED:
      return state.set("loadingAttempts", false);
    case LOADING_LEADERBOARD_DATA:
      return state
        .set("loadingChallenges", true)
        .set("loadingTeams", true)
        .set("loadingAttempts", true);
    default:
      break;
  }
  return state;
}

export default leaderboardReducer;
