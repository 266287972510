import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import _ from "lodash";
import { reduxForm } from "redux-form/immutable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Dropdown, Popup, Icon, List, Divider } from "semantic-ui-react";
import GooglePlacesSearchInput from "components/GoogleMaps/GooglePlacesSearchInput";
import GoogleChallengeMap from "components/GoogleMaps/GoogleGeoPointChallengeMap";

import Field from "components/Field/index";
import {
  CREATE_EDIT_CHALLENGE_FORM_NAME,
  CHALLENGE_TYPES as challengeTypes
} from "../constants";
import { required } from "utils/validators";

import { createStructuredSelector } from "reselect";
import {
  selectChallengeType,
  selectShouldShowMap,
  selectShouldShowPassword,
  selectErrors,
  selectLatitude,
  selectLongitude,
  selectRadius,
  selectHasPoints,
  selectChallengeFormInitialValues
} from "../selectors";
import { selectHuntCapabilityChallengeTypes } from "../../../redux/selectors";

class CreateEditChallengeForm extends React.Component {
  handleGeopointMoved = (lat, lon) => {
    this.props.change("latitude", lat);
    this.props.change("longitude", lon);
  };

  handleRadiusChanged = radius => {
    this.props.change("radius", _.toNumber(radius));
  };

  handleLocationSelected = placeObject => {
    const lat = placeObject.geometry.location.lat();
    const lon = placeObject.geometry.location.lng();
    this.handleGeopointMoved(Number(lat), Number(lon));
  };

  render() {
    const { availableChallengeTypes } = this.props;

    const challengeTypeOptions = challengeTypes.map(
      ({ icon, value, key, text }) => {
        const isLocked = !availableChallengeTypes.includes(value);
        return {
          icon: isLocked ? "lock" : icon,
          disabled: isLocked,
          key,
          text,
          value
        };
      }
    );
    return (
      <Form error={!!this.props.errors}>
        <Form.Field required>
          <label>
            Challenge Type <span> </span>
            <Popup
              size="small"
              wide
              header="Challenge Types"
              on="hover"
              trigger={<Icon name="question circle outline" />}
              content={
                <div>
                  <Divider hidden />
                  <List>
                    {challengeTypes.map(c => (
                      <List.Item key={c.key + "_desc"}>
                        <Icon name={c.icon} />
                        <List.Content>
                          <List.Header>{c.text}</List.Header>
                          <List.Description>{c.description}</List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </div>
              }
            />
          </label>
          <Field
            name="type"
            component={Dropdown}
            placeholder="Pick a Challenge Type"
            selection={true}
            compact
            options={challengeTypeOptions}
            required
            validate={[required]}
            fluid
          />
        </Form.Field>
        <Form.Field required>
          <label>Challenge Name</label>
          <Field
            name="name"
            component={Form.Input}
            placeholder="A Creative Challenge Name"
            validate={[required]}
          />
        </Form.Field>
        <Form.Field required>
          <label>Details</label>
          <Field
            name="description"
            component="textarea"
            placeholder="How does someone complete this challenge?"
            required
            validate={[required]}
          />
        </Form.Field>
        {this.props.hasPoints && (
          <Form.Field required>
            <label>Points</label>
            <Field
              name="points"
              type="number"
              component={Form.Input}
              placeholder={0}
              normalize={value => (_.isEmpty(value) ? "" : Number(value))}
              validate={[required]}
            />
          </Form.Field>
        )}
        {this.props.showPassword && (
          <Form.Field required>
            <label>Password Answer</label>
            <Field
              name="password"
              component={Form.Input}
              placeholder="solveme"
              validate={[required]}
            />
          </Form.Field>
        )}
        {this.props.showMap && (
          <div>
            <Divider />
            <Form.Group widths="equal">
              <Field
                name="latitude"
                label="Latitude"
                placeholder="Latitude"
                component={Form.Input}
                required
                normalize={value => Number(value)}
                validate={[required]}
              />
              <Field
                name="longitude"
                label="Longitude"
                placeholder="Longitude"
                component={Form.Input}
                required
                normalize={value => Number(value)}
                validate={[required]}
              />
              <Field
                name="radius"
                label="Radius (meters)"
                type="number"
                placeholder="Radius"
                component={Form.Input}
                required
                normalize={value => Number(value)}
                validate={[required]}
              />
            </Form.Group>
            <Form.Field>
              <label>Search for a Location</label>
              <GooglePlacesSearchInput
                handlePlaceSelected={this.handleLocationSelected}
              />
            </Form.Field>
            <Divider hidden />
            <GoogleChallengeMap
              center={{
                lat: this.props.latitude,
                lng: this.props.longitude
              }}
              challengeLocation={{
                lat: this.props.latitude,
                lng: this.props.longitude
              }}
              challengeRadius={this.props.radius}
              onCenterChanged={this.handleGeopointMoved}
              onRadiusChanged={this.handleRadiusChanged}
              editable={true}
            />
            {/*<MapBoxMap*/}
            {/*  longitude={this.props.longitude}*/}
            {/*  latitude={this.props.latitude}*/}
            {/*  radius={this.props.radius}*/}
            {/*  readOnly={false}*/}
            {/*  onGeopointMoved={this.handleGeopointMoved}*/}
            {/*  onRadiusChanged={this.handleRadiusChanged}*/}
            {/*/>*/}
          </div>
        )}
      </Form>
    );
  }
}

CreateEditChallengeForm.propTypes = {
  change: PropTypes.func.isRequired,

  challenge: PropTypes.object,
  availableChallengeTypes: PropTypes.array.isRequired,
  hasPoints: PropTypes.bool.isRequired,
  challengeType: PropTypes.string,
  showPassword: PropTypes.bool.isRequired,
  showMap: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  radius: PropTypes.number
};

const ReduxCreateEditChallengeForm = reduxForm({
  form: CREATE_EDIT_CHALLENGE_FORM_NAME
})(CreateEditChallengeForm);

const mapStateToProps = createStructuredSelector({
  challengeType: selectChallengeType,
  showPassword: selectShouldShowPassword,
  showMap: selectShouldShowMap,
  latitude: selectLatitude,
  longitude: selectLongitude,
  radius: selectRadius,
  errors: selectErrors,
  hasPoints: selectHasPoints,
  initialValues: selectChallengeFormInitialValues,
  availableChallengeTypes: selectHuntCapabilityChallengeTypes
});

export default withRouter(
  connect(mapStateToProps)(ReduxCreateEditChallengeForm)
);
