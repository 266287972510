import log from "loglevel"; // eslint-disable-line no-unused-vars

export const getPlaceDetails = placeId => {
  if (!window.google) {
    log.error("Google places not loaded");
    return;
  }

  const promise = new Promise(function(resolve) {
    const callback = (result, status) => {
      log.debug("place details: ", result, status);
      resolve(result);
    };

    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    placesService.getDetails(
      {
        placeId
      },
      callback
    );
  });

  return promise;
};

export const getAddressObjectFromPlace = placeObject => {
  const resultValue = fieldName => {
    const fieldObjects = placeObject.address_components.filter(c =>
      c.types.includes(fieldName)
    );
    if (fieldObjects.length > 0) {
      return fieldObjects[0].long_name;
    } else {
      return null;
    }
  };

  const streetNumber = resultValue("street_number");
  const route = resultValue("route");
  let streetAddr = [];
  if (streetNumber) {
    streetAddr.push(streetNumber);
  }
  if (route) {
    streetAddr.push(route);
  }
  const streetAddress1 = streetAddr.join(" ");
  const streetAddress2 = resultValue("subpremise");
  const city = resultValue("locality") || resultValue("sublocality");
  const state = resultValue("administrative_area_level_1");
  const zip = resultValue("postal_code");
  const country = resultValue("country");

  return {
    locationName: placeObject.name,
    streetAddress1,
    streetAddress2,
    city,
    state,
    zip,
    country,
    point: {
      lat: placeObject.geometry.location.lat(),
      lng: placeObject.geometry.location.lng()
    }
  };
};
