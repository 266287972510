import React from "react";
import { Header } from "semantic-ui-react";

class ManageManagers extends React.PureComponent {
  render() {
    return (
      <div>
        <Header>Managers</Header>
      </div>
    );
  }
}

export default ManageManagers;
