// export const LOAD_HUNT = "scavr/ManageHunt/LOAD_HUNT";
// export const LOAD_HUNT_FAILURE = "scavr/ManageHunt/LOAD_HUNT_FAILURE";
// export const LOAD_HUNT_SUCCESS = "scavr/ManageHunt/LOAD_HUNT_SUCCESS";

export const UPDATE_HUNT = "scavr/ManageHunt/UPDATE_HUNT";
export const UPDATE_HUNT_SUCCESS = "scavr/ManageHunt/UPDATE_HUNT_SUCCESS";
export const UPDATE_HUNT_FAILURE = "scavr/ManageHunt/UPDATE_HUNT_FAILURE";

export const DELETE_HUNT = "scavr/ManageHunt/DELETE_HUNT";
export const DELETE_HUNT_SUCCESS = "scavr/ManageHunt/DELETE_HUNT_SUCCESS";
export const DELETE_HUNT_FAILURE = "scavr/ManageHunt/DELETE_HUNT_FAILED";

export const COPY_HUNT = "scavr/ManageHunt/COPY_HUNT";
export const COPY_HUNT_SUCCESS = "scavr/ManageHunt/COPY_HUNT_SUCCESS";
export const COPY_HUNT_FAILURE = "scavr/ManageHunt/COPY_HUNT_FAILED";

export const PARTICIPANTS_LOADED = "scavr/ManageHunt/PARTICIPANTS_LOADED";
export const TEAMS_LOADED = "scavr/ManageHunt/TEAMS_LOADED";
export const CHALLENGE_ATTEMPTS_LOADED =
  "scavr/ManageHunt/CHALLENGE_ATTEMPTS_LOADED";

export const CHALLENGE_ATTEMPT_DIFF_LOADED =
  "scavr/ManageHunt/CHALLENGE_ATTEMPT_DIFF_LOADED";

export const MANAGE_HUNT_DETAILS_FORM_NAME = "manageHuntDetailsForm";

export const UPDATING_HUNT_LOGO = "scavr/ManageHunt/UPDATING_HUNT_LOGO";
export const UPDATING_HUNT_LOGO_PROGRESS =
  "scavr/ManageHunt/UPDATING_HUNT_LOGO_PROGRESS";
export const UPDATING_HUNT_LOGO_COMPLETE =
  "scavr/ManageHunt/UPDATING_HUNT_LOGO_COMPLETE";
