/*
 *
 * Signup reducer
 *
 */

import { fromJS } from "immutable";
import {
  SIGNUP_CHANGE_EMAIL,
  SIGNUP_CHANGE_PASSWORD,
  SIGNUP_CHANGE_FIRST_NAME,
  SIGNUP_CHANGE_LAST_NAME,
} from "./constants";

import {
  EMAIL_SIGNUP,
  EMAIL_SIGNUP_SUCCESS,
  EMAIL_SIGNUP_FAILURE,
} from "reducers/AuthReducer";

const initialState = fromJS({
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  loading: false,
  error: null,
});

function signupReducer(state = initialState, action) {
  // eslint-disable-next-line no-var
  var errorMessage = action.error;
  switch (action.type) {
    case SIGNUP_CHANGE_EMAIL:
      return state.set("email", action.email);
    case SIGNUP_CHANGE_PASSWORD:
      return state.set("password", action.password);
    case SIGNUP_CHANGE_FIRST_NAME:
      return state.set("firstName", action.firstName);
    case SIGNUP_CHANGE_LAST_NAME:
      return state.set("lastName", action.lastName);
    case EMAIL_SIGNUP:
      return state.set("loading", true).set("error", null);
    case EMAIL_SIGNUP_FAILURE:
      if (errorMessage === "Account already exists for this username.") {
        errorMessage = "Account already exists for this email address.";
      }
      return state.set("loading", false).set("error", errorMessage);
    case EMAIL_SIGNUP_SUCCESS:
      return state
        .set("loading", false)
        .set("error", null)
        .set("email", "")
        .set("password", "")
        .set("firstName", "")
        .set("lastName", "");
    default:
      return state;
  }
}

export default signupReducer;
