import React from "react";
import PropTypes from "prop-types";
import { Card, Button, Image, List, Divider } from "semantic-ui-react";

import {
  HUNT_TYPE_RACE,
  HUNT_TYPE_SCAVENGER_HUNT,
  HUNT_TYPE_TREASURE_HUNT,
  HUNT_LEVEL_BASIC
} from "../constants";

import timer from "images/timer.png";
import compass from "images/compass.png";
import treasuremap from "images/treasuremap.png";

import log from "loglevel"; // eslint-disable-line no-unused-vars

class HuntTypeCard extends React.PureComponent {
  render() {
    const {
      huntType,
      huntSKUShortCode,
      name,
      summary,
      description,
      details,
      onUpgrade,
      onSelect,
      disabled,
      image
    } = this.props;

    log.debug(
      "upgrade available:",
      HUNT_LEVEL_BASIC,
      HUNT_TYPE_SCAVENGER_HUNT,
      huntSKUShortCode,
      huntType
    );

    const upgradeAvailable =
      huntSKUShortCode === HUNT_LEVEL_BASIC &&
      huntType !== HUNT_TYPE_SCAVENGER_HUNT;
    return (
      <Card>
        <Card.Content>
          <Image floated="right" src={image} size="mini" />
          <Card.Header>{name}</Card.Header>
          <Card.Meta>{summary}</Card.Meta>
          <Card.Description>
            {description}
            <Divider hidden />
            <List as="ul">
              {details.map((deet, index) => (
                <List.Item key={index} as="li">
                  {deet}
                </List.Item>
              ))}
            </List>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button
            fluid
            color={upgradeAvailable ? "green" : "blue"}
            onClick={() =>
              upgradeAvailable ? onUpgrade(huntType) : onSelect(huntType)
            }
            disabled={disabled}
          >
            {disabled
              ? "Coming Soon"
              : upgradeAvailable
              ? "Upgrade to Unlock"
              : "Create a " + name}
          </Button>
        </Card.Content>
      </Card>
    );
  }
}

HuntTypeCard.propTypes = {
  name: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  details: PropTypes.array,
  huntType: PropTypes.string.isRequired,
  huntSKUShortCode: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUpgrade: PropTypes.func,
  image: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

const huntInfos = [
  {
    name: "Race",
    summary: "Timed Event",
    description: "The first team to complete all the challenges is the winner.",
    details: [
      "Teams can complete challenges in any order.",
      "No point values. Teams must complete all challenges to win."
    ],
    huntType: HUNT_TYPE_RACE,
    image: timer,
    disabled: true
  },
  {
    name: "Scavenger Hunt",
    summary: "Go for Points",
    description:
      "The classic scavenger hunt. When the hunt is over, the team with the most points wins.",
    details: [
      "Teams can complete challenges in any order.",
      "Each challenge can have a point value."
    ],
    huntType: HUNT_TYPE_SCAVENGER_HUNT,
    image: compass,
    disabled: false
  },
  {
    name: "Treasure Hunt",
    summary: "Find the Goal",
    description: "Follow clues to get to the end.",
    details: ["Solving a clue unlocks the next one.", "No point values."],
    huntType: HUNT_TYPE_TREASURE_HUNT,
    image: treasuremap,
    disabled: true
  }
];

class ChooseHuntType extends React.PureComponent {
  render() {
    return (
      <Card.Group textAlign="center" itemsPerRow={3} stackable>
        {huntInfos.map(huntInfo => (
          <HuntTypeCard
            key={huntInfo.huntType}
            {...huntInfo}
            onSelect={this.props.onSelect}
            onUpgrade={this.props.onChangeHuntLevel}
            huntSKUShortCode={this.props.huntSKUShortCode}
          />
        ))}
      </Card.Group>
    );
  }
}

ChooseHuntType.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onChangeHuntLevel: PropTypes.func.isRequired,
  huntSKUShortCode: PropTypes.string.isRequired
};

export default ChooseHuntType;
