import { normalize } from "normalizr";
import { ChallengeSchema } from "models/Challenge";
import {
  WATCHING_CHALLENGES,
  LOAD_HUNT_CHALLENGES,
  LOAD_HUNT_CHALLENGES_FAILURE,
  SAVE_CHALLENGE,
  CREATE_CHALLENGE,
  CREATE_CHALLENGE_SUCCESS,
  CREATE_CHALLENGE_FAILURE,
  REORDER_CHALLENGE,
  REORDER_CHALLENGE_SUCCESS,
  REORDER_CHALLENGE_FAILURE,
  SAVE_CHALLENGE_SUCCESS,
  SAVE_CHALLENGE_FAILURE,
  DELETE_CHALLENGE,
  DELETE_CHALLENGE_FAILURE,
  DELETE_CHALLENGE_SUCCESS,
} from "./constants";
import { ANALYTICS_TRACK } from "analytics/constants";

import log from "loglevel"; // eslint-disable-line no-unused-vars

export function watchChallenges(huntId) {
  return {
    type: WATCHING_CHALLENGES,
    payload: {
      huntId,
    },
  };
}

export function loadHuntChallenges(huntId) {
  return {
    type: LOAD_HUNT_CHALLENGES,
    payload: {
      huntId,
    },
  };
}
export function loadHuntChallengesFailure(error) {
  return {
    type: LOAD_HUNT_CHALLENGES_FAILURE,
    error,
  };
}

export function createChallenge(challengeData, huntId) {
  return {
    type: CREATE_CHALLENGE,
    payload: {
      challengeData,
      huntId,
    },
  };
}

export function createChallengeSucceeded(challenge) {
  const normalized = normalize(challenge, ChallengeSchema);
  return {
    type: CREATE_CHALLENGE_SUCCESS,
    payload: normalized,
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "Manage.ChallengeCreated",
        payload: {
          challenge: challenge,
        },
      },
    },
  };
}

export function createChallengeFailed(error) {
  return {
    type: CREATE_CHALLENGE_FAILURE,
    error,
  };
}

export function deleteChallenge(challenge) {
  return {
    type: DELETE_CHALLENGE,
    payload: {
      challenge,
    },
  };
}

export function deleteChallengeSucceeded(deletedChallenge) {
  const normalized = normalize(deletedChallenge, ChallengeSchema);
  return {
    type: DELETE_CHALLENGE_SUCCESS,
    payload: normalized,
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "Manage.ChallengeDeleted",
        payload: {
          challenge: deletedChallenge,
        },
      },
    },
  };
}

export function deleteChallengeFailed(error) {
  return {
    type: DELETE_CHALLENGE_FAILURE,
    error,
  };
}

export function updateChallenge(challenge, newChallengeData) {
  return {
    type: SAVE_CHALLENGE,
    payload: {
      challenge,
      newChallengeData,
    },
  };
}

export function updateChallengeSucceeded(challenge, oldChallenge) {
  const normalized = normalize(challenge, ChallengeSchema);
  return {
    type: SAVE_CHALLENGE_SUCCESS,
    payload: normalized,
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "Manage.ChallengeUpdated",
        payload: {
          challenge: challenge,
          oldChallenge: oldChallenge,
        },
      },
    },
  };
}

export function updateChallengeFailed(error) {
  return {
    type: SAVE_CHALLENGE_FAILURE,
    error,
  };
}

export function reorderChallenge(challengeId, huntId, oldIndex, newIndex) {
  return {
    type: REORDER_CHALLENGE,
    payload: {
      challengeId,
      huntId,
      oldIndex,
      newIndex,
    },
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "Manage.ChallengeReOrder",
        payload: {
          challengeId,
          huntId,
          oldIndex,
          newIndex,
        },
      },
    },
  };
}

export function reorderChallengeSucceeded() {
  log.debug("reorder success");
  return {
    type: REORDER_CHALLENGE_SUCCESS,
  };
}

export function reorderChallengeFailed(error) {
  return {
    type: REORDER_CHALLENGE_FAILURE,
    error,
  };
}
