import React from "react";
import PropTypes from "prop-types";
import log from "loglevel";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import * as firebaseui from "firebaseui";
import firebase from "firebase/app";
import "firebase/auth";
import { Grid, Header, Segment } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import Login from "containers/Login";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "redirect",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/",
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    {
      provider: "apple.com",
      providerName: "Apple",
      buttonColor: "#000000",
      iconUrl:
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_PUBLIC_URL + "/images/Apple%20White%20Logo.png",
      scopes: ["name", "email"]
    }
  ],
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

class FirebaseAuth extends React.Component {
  render() {
    log.debug("Rendering firebase auth.");
    const { useEmulator } = this.props;

    if (useEmulator) {
      return <Login />;
    }

    return (
      <Grid verticalAlign="middle" centered style={{ height: "100vh" }}>
        <Helmet
          title="Login"
          meta={[{ name: "description", content: "Login to Scavr" }]}
        />
        <Grid.Column computer={6} tablet={8} mobile={14}>
          <Header as="h2" textAlign="center">
            <Header.Content>
              <h2>Sign In or Sign Up</h2>
            </Header.Content>
          </Header>
          <Segment.Group>
            <Segment.Group horizontal>
              <Segment textAlign="center">
                <StyledFirebaseAuth
                  uiConfig={uiConfig}
                  firebaseAuth={firebase.auth()}
                />
              </Segment>
            </Segment.Group>
          </Segment.Group>
        </Grid.Column>
      </Grid>
    );
  }
}

FirebaseAuth.propTypes = {
  useEmulator: PropTypes.bool
};

export default FirebaseAuth;
