import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createStructuredSelector } from "reselect";
import { MediaContextProvider, Media } from "components/Responsive/Media";
import PropTypes from "prop-types";

import { logout } from "reducers/AuthReducer";

import DesktopNav from "./GlobalNavDesktop";
import MobileNav from "./GlobalNavMobile";

import { selectUserInfo } from "./selectors";

import log from "loglevel";

class GlobalNav extends React.PureComponent {
  leftItems = [
    { content: "Manage Hunts", key: "hunts", to: "/hunts" }
    // { content: "Join a Hunt", key: "join", to: "/join" },
  ];

  rightItems = [
    {
      content: "Sign Out",
      key: "signout",
      onClick: this.props.onSignOutEvent
    }
  ];

  state = {
    visible: false
  };

  handlePusher = () => {
    const { visible } = this.state;

    if (visible) this.setState({ visible: false });
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  render() {
    return (
      <MediaContextProvider>
        <Media at="sm">
          <MobileNav
            location={this.props.location}
            leftItems={this.leftItems}
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            rightItems={this.rightItems}
            visible={this.state.visible}
            onSignOut={this.props.onSignOutEvent}
          />
        </Media>
        <Media greaterThanOrEqual="md">
          <DesktopNav
            location={this.props.location}
            leftItems={this.leftItems}
            rightItems={this.rightItems}
            userInfo={this.props.userInfo}
            onSignOut={this.props.onSignOutEvent}
          />
        </Media>
      </MediaContextProvider>
    );
  }
}

GlobalNav.propTypes = {
  onSignOutEvent: PropTypes.func.isRequired,
  location: PropTypes.object,
  userInfo: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  userInfo: selectUserInfo
});

function mapDispatchToProps(dispatch) {
  return {
    onSignOutEvent: evt => {
      log.debug("Log out event fired", evt);
      dispatch(logout());
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GlobalNav)
);
