import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import log from "loglevel"; // eslint-disable-line
import {
  Header,
  Divider,
  Input,
  Placeholder,
  Segment,
  Form,
} from "semantic-ui-react";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { deleteHunt } from "containers/ManageHunt/actions";
import RemoteConfig from "RemoteConfig";
import { RC_FEED_FEATURE } from "RemoteConfig/constants";
import { loadHunt } from "redux/actionCreators";
import { selectJoinHuntURL } from "containers/ManageHunt/selectors";
import CopyableInput from "components/CopyableInput";
import {
  selectIsDeleting,
  selectIsCopying,
  selectIsLoadingSettings,
} from "./selectors";
import { HUNT_STATE_UNPUBLISHED } from "models/constants";
import {
  selectCurrentHunt,
  selectCurrentHuntId,
  selectCurrentHuntSettings,
} from "redux/selectors";
import DeleteHuntButton from "./components/deleteHunt";
// import CopyHuntButton from "./components/copyHunt";
import FeedSettings from "./components/feedSettings";
import { loadHuntSettings, updateHuntSetting } from "redux/actionCreators";
import {
  FEED_OPTION_ATTEMPTSTOSHOW,
  FEED_OPTION_AUTO_ENABLE,
  FEED_OPTION_ENABLED,
} from "containers/Feed/constants";

class ManageSettings extends React.PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.hunt) {
      this.props.loadHunt(this.props.huntId);
    }
  }

  componentDidMount() {
    this.props.loadSettings(this.props.huntId);
  }

  state = { deleteModalOpen: false, copyModalOpen: false };

  handleDeleteOpen = () => {
    this.setState({ deleteModalOpen: true });
  };

  handleDeleteClose = () => {
    this.setState({ deleteModalOpen: false });
  };

  handleCopyOpen = () => {
    this.setState({ copyModalOpen: true });
  };

  handleCopyClose = () => {
    this.setState({ copyModalOpen: false });
  };

  onClickDelete = () => {
    this.props.deleteHunt(this.props.hunt.objectId);
  };

  handleSettingUpdated = (key, newValue) => {
    const { huntId } = this.props;
    this.props.updateHuntSetting(huntId, key, newValue);
  };

  render() {
    const { hunt, isDeleting, isLoadingSettings, huntSettings } = this.props;
    const { deleteModalOpen } = this.state;

    const feedTabConfig = RemoteConfig.getStringValue(RC_FEED_FEATURE);
    const showFeedTab = feedTabConfig === "on";

    if (!hunt) {
      return (
        <div>
          <div />
          <Header as="h2">Hunt Settings</Header>

          <Segment>
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Segment>
        </div>
      );
    }

    let {
      feedEnabled,
      feedAttemptsToShow,
      feedAutoEnableAfterComplete,
    } = huntSettings ? huntSettings : {};

    return (
      <div>
        <div />
        <Header as="h2">Hunt Settings</Header>

        <Segment>
          <Header as="h3">General</Header>
          <div className="ui form">
            <Form.Field className="field">
              <label>Hunt Type</label>
              <Input
                fluid
                readOnly={true}
                transparent
                value={_.startCase(_.camelCase(hunt.type))}
              />
            </Form.Field>
            <Form.Field className="field">
              <label>Feature Level</label>
              <Input
                fluid
                readOnly={true}
                transparent
                value={_.startCase(
                  _.camelCase(hunt.skuName ? hunt.skuName : hunt.priceLevel)
                )}
              />
            </Form.Field>
            {hunt.status === HUNT_STATE_UNPUBLISHED ? (
              ""
            ) : (
              <CopyableInput
                label="Hunt Sign-Up Link"
                value={this.props.joinHuntURL}
                fluid
              />
            )}

            {showFeedTab && (
              <div>
                <Divider />
                <Header as="h3">Feed Settings</Header>
                <FeedSettings
                  feedEnabled={feedEnabled}
                  attemptsToShow={feedAttemptsToShow}
                  autoEnableAfterComplete={feedAutoEnableAfterComplete}
                  loading={isLoadingSettings}
                  onToggleEnabled={() => {
                    this.handleSettingUpdated(
                      FEED_OPTION_ENABLED,
                      !feedEnabled
                    );
                  }}
                  onChangeAttemptsToShow={(e, t) => {
                    this.handleSettingUpdated(
                      FEED_OPTION_ATTEMPTSTOSHOW,
                      t.value
                    );
                  }}
                  onToggleAutoEnable={() => {
                    this.handleSettingUpdated(
                      FEED_OPTION_AUTO_ENABLE,
                      !feedAutoEnableAfterComplete
                    );
                  }}
                />
              </div>
            )}

            {/*<Divider />*/}
            {/*<Header as="h3">Copy Hunt</Header>*/}
            {/*<p>Make a copy of this hunt.</p>*/}
            {/*<CopyHuntButton*/}
            {/*  handleCopyOpen={this.handleCopyOpen}*/}
            {/*  isCopying={isCopying}*/}
            {/*  handleCopyClose={this.handleCopyClose}*/}
            {/*  copyModalOpen={copyModalOpen}*/}
            {/*/>*/}

            <Divider />
            <Header as="h3">Delete Hunt</Header>
            <DeleteHuntButton
              onClickDelete={this.onClickDelete}
              isDeleting={isDeleting}
              deleteModalOpen={deleteModalOpen}
              handleDeleteClose={this.handleDeleteClose}
              handleDeleteOpen={this.handleDeleteOpen}
            />
          </div>
        </Segment>
      </div>
    );
  }
}

ManageSettings.propTypes = {
  loadHunt: PropTypes.func,
  loadSettings: PropTypes.func.isRequired,
  updateHuntSetting: PropTypes.func.isRequired,

  huntId: PropTypes.string.isRequired,
  hunt: PropTypes.object,
  huntSettings: PropTypes.object,

  deleteHunt: PropTypes.func,
  isDeleting: PropTypes.bool,
  isCopying: PropTypes.bool,
  isLoadingSettings: PropTypes.bool,
  joinHuntURL: PropTypes.string,
};

const mapStatetoProps = createStructuredSelector({
  huntId: selectCurrentHuntId,
  hunt: selectCurrentHunt,
  huntSettings: selectCurrentHuntSettings,
  isDeleting: selectIsDeleting,
  isCopying: selectIsCopying,
  isLoadingSettings: selectIsLoadingSettings,
  joinHuntURL: selectJoinHuntURL,
});

const mapDispatchToProps = (dispatch) => ({
  loadHunt: (huntId) => dispatch(loadHunt(huntId)),
  loadSettings: (huntId) => dispatch(loadHuntSettings(huntId)),
  deleteHunt: (huntId) => {
    dispatch(deleteHunt(huntId));
  },
  updateHuntSetting: (huntId, key, value) =>
    dispatch(updateHuntSetting(huntId, key, value)),
});

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(ManageSettings)
);
