import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import { Header, Button, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import DataProvider from "DataProvider";

import {
  selectCurrentChallenges,
  selectLoading,
  makeSelectError
} from "./selectors";
import {
  selectCurrentHunt,
  selectHuntCapabilityChallengeLimit
} from "redux/selectors";
import { loadHunt } from "redux/actionCreators";
import { loadHuntChallengesSuccess } from "containers/ManageHunt/actions";
import { createChallenge, reorderChallenge, watchChallenges } from "../actions";

import ChallengesList from "./ChallengesList";
import CreateChallengeModal from "containers/Challenges/CreateChallengeModal/index";

import "./index.css";

class ManageChallenges extends React.PureComponent {
  handleChallengeCreated = challengeData => {
    let cData = challengeData.toJS();
    cData["sortIndex"] = this.props.challenges.length;
    log.debug("chal data", cData);
    this.props.createChallenge(cData, this.props.hunt.objectId);
  };

  handleChallengeReordered = (challengeId, oldIndex, newIndex) => {
    if (oldIndex === newIndex) {
      return;
    }
    this.props.reOrderChallenge(
      challengeId,
      this.props.hunt.objectId,
      oldIndex,
      newIndex
    );
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.hunt) {
      this.props.loadHunt(this.props.match.params.huntId);
    }
  }

  UNSAFE_componentWillMount() {
    this.props.startLoading(this.props.match.params.huntId);
    this.challengeListener = DataProvider.instance().watchChallenges(
      this.props.match.params.huntId,
      this.props.onChallengesUpdated
    );
  }

  componentWillUnmount() {
    DataProvider.instance().stopWatching(this.challengeListener);
  }

  render() {
    const { hunt, challenges, challengeLimit } = this.props;

    if (!hunt) {
      return null;
    }

    let challengeLimitCount = challengeLimit;
    if (challengeLimit == null) {
      if (hunt.priceLevel.toLowerCase() === "basic") {
        challengeLimitCount = 25;
      } else if (hunt.priceLevel.toLowerCase() === "enhanced") {
        challengeLimitCount = 100;
      } else {
        challengeLimitCount = 10;
      }
    }

    const atChallengeLimit = challenges.length >= challengeLimitCount;

    const addChallengeButton = atChallengeLimit ? (
      <Button size="small" floated="right" basic>
        Add a Challenge
      </Button>
    ) : (
      <Button primary size="small" floated="right">
        Add a Challenge
      </Button>
    );

    const limitMessage = hunt.skuName
      ? `With a ${hunt.skuName} you may only have ${challengeLimitCount} challenges`
      : `With a ${hunt.priceLevel} hunt you may only have ${challengeLimitCount} challenges`;

    return (
      <div className="manage-challenges">
        {atChallengeLimit ? (
          <Popup content={limitMessage} trigger={addChallengeButton} />
        ) : (
          <CreateChallengeModal
            triggerElement={addChallengeButton}
            onCreateChallenge={this.handleChallengeCreated}
          />
        )}
        <Header as="h2">Challenges</Header>
        <Header sub style={{ marginTop: "5px" }}>
          {this.props.challenges.length} total challenges
        </Header>
        <ChallengesList
          challenges={this.props.challenges}
          loading={this.props.loading}
          error={this.props.error}
          handleResortChallenge={this.handleChallengeReordered}
          handleChallengeCreated={this.handleChallengeCreated}
        />
      </div>
    );
  }
}

ManageChallenges.propTypes = {
  match: PropTypes.object.isRequired,
  hunt: PropTypes.object,
  challenges: PropTypes.array,
  challengeLimit: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,

  startLoading: PropTypes.func,
  loadHunt: PropTypes.func,
  createChallenge: PropTypes.func,
  reOrderChallenge: PropTypes.func,
  onChallengesUpdated: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  hunt: selectCurrentHunt,
  challenges: selectCurrentChallenges,
  challengeLimit: selectHuntCapabilityChallengeLimit,
  loading: selectLoading,
  error: makeSelectError()
});

const mapDispatchToProps = dispatch => ({
  startLoading: huntId => dispatch(watchChallenges(huntId)),
  loadHunt: huntId => dispatch(loadHunt(huntId)),
  createChallenge: (challengeData, huntId) =>
    dispatch(createChallenge(challengeData, huntId)),
  reOrderChallenge: (challengeId, huntId, oldIndex, newIndex) =>
    dispatch(reorderChallenge(challengeId, huntId, oldIndex, newIndex)),
  onChallengesUpdated: (huntId, challenges) =>
    dispatch(loadHuntChallengesSuccess(huntId, challenges))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageChallenges)
);
