import log from "loglevel";
import { loadStripe } from "@stripe/stripe-js";
import {
  loadModelForDocumentSnapshot,
  loadModelsForQuerySnapshot
} from "./firebaseModels";
import firebaseUtils from "./firebaseUtils";
import { HUNT_STATE_LIST_ENABLED } from "models/constants";
import {
  FIREBASE_COLLECTION_HUNTS,
  FIREBASE_FUNCTION_CREATE_CHECKOUT_SESSION,
  FIREBASE_FUNCTION_CREATE_HUNT,
  FIREBASE_FUNCTION_CREATE_HUNT_WITH_SKU,
  FIREBASE_FUNCTION_DELETE_HUNT,
  FIREBASE_FUNCTION_CREATE_CHECKOUT_SESSION_WITH_SKU,
  FIREBASE_DOCID_MANAGERINFO,
  FIREBASE_COLLECTION_INTERNAL,
  FIREBASE_STORAGE_LOGOFOLDER
} from "./constants";

// eslint-disable-next-line no-undef
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

const createHuntOld = data => {
  data["challengeCount"] = 0;
  return firebaseUtils
    .db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .add(data)
    .then(docRef => docRef.get())
    .then(docSnapshot => loadModelForDocumentSnapshot(docSnapshot))
    .catch(error => {
      log.error("Error creating hunt", error);
    });
};

const createHunt = async huntData => {
  const createHuntFunc = firebaseUtils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_CREATE_HUNT);

  try {
    const huntId = firebaseUtils
      .db()
      .collection(FIREBASE_COLLECTION_HUNTS)
      .doc().id;

    if (huntData.startTime) {
      huntData.startTime = huntData.startTime.getTime();
    }

    log.info("Creating hunt with data: ", huntData, "for ID", huntId);

    const body = {
      huntId,
      huntData
    };
    const result = await createHuntFunc(body);
    log.debug("Got result from creating hunt: ", result.data);

    const resultObj = result.data;

    if ("error" in resultObj) {
      return resultObj;
    }

    const huntRef = firebaseUtils
      .db()
      .collection(FIREBASE_COLLECTION_HUNTS)
      .doc(huntId);

    const newHuntSnapshot = await huntRef.get();

    if (!newHuntSnapshot.exists) {
      return {
        error: {
          message: "Failed to create your hunt. Please try again later."
        }
      };
    }

    const huntModel = await loadModelForDocumentSnapshot(newHuntSnapshot);

    return {
      hunt: huntModel
    };
  } catch (e) {
    log.error("Error creating hunt: ", e);
    throw e;
  }
};

const createHuntWithSKU = async huntData => {
  const createHuntFunc = firebaseUtils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_CREATE_HUNT_WITH_SKU);

  try {
    const huntId = firebaseUtils
      .db()
      .collection(FIREBASE_COLLECTION_HUNTS)
      .doc().id;

    if (huntData.startTime) {
      huntData.startTime = huntData.startTime.getTime();
    }

    log.info("Creating hunt with data: ", huntData, "for ID", huntId);

    const body = {
      huntId,
      huntData
    };
    const result = await createHuntFunc(body);
    log.debug("Got result from creating hunt: ", result.data);

    const resultObj = result.data;

    if ("error" in resultObj) {
      return resultObj;
    }

    const huntRef = firebaseUtils
      .db()
      .collection(FIREBASE_COLLECTION_HUNTS)
      .doc(huntId);

    const newHuntSnapshot = await huntRef.get();

    if (!newHuntSnapshot.exists) {
      return {
        error: {
          message: "Failed to create your hunt. Please try again later."
        }
      };
    }

    const huntModel = await loadModelForDocumentSnapshot(newHuntSnapshot);

    return {
      hunt: huntModel
    };
  } catch (e) {
    log.error("Error creating hunt: ", e);
    throw e;
  }
};

const loadHunt = huntId =>
  firebaseUtils
    .db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId)
    .get()
    .then(docSnapshot => loadModelForDocumentSnapshot(docSnapshot))
    .catch(error => {
      log.error("Error getting LoadHunt document:", error);
      return { err: error };
    });

const loadMyHunts = currentUser =>
  firebaseUtils
    .db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .where(`managers.${currentUser.uid}`, "==", true)
    .where("status", "in", HUNT_STATE_LIST_ENABLED)
    .get()
    .then(querySnapshot => loadModelsForQuerySnapshot(querySnapshot))
    .catch(error => {
      log.error("Error getting LoadMyHunts document:", error);
      return { err: error };
    });

const checkoutWithHunt = async huntId => {
  const createCheckoutSessionFunc = firebaseUtils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_CREATE_CHECKOUT_SESSION);
  const result = await createCheckoutSessionFunc({ huntId });

  const resultObj = result.data;
  if ("error" in resultObj) {
    log.error("Error creating checkout session: ", resultObj.error);
    return resultObj;
  }

  const { checkoutSessionId } = resultObj;

  const stripe = await stripePromise;
  const { error } = await stripe.redirectToCheckout({
    sessionId: checkoutSessionId
  });

  if (error) {
    log.debug("Error checking out: ", error);
    return error;
  }
};

const checkoutWithHuntAndSKU = async (huntId, skuId) => {
  const createCheckoutSessionFunc = firebaseUtils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_CREATE_CHECKOUT_SESSION_WITH_SKU);
  const result = await createCheckoutSessionFunc({ huntId, skuId });

  const resultObj = result.data;
  if ("error" in resultObj) {
    log.error("Error creating checkout session: ", resultObj.error);
    return resultObj;
  }

  const { checkoutSessionId } = resultObj;

  const stripe = await stripePromise;
  const { error } = await stripe.redirectToCheckout({
    sessionId: checkoutSessionId
  });

  if (error) {
    log.debug("Error checking out: ", error);
    return error;
  }
};

const deleteHunt = async huntId => {
  // We're going to outsource this work to a cloud service in order to make sure we clean up all the random stuff that goes with it.
  const deleteHunt = firebaseUtils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_DELETE_HUNT);
  try {
    return await deleteHunt({ huntId: huntId });
  } catch (e) {
    log.error("Error deleting hunt: ", e);
    throw e;
  }
};

const watchHuntArchiveInfo = (huntId, onInfoUpdated) => {
  const huntArchiveInfoRef = firebaseUtils
    .db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId)
    .collection(FIREBASE_COLLECTION_INTERNAL)
    .doc(FIREBASE_DOCID_MANAGERINFO);

  const listener = huntArchiveInfoRef.onSnapshot(docSnapshot => {
    const rawData = docSnapshot.data();
    if (rawData) {
      onInfoUpdated({
        ...rawData,
        archiveDate: rawData.archiveDate ? rawData.archiveDate.toDate() : null
      });
    }
  });

  return listener;
};

const getPlaceholderHuntLogo = async () => {
  const folderPrefix = FIREBASE_STORAGE_LOGOFOLDER;

  const stor = firebaseUtils.storage();
  const randomNumber = Math.floor(Math.random() * 100);
  const fileName = `${folderPrefix}/logo-${randomNumber}.png`;
  const fileRef = stor.ref(fileName);
  let result = await fileRef.getDownloadURL();

  log.info("Got download URL for logo: ", result);
  return {
    url: result,
    path: fileName
  };
};

// eslint-disable-next-line no-unused-vars
export async function copyHunt(huntId, newHuntName, newHuntPriceLevel) {}

const api = {
  createHuntOld,
  createHunt,
  createHuntWithSKU,
  checkoutWithHunt,
  checkoutWithHuntAndSKU,
  loadHunt,
  loadMyHunts,
  deleteHunt,
  watchHuntArchiveInfo,
  getPlaceholderHuntLogo
};

export default api;
