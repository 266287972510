import { createSelector } from "reselect";
import { fromJS } from "immutable";

/**
 * Direct selector to the login state domain
 */
export const selectCreateHuntDomain = state => state.createHunt;
const selectData = state => state.data;
const selectBasicHuntInfoForm = state => state.form.get("basicHuntInfo");

const selectStep = createSelector([selectCreateHuntDomain], state =>
  state.get("step")
);

const selectHuntType = createSelector([selectCreateHuntDomain], state =>
  state.get("huntType")
);

const selectHuntName = createSelector([selectBasicHuntInfoForm], form =>
  form ? form.getIn(["values", "huntName"], null) : null
);

const selectHuntLocationObject = createSelector(
  [selectBasicHuntInfoForm],
  form => (form ? form.getIn(["values", "location"], fromJS({})).toJS() : null)
);

const selectHuntLocationPoint = createSelector(
  [selectBasicHuntInfoForm],
  form => (form ? form.getIn(["values", "location", "point"], null) : null)
);

const selectHuntDescription = createSelector([selectBasicHuntInfoForm], form =>
  form ? form.getIn(["values", "description"], null) : null
);

const selectHuntImageURL = createSelector([selectBasicHuntInfoForm], form =>
  form ? form.getIn(["values", "huntImageURL"], null) : null
);

const selectHuntImagePath = createSelector([selectBasicHuntInfoForm], form =>
  form ? form.getIn(["values", "huntImagePath"], null) : null
);

const selectHuntStartDateTime = createSelector(
  [selectBasicHuntInfoForm],
  form => (form ? form.getIn(["values", "huntStartDateTime"], null) : null)
);

const selectHuntStartDate = createSelector([selectBasicHuntInfoForm], form =>
  form ? form.getIn(["values", "huntStartDate"], null) : null
);

const selectHuntStartTime = createSelector([selectBasicHuntInfoForm], form =>
  form ? form.getIn(["values", "huntStartTime"], null) : null
);

// const selectHuntLevel = createSelector(selectCreateHuntDomain, (state) =>
//   state.get("huntLevel")
// );

const selectHuntSKU = createSelector(
  [selectCreateHuntDomain, selectData],
  (createState, data) => {
    let skuId = createState.get("huntSKUId");
    let sku = data.getIn(["skus", skuId]);
    return sku ? sku.toJS() : null;
  }
);

const selectHuntSKUId = createSelector(selectCreateHuntDomain, state =>
  state.get("huntSKUId")
);

const selectHuntId = createSelector([selectCreateHuntDomain], state =>
  state.get("huntId")
);

const selectHuntSKUShortCode = createSelector(
  selectHuntSKUId,
  selectData,
  (skuId, data) => data.getIn(["skus", skuId, "shortCode"], null)
);

const selectHuntError = createSelector(
  [selectCreateHuntDomain],
  domain => domain.error
);

const selectSKUs = createSelector([selectCreateHuntDomain], createDomain => {
  let skus = createDomain.get("skus");
  return skus ? skus.toJS() : [];
});

const selectLoadingSKUs = createSelector(selectCreateHuntDomain, createDomain =>
  createDomain.get("loadingSKUs")
);

export {
  selectHuntId,
  selectSKUs,
  selectLoadingSKUs,
  selectHuntSKU,
  selectHuntSKUId,
  selectHuntSKUShortCode,
  selectHuntError,
  selectHuntStartDateTime,
  selectHuntStartDate,
  selectHuntStartTime,
  selectHuntImagePath,
  selectHuntImageURL,
  selectHuntLocationObject,
  selectHuntDescription,
  selectHuntLocationPoint,
  selectHuntName,
  selectHuntType,
  selectStep
};
