import React from "react";
import PropTypes from "prop-types";
import { Form, Grid, Icon } from "semantic-ui-react";
import AddressFormField from "components/AddressField";
import GoogleMap from "components/GoogleMaps/GoogleMap";
import GooglePlacesSearchInput from "components/GoogleMaps/GooglePlacesSearchInput";

class LocationField extends React.PureComponent {
  render() {
    const {
      handlePlaceSelected,
      handleSwitchStateToAddress,
      handleSwitchStateToSearch,
      showSwitchButton,
      label,
      showAddressFields,
      locationPoint,
    } = this.props;
    let showMap = !!locationPoint;
    const addressWidth = showMap ? 10 : 16;

    return (
      <Form.Field>
        <label>{label}</label>
        {showAddressFields ? (
          <Grid stackable columns={2}>
            <Grid.Row>
              <Grid.Column computer={addressWidth} mobile={16}>
                <AddressFormField />
                {showSwitchButton ? (
                  <a
                    onClick={handleSwitchStateToSearch}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontSize: ".9rem",
                    }}
                  >
                    <Icon name="redo" />
                    Search for Address
                  </a>
                ) : (
                  ""
                )}
              </Grid.Column>
              {showMap && (
                <Grid.Column computer={6} mobile={16}>
                  <GoogleMap
                    zoom={16}
                    initialCenter={locationPoint}
                    markers={[
                      {
                        position: locationPoint,
                      },
                    ]}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        ) : (
          <div>
            <GooglePlacesSearchInput
              handlePlaceSelected={handlePlaceSelected}
            />
            {showSwitchButton ? (
              <a
                onClick={handleSwitchStateToAddress}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontSize: ".9rem",
                }}
              >
                <Icon name="file alternate outline" />
                Enter Address
              </a>
            ) : (
              ""
            )}
          </div>
        )}
      </Form.Field>
    );
  }
}

LocationField.defaultProps = {
  showAddressFields: false,
  showSwitchButton: true,
};

LocationField.propTypes = {
  label: PropTypes.string,
  showAddressFields: PropTypes.bool,
  locationPoint: PropTypes.object,
  showSwitchButton: PropTypes.bool,
  handleSwitchStateToAddress: PropTypes.func,
  handleSwitchStateToSearch: PropTypes.func,
  handlePlaceSelected: PropTypes.func.isRequired,
};

export default LocationField;
