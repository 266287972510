export const LOADING_JUDGING_DATA = "scavr/Judging/LOADING_JUDGING_DATA";

export const APPROVE_ATTEMPT = "scavr/Judging/APPROVE_ATTEMPT";
export const APPROVE_ATTEMPT_SUCCEEDED =
  "scavr/Judging/APPROVE_ATTEMPT_SUCCEEDED";
export const APPROVE_ATTEMPT_FAILED = "scavr/Judging/APPROVE_ATTEMPT_FAILED";
export const REJECT_ATTEMPT = "scavr/Judging/REJECT_ATTEMPT";
export const REJECT_ATTEMPT_SUCCEEDED =
  "scavr/Judging/REJECT_ATTEMPT_SUCCEEDED";
export const REJECT_ATTEMPT_FAILED = "scavr/Judging/REJECT_ATTEMPT_FAILED";

export const FILTER_CHANGED = "scavr/Judging/FILTER_CHANGED";

export const JUDGING_FILTER_TEAM = "TEAM";
export const JUDGING_FILTER_CHALLENGE = "CHALLENGE";
export const JUDGING_FILTER_CHALLENGE_STATUS = "CHALLENGE_STATUS";
export const JUDGING_FILTER_CHALLENGE_TYPE = "CHALLENGE_TYPE";
