import React from "react";
import PropTypes from "prop-types";
import PlacesAutocomplete from "react-places-autocomplete";
import { Search } from "semantic-ui-react";
import { getPlaceDetails } from "./PlaceUtils";

import log from "loglevel";
import { GoogleApiWrapper } from "google-maps-react"; // eslint-disable-line no-unused-vars

class GooglePlacesSearchInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      googleLoading: true,
      googleLoaded: false,
      googleLoadError: false,
      loading: false,
      address: "",
      sessionToken: new window.google.maps.places.AutocompleteSessionToken(),
    };
  }

  // handleScriptCreate = () =>
  //   this.setState({ googleLoading: true, googleLoaded: false });
  // handleScriptLoad = () =>
  //   this.setState({
  //     googleLoading: false,
  //     googleLoaded: true,,
  //   });
  // handleScriptError = () =>
  //   this.setState({ googleLoading: false, googleLoadError: true });

  handleSearchChange = (event, target) => {
    target.onChange(event);
  };
  handleError = x => {
    log.debug("error: ", x);
  };

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = (address, placeId) => {
    const self = this;
    log.debug("selected address", address, placeId);
    this.setState({ loading: true });
    getPlaceDetails(placeId).then(placeObject => {
      self.setState({ loading: false });
      log.debug("result place", placeObject);
      self.props.handlePlaceSelected(placeObject);
    });
  };

  render() {
    const { address, sessionToken, loading } = this.state;

    const loading1 = loading;
    // eslint-disable-next-line no-undef
    // const googleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    // const googleMapsURL = `https://maps.googleapis.com/maps/api/js?key=${googleMapsAPIKey}&libraries=places`;
    //
    // const googleScript = (
    //   <Script
    //     key="google-maps-script"
    //     url={googleMapsURL}
    //     onCreate={this.handleScriptCreate}
    //     onLoad={this.handleScriptLoad}
    //     onError={this.handleScriptError}
    //   />
    // );

    const searchChange = this.handleSearchChange;

    const resultRenderer = getSuggestionItemProps => suggestion => (
      <div {...getSuggestionItemProps(suggestion)}>
        <strong>{suggestion.formattedSuggestion.mainText}</strong>
        <span style={{ color: "grey" }}>
          {" "}
          {suggestion.formattedSuggestion.secondaryText}
        </span>
      </div>
    );

    return (
      <PlacesAutocomplete
        key="places-autocomplete"
        value={address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.handleError}
        searchOptions={{
          sessionToken,
        }}
        googleCallbackName="googleLoadedCallback"
      >
        {({ suggestions, getInputProps, loading, getSuggestionItemProps }) => {
          const inputProps = getInputProps({
            loading: loading || loading1,
            fluid: true,
            icon: "search",
            placeholder: "Search...",
            className: "location-search-input",
            onSearchChange: searchChange,
            results: suggestions,
            resultRenderer: resultRenderer(getSuggestionItemProps),
          });
          return (
            <div className="autocomplete-root field autocomplete-dropdown-container">
              <Search {...inputProps} />
            </div>
          );
        }}
      </PlacesAutocomplete>
    );

    // return <div>autoCompleteField</div>;
  }
}

GooglePlacesSearchInput.propTypes = {
  handlePlaceSelected: PropTypes.func,
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  callback: "googleLoadedCallback",
})(GooglePlacesSearchInput);
