import DataProvider from "DataProvider";
import {
  saveHuntDetails,
  updateLogoComplete,
  updateLogoProgress,
  updatingLogo
} from "./actions";
import { v4 as uuidv4 } from "uuid";

const dataProvider = DataProvider.instance();

const uploadAndSetLogo = (file, hunt) => async dispatch => {
  // dispatch an "updating logo" event to start a placeholder spinner or whatever
  dispatch(updatingLogo(hunt.objectId));

  const uid = uuidv4();
  const storagePath = `hunt-files/${hunt.objectId}/logos/${uid}`;

  const progressCallback = progress => {
    // dispatch progress events specific to updating the hunt logo
    dispatch(updateLogoProgress(hunt.objectId, progress));
  };

  const completionCallback = async (storagePath, url) => {
    // dispatch an upload completion event specific to updating the hunt logo

    // update the hunt model to point to the new file
    const newData = {
      imagePath: storagePath,
      imageURL: url
    };
    dispatch(saveHuntDetails(hunt, newData));
    dispatch(updateLogoComplete(hunt.objectId));
  };

  // upload the file
  dataProvider.uploadFileFromFile(
    file,
    storagePath,
    progressCallback,
    completionCallback
  );
};

export { uploadAndSetLogo };
