import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Header, Button, Divider } from "semantic-ui-react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { push as pushRoute } from "connected-react-router";
import { createStructuredSelector } from "reselect";

import "./MyHunts.css";
import {
  selectMyHunts,
  makeSelectMyHuntsLoading,
  makeSelectMyHuntsError
} from "./selectors";
import { loadMyHunts } from "./actions";
import MyHuntsList from "./MyHuntList";

// import log from "loglevel";

class MyHunts extends React.PureComponent {
  UNSAFE_componentWillMount() {
    this.props.loadHunts();
  }
  render() {
    const { loading, error, myHunts, onClickHunt } = this.props;
    return (
      <div className="myhunts">
        <Helmet>
          <title>Scavr - Manage Hunts</title>
        </Helmet>
        <Header as="h1" floated="left">
          Manage Hunts
        </Header>
        <Link to="/create">
          <Button floated="right" primary>
            Create a New Hunt
          </Button>
        </Link>
        <Divider clearing={true} />
        <MyHuntsList
          loading={loading}
          error={error}
          hunts={myHunts}
          onClickHunt={onClickHunt}
        />
      </div>
    );
  }
}

MyHunts.propTypes = {
  loadHunts: PropTypes.func.isRequired,
  myHunts: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onClickHunt: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  myHunts: selectMyHunts,
  loading: makeSelectMyHuntsLoading(),
  error: makeSelectMyHuntsError()
});

const mapDispatchToProps = dispatch => ({
  loadHunts: () => dispatch(loadMyHunts()),
  onClickHunt: huntId => dispatch(pushRoute(`/manage/${huntId}`))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyHunts)
);
