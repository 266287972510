import React from "react";
import PropTypes from "prop-types";
import { Loader, Form, Container, Message } from "semantic-ui-react";
import { reduxForm } from "redux-form/immutable";
import { connect } from "react-redux";
import Field from "components/Field";
import { CREATE_TEAM_FORM_NAME } from "../constants";

import log from "loglevel"; // eslint-disable-line no-unused-vars

class CreateTeamAndRegister extends React.PureComponent {
  register = values => {
    const teamName = values.get("createTeamName");
    const teamPassword = values.get("createTeamPassword");
    const inviteTeammates = values.get("inviteTeammates");
    log.debug(
      "Create Team & Register: ",
      teamName,
      teamPassword,
      inviteTeammates
    );
    this.props.onCreateTeamAndRegister(
      this.props.hunt,
      teamName,
      teamPassword,
      inviteTeammates
    );
  };

  render() {
    if (this.props.loading) {
      return this.props.loading ? <Loader active inline="centered" /> : null;
    }
    return (
      <div>
        <h2>
          Create a Team
          {/*<Popup*/}
          {/*  size="small"*/}
          {/*  wide*/}
          {/*  header="Creating a Team"*/}
          {/*  on="hover"*/}
          {/*  trigger={*/}
          {/*    <Icon*/}
          {/*      name="question circle outline"*/}
          {/*      size="small"*/}
          {/*      color="grey"*/}
          {/*      className="h1small"*/}
          {/*    />*/}
          {/*  }*/}
          {/*  content={*/}
          {/*    <div>*/}
          {/*      To join this hunt, you must be on a team. You can get started by*/}
          {/*      creating a team of your own. Or, you can join an existing team*/}
          {/*      instead.*/}
          {/*    </div>*/}
          {/*  }*/}
          {/*/>*/}
        </h2>
        <Container textAlign="left">
          <Form
            onSubmit={this.props.handleSubmit(this.register)}
            error={this.props.registerError}
          >
            <Form.Field readOnly>
              <label>Name</label>
              <Field type="text" component={Form.Input} name="user" readOnly />
            </Form.Field>
            <Form.Field required>
              <label>Team Name</label>
              <Field
                type="text"
                name="createTeamName"
                component={Form.Input}
                required
                placeholder="e.g. The Sea Mooses"
              />
            </Form.Field>
            {/*<Form.Field>*/}
            {/*  <label>*/}
            {/*    Team Password (optional){" "}*/}
            {/*    <Popup*/}
            {/*      size="small"*/}
            {/*      wide*/}
            {/*      header="Team Passwords"*/}
            {/*      on="hover"*/}
            {/*      trigger={<Icon name="question circle outline" />}*/}
            {/*      content={*/}
            {/*        <div>*/}
            {/*          You can set a team password to keep your team private.*/}
            {/*          Only someone you've shared the password with can join your*/}
            {/*          team. If you want, you can leave this blank and anyone can*/}
            {/*          join your team.*/}
            {/*        </div>*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </label>*/}
            {/*  <Field*/}
            {/*    type="text"*/}
            {/*    name="createTeamPassword"*/}
            {/*    component={Form.Input}*/}
            {/*    placeholder="team_password"*/}
            {/*  />*/}
            {/*</Form.Field>*/}
            {this.props.registerError && (
              <Message
                error
                header="Error Registering"
                content={this.props.registerError}
              />
            )}
            <Form.Button primary>Create Team & Register</Form.Button>
          </Form>
          {/*<br />*/}
          {/*<p>*/}
          {/*  Or you can{" "}*/}
          {/*  <a onClick={this.joinTeam} style={{ textDecoration: "underline" }}>*/}
          {/*    join an existing team*/}
          {/*  </a>.*/}
          {/*</p>*/}
        </Container>
      </div>
    );
  }
}

CreateTeamAndRegister.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCreateTeamAndRegister: PropTypes.func.isRequired,

  user: PropTypes.object.isRequired,
  hunt: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  registerError: PropTypes.string
};

const ReduxCreateTeamAndRegister = reduxForm({
  form: CREATE_TEAM_FORM_NAME
})(CreateTeamAndRegister);

const mapStateToProps = (state, props) => ({
  initialValues: {
    user: props.user.displayName
  }
});

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReduxCreateTeamAndRegister);
