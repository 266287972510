import log from "loglevel";
import { fromJS, OrderedSet } from "immutable";
import { LOAD_HUNT, STARTED_WATCHING_SETTINGS } from "redux/constants";
import { LOADING_FEED, LOADING_FEED_SUCCEEDED, RESET_FEED } from "./constants";

const initialHuntState = fromJS({
  feedItemIds: OrderedSet(),
  loadingFeed: false,
  hasMore: true
});

function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case LOAD_HUNT:
      return state.set(action.payload.huntId, initialHuntState);
    case RESET_FEED:
      return state
        .setIn([action.payload.huntId, "feedItemIds"], OrderedSet())
        .setIn([action.payload.huntId, "loadingFeed"], true);
    case LOADING_FEED:
      return state.setIn([action.payload.huntId, "loadingFeed"], true);
    case STARTED_WATCHING_SETTINGS:
      return state.setIn([action.payload.huntId, "loadingFeed"], true);
    case LOADING_FEED_SUCCEEDED:
      return state
        .updateIn([action.payload.huntId, "feedItemIds"], set => {
          log.debug("pushing to set: ", set);
          return set.union(action.payload.result);
        })
        .setIn([action.payload.huntId, "hasMore"], action.payload.hasMore)
        .setIn([action.payload.huntId, "loadingFeed"], false);
  }
  return state;
}

export default reducer;
