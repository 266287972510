import log from "loglevel"; // eslint-disable-line no-unused-vars
import { createSelector } from "reselect";
import { selectCurrentHuntId } from "redux/selectors";
import { fromJS } from "immutable";
import moment from "moment";

const selectArchiveState = state => {
  return state.manageHunt.archive;
};

const selectHuntArchiveState = createSelector(
  selectCurrentHuntId,
  selectArchiveState,
  (huntId, archiveState) => {
    const state = archiveState.get(huntId);
    return state ? state : fromJS({});
  }
);

const selectArchiveRequested = createSelector(selectHuntArchiveState, state =>
  state.get("archiveRequested")
);

const selectArchiveURL = createSelector(selectHuntArchiveState, state =>
  state.get("archiveURL")
);

const selectArchiveDate = createSelector(selectHuntArchiveState, state => {
  if (state && state.get("archiveDate")) {
    return moment(state.get("archiveDate")).format("LLL");
  }
  return null;
});

const selectArchiveSize = createSelector(selectHuntArchiveState, state =>
  state.get("archiveSize")
);

export {
  selectArchiveRequested,
  selectArchiveURL,
  selectArchiveDate,
  selectArchiveSize
};
