import {
  CREATE_HUNT_TYPE_SELECTED,
  CREATE_HUNT_STARTED,
  CREATE_HUNT_INFO_SUBMITTED,
  CREATE_HUNT_PRICING_CHOSEN,
  CREATE_HUNT_CHANGE_LEVEL,
  SAVE_HUNT_SUCCEEDED,
  SAVE_HUNT_FAILED,
  CREATE_HUNT_COMPLETED,
  CREATE_HUNT_SKU_SELECTED,
} from "./constants";
import { ANALYTICS_TRACK } from "analytics/constants";
import { normalize } from "normalizr";
import { HuntSchema } from "models/Hunt";

import log from "loglevel"; // eslint-disable-line no-unused-vars

const startCreateHunt = () => ({
  type: CREATE_HUNT_STARTED,
  meta: {
    analytics: {
      method: ANALYTICS_TRACK,
      type: "Create.Started",
    },
  },
});

const changeHuntLevel = (currentLevel, huntTypeChosen) => ({
  type: CREATE_HUNT_CHANGE_LEVEL,
  meta: {
    analytics: {
      method: ANALYTICS_TRACK,
      type: "Create.ChangePricingLevelIntent",
      payload: {
        currentHuntLevel: currentLevel,
        huntTypeChosen: huntTypeChosen,
      },
    },
  },
});

const huntSKUSelected = (skuId, skuName) => ({
  type: CREATE_HUNT_SKU_SELECTED,
  payload: {
    skuId,
    skuName,
  },
  meta: {
    analytics: {
      method: ANALYTICS_TRACK,
      type: "Create.SKUChosen",
      payload: {
        skuId,
        skuName,
      },
    },
  },
});

const huntLevelSelected = (huntLevel) => ({
  type: CREATE_HUNT_PRICING_CHOSEN,
  payload: { huntLevel },
  meta: {
    analytics: {
      method: ANALYTICS_TRACK,
      type: "Create.PricingLevelChosen",
      payload: {
        huntLevel,
      },
    },
  },
});

const huntTypeSelected = (huntType) => ({
  type: CREATE_HUNT_TYPE_SELECTED,
  payload: { huntType },
  meta: {
    analytics: {
      method: ANALYTICS_TRACK,
      type: "Create.HuntTypeSelected",
      payload: {
        huntType,
      },
    },
  },
});

const basicInfoSubmitted = (huntData) => ({
  type: CREATE_HUNT_INFO_SUBMITTED,
  payload: { huntData },
  meta: {
    analytics: {
      method: ANALYTICS_TRACK,
      type: "Create.HuntInfoSubmitted",
      payload: {
        huntData: huntData,
      },
    },
  },
});

const saveHuntSucceeded = (hunt) => {
  const normalized = normalize(hunt, HuntSchema);
  log.debug("normalized saved hunt", normalized);
  return {
    type: SAVE_HUNT_SUCCEEDED,
    payload: normalized,
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "Create.HuntSaved",
        payload: {
          hunt: hunt,
        },
      },
    },
  };
};

const createHuntCompleted = () => ({
  type: CREATE_HUNT_COMPLETED,
  payload: {},
});

const saveHuntFailed = (error) => ({
  type: SAVE_HUNT_FAILED,
  error,
  meta: {
    analytics: {
      method: ANALYTICS_TRACK,
      type: "Create.SaveHuntFailed",
      payload: {
        error,
      },
    },
  },
});

export {
  startCreateHunt,
  changeHuntLevel,
  huntSKUSelected,
  huntLevelSelected,
  huntTypeSelected,
  basicInfoSubmitted,
  saveHuntSucceeded,
  createHuntCompleted,
  saveHuntFailed,
};
