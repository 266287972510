import { normalize } from "normalizr";
import { ANALYTICS_TRACK } from "analytics/constants";
import {
  CREATE_TEAM_AND_JOIN_HUNT,
  CREATE_TEAM_AND_JOIN_HUNT_FAILED,
  CREATE_TEAM_AND_JOIN_HUNT_SUCCEEDED,
  FETCH_AM_I_REGISTERED,
  FETCH_AM_I_REGISTERED_FAILED,
  FETCH_AM_I_REGISTERED_SUCCEEDED,
  FETCH_REGISTERED_TEAMS,
  FETCH_REGISTERED_TEAMS_FAILED,
  UPDATE_TEAM_NAME,
  UPDATE_TEAM_NAME_FAILED,
  UPDATE_TEAM_NAME_SUCCEEDED
} from "./constants";
import { TeamSchema } from "models/Team";
import log from "loglevel";
import { UPDATE_HUNT_STATUS_FAILED } from "../ManageHunt/sections/HuntStatusSteps/constants"; // eslint-disable-line no-unused-vars

export function createTeamAndRegister(huntId, teamName, password, invites) {
  return {
    type: CREATE_TEAM_AND_JOIN_HUNT,
    payload: {
      huntId,
      teamName,
      password,
      invites
    }
  };
}

export function createTeamAndRegisterSucceeded(team, hunt) {
  log.debug("registered", team, "for", hunt.objectId);
  const normalized = normalize(team, TeamSchema);
  return {
    type: CREATE_TEAM_AND_JOIN_HUNT_SUCCEEDED,
    payload: normalized,
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "JoinHunt.CreatedTeamAndRegistered",
        payload: {
          team: team,
          huntId: hunt.objectId,
          huntName: hunt.name
        }
      }
    }
  };
}

export function createTeamAndRegisterFailed(error) {
  return {
    type: CREATE_TEAM_AND_JOIN_HUNT_FAILED,
    error
  };
}

export function loadRegisteredTeams(huntId) {
  return {
    type: FETCH_REGISTERED_TEAMS,
    payload: {
      huntId
    }
  };
}

export function fetchRegisteredTeamsFailed(error) {
  return {
    type: FETCH_REGISTERED_TEAMS_FAILED,
    error
  };
}

export function fetchAmIRegistered(huntId) {
  return {
    type: FETCH_AM_I_REGISTERED,
    payload: {
      huntId
    }
  };
}

export function fetchAmIRegisteredSucceeded(team) {
  log.debug("registered team: ", team);
  let normalized = {
    entities: {},
    result: null
  };
  if (team) {
    normalized = normalize(team, TeamSchema);
  }
  return {
    type: FETCH_AM_I_REGISTERED_SUCCEEDED,
    payload: normalized,
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "JoinHunt.AmIRegistered",
        payload: {
          isRegistered: !!team
        }
      }
    }
  };
}

export function fetchAmIRegisteredFailed(error) {
  return {
    type: FETCH_AM_I_REGISTERED_FAILED,
    error
  };
}

export function updateTeamName(teamId, newTeamName) {
  return {
    type: UPDATE_TEAM_NAME,
    payload: {
      teamId,
      newTeamName
    },
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "JoinHunt.UpdateTeamName",
        payload: {
          teamId,
          newTeamName
        }
      }
    }
  };
}

export function updateTeamNameSuccess(team) {
  log.debug("Got updated team: ", team);
  let normalized = normalize(team, TeamSchema);
  return {
    type: UPDATE_TEAM_NAME_SUCCEEDED,
    payload: normalized
  };
}

export function updateTeamNameFailed(error) {
  log.error("Failed to update team name: ", error);
  return {
    type: UPDATE_TEAM_NAME_FAILED,
    error
  };
}
