import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import { Card, Image, Popup } from "semantic-ui-react";
// import LazyImage from "../LazyLoadImage";

class FeedItem extends React.PureComponent {
  componentDidMount() {
    if (this.props.onLoad) {
      this.props.onLoad();
    }
  }

  render() {
    const {
      // itemId,
      teamName,
      challengeName,
      challengeDescription,
      submittedTime,
      // likeCount,
      mediaType,
      mediaURL,
      onLoad,
      // attemptId,
      // feedId,
      submissionTimeRaw,
    } = this.props;

    return (
      <Card
        raised
        style={{
          maxWidth: 550,
        }}
      >
        <Card.Content header={teamName} />
        <Card.Content>
          {mediaType === "photo" ? (
            // <LazyImage src={mediaURL} size="big" onLoad={onLoad} />
            <Image src={mediaURL} size="big" onLoad={onLoad} />
          ) : (
            <video
              controls
              style={{
                backgroundSize: "contain",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              onLoad={onLoad}
              onLoadedData={onLoad}
            >
              <source src={mediaURL} type="video/mp4" />
            </video>
          )}
        </Card.Content>
        <Card.Content extra>
          <Card.Description>
            <b>{challengeName}</b> submitted{" "}
            <Popup
              trigger={
                <span style={{ color: "grey" }}>
                  <em>{submittedTime}</em>
                </span>
              }
              content={<p>{submissionTimeRaw}</p>}
              size="mini"
            />
            <br />
            <p>{challengeDescription}</p>
          </Card.Description>
        </Card.Content>
        {/*<Card.Content extra>{likeCount} likes</Card.Content>*/}
        {/*<Card.Content extra>*/}
        {/*  <p>Attempt ID: {attemptId}</p>*/}
        {/*  <p>Feed ID: {feedId}</p>*/}
        {/*  <p>Submission Time: {submissionTimeRaw}</p>*/}
        {/*</Card.Content>*/}
      </Card>
    );
  }
}

FeedItem.propTypes = {
  itemId: PropTypes.string,
  teamName: PropTypes.string,
  challengeName: PropTypes.string,
  challengeDescription: PropTypes.string,
  attemptId: PropTypes.string,
  feedId: PropTypes.string,
  submittedTime: PropTypes.string,
  likeCount: PropTypes.number,
  mediaType: PropTypes.string,
  mediaURL: PropTypes.string,
  submissionTimeRaw: PropTypes.string,

  onLoad: PropTypes.func,
};

export default FeedItem;
