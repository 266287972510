/* eslint-disable no-case-declarations */
import { fromJS } from "immutable";
import _ from "lodash";
import { put, call, takeLatest, select } from "redux-saga/effects";
import { push as pushRoute } from "connected-react-router";

import DataProvider from "DataProvider";

import log from "loglevel";

import { selectCurrentUser } from "utils/selectors";
import {
  LOAD_MY_HUNTS,
  LOAD_MY_HUNTS_SUCCESS
} from "containers/MyHunts/constants";

import {
  loadMyHuntsFailure,
  loadMyHuntsSuccess
} from "containers/MyHunts/actions";

import {
  // CREATE_HUNT_INFO_SUBMITTED,
  SAVE_HUNT_SUCCEEDED
} from "containers/CreateHunt/constants";
import {
  // createHuntCompleted,
  saveHuntFailed,
  saveHuntSucceeded
} from "containers/CreateHunt/actions";
import {
  CREATE_CHALLENGE,
  CREATE_CHALLENGE_SUCCESS,
  LOAD_HUNT_CHALLENGES,
  LOAD_HUNT_CHALLENGES_SUCCESS,
  REORDER_CHALLENGE,
  DELETE_CHALLENGE,
  DELETE_CHALLENGE_SUCCESS,
  SAVE_CHALLENGE,
  SAVE_CHALLENGE_SUCCESS
} from "containers/Challenges/constants";
import {
  createChallengeFailed,
  createChallengeSucceeded,
  loadHuntChallengesFailure,
  loadHuntChallenges as loadHuntChallengesAction,
  deleteChallengeFailed,
  deleteChallengeSucceeded,
  reorderChallengeFailed,
  reorderChallengeSucceeded,
  updateChallengeFailed,
  updateChallengeSucceeded
} from "containers/Challenges/actions";
import {
  UPDATE_HUNT,
  // LOAD_HUNT,
  // LOAD_HUNT_SUCCESS,
  PARTICIPANTS_LOADED,
  TEAMS_LOADED,
  CHALLENGE_ATTEMPTS_LOADED,
  DELETE_HUNT,
  DELETE_HUNT_SUCCESS,
  UPDATE_HUNT_SUCCESS,
  COPY_HUNT,
  CHALLENGE_ATTEMPT_DIFF_LOADED
} from "containers/ManageHunt/constants";
import { LOAD_HUNT_SETTINGS_SUCCESS, LOAD_HUNT_SUCCESS } from "redux/constants";
import {
  // loadHuntFailure,
  // loadHuntSuccess,
  loadHuntChallengesSuccess,
  saveHuntDetailsSucceeded,
  saveHuntDetailsFailed,
  deleteHuntFailure,
  deleteHuntSuccess,
  loadTeamsSuccess,
  copyHuntFailure,
  copyHuntSuccess
} from "containers/ManageHunt/actions";
import {
  CREATE_TEAM_AND_JOIN_HUNT,
  CREATE_TEAM_AND_JOIN_HUNT_SUCCEEDED,
  FETCH_AM_I_REGISTERED,
  FETCH_AM_I_REGISTERED_SUCCEEDED,
  FETCH_REGISTERED_TEAMS,
  UPDATE_TEAM_NAME,
  UPDATE_TEAM_NAME_SUCCEEDED
} from "containers/JoinHunt/constants";
import {
  createTeamAndRegisterSucceeded,
  createTeamAndRegisterFailed,
  fetchAmIRegisteredFailed,
  fetchAmIRegisteredSucceeded,
  fetchRegisteredTeamsFailed,
  updateTeamNameFailed,
  updateTeamNameSuccess
} from "containers/JoinHunt/actions";
import {
  APPROVE_ATTEMPT,
  REJECT_ATTEMPT
} from "containers/ManageHunt/sections/Judging/constants";
import {
  approveAttemptFailed,
  approveAttemptSucceeded,
  rejectAttemptFailed,
  rejectAttemptSucceeded
} from "containers/ManageHunt/sections/Judging/actions";

import { LOGGED_OUT } from "../AuthReducer";
import {
  UPDATE_HUNT_STATUS,
  UPDATE_HUNT_STATUS_SUCCESS
} from "containers/ManageHunt/sections/HuntStatusSteps/constants";
import {
  updateHuntStatusFailed,
  updateHuntStatusSuccess
} from "containers/ManageHunt/sections/HuntStatusSteps/actions";
import {
  LOAD_HUNT_LEVELS_SUCCESS,
  MODELS_UPDATED
} from "../../redux/constants";
import { LOADING_FEED_SUCCEEDED } from "../../containers/Feed/constants";

const dataProvider = DataProvider.instance();
// ---------- Sagas ------------

function* loadMyHuntsSaga() {
  const currentUser = yield select(selectCurrentUser);
  try {
    const result = yield call(dataProvider.loadMyHunts, currentUser);
    if (result.err) {
      yield put(loadMyHuntsFailure(result.err.message));
    } else {
      yield put(loadMyHuntsSuccess(result));
    }
  } catch (err) {
    log.error("error loading hunts", err);
    yield put(loadMyHuntsFailure("Error loading hunts"));
  }
}

// function* loadHuntSaga(action) {
//   const huntId = action.payload.huntId;
//   try {
//     const result = yield call(dataProvider.loadHunt, huntId);
//     if (result.err) {
//       yield put(loadHuntFailure(result.err.message));
//     } else {
//       yield put(loadHuntSuccess(result));
//     }
//   } catch (err) {
//     log.error("Error loading hunt", err);
//     yield put(loadHuntFailure(err));
//   }
// }

function* loadHuntChallenges(action) {
  const huntId = action.payload.huntId;
  try {
    const result = yield call(dataProvider.loadHuntChallenges, huntId);
    if (result.err) {
      yield put(loadHuntChallengesFailure(result.err.message));
    } else {
      yield put(loadHuntChallengesSuccess(huntId, result));
    }
  } catch (err) {
    yield put(loadHuntChallengesFailure(err));
  }
}

// function* createHuntSaga(action) {
//   const { huntData } = action.payload;
//
//   try {
//     const result = yield call(dataProvider.createHunt, huntData);
//     if (result.error) {
//       yield put(saveHuntFailed(result.error));
//     } else {
//       const { hunt } = result;
//       log.debug("Got create result: ", result);
//       yield put(saveHuntSucceeded(hunt));
//       if (hunt.status === "draft") {
//         // Need to check out
//         yield call(dataProvider.checkoutWithHunt, hunt.objectId);
//       } else {
//         yield put(pushRoute("/manage/" + result.hunt.objectId));
//       }
//       yield put(createHuntCompleted());
//     }
//   } catch (error) {
//     log.error("Error creating hunt: ", error);
//     yield put(saveHuntFailed(error));
//   }
// }

// eslint-disable-next-line no-unused-vars
function* createHuntSagaOld(action) {
  const data = action.payload.huntData;

  const currentUser = yield select(selectCurrentUser);

  data["managers"] = {
    [currentUser.uid]: true
  };
  data["status"] = "unpublished";
  try {
    const result = yield call(dataProvider.createHuntOld, data);
    if (result.err) {
      log.error(result.err);
      yield put(saveHuntFailed(result.err.message));
    } else {
      yield put(saveHuntSucceeded(result));
    }
  } catch (err) {
    log.error("err", err);
    yield put(saveHuntFailed(err));
  }
}

function* deleteHuntSaga(action) {
  const { huntId } = action.payload;
  try {
    log.debug("Deleting hunt", huntId, action);
    const result = yield call(dataProvider.deleteHunt, huntId);
    if (result.err) {
      log.error("error deleting hunt " + huntId + ": ", result.err);
      yield put(saveHuntFailed(result.err));
    } else {
      yield put(deleteHuntSuccess(huntId));
      yield put(pushRoute("/"));
    }
  } catch (err) {
    log.error("error deleting hunt " + huntId + ": ", err);
    yield put(deleteHuntFailure(err));
  }
}

function* copyHuntSaga(action) {
  const { huntId, newName, newPriceLevel } = action.payload;
  try {
    log.debug("Making a copy of " + huntId);
    const result = yield call(
      dataProvider.copyHunt,
      huntId,
      newName,
      newPriceLevel
    );
    if (result.err) {
      log.error("Erroc copying hunt " + huntId + ": ", result.err);
      yield put(copyHuntFailure(result.err));
    } else {
      yield put(copyHuntSuccess(huntId, result));
      yield put(pushRoute("/"));
    }
  } catch (err) {
    log.error("Error copying hunt " + huntId + ": ", err);
    yield put(copyHuntFailure(err));
  }
}

function* updateHuntSaga(action) {
  log.debug("Updating hunt", action.payload.hunt, action.payload.newHuntData);
  const { hunt, newHuntData } = action.payload;
  try {
    if (newHuntData.location && newHuntData.location.point) {
      const newPoint = dataProvider.getGeoPoint(
        newHuntData.location.point.lat,
        newHuntData.location.point.lng
      );
      newHuntData.location.point = newPoint;
    }

    const result = yield call(dataProvider.saveObject, hunt, newHuntData);
    if (result.err) {
      yield put(saveHuntDetailsFailed(result.err.message));
    } else {
      yield put(saveHuntDetailsSucceeded(result, hunt));
    }
  } catch (err) {
    yield put(saveHuntDetailsFailed(err));
  }
}

function* updateHuntStatus(action) {
  const { hunt, newStatus } = action.payload;
  try {
    const result = yield call(dataProvider.saveObject, hunt, {
      status: newStatus
    });
    log.debug("updated status result: ", result);
    if (result.error) {
      yield put(updateHuntStatusFailed(hunt, result.err));
    } else {
      yield put(updateHuntStatusSuccess(result));
    }
  } catch (err) {
    yield put(updateHuntStatusFailed(hunt, err));
  }
}

function* saveChallengeSaga(action) {
  log.debug("save challenge saga", action.payload);
  let { challenge, newChallengeData } = action.payload;
  if (newChallengeData.latitude && newChallengeData.longitude) {
    const { latitude, longitude, ...rest } = newChallengeData;
    newChallengeData = {
      ...rest,
      point: dataProvider.getGeoPoint(latitude, longitude)
    };
  }

  try {
    const result = yield call(
      dataProvider.saveObject,
      challenge,
      newChallengeData
    );
    if (result.err) {
      yield put(updateChallengeFailed(result.err.message));
    } else {
      yield put(updateChallengeSucceeded(result, challenge));
    }
  } catch (err) {
    yield put(updateChallengeFailed(err));
  }
}

function* deleteChallengeSaga(action) {
  const { challenge } = action.payload;
  log.debug("Challenge to be deleted", challenge);
  try {
    // const obj = Parse.Object.fromJSON({ ...challenge, className: "Challenge" });
    const result = yield call(
      dataProvider.deleteChallenge,
      challenge,
      challenge.hunt
    );
    log.debug("delete challenge result", result);
    yield put(deleteChallengeSucceeded(challenge));
    yield put(loadHuntChallengesAction(challenge.hunt));
  } catch (err) {
    log.error("Error deleting challenge", err);
    yield put(deleteChallengeFailed(err));
  }
}

function* createChallengeSaga(action) {
  let challengeData = action.payload.challengeData;
  const huntId = action.payload.huntId;
  if (challengeData.latitude && challengeData.longitude) {
    const { latitude, longitude, ...rest } = challengeData;
    challengeData = {
      ...rest,
      point: dataProvider.getGeoPoint(latitude, longitude)
    };
  }
  try {
    log.debug("Submitting challenge data to provider: ", challengeData);
    const result = yield call(
      dataProvider.createChallenge,
      huntId,
      challengeData
    );
    if (result.err) {
      yield put(createChallengeFailed(result.err.message));
    } else {
      yield put(createChallengeSucceeded(result));
    }
  } catch (err) {
    log.error("failed in creating challenge: ", err);
    yield put(createChallengeFailed(err));
  }
}

function* reorderChallengeSaga(action) {
  const { challengeId, huntId, oldIndex, newIndex } = action.payload;
  try {
    yield call(
      dataProvider.reorderChallenge,
      challengeId,
      huntId,
      oldIndex,
      newIndex
    );
    yield put(reorderChallengeSucceeded());
  } catch (err) {
    log.error("Failed to reorder challenges", err);
    yield put(reorderChallengeFailed(err));
  }
}

function* approveAttemptSaga(action) {
  const { attempt } = action.payload;
  try {
    const newAttemptData = { status: "completed" };
    const result = yield call(
      dataProvider.saveObject,
      attempt.toJS(),
      newAttemptData
    );
    log.debug("approve attempt result...", result);
    yield put(approveAttemptSucceeded(attempt.get("objectId")));
  } catch (error) {
    log.error("Failed to approve attempt.", error);
    yield put(approveAttemptFailed(error));
  }
}

function* rejectAttemptSaga(action) {
  const { attempt, message } = action.payload;
  try {
    const newAttemptData = { status: "rejected", message };
    const result = yield call(
      dataProvider.saveObject,
      attempt.toJS(),
      newAttemptData
    );
    log.debug("reject attempt result...", result);
    yield put(rejectAttemptSucceeded(attempt.get("objectId")));
  } catch (error) {
    log.error("Failed to reject attempt.", error);
    yield put(rejectAttemptFailed(error));
  }
}

function* createTeamAndJoinHuntSaga(action) {
  const { huntId, teamName, password } = action.payload;
  const currentUser = yield select(selectCurrentUser);

  try {
    const result = yield call(
      dataProvider.createTeamAndJoinHunt,
      huntId,
      teamName,
      password,
      currentUser
    );
    if (result.err) {
      yield put(createTeamAndRegisterFailed(result.err.message));
    } else {
      yield put(createTeamAndRegisterSucceeded(result));
      yield put(pushRoute(`/join/${huntId}/confirmed`));
    }
  } catch (err) {
    log.error("create and join error", err);
    yield put(createTeamAndRegisterFailed(err));
  }
}

function* upateTeamSaga(action) {
  const { teamId, newTeamName } = action.payload;

  try {
    const result = yield call(dataProvider.updateTeamName, teamId, newTeamName);
    if (result.err) {
      yield put(updateTeamNameFailed(result.err));
    } else {
      yield put(updateTeamNameSuccess(result));
    }
  } catch (err) {
    log.error("Failed to update team name: ", err);
    yield put(updateTeamNameFailed(err));
  }
}

function* fetchAmIRegisteredSaga(action) {
  const huntId = action.payload.huntId;
  const currentUser = yield select(selectCurrentUser);

  try {
    if (!currentUser) {
      yield put(fetchAmIRegisteredSucceeded(undefined));
    } else {
      const result = yield call(
        dataProvider.fetchAmIRegistered,
        currentUser.uid,
        huntId
      );
      if (result.err) {
        yield put(fetchAmIRegisteredFailed(result.err.message));
      } else {
        if (_.isEmpty(result)) {
          yield put(fetchAmIRegisteredSucceeded(null));
        } else {
          yield put(fetchAmIRegisteredSucceeded(result.response));
        }
      }
    }
  } catch (err) {
    log.error("Failed to fetch amIRegistered", err);
    yield put(fetchAmIRegisteredFailed(err));
  }
}

function* fetchRegisteredTeamsSaga(action) {
  const huntId = action.payload.huntId;

  try {
    const result = yield call(dataProvider.loadHuntTeams, huntId);
    if (result.err) {
      yield put(fetchRegisteredTeamsFailed(result.err));
    } else {
      yield put(loadTeamsSuccess(huntId, result));
    }
  } catch (error) {
    log.error("Failed to fetch registered teams", error);
    yield put(fetchRegisteredTeamsFailed(error));
  }
}

export function* dataSaga() {
  yield takeLatest(LOAD_MY_HUNTS, loadMyHuntsSaga);
  // yield takeLatest(LOAD_HUNT, loadHuntSaga);
  yield takeLatest(LOAD_HUNT_CHALLENGES, loadHuntChallenges);
  yield takeLatest(DELETE_HUNT, deleteHuntSaga);
  yield takeLatest(COPY_HUNT, copyHuntSaga);
  // yield takeLatest(CREATE_HUNT_INFO_SUBMITTED, createHuntSaga);
  yield takeLatest(UPDATE_HUNT, updateHuntSaga);
  yield takeLatest(CREATE_CHALLENGE, createChallengeSaga);
  yield takeLatest(REORDER_CHALLENGE, reorderChallengeSaga);
  yield takeLatest(SAVE_CHALLENGE, saveChallengeSaga);
  yield takeLatest(DELETE_CHALLENGE, deleteChallengeSaga);

  // Hunt State
  yield takeLatest(UPDATE_HUNT_STATUS, updateHuntStatus);

  // Judging
  yield takeLatest(APPROVE_ATTEMPT, approveAttemptSaga);
  yield takeLatest(REJECT_ATTEMPT, rejectAttemptSaga);

  // Register/Join Hunts
  yield takeLatest(CREATE_TEAM_AND_JOIN_HUNT, createTeamAndJoinHuntSaga);
  yield takeLatest(FETCH_AM_I_REGISTERED, fetchAmIRegisteredSaga);
  yield takeLatest(FETCH_REGISTERED_TEAMS, fetchRegisteredTeamsSaga);
  yield takeLatest(UPDATE_TEAM_NAME, upateTeamSaga);
}

// -- Reducers --
const initialState = fromJS({});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_MY_HUNTS_SUCCESS:
    case LOAD_HUNT_SUCCESS:
    case SAVE_HUNT_SUCCEEDED:
    case UPDATE_HUNT_STATUS_SUCCESS:
    case UPDATE_HUNT_SUCCESS:
    case LOAD_HUNT_CHALLENGES_SUCCESS:
    case PARTICIPANTS_LOADED:
    case CHALLENGE_ATTEMPTS_LOADED:
    case TEAMS_LOADED:
    case CREATE_CHALLENGE_SUCCESS:
    case SAVE_CHALLENGE_SUCCESS:
    case CREATE_TEAM_AND_JOIN_HUNT_SUCCEEDED:
    case FETCH_AM_I_REGISTERED_SUCCEEDED:
    case UPDATE_TEAM_NAME_SUCCEEDED:
    case LOAD_HUNT_LEVELS_SUCCESS:
    case LOAD_HUNT_SETTINGS_SUCCESS:
    case LOADING_FEED_SUCCEEDED:
      return state.mergeDeep(fromJS(action.payload.entities));
    case DELETE_CHALLENGE_SUCCESS:
      log.debug("Deleting challenge", action.payload.result, state);
      return state.deleteIn(["challenges", action.payload.result]);
    case DELETE_HUNT_SUCCESS:
      log.debug("Deleting hunt data", action.payload.result, state);
      return state.deleteIn(["hunts", action.payload.result]);
    case LOGGED_OUT:
      return fromJS({});
    case CHALLENGE_ATTEMPT_DIFF_LOADED:
    case MODELS_UPDATED:
      let newState = state
        .mergeDeep(fromJS(action.payload.added.entities))
        .mergeDeep(fromJS(action.payload.updated.entities));

      _.forEach(action.payload.removed.entities, (objects, type) => {
        _.forEach(_.keys(objects), key => {
          newState = newState.deleteIn([type, key]);
        });
      });
      return newState;
    default:
      return state;
  }
}
