import React from "react";
import PropTypes from "prop-types";
import { Header, Segment, Table } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";
import { stopWatchingLeaderboard, watchLeaderboard } from "./actionCreators";
import LeaderboardPlaceholder from "components/placeholderTable";
import { selectLeaderboard, selectLeaderboardLoading } from "./selectors";
import { HUNT_STATE_PUBLISHED, HUNT_STATE_UNPUBLISHED } from "models/constants";
import { selectCurrentHunt } from "redux/selectors";

class Leaderboard extends React.PureComponent {
  UNSAFE_componentWillMount() {
    this.props.watchLeaderboard(this.props.match.params.huntId);
  }

  componentWillUnmount() {
    this.props.stopWatchingLeaderboard(this.props.match.params.huntId);
  }

  render() {
    const { leaderboard, loading, hunt } = this.props;

    if (!hunt) {
      return (
        <div>
          <div />
          <Header as="h2">Leaderboard</Header>
          <Table>
            <LeaderboardPlaceholder />
          </Table>
        </div>
      );
    }

    const huntUnstarted =
      hunt.status === HUNT_STATE_UNPUBLISHED ||
      hunt.status === HUNT_STATE_PUBLISHED;

    return (
      <div>
        <div />
        <Header as="h2">Leaderboard</Header>

        {huntUnstarted ? (
          <Segment textAlign="center">
            <div>
              <Header>You have not started your hunt yet.</Header>
              <p>
                Once your hunt is active and participants complete challenges,
                you&apos;ll see the standings here in real time!
              </p>
            </div>
          </Segment>
        ) : (
          <Table unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Rank</Table.HeaderCell>
                <Table.HeaderCell>Team</Table.HeaderCell>
                <Table.HeaderCell>Number of Attempts</Table.HeaderCell>
                <Table.HeaderCell>Completed Points</Table.HeaderCell>
                <Table.HeaderCell>Last Submission Time</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            {loading ? (
              <LeaderboardPlaceholder />
            ) : (
              <Table.Body>
                {leaderboard.map((entry, idx) => (
                  <Table.Row key={idx}>
                    <Table.Cell>{idx + 1}</Table.Cell>
                    <Table.Cell>{entry.name}</Table.Cell>
                    <Table.Cell>{entry.challengeAttempts}</Table.Cell>
                    <Table.Cell>{entry.points}</Table.Cell>
                    <Table.Cell>
                      {entry.lastSubmission
                        ? moment(entry.lastSubmission).format(
                            "MMM D, YYYY - h:mm:ss a"
                          )
                        : ""}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>
        )}
      </div>
    );
  }
}

Leaderboard.propTypes = {
  match: PropTypes.object,
  hunt: PropTypes.object,
  leaderboard: PropTypes.array,
  loading: PropTypes.bool,

  watchLeaderboard: PropTypes.func,
  stopWatchingLeaderboard: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  hunt: selectCurrentHunt,
  leaderboard: selectLeaderboard,
  loading: selectLeaderboardLoading
});

const mapDispatchToProps = dispatch => ({
  watchLeaderboard: huntId => dispatch(watchLeaderboard(huntId)),
  stopWatchingLeaderboard: huntId => dispatch(stopWatchingLeaderboard(huntId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Leaderboard)
);
