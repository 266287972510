import log from "loglevel"; // eslint-disable-line no-unused-vars
import { FIREBASE_FUNCTION_CREATE_ARCHIVE } from "./constants";
import firebaseUtils from "./firebaseUtils";

// import { FIREBASE_COLLECTION_HUNTS } from "./constants";
// import { loadDiffOfModelsForQuerySnapshot } from "./firebaseModels";
// import firebaseUtils from "./firebaseUtils";
//
// const loadChallengeAttempts = async (
//   huntId,
//   teamId,
//   challengeId,
//   challengeType,
//   challengeStatus,
//   limit = 20
// ) => {
//   let huntRef = firebaseUtils
//     .db()
//     .collection(FIREBASE_COLLECTION_HUNTS)
//     .doc(huntId);
//   let challengeAttemptRef = firebaseUtils
//     .db()
//     .collection("challengeAttempts")
//     .where("hunt", "==", huntRef);
//
//   if (teamId) {
//     let teamRef = firebaseUtils.db().collection("teams").doc(teamId);
//     challengeAttemptRef = challengeAttemptRef.where("team", "==", teamRef);
//   }
//
//   if (challengeId) {
//     let challengeRef = firebaseUtils
//       .db()
//       .collection(FIREBASE_COLLECTION_HUNTS)
//       .doc(huntId)
//       .collection("challenges")
//       .doc(challengeId);
//
//     challengeAttemptRef = challengeAttemptRef.where(
//       "challenge",
//       "==",
//       challengeRef
//     );
//   }
//
//   if (challengeType) {
//     challengeAttemptRef = challengeAttemptRef.where(
//       "itemType",
//       "==",
//       challengeType
//     );
//   }
//
//   if (challengeStatus) {
//     challengeAttemptRef = challengeAttemptRef.where(
//       "status",
//       "==",
//       challengeStatus
//     );
//   }
//
//   let snaps = await challengeAttemptRef.get();
//
//   return challengeAttemptRef.onSnapshot(function (querySnapshot) {
//     loadDiffOfModelsForQuerySnapshot(querySnapshot).then((changedModels) => {
//       onChallengeAttemptsUpdated(changedModels);
//     });
//   });
// };
//

const createAttemptArchive = async (huntId, settings) => {
  const createArchiveFunc = firebaseUtils
    .functions()
    .httpsCallable(FIREBASE_FUNCTION_CREATE_ARCHIVE);

  const result = await createArchiveFunc({ huntId, settings });

  log.debug("Got create archive result: ", result);

  return result.data;
};

const api = {
  createAttemptArchive
};

export default api;
