import log from "loglevel"; // eslint-disable-line no-unused-vars
import { fromJS, Map } from "immutable";
import {
  DELETE_TEAM,
  DELETE_TEAM_FAILED,
  DELETE_TEAM_SUCCESS,
} from "redux/constants";
import { PARTICIPANTS_LOADED } from "../ManageHunt/constants";

const initialState = fromJS({
  isDeleting: false,
  error: null,
});

function deleteTeamReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_TEAM:
      return state.set("isDeleting", true).set("error", null);
    case DELETE_TEAM_SUCCESS:
      return state.set("isDeleting", false);
    case DELETE_TEAM_FAILED:
      return state.set("error", action.error).set("isDeleting", false);
    default:
      break;
  }
  return state;
}

function teamIdReducer(state = Map(), action) {
  let teamId;

  switch (action.type) {
    case PARTICIPANTS_LOADED:
      action.payload.result.teams.forEach((teamId) => {
        if (!state.has(teamId)) {
          let teamState = deleteTeamReducer(undefined, action);
          log.debug("Team state: ", teamId, teamState);
          state = state.set(teamId, teamState);
        }
      });
      return state;
    case DELETE_TEAM:
    case DELETE_TEAM_FAILED:
    case DELETE_TEAM_SUCCESS:
      teamId = action.payload.teamId;
      return state.set(teamId, deleteTeamReducer(state.get(teamId), action));
    default:
      break;
  }
  return state;
}

export default teamIdReducer;
