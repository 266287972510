// import { fromJS } from "immutable";
import { put, call, takeLatest, all } from "redux-saga/effects";
import DataProvider from "DataProvider";
import { updateUserProfile } from "utils/auth";

import log from "loglevel";

// -- Constants --
export const EMAIL_LOGIN = "scavr/auth/EMAIL_LOGIN";
export const EMAIL_LOGIN_FAILURE = "scavr/auth/EMAIL_LOGIN_FAILURE";
export const EMAIL_LOGIN_SUCCESS = "scavr/auth/EMAIL_LOGIN_SUCCESS";

export const EMAIL_SIGNUP = "scavr/auth/EMAIL_SIGNUP";
export const EMAIL_SIGNUP_SUCCESS = "scavr/auth/EMAIL_SIGNUP_SUCCESS";
export const EMAIL_SIGNUP_FAILURE = "scavr/auth/EMAIL_SIGNUP_FAILURE";

export const FB_SIGNUP_SUCCESS = "scavr/auth/FB_SIGNED_UP";
export const FB_LOGIN_SUCCESS = "scavr/auth/FB_LOGGED_IN";
export const FB_LOGIN_FAILURE = "scavr/auth/FB_LOGIN_FAILURE";

export const LOGOUT = "scavr/auth/LOGOUT";
export const LOGGED_OUT = "scavr/auth/LOGGED_OUT";
const LOGOUT_FAILURE = "scavr/auth/LOGOUT_FAILURE";

const LISTEN_FOR_AUTH_CHANGE = "scavr/auth/LISTEN_FOR_AUTH_CHANGE";
const AUTH_STATE_CHANGED = "scavr/auth/AUTH_STATE_CHANGED";

export const USER_UPDATED = "scavr/auth/USER_UPDATED";

const dataProvider = DataProvider.instance();

// -- Actions --
export function emailLogin(email, password) {
  return {
    type: EMAIL_LOGIN,
    payload: {
      email,
      password
    }
  };
}

export function emailLoggedIn(user) {
  return {
    type: EMAIL_LOGIN_SUCCESS,
    payload: { user },
    meta: {
      analytics: {
        method: "identify",
        payload: {
          user: user
        }
      }
    }
  };
}

export function emailLoginFailure(error) {
  return {
    type: EMAIL_LOGIN_FAILURE,
    error
  };
}

export function emailSignup(email, password, userData) {
  return {
    type: EMAIL_SIGNUP,
    payload: {
      email,
      password,
      userData
    }
  };
}

export function emailSignupFailure(error) {
  log.trace("Signup Failure", error);
  return {
    type: EMAIL_SIGNUP_FAILURE,
    error
  };
}

export function emailSignupSuccess(user) {
  return {
    type: EMAIL_SIGNUP_SUCCESS,
    payload: { user },
    meta: {
      analytics: {
        method: "identify",
        payload: {
          user: user.toJSON()
        }
      }
    }
  };
}

export function fbSignupSuccess(user, fbUserData) {
  return {
    type: FB_SIGNUP_SUCCESS,
    payload: {
      user,
      fbUserData
    },
    meta: {
      analytics: {
        method: "identify",
        payload: {
          user: user.toJSON()
        }
      }
    }
  };
}

export function fbLoginSuccess(user) {
  return {
    type: FB_LOGIN_SUCCESS,
    payload: {
      user
    },
    meta: {
      analytics: {
        method: "identify",
        payload: {
          user: user.toJSON()
        }
      }
    }
  };
}

export function fbLoginFailure(error) {
  return {
    type: FB_LOGIN_FAILURE,
    error: error
  };
}

export function userUpdated(user) {
  return {
    type: USER_UPDATED,
    payload: { user },
    meta: {
      analytics: {
        method: "identify",
        payload: {
          user: user.toJSON()
        }
      }
    }
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}

export function loggedOut() {
  return {
    type: LOGGED_OUT,
    meta: {
      analytics: {
        method: "identify",
        payload: {
          user: null
        }
      }
    }
  };
}

export function logoutFailure(error) {
  return {
    type: LOGOUT_FAILURE,
    error
  };
}

export function authChangedAction(user) {
  return {
    type: AUTH_STATE_CHANGED,
    payload: { user },
    meta: {
      analytics: {
        method: "identify",
        payload: {
          user: user
        }
      }
    }
  };
}

export function listenForAuthChange(callback) {
  return {
    type: LISTEN_FOR_AUTH_CHANGE,
    payload: {
      callback
    }
  };
}

// -- Sagas --

function saveUser(user) {
  return user
    .save()
    .then(response => {
      return response;
    })
    .catch(err => ({ err }));
}

function* logoutSaga() {
  yield call(dataProvider.logOut);
  yield put(loggedOut());
}

function* emailLoginSaga(action) {
  log.debug("Running email login saga.");
  // const email = yield select(makeSelectEmail());
  // const password = yield select(makeSelectPassword());
  const email = action.payload.email;
  const password = action.payload.password;

  try {
    const user = yield call(dataProvider.emailLogIn, email, password);
    yield put(emailLoggedIn(user));
  } catch (err) {
    yield put(emailLoginFailure(err));
  }
}

function* emailSignupSaga(action) {
  log.info("Signing up with email.", action);

  const { email, password, userData } = action.payload;
  const { firstName, lastName } = userData;

  if (!email || !password || !firstName || !lastName) {
    put(emailSignupFailure("You must fill out all the fields."));
  }

  const userInfo = {
    firstName,
    lastName,
    email,
    password,
    username: email
  };

  try {
    const result = yield call(dataProvider.emailSignup, userInfo);
    if (result.err) {
      log.error("Signup Error", result.err);
      yield put(emailSignupFailure(result.err.message));
    } else {
      yield put(emailSignupSuccess(result));
    }
  } catch (err) {
    log.error("Signup Error", err);
    yield put(emailSignupFailure(err));
  }
}

function* saveFbUserDataSaga(action) {
  const { user, fbUserData } = action.payload;
  if (fbUserData.first_name) {
    user.set("firstName", fbUserData.first_name);
  }
  if (fbUserData.last_name) {
    user.set("lastName", fbUserData.last_name);
  }
  if (fbUserData.email) {
    user.setEmail(fbUserData.email);
  }
  if (
    fbUserData.picture &&
    fbUserData.picture.data &&
    !fbUserData.picture.data.is_silhouette &&
    fbUserData.picture.data.url
  ) {
    user.set("avatarURL", fbUserData.picture.data.url);
  }
  try {
    const result = yield call(saveUser, user);
    if (result.err) {
      log.error("Save User Error", result.err);
      yield put(fbLoginFailure(result.err.message));
    } else {
      yield put(userUpdated(result));
    }
  } catch (err) {
    log.error("FB Signup Error", err);
    yield put(fbLoginFailure(err));
  }
}

function* listenForAuthChangeSaga(action) {
  log.debug("Auth change sata started.");
  const callback = action.payload.callback;
  yield call(dataProvider.listenForAuthChanges, callback);
}

function* authChangedSaga(action) {
  const user = action.payload.user;
  if (user) {
    const profileInfo = {
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL
    };
    yield call(updateUserProfile, user.uid, profileInfo);
  }
}

export function* authSaga() {
  log.info("Running Auth Saga");
  yield all([
    takeLatest(LOGOUT, logoutSaga),
    takeLatest(EMAIL_LOGIN, emailLoginSaga),
    takeLatest(EMAIL_SIGNUP, emailSignupSaga),
    takeLatest(FB_SIGNUP_SUCCESS, saveFbUserDataSaga),
    takeLatest(LISTEN_FOR_AUTH_CHANGE, listenForAuthChangeSaga),
    takeLatest(AUTH_STATE_CHANGED, authChangedSaga)
  ]);
}
