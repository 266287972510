import log from "loglevel"; //eslint-disable-line no-unused-vars
import { createSelector } from "reselect";
import {
  selectHuntChallenges,
  selectHuntTeams,
  selectHuntChallengeAttempts
} from "containers/ManageHunt/selectors";

const selectJudgingState = state => {
  return state.judging;
};

export const selectTeamFilter = createSelector(
  [selectJudgingState],
  judgingState => judgingState.get("selectedTeamId")
);
export const selectChallengeFilter = createSelector(
  [selectJudgingState],
  judgingState => judgingState.get("selectedChallengeId")
);
export const selectChallengeStatusFilter = createSelector(
  [selectJudgingState],
  judgingState => judgingState.get("selectedChallengeStatus")
);
export const selectChallengeTypeFilter = createSelector(
  [selectJudgingState],
  judgingState => judgingState.get("selectedChallengeType")
);

export const selectChallengeAttemptCount = createSelector(
  [selectHuntChallengeAttempts],
  attempts => attempts.length
);

export const selectViewChallengeAttempts = createSelector(
  [
    selectHuntChallengeAttempts,
    selectHuntChallenges,
    selectHuntTeams,
    selectTeamFilter,
    selectChallengeFilter,
    selectChallengeStatusFilter,
    selectChallengeTypeFilter
  ],
  (
    attempts,
    challenges,
    teams,
    teamId,
    challengeId,
    challengeStatus,
    challengeType
  ) => {
    if (attempts == null || challenges.isEmpty() || teams.isEmpty()) {
      return [];
    }

    log.debug("Selecting challenge attempts");

    log.debug("Filters: ", teamId, challengeId, challengeStatus, challengeType);

    const filteredAttempts = attempts.filter(attempt => {
      let include = true;
      if (teamId) {
        include = include && teamId === attempt.get("team");
      }

      if (challengeId) {
        include = include && challengeId === attempt.get("challenge");
      }

      if (challengeStatus) {
        include = include && challengeStatus === attempt.get("status");
      }
      include =
        include &&
        attempt.get("status") !== "unsubmitted" &&
        attempt.get("status") !== "unattempted";

      if (challengeType) {
        include = include && challengeType === attempt.get("itemType");
      }

      if (
        attempt.get("status") === "unsubmitted" &&
        attempt.get("itemType") === "qr"
      ) {
        log.debug("Including: ", include);
      }

      return include;
    });

    const inflatedAttempts = filteredAttempts.map(attempt => {
      const challengeId = attempt.get("challenge");
      const challenge = challenges.get(challengeId, null);
      const teamId = attempt.get("team");
      const team = teams.get(teamId, null);
      return attempt
        .set("team", team)
        .set("challenge", challenge)
        .toJS();
    });

    const sortedAttempts = inflatedAttempts.sort((a, b) => {
      if (!a.submittedAt || !b.submittedAt) {
        return 0;
      }
      if (a.submittedAt.seconds < b.submittedAt.seconds) {
        return -1;
      }
      if (a.submittedAt.seconds > b.submittedAt.seconds) {
        return 1;
      }
      return 0;
    });

    return sortedAttempts;
  }
);

export const selectJudgingLoading = createSelector(
  [selectJudgingState],
  judgeState =>
    judgeState.get("loadingAttempts") ||
    judgeState.get("loadingTeams") ||
    judgeState.get("loadingChallenges")
);

export const selectAttemptsBeingJudged = createSelector(
  [selectJudgingState],
  jState => jState.get("attemptsBeingJudged").toArray()
);
