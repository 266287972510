import log from "loglevel"; // eslint-disable-line no-unused-vars
import { LISTENER_ACTION_CLOSE, LISTENER_ACTION_OPEN } from "./constants";
import DataProvider from "DataProvider";

const socketMiddleware = () => {
  let listeners = {};

  // the actual middleware
  // eslint-disable-next-line no-unused-vars
  return () => next => action => {
    let result = next(action);

    const {
      listenerAction, // == CONNECT or DISCONNECT
      openFunction, // = () => {}, // <-- This should return the listener
      listenerId // <-- To store it. If this is missing, this is required
    } = action;

    if (!listenerAction) {
      // If not a socket action, pass it on
      return result;
    }

    if (listenerAction === LISTENER_ACTION_OPEN) {
      log.info("Opening a listener function... on id", listenerId);

      if (listeners[listenerId]) {
        log.error("Opening a listener that already exists: ", listenerId);
        DataProvider.instance().stopWatching(listeners[listenerId]);
      }

      listeners[listenerId] = openFunction();
    } else if (listenerAction === LISTENER_ACTION_CLOSE) {
      log.info("Closing a listener function... on id", listenerId);
      if (listeners[listenerId]) {
        DataProvider.instance().stopWatching(listeners[listenerId]);
      }
      delete listeners[listenerId];
    }

    return result;
  };
};

export default socketMiddleware();
