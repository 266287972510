import React from "react";
import PropTypes from "prop-types";
import log from "loglevel";
import { connect } from "react-redux";
import { Form, Button, Divider, Icon, Image, Message } from "semantic-ui-react";
import Field from "components/Field";
import { reduxForm, propTypes } from "redux-form/immutable";
import moment from "moment";
import { required } from "utils/validators";
import { v4 as uuidv4 } from "uuid";
import DropzoneField from "components/DropzoneField";
import { getAddressObjectFromPlace } from "components/GoogleMaps/PlaceUtils";
import TimeField from "components/DateTimeField/TimeField";
import DateField from "components/DateTimeField/DateField";
import { createStructuredSelector } from "reselect";
import {
  selectHuntLocationPoint,
  selectHuntError,
  selectHuntSKU
} from "../selectors";
import DataProvider from "DataProvider";

// import { generateRandomImageURI } from "utils/abstractImage";
import LocationField from "components/LocationField";

import "./index.css";

const dataProvider = DataProvider.instance();

class CreateBasicHuntInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      date: moment(),
      scriptLoaded: false,
      showAddressFields: false,
      usePlaceholderImage: false,
      // placeholderURI: generateRandomImageURI({
      //   seed: Math.random(),
      //   width: 1000,
      //   height: 1000
      // }),
      localFile: null,
      localFileURL: null,
      loading: false
    };
  }

  handleNext = async (event, target) => {
    // Validate
    if (this.props.valid) {
      this.setState({ loading: true });
      // Inserting an upload step
      const progressCallback = progress =>
        log.debug("TODO: Dispatch progress callback", progress);
      const completionCallback = (storagePath, url) => {
        this.setState({ loading: false });
        this.props.change("huntImageURL", url);
        this.props.change("huntImagePath", storagePath);
        this.props.handleNext(event, target);
      };
      const uid = uuidv4();
      const filePath = `hunt-files/logos/${uid}`;

      if (this.state.localFile) {
        dataProvider.uploadFileFromFile(
          this.state.localFile,
          filePath,
          progressCallback,
          completionCallback
        );
      } else {
        let { url, path } = await dataProvider.getPlaceholderHuntLogo();

        log.info("Got URL & Path: ", url, path);

        completionCallback(path, url);

        // dataProvider.uploadFileFromDataURL(
        //   this.state.placeholderURI,
        //   filePath,
        //   progressCallback,
        //   completionCallback
        // );
      }
    } else {
      log.debug("Error, form not completed");
    }
  };

  removeImage = () => {
    this.setState({
      localFile: null,
      localFileURL: null
    });
  };

  handleAddFiles = acceptedFiles => {
    log.debug("Added file: ", acceptedFiles);
    if (acceptedFiles.length > 0) {
      const localURL = URL.createObjectURL(acceptedFiles[0]);
      this.setState({ localFile: acceptedFiles[0], localFileURL: localURL });
    }
  };

  handlePlaceholderButtonClicked = () =>
    this.setState({
      usePlaceholderImage: !this.state.usePlaceholderImage,
      localFileURL: null,
      localFile: null
    });

  handlePlaceSelected = placeObject => {
    this.setState({ showAddressFields: true });
    const addressObject = getAddressObjectFromPlace(placeObject);
    // this.props.changeField(this.props.change("location", addressObject));
    for (const [key, value] of Object.entries(addressObject)) {
      this.props.changeField(this.props.change(`location.${key}`, value));
      log.debug("key, value: ", key, value);
      // console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
    }
  };

  clearDate = () => {
    this.props.changeField(this.props.change("huntStartDate", null));
  };

  switchToAddress = () => {
    this.setState({ showAddressFields: true });
  };

  resetAddress = e => {
    e.preventDefault();
    this.setState({ showAddressFields: false });
    const locationFields = [
      "locationName",
      "streetAddress",
      "streetAddress2",
      "state",
      "zip",
      "country"
    ];

    for (let fieldName in locationFields) {
      this.props.changeField(this.props.change(`location.${fieldName}`, ""));
    }

    this.props.changeField(this.props.change("location.point", null));
  };

  render() {
    let {
      showAddressFields,
      usePlaceholderImage,
      // placeholderURI,
      localFileURL,
      loading
    } = this.state;

    const { locationPoint, error, huntSKU } = this.props;

    if (!huntSKU) {
      return <div />;
    }

    if (error) {
      loading = false;
    }

    return (
      <Form>
        <Field
          required
          name="huntName"
          component={Form.Input}
          label="Name"
          placeholder="Give your hunt a name"
          validate={[required]}
        />
        <Field
          label="Description"
          name="description"
          component="textarea"
          placeholder="Write a short description of your hunt."
        />
        <Form.Group widths="equal">
          <DateField
            label="Hunt Start Date"
            name="huntStartDate"
            placeholder="Hunt Start Date"
            onClear={() => {
              this.clearDate();
            }}
          />
          <TimeField
            label="Hunt Start Time"
            name="huntStartTime"
            placeholder="Hunt Start Time"
          />
        </Form.Group>
        <LocationField
          label="Location"
          handlePlaceSelected={this.handlePlaceSelected}
          showAddressFields={showAddressFields}
          locationPoint={locationPoint}
          showSwitchButton={true}
          handleSwitchStateToAddress={this.switchToAddress}
          handleSwitchStateToSearch={this.resetAddress}
        />
        <Form.Field>
          <label>Hunt Image</label>
          {/*{usePlaceholderImage ? (*/}
          {/*  <Image src={placeholderURI} size="small" />*/}
          {/*) : (*/}
          <div>
            {localFileURL ? (
              <div>
                <Image src={localFileURL} size="small" bordered />
                <br />
                <Button size="small" onClick={this.removeImage}>
                  Remove Image
                </Button>
              </div>
            ) : (
              ""
            )}
            <DropzoneField
              onAddFiles={this.handleAddFiles}
              visible={!localFileURL}
            />
          </div>
          {/*)}*/}
          <br />
          <a
            onClick={this.handlePlaceholderButtonClicked}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: ".9rem"
            }}
            hidden
          >
            <Icon name="clone outline" />
            {usePlaceholderImage
              ? "Upload an Image"
              : "Generate Placeholder Image"}
          </a>
        </Form.Field>
        <Field name="huntImageURL" type="hidden" />
        <Field name="huntImagePath" type="hidden" />
        <Divider hidden />
        {error ? (
          <Message error header="There was an error with your submission">
            {error.message}
          </Message>
        ) : (
          ""
        )}
        <Button
          onClick={this.handleNext}
          primary
          loading={loading}
          disabled={loading}
        >
          {huntSKU.price > 0 ? "Go to Checkout" : "Get Started"}
        </Button>
      </Form>
    );
  }
}

CreateBasicHuntInfo.propTypes = {
  ...propTypes,
  handleNext: PropTypes.func.isRequired,
  locationPoint: PropTypes.object,
  // priceLevel: PropTypes.string,
  huntSKU: PropTypes.object.isRequired,
  error: PropTypes.object
};

const FormHuntInfo = reduxForm({
  form: "basicHuntInfo"
})(CreateBasicHuntInfo);

const mapStateToProps = createStructuredSelector({
  locationPoint: selectHuntLocationPoint,
  // priceLevel: selectHuntLevel,
  huntSKU: selectHuntSKU,
  error: selectHuntError
});

const mapDispatchToProps = dispatch => ({
  changeField: changeAction => dispatch(changeAction)
});

const ConnectedFormHuntInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormHuntInfo);

export default ConnectedFormHuntInfo;
