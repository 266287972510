import { schema } from "normalizr";
import { HuntSchema } from "./Hunt";
import { TeamSchema } from "./Team";
import { ChallengeSchema } from "./Challenge";

export const ChallengeAttemptSchema = new schema.Entity(
  "challengeAttempts",
  {
    hunt: HuntSchema,
    team: TeamSchema,
    challenge: ChallengeSchema,
  },
  {
    idAttribute: "objectId",
  }
);
