import { createSelector } from "reselect";
import { CREATE_EDIT_CHALLENGE_FORM_NAME } from "./constants";
import { selectCurrentHunt } from "../../redux/selectors";

const selectChallengeForm = (state) =>
  state.form.get(CREATE_EDIT_CHALLENGE_FORM_NAME);

export const selectHasPoints = createSelector(selectCurrentHunt, (hunt) =>
  hunt ? hunt.type.toLowerCase() === "classic" : false
);

// export const selectIsPremium = createSelector(selectCurrentHunt, (hunt) =>
//   hunt ? hunt.priceLevel.toLowerCase() !== "basic" : false
// );

export const selectChallengeType = createSelector(selectChallengeForm, (form) =>
  form ? form.getIn(["values", "type"], null) : null
);

export const selectLatitude = createSelector(selectChallengeForm, (form) => {
  return form ? form.getIn(["values", "latitude"], null) : null;
});

export const selectLongitude = createSelector(selectChallengeForm, (form) =>
  form ? form.getIn(["values", "longitude"], null) : null
);

export const selectRadius = createSelector(selectChallengeForm, (form) =>
  form ? form.getIn(["values", "radius"], null) : null
);

export const selectShouldShowPassword = createSelector(
  selectChallengeType,
  (challengeType) => challengeType === "password"
);

export const selectShouldShowMap = createSelector(
  selectChallengeType,
  (challengeType) => challengeType === "geopoint"
);

export const selectErrors = createSelector(selectChallengeForm, (form) =>
  form ? form.get("syncErrors", null) : null
);

export const selectValues = createSelector(selectChallengeForm, (form) =>
  form ? form.get("values") : null
);

export const selectChallengeFormInitialValues = createSelector(
  (state, props) => props.challenge,
  (challenge) =>
    challenge
      ? {
          type: challenge.type,
          latitude: challenge.point ? challenge.point.latitude : undefined,
          longitude: challenge.point ? challenge.point.longitude : undefined,
          radius: challenge.radius ? challenge.radius : undefined,
          password: challenge.password,
          name: challenge.name,
          description: challenge.description,
          points: challenge.points,
        }
      : {
          latitude: 40.689247,
          longitude: -74.044502,
          radius: 100,
        }
);
