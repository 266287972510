/* eslint-disable no-undef */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import App from "./App";
import configureStore from "./redux/store";
import registerServiceWorker from "./registerServiceWorker";
import log from "loglevel";
import prefix from "loglevel-plugin-prefix";
import * as Sentry from "@sentry/browser";
import "./index.css";

import DataProvider from "DataProvider";

// import RemoteConfig from "RemoteConfig";

// Configure logging
const logLevel = process.env.REACT_APP_LOG_LEVEL || "error";
log.setDefaultLevel(logLevel);
prefix.apply(log);

DataProvider.instance().initializeDataProvider();
// RemoteConfig.initialize();

// Configure Sentry.io crash logging
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN
});

const history = createBrowserHistory();

// Initialize Store
let store = configureStore(history);

// Run the app!
ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
