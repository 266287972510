import { fromJS } from "immutable";
import { INITIALIZE_APP, INITIALIZE_APP_COMPLETE } from "redux/constants";

const initialState = fromJS({
  initializing: true,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_APP:
      return state.set("initializing", true);
    case INITIALIZE_APP_COMPLETE:
      return state.set("initializing", false);
    default:
      break;
  }
  return state;
}

export default appReducer;
