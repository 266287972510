import log from "loglevel"; // eslint-disable-line no-unused-vars
import { createSelector } from "reselect";
import { inflateObjects } from "utils/selectors";
import { fromJS } from "immutable";
import { selectCurrentHunt } from "redux/selectors";

const selectDomain = state => state.manageHunt.challenges;

const selectHuntChallenges = state =>
  selectDomain(state).get("huntChallenges", fromJS({}));

export const selectCurrentChallenges = createSelector(
  state => state.data,
  selectCurrentHunt,
  selectHuntChallenges,
  (data, hunt, huntChallenges) => {
    if (data.size === 0) {
      return [];
    }
    if (!hunt) {
      return [];
    }

    const challenges = inflateObjects(
      data,
      huntChallenges.get(hunt.objectId),
      "challenges"
    );
    return challenges.sort((a, b) => a.sortIndex - b.sortIndex);
  }
);

export const selectLoading = createSelector(selectDomain, ui =>
  ui.get("loading")
);
export const makeSelectError = () =>
  createSelector(selectDomain, ui => ui.get("error"));
