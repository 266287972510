import log from "loglevel"; //eslint-disable-line no-unused-vars
import { createSelector } from "reselect";
import { List } from "immutable";
import {
  selectFeedItems,
  selectCurrentHunt,
  selectCurrentHuntId,
  selectData
} from "redux/selectors";
import { inflateObjects } from "utils/selectors";

const selectFeedState = state => state.feed;
const selectHuntFeedState = createSelector(
  selectFeedState,
  selectCurrentHuntId,
  (feedState, huntId) => feedState.get(huntId)
);

const selectFeedIsLoading = createSelector(selectHuntFeedState, state => {
  log.debug("Selecting feed loading: ", state.get("loadingFeed"));
  return state ? state.get("loadingFeed") : false;
});

const selectHasMoreItems = createSelector(selectHuntFeedState, state =>
  state ? state.get("hasMore") : false
);

const selectFeedItemIds = createSelector(selectHuntFeedState, state => {
  return state ? state.get("feedItemIds") : List();
});

const selectHuntFeedItems = createSelector(
  selectData,
  selectCurrentHuntId,
  selectCurrentHunt,
  selectFeedItems,
  selectFeedItemIds,
  (data, huntId, hunt, feedItems, feedItemIds) => {
    if (!hunt || !feedItemIds || !data) {
      return [];
    }

    let inflatedObjects = inflateObjects(data, feedItemIds, "feedItem");

    return inflatedObjects.filter(feedItem => {
      return !!feedItem.mediaURL;
    });
    // return inflatedFeedItems;

    // const { settings } = hunt;
    //
    // let output = feedItems
    //   .filter(item => item.get("hunt") === huntId)
    //   .toList()
    //   .sortBy(item => item.get("submittedAt.seconds"))
    //   .reverse();
    //
    // log.info(
    //   `Selecting from ${feedItems.size} items and found ${output.size} items`
    // );
    //
    // return output.toJS();
  }
);

export { selectFeedIsLoading, selectHuntFeedItems, selectHasMoreItems };
