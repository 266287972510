import { fromJS } from "immutable";

import {
  CREATE_TEAM_AND_JOIN_HUNT,
  CREATE_TEAM_AND_JOIN_HUNT_FAILED,
  CREATE_TEAM_AND_JOIN_HUNT_SUCCEEDED,
  FETCH_AM_I_REGISTERED,
  FETCH_AM_I_REGISTERED_FAILED,
  FETCH_AM_I_REGISTERED_SUCCEEDED,
  UPDATE_TEAM_NAME,
  UPDATE_TEAM_NAME_FAILED,
  UPDATE_TEAM_NAME_SUCCEEDED,
} from "./constants";
import {
  LOAD_HUNT,
  LOAD_HUNT_CAPABILITIES,
  LOAD_HUNT_CAPABILITIES_FAILURE,
  LOAD_HUNT_CAPABILITIES_SUCCESS,
  LOAD_HUNT_FAILURE,
  LOAD_HUNT_SUCCESS,
} from "redux/constants";
import { LOGGED_OUT } from "reducers/AuthReducer";

import log from "loglevel"; // eslint-disable-line no-unused-vars

const initialState = fromJS({
  myRegisteredTeam: undefined,
  registeredLoading: false,
  registeredConfirmed: false,
  // loading: false,
  loadingHunt: false,
  loadingNewTeam: false,
  loadingHuntCapabilities: false,
  error: null,
  teamNameSaving: false,
});

export default function joinHuntReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_HUNT:
      return state.set("loadingHunt", true).set("error", null);
    case LOAD_HUNT_SUCCESS:
      return state.set("loadingHunt", false);
    case LOAD_HUNT_FAILURE:
      return state.set("loadingHunt", false).set("error", action.error);
    case LOAD_HUNT_CAPABILITIES:
      return state.set("loadingHuntCapabilities", true);
    case LOAD_HUNT_CAPABILITIES_SUCCESS:
      return state.set("loadingHuntCapabilities", false);
    case LOAD_HUNT_CAPABILITIES_FAILURE:
      return state.set("loadingHuntCapabilities", false);
    case CREATE_TEAM_AND_JOIN_HUNT:
      return state.set("loadingNewTeam", true).set("error", null);
    case CREATE_TEAM_AND_JOIN_HUNT_SUCCEEDED:
      return state
        .set("loadingNewTeam", false)
        .set("error", null)
        .set("currentUserIsRegistered", true);
    case CREATE_TEAM_AND_JOIN_HUNT_FAILED:
      return state.set("loadingHunt", false).set("error", action.error.message);
    case FETCH_AM_I_REGISTERED:
      return state.set("registeredLoading", true);
    case FETCH_AM_I_REGISTERED_FAILED:
      return state.set("registeredLoading", false).set("error", action.error);
    case FETCH_AM_I_REGISTERED_SUCCEEDED:
      return state
        .set("registeredLoading", false)
        .set("myRegisteredTeam", action.payload.result);
    case LOGGED_OUT:
      return initialState;
    case UPDATE_TEAM_NAME:
      return state.set("teamNameSaving", true);
    case UPDATE_TEAM_NAME_FAILED:
      return state.set("teamNameSaving", false);
    case UPDATE_TEAM_NAME_SUCCEEDED:
      return state.set("teamNameSaving", false);
    default:
      return state;
  }
}
