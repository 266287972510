import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Accordion, Dropdown, Header, Icon, Segment } from "semantic-ui-react";
import {
  JUDGING_FILTER_CHALLENGE,
  JUDGING_FILTER_CHALLENGE_STATUS,
  JUDGING_FILTER_CHALLENGE_TYPE,
  JUDGING_FILTER_TEAM
} from "../constants";

class JudgingFilters extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      showFilters: false
    };
  }

  handleFilterClicked = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  render() {
    const {
      selectedChallengeId,
      selectedTeamId,
      selectedChallengeType,
      selectedChallengeStatus,
      teams,
      challenges,
      handleFilterChanged,
      clearAllFilters,
      resetFilters
    } = this.props;
    const { showFilters } = this.state;

    const teamOptions = teams
      .toArray()
      .map(team => {
        return {
          key: team.get("objectId"),
          value: team.get("objectId"),
          text: team.get("name")
        };
      })
      .sort((t1, t2) => (t1.text > t2.text ? 1 : t2.text > t1.text ? -1 : 0));
    const challengeOptions = _.sortBy(
      challenges.toArray().map(challenge => {
        return {
          key: challenge.get("objectId"),
          value: challenge.get("objectId"),
          text: challenge.get("name"),
          index: challenge.get("sortIndex")
        };
      }),
      ["index"]
    );
    const challengeStatusOptions = [
      {
        key: "attempted",
        text: "Not Reviewed",
        value: "attempted",
        icon: <Icon name="circle outline" color="grey" />
      },
      {
        key: "rejected",
        text: "Rejected",
        value: "rejected",
        icon: <Icon name="times circle" color="red" />
      },
      {
        key: "approved",
        text: "Approved/Completed",
        value: "completed",
        icon: <Icon name="check circle" color="green" />
      }
    ];
    const challengeTypeOptions = [
      {
        key: "photo",
        text: "Photo",
        value: "photo",
        icon: "photo"
      },
      {
        key: "password",
        text: "Password",
        value: "password",
        icon: "lightbulb"
      },
      {
        key: "video",
        text: "Video",
        value: "video",
        icon: "video camera"
      },
      {
        key: "qr",
        text: "QR Code",
        value: "qr",
        icon: "qrcode"
      },
      {
        key: "geo",
        text: "Geo-Location",
        value: "geopoint",
        icon: "marker"
      }
    ];

    let filterCount =
      (selectedTeamId ? 1 : 0) +
      (selectedChallengeId ? 1 : 0) +
      (selectedChallengeType ? 1 : 0) +
      (selectedChallengeStatus ? 1 : 0);

    return (
      <div>
        <Accordion>
          <Accordion.Title
            active={showFilters}
            onClick={this.handleFilterClicked}
          >
            {showFilters ? (
              <Icon name="triangle down" />
            ) : (
              <Icon name="triangle right" />
            )}
            Filters ({filterCount})
          </Accordion.Title>

          <Accordion.Content active={showFilters}>
            <Segment secondary>
              <Header as="h4">Challenge Status</Header>
              <Dropdown
                fluid
                search
                selection
                clearable
                placeholder={"All Challenge Statuses"}
                options={challengeStatusOptions}
                value={selectedChallengeStatus}
                onChange={handleFilterChanged(JUDGING_FILTER_CHALLENGE_STATUS)}
              />
              <Header as="h4">Challenge Type</Header>
              <Dropdown
                fluid
                search
                selection
                clearable
                placeholder={"All Challenge Types"}
                options={challengeTypeOptions}
                value={selectedChallengeType}
                onChange={handleFilterChanged(JUDGING_FILTER_CHALLENGE_TYPE)}
              />
              <Header as="h4">Team</Header>
              <Dropdown
                fluid
                search
                selection
                clearable
                placeholder={"All Teams"}
                options={teamOptions}
                value={selectedTeamId}
                onChange={handleFilterChanged(JUDGING_FILTER_TEAM)}
              />
              <Header as="h4">Challenge</Header>
              <Dropdown
                fluid
                search
                selection
                clearable
                placeholder={"All Challenges"}
                options={challengeOptions}
                value={selectedChallengeId}
                onChange={handleFilterChanged(JUDGING_FILTER_CHALLENGE)}
              />
            </Segment>
          </Accordion.Content>
        </Accordion>
        {filterCount > 0 && (
          <a
            onClick={clearAllFilters}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: ".9rem"
            }}
          >
            <Icon name="eye" />
            {"Show all submissions"}
          </a>
        )}
        {filterCount === 0 && (
          <a
            onClick={resetFilters}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: ".9rem"
            }}
          >
            <Icon name="repeat" />
            {"Only show submissions needing approval"}
          </a>
        )}
      </div>
    );
  }
}

JudgingFilters.propTypes = {
  selectedChallengeType: PropTypes.string,
  selectedChallengeStatus: PropTypes.string,
  selectedChallengeId: PropTypes.string,
  selectedTeamId: PropTypes.string,
  teams: PropTypes.object,
  challenges: PropTypes.object,

  handleFilterChanged: PropTypes.func,
  clearAllFilters: PropTypes.func,
  resetFilters: PropTypes.func
};

export default JudgingFilters;
