import React from "react";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import { Card, Placeholder } from "semantic-ui-react";

const PlaceholderFeedItem = () => (
  <Card raised style={{ width: "420px" }}>
    <Card.Content>
      <Placeholder>
        <Placeholder.Header>
          <Placeholder.Line length="medium" />
        </Placeholder.Header>
      </Placeholder>
    </Card.Content>
    <Placeholder>
      <Placeholder.Image rectangular />
    </Placeholder>
    <Card.Content extra>
      <Card.Description>
        <Placeholder>
          <Placeholder.Line length="long" />
          <Placeholder.Line length="medium" />
        </Placeholder>
      </Card.Description>
    </Card.Content>
  </Card>
);

export default PlaceholderFeedItem;
