/* eslint-disable no-undef */
/* eslint-disable no-case-declarations  */
import makeSegment from "./segment";
import log from "loglevel";
import {
  ANALYTICS_IDENTIFY,
  ANALYTICS_PAGE,
  ANALYTICS_TRACK,
  USER_FIELDS_TO_IGNORE,
  OBJECT_FIELDS_TO_IGNORE
} from "./constants";
import _ from "lodash";

import { selectCurrentUser } from "utils/selectors";

const reduxAnalytics = (
  track,
  select = ({ meta }) => meta.analytics
) => store => next => action => {
  const returnValue = next(action);

  if (!action || !action.meta) {
    return returnValue;
  }

  const event = select(action);

  if (!event) {
    return returnValue;
  }

  track(event, store.getState());

  return returnValue;
};

makeSegment();
const middleware = reduxAnalytics(({ method, type, payload }, state) => {
  // if (process.env.NODE_ENV !== "production") {
  //   return;
  // }
  switch (method) {
    case ANALYTICS_IDENTIFY:
      const user = _.omit(selectCurrentUser(state), USER_FIELDS_TO_IGNORE);
      user["name"] = user.displayName;
      user["avatar"] = user.photoURL;
      if (user) {
        log.debug("identifying user", user.uid, user);
      } else {
        log.debug("identifying null user/resetting");
      }
      if (user) {
        analytics.identify(user.uid, user);
      } else {
        analytics.reset();
      }
      break;
    case ANALYTICS_TRACK:
      const modifiedPayload = _.omit(payload, OBJECT_FIELDS_TO_IGNORE);
      analytics.track(type, modifiedPayload);
      break;
    case ANALYTICS_PAGE:
      analytics.page({
        path: payload.path
      });
      break;
    default:
      return;
  }
});
export default middleware;
