import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Divider,
  Card,
  Header,
  Segment,
  Input,
  Modal,
  Button,
  Pagination
} from "semantic-ui-react";
import DataProvider from "DataProvider";
import { createStructuredSelector } from "reselect";
import {
  selectHuntChallenges,
  selectHuntTeams
} from "containers/ManageHunt/selectors";
import { loadHunt } from "redux/actionCreators";
import {
  loadTeamsSuccess,
  loadHuntChallengesSuccess,
  challengeAttemptsUpdated
} from "containers/ManageHunt/actions";
import {
  selectViewChallengeAttempts,
  selectChallengeFilter,
  selectChallengeStatusFilter,
  selectChallengeTypeFilter,
  selectTeamFilter,
  selectChallengeAttemptCount,
  selectJudgingLoading,
  selectAttemptsBeingJudged
} from "./selectors";
import {
  approveAttempt,
  changeJudgingFilter,
  rejectAttempt,
  startLoadingJudgingData
} from "./actions";
import ChallengeAttemptCard from "./components/challengeAttemptCard";
import PlaceholderAttemptCard from "./components/placeholderAttemptCard";
import JudgingFilters from "./components/judgingFilters";
import {
  JUDGING_FILTER_TEAM,
  JUDGING_FILTER_CHALLENGE,
  JUDGING_FILTER_CHALLENGE_STATUS,
  JUDGING_FILTER_CHALLENGE_TYPE
} from "./constants";
import { selectCurrentHunt } from "redux/selectors";
import { HUNT_STATE_PUBLISHED, HUNT_STATE_UNPUBLISHED } from "models/constants";

class ManageJudging extends React.PureComponent {
  handleApproveClicked = attempt => () => {
    log.debug("Attempt approved", attempt);
    // Dispatch an action to approve that
    this.props.onApproveAttempt(attempt);
  };

  handleMessageChanged = (event, data) => {
    this.setState({ rejectMessage: data.value });
  };

  handleRejectClicked = attempt => () => {
    // Show the modal
    this.setState({
      showMessageModal: true,
      rejectMessage: "",
      rejectedAttempt: attempt
    });
  };

  handleMessageModalSaved = () => {
    this.props.onRejectAttempt(
      this.state.rejectedAttempt,
      this.state.rejectMessage
    );

    this.setState({
      showMessageModal: false,
      rejectMessage: "",
      rejectedAttempt: null
    });
  };

  handleMessageModalCancel = () => {
    this.setState({ showMessageModal: false, rejectMessage: "" });
  };

  handleFilterChanged = filterType => (event, data) => {
    let newValue = data.value;
    this.props.onFilterChanged(filterType, newValue);
    this.setState({ page: 1 });
  };

  clearAllFilters = () => {
    [
      JUDGING_FILTER_TEAM,
      JUDGING_FILTER_CHALLENGE,
      JUDGING_FILTER_CHALLENGE_STATUS,
      JUDGING_FILTER_CHALLENGE_TYPE
    ].forEach(filterName => {
      this.props.onFilterChanged(filterName, "");
    });
    this.setState({ page: 1 });
  };

  resetFilters = () => {
    [
      JUDGING_FILTER_TEAM,
      JUDGING_FILTER_CHALLENGE,
      JUDGING_FILTER_CHALLENGE_TYPE
    ].forEach(filterName => {
      this.props.onFilterChanged(filterName, "");
    });
    this.props.onFilterChanged(JUDGING_FILTER_CHALLENGE_STATUS, "attempted");
    this.setState({ page: 1 });
  };

  handlePageChanged = (e, d) => {
    this.setState({ page: d.activePage });
  };

  constructor() {
    super();
    this.state = {
      showMessageModal: false,
      rejectMessage: "",
      rejectedAttempt: null,
      page: 1
    };
  }

  componentDidMount() {
    this.props.startLoading();
    this.props.loadHunt(this.props.match.params.huntId);
    this.attemptListener = DataProvider.instance().watchChallengeAttempts(
      this.props.match.params.huntId,
      null,
      null,
      null,
      null,
      this.props.onChallengeAttemptsUpdated
    );
    this.challengeListener = DataProvider.instance().watchChallenges(
      this.props.match.params.huntId,
      this.props.onChallengesUpdated
    );
    this.teamListener = DataProvider.instance().watchTeams(
      this.props.match.params.huntId,
      this.props.onTeamsUpdated
    );
  }

  componentWillUnmount() {
    DataProvider.instance().stopWatching(this.challengeListener);
    DataProvider.instance().stopWatching(this.attemptListener);
    DataProvider.instance().stopWatching(this.teamListener);
  }

  render() {
    const {
      hunt,
      challengeAttempts,
      teams,
      challenges,
      attemptCount,
      loading,
      selectedChallengeStatus,
      selectedChallengeId,
      selectedTeamId,
      selectedChallengeType,
      attemptsBeingJudged
    } = this.props;

    const { showMessageModal, page } = this.state;

    let huntNotStarted = false;

    if (hunt) {
      huntNotStarted =
        hunt.status === HUNT_STATE_UNPUBLISHED ||
        hunt.status === HUNT_STATE_PUBLISHED;
    }

    const title =
      "Judging" + (loading ? "" : ` (${attemptCount} Total Submissions)`);

    const attemptsPerPage = 10;
    const totalPages = _.floor(challengeAttempts.length / attemptsPerPage) + 1;

    const currentAttempts = _.slice(
      challengeAttempts,
      attemptsPerPage * (page - 1),
      attemptsPerPage * page
    );

    const headerAndFilters = (
      <div>
        <Header as="h2">{title}</Header>
        <JudgingFilters
          challenges={challenges}
          teams={teams}
          selectedChallengeId={selectedChallengeId}
          selectedChallengeStatus={selectedChallengeStatus}
          selectedChallengeType={selectedChallengeType}
          selectedTeamId={selectedTeamId}
          handleFilterChanged={this.handleFilterChanged}
          clearAllFilters={this.clearAllFilters}
          resetFilters={this.resetFilters}
        />
      </div>
    );

    const notStartedBody = (
      <Segment textAlign="center">
        <div>
          <Header>You have not started your hunt yet.</Header>
          <p>
            Once your hunt is active and participants complete challenges,
            you&apos;ll see submissions come in here to approve!
          </p>
        </div>
      </Segment>
    );

    const loadingBody = (
      <Card.Group itemsPerRow={1}>
        <PlaceholderAttemptCard />
      </Card.Group>
    );

    const emptyStateBody = (
      <Segment textAlign="center">
        <Header>No submissions to review.</Header>
        {attemptCount > 0
          ? `(${attemptCount} submissions currently filtered)`
          : ""}
      </Segment>
    );

    const judgingCardsBody = (
      <div>
        <Card.Group itemsPerRow={1}>
          {currentAttempts.map(attempt => (
            <ChallengeAttemptCard
              challengeAttempt={attempt}
              key={attempt.objectId}
              handleAccept={this.handleApproveClicked(attempt)}
              handleReject={this.handleRejectClicked(attempt)}
              isJudging={attemptsBeingJudged.includes(attempt.objectId)}
            />
          ))}
        </Card.Group>

        {challengeAttempts.length > attemptsPerPage ? (
          <div>
            <Divider />
            <Pagination
              totalPages={totalPages}
              activePage={page}
              boundaryRange={2}
              siblingRange={2}
              onPageChange={this.handlePageChanged}
            />
          </div>
        ) : null}
      </div>
    );

    const rejectModal = (
      <Modal
        size="tiny"
        open={showMessageModal}
        onClose={this.handleMessageModalCancel}
      >
        <Modal.Header>Add Rejection Reason</Modal.Header>
        <Modal.Content>
          <Input
            fluid
            placeholder="Rejection message..."
            onChange={this.handleMessageChanged}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleMessageModalCancel}>Cancel</Button>
          <Button onClick={this.handleMessageModalSaved} primary>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );

    let body = judgingCardsBody;
    if (loading) {
      body = loadingBody;
    } else if (huntNotStarted) {
      body = notStartedBody;
    } else if (challengeAttempts.length === 0) {
      body = emptyStateBody;
    }

    return (
      <div>
        {headerAndFilters}
        <Divider />
        {body}
        {rejectModal}
      </div>
    );
  }
}

ManageJudging.propTypes = {
  match: PropTypes.object,
  hunt: PropTypes.object,
  teams: PropTypes.object,
  challenges: PropTypes.object,
  challengeAttempts: PropTypes.array,
  selectedTeamId: PropTypes.string,
  selectedChallengeId: PropTypes.string,
  selectedChallengeStatus: PropTypes.string,
  selectedChallengeType: PropTypes.string,
  attemptsBeingJudged: PropTypes.array,
  attemptCount: PropTypes.number,
  loading: PropTypes.bool,

  startLoading: PropTypes.func,
  loadHunt: PropTypes.func,
  onChallengesUpdated: PropTypes.func,
  onChallengeAttemptsUpdated: PropTypes.func,
  onParticipantsUpdated: PropTypes.func,
  onTeamsUpdated: PropTypes.func,
  onApproveAttempt: PropTypes.func,
  onRejectAttempt: PropTypes.func,
  onFilterChanged: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  hunt: selectCurrentHunt,
  teams: selectHuntTeams,
  challenges: selectHuntChallenges,
  challengeAttempts: selectViewChallengeAttempts,
  attemptCount: selectChallengeAttemptCount,
  selectedTeamId: selectTeamFilter,
  selectedChallengeId: selectChallengeFilter,
  selectedChallengeStatus: selectChallengeStatusFilter,
  selectedChallengeType: selectChallengeTypeFilter,
  attemptsBeingJudged: selectAttemptsBeingJudged,
  loading: selectJudgingLoading
});

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(startLoadingJudgingData()),
  loadHunt: huntId => dispatch(loadHunt(huntId)),
  onChallengeAttemptsUpdated: challengeAttempts =>
    dispatch(challengeAttemptsUpdated(challengeAttempts)),
  onChallengesUpdated: (huntId, challenges) =>
    dispatch(loadHuntChallengesSuccess(huntId, challenges)),
  onTeamsUpdated: (huntId, teams) => dispatch(loadTeamsSuccess(huntId, teams)),
  onApproveAttempt: attempt => dispatch(approveAttempt(attempt)),
  onRejectAttempt: (attempt, message) =>
    dispatch(rejectAttempt(attempt, message)),
  onFilterChanged: (filterType, newValue) =>
    dispatch(changeJudgingFilter(filterType, newValue))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageJudging)
);
