import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { Segment, Header } from "semantic-ui-react";

import log from "loglevel"; // eslint-disable-line no-unused-vars

function DropzoneField({
  onAddFiles,
  acceptedFileTypes,
  multiple,
  maxSize,
  visible
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onAddFiles,
    accept: acceptedFileTypes,
    multiple,
    maxSize
  });

  const style = visible ? {} : { display: "none" };

  return (
    <div {...getRootProps()} style={style}>
      <input {...getInputProps()} />
      <Segment placeholder>
        <Header icon>
          {isDragActive
            ? "Drop the Image here"
            : "Drag and drop a jpg or png file here, or click to select an image."}
        </Header>
      </Segment>
    </div>
  );
}

DropzoneField.propTypes = {
  onAddFiles: PropTypes.func.isRequired,
  acceptedFileTypes: PropTypes.string,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  visible: PropTypes.bool
};

DropzoneField.defaultProps = {
  acceptedFileTypes: "image/jpeg, image/png",
  maxSize: 5000000,
  multiple: false,
  visible: true
};

export default DropzoneField;
