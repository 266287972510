import { fromJS } from "immutable";
import {
  LEADERBOARD_UPDATED,
  LOAD_HUNT,
  WATCH_LEADERBOARD
} from "redux/constants";

const initialState = fromJS({});

const initialHuntState = fromJS({
  loading: false,
  sortField: "points",
  sortDirection: "desc",
  leaderboardData: []
});

function leaderboardReducer2(state = initialState, action) {
  switch (action.type) {
    case LOAD_HUNT:
      return state.set(action.payload.huntId, initialHuntState);
    case WATCH_LEADERBOARD:
      return state.setIn([action.payload.huntId, "loading"], true);
    case LEADERBOARD_UPDATED:
      return state
        .setIn(
          [action.payload.huntId, "leaderboardData"],
          fromJS(action.payload.leaderboardData)
        )
        .setIn([action.payload.huntId, "loading"], false);
    default:
      break;
  }
  return state;
}

export default leaderboardReducer2;
