import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import moment from "moment";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Grid, Segment, Header, Image } from "semantic-ui-react";
import { loadHunt } from "redux/actionCreators";
import { fetchAmIRegistered } from "../actions";
import { selectCurrentUser, selectIsLoggedIn } from "utils/selectors";
import { selectMyRegisteredTeam } from "../selectors";

import confirmed from "images/mirage-message-sent.png";
import appleStoreImage from "images/Download-on-the-App-Store/US/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import { selectCurrentHunt } from "../../../redux/selectors";

class JoinHuntConfirmation extends React.PureComponent {
  UNSAFE_componentWillMount() {
    const { match } = this.props;

    this.props.loadHunt(match.params.huntId);
    this.props.fetchAmIRegistered(match.params.huntId);
  }

  render() {
    const { hunt, myTeam } = this.props;

    if (!hunt || !myTeam) {
      return "loading";
    }

    return (
      <div
        style={{
          color: "#3d3d3d",
          backgroundColor: "#333333",
          width: "100%",
          height: "100%",
          position: "absolute",
          x: "0",
          y: "0"
        }}
      >
        <Helmet
          title={`${hunt.name} - on Scavr`}
          meta={[
            { name: "description", content: `Confirmed for ${hunt.name}` }
          ]}
        />
        <Grid
          centered
          style={{
            marginTop: "4em"
          }}
        >
          <Grid.Column width={10}>
            <Segment padded={false}>
              <div>
                <Image src={confirmed} size="small" centered />
                <Header textAlign="center" icon>
                  <Header.Content>{"You're all set!"}</Header.Content>
                </Header>
                <p>
                  You have successfully signed up for <b>{hunt.name}</b> as the
                  team <b>{myTeam.name}</b>.<br />
                  <br />
                  {hunt.startTime
                    ? "Your hunt will begin on " +
                      moment(hunt.startTime).format(
                        "dddd, MMMM Do YYYY [at] h:mm a"
                      )
                    : ""}
                  .<br />
                  <br />
                  Please install the app before you arrive!
                </p>
                <a href="https://apps.apple.com/us/app/scavr-scavenger-hunts/id854221002">
                  <Image src={appleStoreImage} centered size="small" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.scavr.scavr&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <Image
                    centered
                    size="small"
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

JoinHuntConfirmation.propTypes = {
  match: PropTypes.object.isRequired,
  hunt: PropTypes.object,
  myTeam: PropTypes.object,

  fetchAmIRegistered: PropTypes.func.isRequired,
  loadHunt: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    loadHunt: huntId => dispatch(loadHunt(huntId)),
    fetchAmIRegistered: huntId => dispatch(fetchAmIRegistered(huntId))
  };
}

const mapStateToProps = createStructuredSelector({
  hunt: selectCurrentHunt,
  currentUser: selectCurrentUser,
  loggedIn: selectIsLoggedIn,
  myTeam: selectMyRegisteredTeam
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JoinHuntConfirmation)
);
