import log from "loglevel"; // eslint-disable-line no-unused-vars
import firebaseUtils from "./firebaseUtils";
import {
  loadModelForDocumentSnapshot,
  loadModelsForQuerySnapshot,
} from "./firebaseModels";
import { FIREBASE_COLLECTION_SKUS } from "./constants";

async function getSKUs(skuIds) {
  let querySnapshot = await firebaseUtils
    .db()
    .collection(FIREBASE_COLLECTION_SKUS)
    .where(firebaseUtils.firestore().FieldPath.documentId(), "in", skuIds)
    .get();

  let models = await loadModelsForQuerySnapshot(querySnapshot);
  return models;
}

async function loadSKU(skuId) {
  let skuSnapshot = await firebaseUtils
    .db()
    .collection(FIREBASE_COLLECTION_SKUS)
    .doc(skuId)
    .get();

  return await loadModelForDocumentSnapshot(skuSnapshot);
}

const api = {
  getSKUs,
  loadSKU,
};

export default api;
