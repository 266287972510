import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; //eslint-disable-line no-unused-vars
import { connect } from "react-redux";
import { withRouter } from "react-router";
import GlobalNav from "containers/GlobalNav";
import { Route } from "react-router-dom";
import { Grid, Container } from "semantic-ui-react";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { locationChange, identifyUser } from "../analytics/actions";

import MyHunts from "containers/MyHunts";
import CreateHunt from "containers/CreateHunt";
import ManageHunt from "containers/ManageHunt";

import "./App.css";
import TestingComponent from "../debugAndTest/TestingComponent";

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.props.onChangeLocation(this.props.history);
    this.props.identifyUser();
    this.props.history.listen(() => {
      this.props.onChangeLocation(this.props.history);
    });
  }

  render() {
    log.debug("in main app.");
    return (
      <div>
        <GlobalNav />
        <ToastContainer position="top-center" transition={Zoom} />
        <Grid>
          <Grid.Column computer={2} />
          <Grid.Column computer={12} mobile={16} verticalAlign="middle">
            <Container className="ScavrApp">
              <Route path="/" exact component={MyHunts} />
              <Route path="/hunts" component={MyHunts} />
              <Route path="/create" component={CreateHunt} />
              <Route path="/manage/:huntId" component={ManageHunt} />
              {/* eslint-disable-next-line no-undef */}
              {process.env.NODE_ENV === "development" ? (
                <Route path="/test/" component={TestingComponent} />
              ) : (
                ""
              )}
            </Container>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  onChangeLocation: PropTypes.func.isRequired,
  identifyUser: PropTypes.func.isRequired
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeLocation: history => {
      dispatch(locationChange(history));
    },
    identifyUser: () => dispatch(identifyUser())
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
