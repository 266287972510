import { fromJS } from "immutable";
import {
  UPDATE_HUNT_STATUS,
  UPDATE_HUNT_STATUS_FAILED,
  UPDATE_HUNT_STATUS_SUCCESS,
} from "./constants";

const initialState = fromJS({
  stateLoading: false,
});

function huntStatusReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_HUNT_STATUS:
      return state.set("stateLoading", true);
    case UPDATE_HUNT_STATUS_FAILED:
    case UPDATE_HUNT_STATUS_SUCCESS:
      return state.set("stateLoading", false);
    default:
      return state;
  }
}

export default huntStatusReducer;
