import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import { List, Label, Icon } from "semantic-ui-react";
import { Media, MediaContextProvider } from "components/Responsive/Media";
import ChallengeModal from "containers/Challenges/ViewEditChallengeModal";

export class ChallengeListItem extends React.PureComponent {
  render() {
    let icon = "";
    switch (this.props.challenge.type) {
      case "photo":
        icon = "photo";
        break;
      case "video":
        icon = "video camera";
        break;
      case "password":
        icon = "lightbulb";
        break;
      case "qr":
        icon = "qrcode";
        break;
      case "geopoint":
        icon = "marker";
        break;
      default:
        break;
    }

    return (
      <List.Item onClick={this.props.onClick}>
        {this.props.sortHandle}
        {this.props.challenge.points && (
          <List.Content floated="right" style={{ width: "auto" }}>
            <Label horizontal circular basic color="blue" size="medium">
              {this.props.challenge.points} point
              {this.props.challenge.points !== "1" && "s"}
            </Label>
          </List.Content>
        )}
        <Icon name={icon} size="large" style={{ width: "36px" }} />
        <List.Content>
          <List.Header>{this.props.challenge.name}</List.Header>
          <List.Description>
            <MediaContextProvider>
              <Media greaterThan={"sm"}>
                {this.props.challenge.description}
              </Media>
            </MediaContextProvider>
          </List.Description>
        </List.Content>
      </List.Item>
    );
  }
}

ChallengeListItem.propTypes = {
  challenge: PropTypes.object.isRequired,
  sortHandle: PropTypes.object,
  onClick: PropTypes.func
};

export class ChallengeListItemWrapper extends React.PureComponent {
  render() {
    return this.props.withModal ? (
      <ChallengeModal {...this.props} />
    ) : (
      <ChallengeListItem {...this.props} />
    );
  }
}

ChallengeListItemWrapper.defaultProps = {
  withModal: false
};

ChallengeListItemWrapper.propTypes = {
  challenge: PropTypes.object.isRequired,
  sortHandle: PropTypes.object,
  withModal: PropTypes.bool
};

export default ChallengeListItemWrapper;
