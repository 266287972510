export const HUNT_STATE_UNPUBLISHED = "unpublished";
export const HUNT_STATE_PUBLISHED = "published";
export const HUNT_STATE_ACTIVE = "active";
export const HUNT_STATE_PAUSED = "paused";
export const HUNT_STATE_FINISHED = "completed";
export const HUNT_STATE_DRAFT = "draft";

export const HUNT_STATE_LIST_ENABLED = [
  HUNT_STATE_UNPUBLISHED,
  HUNT_STATE_PUBLISHED,
  HUNT_STATE_ACTIVE,
  HUNT_STATE_PAUSED,
  HUNT_STATE_FINISHED,
];
