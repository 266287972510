import { fromJS } from "immutable";
import { CHANGE_PARTICIPANTS_SORT } from "./constants";
import {
  LOAD_HUNT,
  LOAD_HUNT_FAILURE,
  LOAD_HUNT_SUCCESS,
} from "redux/constants";

const initialState = fromJS({
  column: "email",
  direction: "ascending",
  loading: false,
});

function participantReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PARTICIPANTS_SORT:
      return state
        .set("column", action.payload.column)
        .set("direction", action.payload.direction);
    case LOAD_HUNT:
      return state.set("loading", true);
    case LOAD_HUNT_SUCCESS:
    case LOAD_HUNT_FAILURE:
      return state.set("loading", false);
    default:
      break;
  }
  return state;
}

export default participantReducer;
