import log from "loglevel"; // eslint-disable-line no-unused-vars
import firebaseUtils from "./firebaseUtils";
import {
  FIREBASE_COLLECTION_HUNTS,
  FIREBASE_COLLECTION_INTERNAL,
  FIREBASE_DOCID_SETTINGS
} from "./constants";

const watchHuntSettings = (huntId, onSettingsUpdate) => {
  let listener = firebaseUtils
    .db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId)
    .collection(FIREBASE_COLLECTION_INTERNAL)
    .doc(FIREBASE_DOCID_SETTINGS)
    .onSnapshot(settingsSnapshot => {
      const settingsData = settingsSnapshot.data();
      onSettingsUpdate(settingsData);
    });
  log.info("listener is: ", listener, typeof listener);
  return listener;
};

const loadHuntSettings = async huntId =>
  await firebaseUtils
    .db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId)
    .collection(FIREBASE_COLLECTION_INTERNAL)
    .doc(FIREBASE_DOCID_SETTINGS)
    .get()
    .then(settingsSnapshot => settingsSnapshot.data())
    .catch(err => {
      log.error("Error loading settings: ", err);
    });

const updateHuntSetting = async (huntId, settingKey, newValue) => {
  const settingsRef = firebaseUtils
    .db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId)
    .collection(FIREBASE_COLLECTION_INTERNAL)
    .doc(FIREBASE_DOCID_SETTINGS);

  await settingsRef.set({ [settingKey]: newValue }, { merge: true });
};

const api = {
  watchHuntSettings,
  loadHuntSettings,
  updateHuntSetting
};

export default api;
