import { createSelector } from "reselect";
import { selectHuntParticipants } from "containers/ManageHunt/selectors";

import log from "loglevel"; // eslint-disable-line no-unused-vars

const getNestedObject = (nestedObj, pathArr) =>
  pathArr.reduce(
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
    nestedObj
  );

const selectParticipantState = (state) => state.manageHunt.participants;

export const selectParticipantSortColumn = createSelector(
  [selectParticipantState],
  (participantState) => participantState.get("column")
);

export const selectParticipantSortDirection = createSelector(
  [selectParticipantState],
  (participantState) => participantState.get("direction")
);

export const selectSortedParticipants = createSelector(
  [
    selectParticipantSortColumn,
    selectParticipantSortDirection,
    selectHuntParticipants,
  ],
  (column, direction, participants) => {
    return participants
      .filter((p) => p != null)
      .sort((p1, p2) => {
        const keys = column.split(".");
        const val1 = getNestedObject(p1, keys);
        const val2 = getNestedObject(p2, keys);

        if (val1 === null || val1 === undefined) {
          return -1;
        }

        const gt = val1.localeCompare(val2);
        return direction === "descending" ? -gt : gt;
      });
  }
);

export const selectLoading = createSelector(
  [selectParticipantState],
  (participantState) => participantState.get("loading")
);
