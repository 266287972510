import React from "react";
import PropTypes from "prop-types";

const addressLabel = ({ locationObject }) => (
  <div>
    {!!locationObject.locationName && (
      <div>
        {locationObject.locationName}
        <br />
      </div>
    )}
    {locationObject.streetAddress1}
    {!!locationObject.streetAddress1 && <br />}
    {!!locationObject.streetAddress2 && "#"}
    {locationObject.streetAddress2}
    {!!locationObject.streetAddress2 && <br />}
    {locationObject.city}, {locationObject.state} {locationObject.zip}
  </div>
);

addressLabel.propTypes = {
  locationObject: PropTypes.object.isRequired,
};

export default addressLabel;
