export const MODELS_UPDATED = "scavr/Data/MODELS_UPDATED";

export const DELETE_TEAM = "scavr/DeleteTeam/DELETE_TEAM";
export const DELETE_TEAM_SUCCESS = "scavr/DeleteTeam/DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAILED = "scavr/DeleteTeam/DELETE_TEAM_FAILED";

export const LOAD_HUNT_LEVELS = "scavr/CreateHunt/LOAD_HUNT_SKUS";
export const LOAD_HUNT_LEVELS_SUCCESS =
  "scavr/CreateHunt/LOAD_HUNT_SKUS_SUCCEEDED";
export const LOAD_HUNT_LEVELS_FAILURE =
  "scavr/CreateHunt/LOAD_HUNT_SKUS_FAILED";

export const LOAD_HUNT = "scavr/Manage/LOAD_HUNT";
export const LOAD_HUNT_SUCCESS = "scavr/Manage/LOAD_HUNT_SUCCESS";
export const LOAD_HUNT_FAILURE = "scavr/Manage/LOAD_HUNT_FAILURE";

export const LOAD_HUNT_CAPABILITIES = "scavr/Manage/LOAD_HUNT_CAPABILITIES";
export const LOAD_HUNT_CAPABILITIES_SUCCESS =
  "scavr/Manage/LOAD_HUNT_CAPABILITIES_SUCCESS";
export const LOAD_HUNT_CAPABILITIES_FAILURE =
  "scavr/Manage/LOAD_HUNT_CAPABILITIES_FAILURE";

export const LOAD_HUNT_SETTINGS = "scavr/Manage/LOAD_HUNT_SETTINGS";
export const LOAD_HUNT_SETTINGS_SUCCESS =
  "scavr/Manage/LOAD_HUNT_SETTINGS_SUCCESS";
export const LOAD_HUNT_SETTINGS_FAILURE =
  "scavr/Manage/LOAD_HUNT_SETTINGS_FAILURE";

export const WATCH_HUNT_SETTINGS = "scavr/Manage/WATCH_HUNT_SETTINGS";
export const STOP_WATCHING_HUNT_SETTINGS =
  "scavr/Manage/STOP_WATCHING_HUNT_SETTINGS";
export const STARTED_WATCHING_SETTINGS =
  "scavr/Manage/STARTED_WATCHING_SETTINGS";
export const STOPPED_WATCHING_SETTINGS =
  "scavr/Manage/STOPPED_WATCHING_SETTINGS";

export const UPDATE_HUNT_SETTING = "scavr/Manage/UPDATE_HUNT_SETTING";
export const UPDATE_HUNT_SETTING_SUCCESS =
  "scavr/Manage/UPDATE_HUNT_SETTING_SUCCESS";
export const UPDATE_HUNT_SETTING_FAILURE =
  "scavr/Manage/UPDATE_HUNT_SETTING_FAILURE";

export const LOAD_REMOTE_CONFIG = "scavr/App/LOAD_REMOTE_CONFIG";
export const LOAD_REMOTE_CONFIG_SUCCESS =
  "scavr/App/LOAD_REMOTE_CONFIG_SUCCESS";

export const INITIALIZE_APP = "scavr/App/INITIALIZE_STARTED";
export const INITIALIZE_APP_COMPLETE = "scavr/App/INITIALIZE_COMPLETED";

export const PAYMENT_COMPLETE = "scavr/Create/PAYMENT_COMPLETE";

export const LOADING_LEADERBOARD_DATA =
  "scavr/Leaderboard/LOADING_LEADERBOARD_DATA";
export const WATCH_LEADERBOARD = "scavr/Leaderboard/WATCH_LEADERBOARD";
export const STARTED_WATCHING_LEADERBOARD =
  "scavr/Leaderboard/STARTED_WATCHING_LEADERBOARD";
export const STOP_WATCHING_LEADERBOARD =
  "Scavr/Leaderboard/STOP_WATCHING_LEADERBOARD";
export const STOPPED_WATCHING_LEADERBOARD =
  "scavr/Leaderboard/STOPPED_WATCHING_LEADERBOARD";
export const LEADERBOARD_UPDATED = "scavr/Leaderboard/LEASDERBOARD_UPDATED";

export const LOADING_HUNT_ARCHIVE = "scavr/Archive/LOADING_ARCHIVE";
export const HUNT_ARCHIVE_REQUESTED = "scavr/Archive/ARCHIVE_REQUESTED";
export const HUNT_ARCHIVE_LOADED = "scavr/Archive/ARCHIVE_LOADED";
export const HUNT_ARCHIVE_FAILED = "scavr/Archive/ARCHIVE_FAILED";
export const WATCH_HUNT_ARCHIVE_INFO = "scavr/Archive/WATCH_HUNT_ARCHIVE_INFO";
export const WATCHING_HUNT_ARCHIVE_INFO =
  "scavr/Archive/WATCHING_HUNT_ARCHIVE_INFO";
export const STOP_WATCHING_HUNT_ARCHIVE_INFO =
  "scavr/Archive/STOP_WATCHING_HUNT_ARCHIVE_INFO";
export const STOPPED_WATCHING_HUNT_ARCHIVE_INFO =
  "scavr/Archive/STOPPED_WATCHING_HUNT_ARCHIVE_INFO";

export const ARCHIVE_INFO_UPDATED = "scavr/Archive/ARCHIVE_INFO_UPDATED";
