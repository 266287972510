import { fromJS } from "immutable";
import {
  ARCHIVE_INFO_UPDATED,
  LOAD_HUNT,
  LOADING_HUNT_ARCHIVE
} from "redux/constants";

const initialState = fromJS({});

const initialHuntState = fromJS({
  archiveRequested: false,
  // archiveInfoListener: null,
  archiveURL: null,
  archiveSize: null,
  archiveDate: null
});

function downloadReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_HUNT:
      return state.set(action.payload.huntId, initialHuntState);
    case LOADING_HUNT_ARCHIVE:
      return state.setIn([action.payload.huntId, "archiveRequested"], true);
    case ARCHIVE_INFO_UPDATED:
      return state
        .setIn(
          [action.payload.huntId, "archiveURL"],
          action.payload.archiveInfo.archiveURL
        )
        .setIn(
          [action.payload.huntId, "archiveSize"],
          action.payload.archiveInfo.archiveSize
        )
        .setIn(
          [action.payload.huntId, "archiveDate"],
          action.payload.archiveInfo.archiveDate
        );
    default:
      return state;
  }
}

export default downloadReducer;
