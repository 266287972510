import log from "loglevel"; // eslint-disable-line no-unused-vars
import _ from "lodash";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import {
  FIREBASE_COLLECTION_ATTEMPTS,
  FIREBASE_COLLECTION_CHALLENGES,
  FIREBASE_COLLECTION_FEED,
  FIREBASE_COLLECTION_HUNTS,
  FIREBASE_COLLECTION_SHARED,
  FIREBASE_COLLECTION_SKUS,
  FIREBASE_COLLECTION_TEAMS,
  FIREBASE_COLLECTION_USERS,
  FIREBASE_DOCID_LEADERBOARD
} from "./constants";

function storage() {
  return firebase
    .app()
    .storage("gs://" + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET); // eslint-disable-line no-undef
}

const loadModelForDocumentSnapshot = async snapshot => {
  if (!snapshot.exists) {
    return;
  }

  const docType = snapshot.ref.parent.id;
  switch (docType) {
    case FIREBASE_COLLECTION_CHALLENGES:
      return loadChallengeFromSnapshot(snapshot);
    case FIREBASE_COLLECTION_ATTEMPTS:
      return loadChallengeAttemptFromSnapshot(snapshot);
    case FIREBASE_COLLECTION_HUNTS:
      return loadHuntFromSnapshot(snapshot);
    case FIREBASE_COLLECTION_TEAMS:
      return loadTeamFromSnapshot(snapshot);
    case FIREBASE_COLLECTION_USERS:
      return loadUserFromSnapshot(snapshot);
    case FIREBASE_COLLECTION_SKUS:
      return loadSKUsFromSnapshot(snapshot);
    case FIREBASE_COLLECTION_FEED:
      return loadFeedItemFromSnapshot(snapshot);
    case FIREBASE_COLLECTION_SHARED:
      if (snapshot.ref.id === FIREBASE_DOCID_LEADERBOARD) {
        return loadLeaderboardFromSnapshot(snapshot);
      }
      break;
    default:
      break;
  }
};

const loadModelsForQuerySnapshot = snapshot => {
  if (snapshot.empty) {
    return Promise.resolve([]);
  }

  const promises = snapshot.docs.map(docSnapshot =>
    loadModelForDocumentSnapshot(docSnapshot)
  );
  return Promise.all(promises);
};

const loadDiffOfModelsForQuerySnapshot = snapshot => {
  // Return an object of normalized values within { added: [], deleted: [], updated: [] }
  let result = {
    added: [],
    deleted: [],
    updated: []
  };

  let promises = [];

  snapshot.docChanges().forEach(docChange => {
    let changeType = docChange.type;
    let snapshot = docChange.doc;
    promises.push(
      loadModelForDocumentSnapshot(snapshot).then(model => {
        // Do something here

        switch (changeType) {
          case "added":
            result.added.push(model);
            break;
          case "modified":
            result.updated.push(model);
            break;
          case "removed":
            result.deleted.push(model);
            break;
          default:
            break;
        }
      })
    );
  });

  return Promise.all(promises).then(() => result);
};

async function loadChallengeFromSnapshot(docSnapshot) {
  if (!docSnapshot.exists) {
    return null;
  }

  const challengeData = docSnapshot.data();
  const huntId = docSnapshot.ref.parent.parent.id;

  return {
    objectId: docSnapshot.id,
    ...challengeData,
    docRef: docSnapshot.ref,
    hunt: huntId
  };
}

async function loadHuntFromSnapshot(docSnapshot) {
  const data = docSnapshot.data();
  const location = Object.assign({}, data.location);
  if (location && location.point) {
    const point = data.location.point;
    location.point = {
      lat: point.latitude,
      lng: point.longitude
    };
  }
  let startTime;
  if (data.startTime) {
    try {
      if (typeof data.startTime === "string") {
        startTime = Date.parse(data.startTime);
      } else {
        startTime = data.startTime.toDate();
      }
    } catch (e) {
      log.error("Couldn't create hunt startTime for hunt: ", docSnapshot.id, e);
      startTime = null;
    }
  }
  return {
    objectId: docSnapshot.id,
    ...data,
    location,
    startTime,
    docRef: docSnapshot.ref
  };
}

async function loadTeamFromSnapshot(docSnapshot) {
  const data = docSnapshot.data();
  const members = data.members;
  return {
    objectId: docSnapshot.id,
    ...data,
    hunt: data.hunt.id,
    members,
    docRef: docSnapshot.ref
  };
}

async function loadUserFromSnapshot(docSnapshot) {
  const data = docSnapshot.data();
  return {
    objectId: docSnapshot.id,
    ...data,
    docRef: docSnapshot.ref
  };
}

async function loadSKUsFromSnapshot(docSnapshot) {
  const data = docSnapshot.data();
  return {
    objectId: docSnapshot.id,
    ...data,
    docRef: docSnapshot.ref
  };
}

async function loadChallengeAttemptFromSnapshot(docSnapshot) {
  const data = docSnapshot.data();

  const obj = {
    objectId: docSnapshot.id,
    ...data,
    hunt: data.hunt.id,
    team: data.team.id,
    challenge: data.challenge.id,
    docRef: docSnapshot.ref
  };

  if (
    (data.itemType === "video" &&
      data.fileUrl &&
      typeof data.fileUrl === "string" &&
      data.fileUrl.startsWith("file:///")) ||
    (!data.fileUrl && data.filePath)
  ) {
    log.debug("Getting File URL for attempt", obj.objectId);
    let promises = [Promise.resolve(obj)];

    const fileRef = storage().ref(data.filePath);
    const fileURLPromise = fileRef.getDownloadURL();
    promises.push(fileURLPromise);

    if (data.thumbPath) {
      const thumbRef = storage().ref(data.thumbPath);
      const thumbPromise = thumbRef.getDownloadURL();
      promises.push(thumbPromise);
    }

    try {
      let results = await Promise.all(promises);
      const [obj, fileURL, thumbURL] = results;
      return {
        ...obj,
        fileUrl: fileURL,
        thumbUrl: thumbURL
      };
    } catch (error) {
      handleStorageError(error);
    }
  }

  return obj;
}

async function loadFeedItemFromSnapshot(docSnapshot) {
  const data = docSnapshot.data();

  let huntId = docSnapshot.ref.parent.parent.id;

  return {
    objectId: docSnapshot.id,
    ...data,
    hunt: huntId,
    team: data.team.id,
    challenge: data.challenge.id,
    attempt: data.attempt.id,
    docRef: docSnapshot.ref
  };
}

/**
 *
 * @param snapshot - Snapshot of the leaderboard document
 * @returns {Promise<Object>} where Object is a leaderboard row entry for this hunt
 */
async function loadLeaderboardFromSnapshot(snapshot) {
  const data = snapshot.data();

  let huntId = snapshot.ref.parent.parent.id;

  /* Data is a dictionary that looks like:

    {
      <teamId>: {
        name: "badgers",
        points: 123,
        challengeAttempts: 3,
        lastSubmission: (null) | Timestamp
      },
      <teamId>: { ... etc. },
   */

  /*
   We want the output of this function to be an unsorted array of objects. The individual objects Id is just the team ID.
   */

  let leaderboard = _.map(data, (value, teamId) => {
    return {
      objectId: teamId,
      teamId: teamId,
      huntId,
      ...value,
      lastSubmission: value.lastSubmission
        ? value.lastSubmission.toDate()
        : null
    };
  });

  // log.debug("Got leaderboard array: ", leaderboard);
  return leaderboard;
}

function handleStorageError(error) {
  // A full list of error codes is available at
  // https://firebase.google.com/docs/storage/web/handle-errors
  switch (error.code) {
    case "storage/object-not-found":
      // File doesn't exist
      break;
    case "storage/unauthorized":
      // User doesn't have permission to access the object
      break;
    case "storage/canceled":
      // User canceled the upload
      break;
    case "storage/unknown":
      // Unknown error occurred, inspect the server response
      break;
    default:
      break;
  }
}

export {
  loadModelsForQuerySnapshot,
  loadModelForDocumentSnapshot,
  loadDiffOfModelsForQuerySnapshot
};
