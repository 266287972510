import log from "loglevel"; // eslint-disable-line no-unused-vars
import { normalize } from "normalizr";
import RemoteConfig from "RemoteConfig";
import DataProvider from "DataProvider";
import { RC_SKU_LINEUP } from "RemoteConfig/constants";
import {
  LOAD_HUNT_LEVELS,
  LOAD_HUNT_LEVELS_FAILURE,
  LOAD_HUNT_LEVELS_SUCCESS
} from "redux/constants";
import { SKUSchema } from "../../models/SKU";
import {
  basicInfoSubmitted,
  createHuntCompleted,
  saveHuntFailed,
  saveHuntSucceeded
} from "./actions";
import { push as pushRoute } from "connected-react-router";

const dataProvider = DataProvider.instance();

const loadHuntSKUs = () => async dispatch => {
  let lineupIds = RemoteConfig.getJSONValue(RC_SKU_LINEUP);
  // lineupIds = lineupIds._value.split(",");
  log.debug("Got lineup IDs: ", lineupIds);

  dispatch({
    types: [
      LOAD_HUNT_LEVELS,
      LOAD_HUNT_LEVELS_SUCCESS,
      LOAD_HUNT_LEVELS_FAILURE
    ],
    callAPI: async () => {
      let skus = await dataProvider.getSKUs(lineupIds);
      log.debug("Got skus: ", skus);
      return normalize(skus, [SKUSchema]);
    },
    payload: { skuIds: lineupIds }
  });
};

const createHuntAction = huntData => async dispatch => {
  dispatch(basicInfoSubmitted(huntData));

  try {
    let result = await dataProvider.createHuntWithSKU(huntData);

    if (result.error) {
      dispatch(saveHuntFailed(result.error));
    } else {
      const { hunt } = result;
      log.debug("Got create result: ", result);

      dispatch(saveHuntSucceeded(hunt));
      if (hunt.status === "draft") {
        // Need to check out
        await dataProvider.checkoutWithHuntAndSKU(hunt.objectId, hunt.skuId);
      } else {
        dispatch(pushRoute("/manage/" + result.hunt.objectId));
      }
      dispatch(createHuntCompleted());
    }
  } catch (error) {
    log.error("Error creating hunt: ", error);
    dispatch(saveHuntFailed(error));
  }
};

export { loadHuntSKUs, createHuntAction };
