import { createSelector } from "reselect";

/**
 * Direct selector to the signup state domain
 */
const selectSignupDomain = state => state.signup;

/**
 * Other specific selectors
 */

const makeRedirectURL = () =>
  createSelector(
    (state, props) => props.location,
    location => {
      if (location && location.query && location.query.redirect) {
        return location.query.redirect;
      }
      return "/";
    }
  );

/**
 * Default selector used by Signup
 */

export default createSelector(selectSignupDomain, substate => substate.toJS());

export { selectSignupDomain, makeRedirectURL };
