import React from "react";
import log from "loglevel"; //eslint-disable-line no-unused-vars
import PropTypes from "prop-types";
import { Form, Radio } from "semantic-ui-react";

const FeedSettingsSection = ({
  feedEnabled,
  attemptsToShow,
  autoEnableAfterComplete,
  loading,
  onToggleEnabled,
  onToggleAutoEnable,
  onChangeAttemptsToShow
}) => {
  return (
    <div>
      <Form>
        <Form.Field>
          <label>Enable Feed</label>
          <Radio
            toggle
            checked={feedEnabled}
            disabled={loading}
            onChange={onToggleEnabled}
          />
        </Form.Field>
        <Form.Checkbox
          label="Auto-enable feed when hunt is completed"
          disabled={loading || feedEnabled}
          checked={autoEnableAfterComplete}
          onChange={onToggleAutoEnable}
        />
        <Form.Group grouped>
          <label>Submissions to Show</label>
          <Form.Radio
            label="All Submissions"
            value="all"
            checked={attemptsToShow === "all"}
            onChange={onChangeAttemptsToShow}
            disabled={loading}
          />
          <Form.Radio
            label="Only Approved Submissions"
            value="completed"
            checked={attemptsToShow === "completed"}
            onChange={onChangeAttemptsToShow}
            disabled={loading}
          />
          {/*<Form.Radio*/}
          {/*  label="Only Shared Submissions"*/}
          {/*  value="shared"*/}
          {/*  checked={attemptsToShow === "shared"}*/}
          {/*  onChange={onChangeAttemptsToShow}*/}
          {/*  disabled={loading}*/}
          {/*/>*/}
        </Form.Group>
      </Form>
    </div>
  );
};

FeedSettingsSection.propTypes = {
  feedEnabled: PropTypes.bool,
  attemptsToShow: PropTypes.string,
  autoEnableAfterComplete: PropTypes.bool,
  onToggleEnabled: PropTypes.func,
  onChangeAttemptsToShow: PropTypes.func,
  onToggleAutoEnable: PropTypes.func,
  loading: PropTypes.bool.isRequired
};

export default FeedSettingsSection;
