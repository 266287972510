import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  Header,
  Button,
  Divider,
  Message,
  Segment,
  Card,
  Table,
  Label,
  Image
} from "semantic-ui-react";
import "./MyHunts.css";
import HuntListItem from "components/HuntListItem";
import moment from "moment";
import { Media, MediaContextProvider } from "components/Responsive/Media";

// import log from "loglevel";
const skuCodeLabelColor = {
  basic: "blue",
  enhanced: "green",
  professional: "purple",
  trial: "grey",
  enhancedplus: "purple"
};

function labelForPriceLevel(priceLevel) {
  let color = skuCodeLabelColor[priceLevel.toLowerCase()];
  if (color) {
    if (priceLevel === "enhancedplus") {
      return <Label color={color}>{_.startCase("enhanced plus")}</Label>;
    }
    return <Label color={color}>{_.startCase(priceLevel)}</Label>;
  } else {
    return <Label>{_.startCase(priceLevel)}</Label>;
  }
}

const statusLabelColor = {
  unpublished: "grey",
  published: "blue",
  active: "green",
  paused: "teal",
  completed: "green"
};

function labelForStatus(status) {
  let color = statusLabelColor[status];
  return (
    <Label color={color} basic={status === "completed"}>
      {_.startCase(status)}
    </Label>
  );
}

export default class MyHuntList extends React.PureComponent {
  render() {
    const { loading, error, hunts, onClickHunt } = this.props;

    if (loading) {
      return <Segment loading size="huge" padded="very" />;
    } else if (error) {
      return (
        <Message error>
          <Header>Something went wrong</Header>
          <Divider hidden />
          {error}
        </Message>
      );
    } else if (hunts.length === 0) {
      return (
        <Segment size="large" textAlign="center">
          <Header textAlign="center">
            You haven&apos;t created any Hunts.
          </Header>
          <Divider hidden />
          When you create a scavenger hunt, it will show up here. Why don&apos;t
          you start one now!
          <Divider hidden />
          <Link to="/create">
            <Button primary>Create Hunt</Button>
          </Link>
        </Segment>
      );
    } else {
      let sortedHunts = _.sortBy(hunts, "name");

      return (
        <MediaContextProvider>
          <Media at="sm">
            <Card.Group>
              {sortedHunts.map(hunt => (
                <HuntListItem
                  key={hunt.objectId}
                  hunt={hunt}
                  onClick={() => onClickHunt(hunt.objectId)}
                />
              ))}
            </Card.Group>
          </Media>
          <Media greaterThanOrEqual="md">
            <Table size="large" padded basic="very" selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Start Date</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Price Level</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sortedHunts.map(hunt => (
                  <Table.Row
                    key={hunt.objectId}
                    onClick={() => onClickHunt(hunt.objectId)}
                  >
                    <Table.Cell collapsing>
                      <Image src={hunt.imageURL} size="mini" />
                    </Table.Cell>
                    <Table.Cell>{hunt.name}</Table.Cell>
                    <Table.Cell>{hunt.description}</Table.Cell>
                    <Table.Cell>
                      {hunt.startTime ? moment(hunt.startTime).calendar() : ""}
                    </Table.Cell>
                    <Table.Cell>
                      {labelForStatus(hunt.status.toLowerCase())}
                    </Table.Cell>
                    <Table.Cell>
                      {labelForPriceLevel(
                        hunt.skuShortCode ? hunt.skuShortCode : hunt.priceLevel
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Media>
        </MediaContextProvider>
      );
    }
  }
}

MyHuntList.propTypes = {
  hunts: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onClickHunt: PropTypes.func.isRequired
};
