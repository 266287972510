import { combineReducers } from "redux";

import challenges from "../Challenges/ManageChallenges/reducer";
import participantReducer from "./sections/Participants/reducer";
import settingsReducer from "./sections/Settings/reducer";
import huntStatusReducer from "./sections/HuntStatusSteps/reducer";
import deleteTeamReducer from "../DeleteTeamButton/reducer";
import downloadReducer from "./sections/Download/reducer";
import editHuntInfoReducer from "./sections/ManageDetails/reducer";
import { fromJS } from "immutable";
import {
  UPDATE_HUNT,
  UPDATE_HUNT_FAILURE,
  UPDATE_HUNT_SUCCESS
} from "./constants";
import {
  LOAD_HUNT,
  LOAD_HUNT_CAPABILITIES,
  LOAD_HUNT_CAPABILITIES_FAILURE,
  LOAD_HUNT_CAPABILITIES_SUCCESS,
  LOAD_HUNT_FAILURE,
  LOAD_HUNT_SUCCESS
} from "redux/constants";

const initialState = fromJS({
  loadingHunt: false,
  loadingCapabilities: false,
  loading: false,
  error: null,
  huntChallenges: {}
});

function manageHuntReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_HUNT:
      return state
        .set("loading", true)
        .set("loadingHunt", true)
        .set("error", false);
    case UPDATE_HUNT:
      return state
        .set("loading", true)
        .set("loadingHunt", true)
        .set("error", false);
    case LOAD_HUNT_SUCCESS:
      return state
        .set("loading", false)
        .set("loadingHunt", false)
        .set("error", false);
    case UPDATE_HUNT_SUCCESS:
      return state
        .set("loading", false)
        .set("loadingHunt", false)
        .set("error", false);
    case LOAD_HUNT_FAILURE:
      return state
        .set("loading", false)
        .set("loadingHunt", false)
        .set("error", action.error);
    case UPDATE_HUNT_FAILURE:
      return state
        .set("loading", false)
        .set("loadingHunt", false)
        .set("error", action.error);
    case LOAD_HUNT_CAPABILITIES:
      return state.set("loadingCapabilities", true);
    case LOAD_HUNT_CAPABILITIES_SUCCESS:
      return state.set("loadingCapabilities", false);
    case LOAD_HUNT_CAPABILITIES_FAILURE:
      return state.set("loadingCapabilities", false);
    default:
      return state;
  }
}

const manageReducer = combineReducers({
  manageHunt: manageHuntReducer,
  challenges,
  participants: participantReducer,
  settings: settingsReducer,
  huntStatus: huntStatusReducer,
  deleteTeam: deleteTeamReducer,
  archive: downloadReducer,
  editHuntInfo: editHuntInfoReducer
});

export default manageReducer;
