import { createSelector } from "reselect";

/**
 * Direct selector to the login state domain
 */
const selectLoginDomain = state => state.login;

/**
 * Other specific selectors
 */

const makeRedirectURL = () =>
  createSelector(
    (state, props) => props.location,
    location => {
      if (location && location.query && location.query.redirect) {
        return location.query.redirect;
      }
      return "/";
    }
  );

/**
 * Default selector
 */
export default createSelector(selectLoginDomain, loginState =>
  loginState.toJS()
);

export { selectLoginDomain, makeRedirectURL };
