import React from "react";
import PropTypes from "prop-types";
import {
  Placeholder,
  Card,
  Button,
  List,
  Divider,
  Label,
} from "semantic-ui-react";

const SKUCard = ({ skuId, displayDescription, onSelect }) => {
  let locale = displayDescription.default;
  let desc = displayDescription[locale];
  return (
    <Card className="skuCard">
      {displayDescription.featured ? (
        <Label color="green" ribbon size="large">
          Most Popular!
        </Label>
      ) : null}
      <Card.Content>
        <Card.Header>{desc.displayName}</Card.Header>
        <Card.Meta>{desc.price}</Card.Meta>
        <Card.Description>
          <Divider hidden />
          <List as="ul">
            {desc.features.map((deet, index) => (
              <List.Item key={index} as="li">
                {deet}
              </List.Item>
            ))}
          </List>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button
          fluid
          color="blue"
          onClick={() => onSelect(skuId)}
          disabled={!displayDescription.enabled}
        >
          Get Started
        </Button>
      </Card.Content>
    </Card>
  );
};

SKUCard.propTypes = {
  skuId: PropTypes.string.isRequired,
  displayDescription: PropTypes.object.isRequired,
  displayName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
};

export const PlaceholderSKUCard = () => {
  return (
    <Card>
      <Card.Content>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="medium" />
            <Placeholder.Line length="short" />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Card.Content>
      <Card.Content extra>
        <Button fluid color="grey" disabled={true} />
      </Card.Content>
    </Card>
  );
};

export default SKUCard;
