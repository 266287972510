import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Message } from "semantic-ui-react";

import log from "loglevel"; // eslint-disable-line no-unused-vars

export default class EmailLoginForm extends React.PureComponent {
  state = {
    password: "",
    email: ""
  };

  onChangeEmail = evt => {
    this.setState({ email: evt.target.value });
  };

  onChangePassword = evt => {
    this.setState({ password: evt.target.value });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.email, this.state.password);
  };

  render() {
    const isError = !!this.props.login.error;
    return (
      <Form
        onSubmit={this.onSubmit}
        method="POST"
        loading={this.props.login.loading}
        error={isError}
      >
        <Form.Input
          type="text"
          icon="at"
          iconPosition="left"
          placeholder="email"
          value={this.state.email}
          onChange={this.onChangeEmail}
          name="email"
        />
        <Form.Input
          type="password"
          placeholder="password"
          icon="lock"
          iconPosition="left"
          name="password"
          value={this.state.password}
          onChange={this.onChangePassword}
        />
        <Message
          error
          header="Error Logging In"
          content={this.props.login.error.message}
        />
        <Button fluid primary>
          Log In
        </Button>
      </Form>
    );
  }
}

EmailLoginForm.propTypes = {
  login: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
};
