import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import { Header, Table, Segment, Divider, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectHuntParticipants,
  selectJoinHuntURL
} from "containers/ManageHunt/selectors";
import { loadHunt } from "redux/actionCreators";
import { changeParticipantSort } from "./actions";
import {
  selectSortedParticipants,
  selectParticipantSortColumn,
  selectParticipantSortDirection,
  selectLoading
} from "./selectors";
import DataProvider from "DataProvider";
import CopyableInput from "components/CopyableInput";
import { HUNT_STATE_PUBLISHED, HUNT_STATE_UNPUBLISHED } from "models/constants";
import { updateHuntStatus } from "../HuntStatusSteps/actions";
import PlaceholderTable from "components/placeholderTable";
import DeleteTeamButton from "containers/DeleteTeamButton";
import { modelsUpdated } from "redux/actions";
import { TeamSchema } from "models/Team";
import { UserSchema } from "models/User";
import { selectCurrentHunt } from "redux/selectors";

class Participants extends React.PureComponent {
  state = { deleteModalOpen: false, teamToDelete: null };

  handleDeleteOpen = teamId => () => {
    this.setState({ deleteModalOpen: true, teamToDelete: teamId });
  };

  handleDeleteClose = () => {
    this.setState({ deleteModalOpen: false, teamToDelete: null });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.hunt) {
      this.props.loadHunt(this.props.match.params.huntId);
    }
  }

  UNSAFE_componentWillMount() {
    this.participantListener = DataProvider.instance().watchParticipants(
      this.props.match.params.huntId,
      this.props.onTeamsUpdated,
      this.props.onUsersUpdated
    );
  }

  componentWillUnmount() {
    DataProvider.instance().stopWatching(this.participantListener);
  }

  handleSort = clickedColumn => () => {
    const { participantSortColumn, participantSortDirection } = this.props;

    if (participantSortColumn !== clickedColumn) {
      this.props.onParticipantSortChanged(clickedColumn, "ascending");
    } else {
      const newDir =
        participantSortDirection === "ascending" ? "descending" : "ascending";
      this.props.onParticipantSortChanged(clickedColumn, newDir);
    }
  };

  publishHunt = () => {
    const { hunt } = this.props;
    this.props.updateHuntStatus(hunt, HUNT_STATE_PUBLISHED);
  };

  render() {
    const {
      participants,
      sortedParticipants,
      joinHuntURL,
      participantSortDirection,
      participantSortColumn,
      hunt,
      loading
    } = this.props;

    return (
      <div>
        <div />
        <Header as="h2">Participants</Header>
        {loading ? (
          <PlaceholderTable />
        ) : participants.length === 0 ? (
          <Segment textAlign="center">
            {hunt.status === HUNT_STATE_UNPUBLISHED ? (
              <div>
                <Header>You have not published your hunt yet.</Header>
                <Divider hidden />
                <Button primary onClick={this.publishHunt}>
                  Publish Hunt
                </Button>
              </div>
            ) : (
              <div>
                <Header>
                  Nobody has joined your hunt yet. Share your signup link!
                </Header>
                <Divider hidden />
                <CopyableInput value={joinHuntURL} />{" "}
              </div>
            )}
          </Segment>
        ) : (
          <div>
            <Table sortable singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={
                      participantSortColumn === "displayName"
                        ? participantSortDirection
                        : null
                    }
                    onClick={this.handleSort("displayName")}
                  >
                    Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={
                      participantSortColumn === "email"
                        ? participantSortDirection
                        : null
                    }
                    onClick={this.handleSort("email")}
                  >
                    Email
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={
                      participantSortColumn === "team.name"
                        ? participantSortDirection
                        : null
                    }
                    onClick={this.handleSort("team.name")}
                  >
                    Team
                  </Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {sortedParticipants.map(participant => (
                  <Table.Row key={participant.objectId}>
                    <Table.Cell>{participant.displayName}</Table.Cell>
                    <Table.Cell>
                      <a href={participant.email}>{participant.email}</a>
                    </Table.Cell>
                    <Table.Cell>{participant.team.name}</Table.Cell>
                    <Table.Cell>
                      <DeleteTeamButton
                        huntId={hunt.objectId}
                        teamToDelete={participant.team.objectId}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Divider hidden />
            <Header as="h3">Registration Link</Header>
            <CopyableInput value={joinHuntURL} />
          </div>
        )}
      </div>
    );
  }
}

Participants.propTypes = {
  match: PropTypes.object,
  hunt: PropTypes.object,
  participants: PropTypes.array,
  sortedParticipants: PropTypes.array,
  joinHuntURL: PropTypes.string,
  loading: PropTypes.bool,

  participantSortColumn: PropTypes.string,
  participantSortDirection: PropTypes.string,

  updateHuntStatus: PropTypes.func,
  onParticipantSortChanged: PropTypes.func.isRequired,
  onTeamsUpdated: PropTypes.func.isRequired,
  onUsersUpdated: PropTypes.func.isRequired,
  loadHunt: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  hunt: selectCurrentHunt,
  participants: selectHuntParticipants,
  sortedParticipants: selectSortedParticipants,
  joinHuntURL: selectJoinHuntURL,
  participantSortColumn: selectParticipantSortColumn,
  participantSortDirection: selectParticipantSortDirection,
  loading: selectLoading
});

const mapDispatchToProps = dispatch => ({
  loadHunt: huntId => dispatch(loadHunt(huntId)),
  onParticipantSortChanged: (column, direction) =>
    dispatch(changeParticipantSort(column, direction)),
  onTeamsUpdated: teamsDiff => dispatch(modelsUpdated(teamsDiff, TeamSchema)),
  onUsersUpdated: usersDiff => dispatch(modelsUpdated(usersDiff, UserSchema)),
  updateHuntStatus: (hunt, newState) =>
    dispatch(updateHuntStatus(hunt, newState))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Participants)
);
