import { createSelector } from "reselect";

const selectAuthState = state => state.firebase.auth;

export const selectUserInfo = createSelector(selectAuthState, auth => {
  return {
    name: auth.displayName,
    email: auth.email,
    avatar: auth.photoURL,
  };
});
