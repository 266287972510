import log from "loglevel"; // eslint-disable-line no-unused-vars
import { HuntSchema } from "models/Hunt";
import { TeamSchema } from "models/Team";
import { UserSchema } from "models/User";
import { normalize } from "normalizr";

import { ANALYTICS_TRACK } from "analytics/constants";

import {
  // LOAD_HUNT,
  // LOAD_HUNT_FAILURE,
  // LOAD_HUNT_SUCCESS,
  UPDATE_HUNT,
  UPDATE_HUNT_FAILURE,
  UPDATE_HUNT_SUCCESS,
  PARTICIPANTS_LOADED,
  TEAMS_LOADED,
  CHALLENGE_ATTEMPTS_LOADED,
  DELETE_HUNT_FAILURE,
  DELETE_HUNT,
  DELETE_HUNT_SUCCESS,
  COPY_HUNT,
  COPY_HUNT_SUCCESS,
  COPY_HUNT_FAILURE,
  CHALLENGE_ATTEMPT_DIFF_LOADED,
  UPDATING_HUNT_LOGO,
  UPDATING_HUNT_LOGO_PROGRESS,
  UPDATING_HUNT_LOGO_COMPLETE
} from "./constants";

import { ChallengeAttemptSchema } from "models/ChallengeAttempt";
import { ChallengeSchema } from "models/Challenge";
import { LOAD_HUNT_CHALLENGES_SUCCESS } from "../Challenges/constants";

function deleteHunt(huntId) {
  return {
    type: DELETE_HUNT,
    payload: {
      huntId
    },
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "Manage.DeleteHunt",
        payload: {
          huntId
        }
      }
    }
  };
}

function deleteHuntSuccess(huntId) {
  return {
    type: DELETE_HUNT_SUCCESS,
    payload: {
      huntId
    }
  };
}

function deleteHuntFailure(error) {
  return {
    type: DELETE_HUNT_FAILURE,
    error
  };
}

function copyHunt(huntId, newName, newPriceLevel) {
  return {
    type: COPY_HUNT,
    payload: {
      huntId,
      newName,
      newPriceLevel
    },
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "Manage.CopyHunt",
        payload: {
          huntId,
          newName,
          newPriceLevel
        }
      }
    }
  };
}

function copyHuntSuccess(oldHuntId, newHuntId) {
  return {
    type: COPY_HUNT_SUCCESS,
    payload: {
      oldHuntId,
      newHuntId
    }
  };
}

function copyHuntFailure(error) {
  return {
    type: COPY_HUNT_FAILURE,
    error
  };
}

function saveHuntDetails(hunt, huntDetails) {
  return {
    type: UPDATE_HUNT,
    payload: {
      hunt,
      newHuntData: huntDetails
    }
  };
}

function saveHuntDetailsSucceeded(hunt) {
  const normalized = normalize(hunt, HuntSchema);

  return {
    type: UPDATE_HUNT_SUCCESS,
    payload: normalized,
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "Manage.HuntUpdated",
        payload: {
          hunt: hunt
        }
      }
    }
  };
}

function saveHuntDetailsFailed(error) {
  return {
    type: UPDATE_HUNT_FAILURE,
    error
  };
}

function loadHuntParticipantsSuccess(huntId, participants) {
  const normalized = normalize(participants, {
    users: [UserSchema],
    teams: [TeamSchema]
  });
  return {
    type: PARTICIPANTS_LOADED,
    payload: normalized
  };
}

function loadTeamsSuccess(huntId, teams) {
  const normalized = normalize(teams, [TeamSchema]);
  return {
    type: TEAMS_LOADED,
    payload: normalized
  };
}

function loadHuntChallengesSuccess(huntId, challenges) {
  const result = normalize(challenges, [ChallengeSchema]);
  return {
    type: LOAD_HUNT_CHALLENGES_SUCCESS,
    payload: {
      huntId,
      ...result
    }
  };
}

function loadChallengeAttemptsSuccess(challengeAttempts) {
  const normalized = normalize(challengeAttempts, [ChallengeAttemptSchema]);

  return {
    type: CHALLENGE_ATTEMPTS_LOADED,
    payload: normalized
  };
}

function challengeAttemptsUpdated(challengeAttemptsDiff) {
  const addedNormalized = normalize(challengeAttemptsDiff.added, [
    ChallengeAttemptSchema
  ]);
  const removedNormalized = normalize(challengeAttemptsDiff.deleted, [
    ChallengeAttemptSchema
  ]);
  const updatedNormalized = normalize(challengeAttemptsDiff.updated, [
    ChallengeAttemptSchema
  ]);

  return {
    type: CHALLENGE_ATTEMPT_DIFF_LOADED,
    payload: {
      added: addedNormalized,
      removed: removedNormalized,
      updated: updatedNormalized
    }
  };
}

const updatingLogo = huntId => ({
  type: UPDATING_HUNT_LOGO,
  payload: {
    huntId
  },
  meta: {
    analytics: {
      method: ANALYTICS_TRACK,
      type: "Manage.UpdatingLogo",
      payload: {
        huntId
      }
    }
  }
});

const updateLogoProgress = (huntId, progress) => ({
  type: UPDATING_HUNT_LOGO_PROGRESS,
  payload: {
    huntId,
    progress
  }
});

const updateLogoComplete = huntId => ({
  type: UPDATING_HUNT_LOGO_COMPLETE,
  payload: {
    huntId
  }
});

export {
  deleteHunt,
  deleteHuntSuccess,
  deleteHuntFailure,
  copyHunt,
  copyHuntSuccess,
  copyHuntFailure,
  saveHuntDetails,
  saveHuntDetailsSucceeded,
  saveHuntDetailsFailed,
  loadHuntParticipantsSuccess,
  loadTeamsSuccess,
  loadHuntChallengesSuccess,
  loadChallengeAttemptsSuccess,
  challengeAttemptsUpdated,
  updatingLogo,
  updateLogoProgress,
  updateLogoComplete
};
