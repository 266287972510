import { UserSchema } from "./User";
import { HuntSchema } from "./Hunt";
import { schema } from "normalizr";

export const TeamSchema = new schema.Entity(
  "teams",
  {
    members: new schema.Array(UserSchema),
    hunt: HuntSchema,
  },
  {
    idAttribute: "objectId",
  }
);
