import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectSKUs, selectLoadingSKUs } from "../selectors";
import SKUCard, { PlaceholderSKUCard } from "./SKUCard";

const ChooseHuntSKU = ({ skus, onSelect, loading }) => {
  if (loading) {
    return (
      <Card.Group itemsPerRow={3} stackable>
        <PlaceholderSKUCard />
        <PlaceholderSKUCard />
        <PlaceholderSKUCard />
      </Card.Group>
    );
  }
  return (
    <Card.Group
      textAlign="center"
      itemsPerRow={Math.max(skus.length, 1)}
      stackable
    >
      {skus.map((sku) => (
        <SKUCard
          key={sku.objectId}
          skuId={sku.objectId}
          {...sku}
          onSelect={() => onSelect(sku.objectId, sku.name)}
        />
      ))}
    </Card.Group>
  );
};
ChooseHuntSKU.propTypes = {
  skus: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const mapDispatchToProps = () => ({});

const mapStateToProps = createStructuredSelector({
  skus: selectSKUs,
  loading: selectLoadingSKUs,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChooseHuntSKU)
);
