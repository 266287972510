import { reducer as form } from "redux-form/immutable";
// import auth from "./AuthReducer";
import data from "../reducers/DataReducer";
import capabilities from "reducers/CapabilityReducer";
import login from "containers/Login/reducer";
import signup from "containers/SignUp/reducer";
import myHunts from "containers/MyHunts/reducer";
import createHunt from "containers/CreateHunt/reducer";
import manageHunt from "containers/ManageHunt/reducer";
import judging from "containers/ManageHunt/sections/Judging/reducer";
import leaderboard_old from "containers/ManageHunt/sections/Leaderboard/reducer";
import leaderboard from "containers/ManageHunt/sections/Leaderboard/reducer2";
import editChallenge from "containers/Challenges/ViewEditChallengeModal/reducer";
import joinHunt from "containers/JoinHunt/reducer";
import feed from "containers/Feed/reducers";
import app from "reducers/appReducer";

const scavrReducer = () => ({
  // auth,
  login,
  signup,
  data,
  capabilities,
  app,
  myHunts,
  createHunt,
  form,
  feed,
  manageHunt,
  editChallenge,
  joinHunt,
  judging,
  leaderboard,
  leaderboard_old
});

export default scavrReducer;
