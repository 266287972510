import React, { createRef } from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import moment from "moment";
import { reduxForm } from "redux-form/immutable";
import {
  Header,
  Form,
  Button,
  Placeholder,
  Progress,
  Divider,
  Image
} from "semantic-ui-react";
import { DateTimeInput } from "semantic-ui-calendar-react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { required } from "utils/validators";
import Field from "components/Field/index";
import LocationField from "components/LocationField";
import { getAddressObjectFromPlace } from "components/GoogleMaps/PlaceUtils";
import { loadHunt } from "redux/actionCreators";
import { selectCurrentHunt } from "redux/selectors";
import {
  makeSelectError,
  makeSelectLoading,
  selectHuntDetailsFormInitialValues,
  selectHuntDetailsFormValues,
  selectJoinHuntURL
} from "../../selectors";
import { saveHuntDetails } from "../../actions";
import { MANAGE_HUNT_DETAILS_FORM_NAME } from "../../constants";
import { selectImageLoading, selectImageLoadingProgress } from "./selectors";
import { uploadAndSetLogo } from "../../actionCreators";

class ManageDetails extends React.PureComponent {
  constructor() {
    super();
    this.dropzoneRef = createRef();
    this.fileInputRef = createRef();
    this.state = {
      showAddressFields: true,
      showFilePicker: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.hunt) {
      this.props.loadHunt(this.props.match.params.huntId);
    }
  }

  logoChanged = () => {
    const file = this.fileInputRef.current.files[0];
    log.debug("File Changed", file.name);
    this.props.replaceHuntLogo(this.props.hunt, file);
  };

  save = () => {
    // Save the thing
    const newHuntDetails = this.props.formData;
    if (newHuntDetails.startTime) {
      newHuntDetails.startTime = newHuntDetails.startTime.toDate();
    }
    this.props.saveHuntDetails(this.props.hunt, newHuntDetails);
  };

  handlePlaceSelected = placeObject => {
    this.setState({ showAddressFields: true });
    const addressObject = getAddressObjectFromPlace(placeObject);
    // this.props.changeField(this.props.change("location", addressObject));
    for (const [key, value] of Object.entries(addressObject)) {
      this.props.changeField(this.props.change(`location.${key}`, value));
      log.debug("key, value: ", key, value);
      // console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
    }
  };

  switchToAddress = e => {
    e.preventDefault();
    this.setState({ showAddressFields: true });
  };

  switchToSearch = e => {
    e.preventDefault();
    this.setState({ showAddressFields: false });
  };

  replaceImage = () => {
    this.setState({ showFilePicker: true });
    this.fileInputRef.current.click();
  };

  render() {
    const {
      loading,
      formData,
      hunt,
      imageLoading,
      imageLoadProgress
    } = this.props;
    const { showAddressFields } = this.state;

    let locationPoint;
    if (formData && formData.location) {
      locationPoint = formData.location.point;
    }

    const renderLoading = () => (
      <Placeholder>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder>
    );

    const renderEditInfo = () => {
      let startDate;
      if (this.props.formData) {
        startDate = this.props.formData.startTime;
      }
      return (
        <div>
          <Form error={!!this.props.errors}>
            <Field
              label="Hunt Name"
              name="name"
              component={Form.Input}
              placeholder="Your Hunt's Name"
              validate={[required]}
            />
            <Field
              label="Description"
              name="description"
              component="textarea"
              placeholder="Write a short description of your hunt."
            />
            <Field
              label="Hunt Start Date & Time"
              name="startTime"
              placeholder="Hunt Start Date & Time"
              iconPosition="left"
              component={DateTimeInput}
              dateTimeFormat="MMM D YYYY hh:mm A"
              format={v => (v ? v.format("MMM D YYYY - hh:mm A") : "")}
              normalize={v => (v ? moment(v) : null)}
              closable={true}
              initialDate={startDate ? startDate : null}
              closeOnMouseLeave={false}
              clearable={true}
              timeFormat="ampm"
            />
            <LocationField
              label="Location"
              handlePlaceSelected={this.handlePlaceSelected}
              showAddressFields={showAddressFields}
              locationPoint={locationPoint}
              showSwitchButton={true}
              handleSwitchStateToSearch={this.switchToSearch}
              handleSwitchStateToAddress={this.switchToAddress}
            />
            <Form.Field>
              <label>Hunt Image</label>
              <input
                hidden={true}
                type="file"
                accept="image/*"
                onChange={this.logoChanged}
                ref={this.fileInputRef}
              />
              <div>
                {imageLoading ? (
                  <div>
                    <Placeholder>
                      <Placeholder.Image square size="medium" bordered />
                    </Placeholder>
                    <br />
                    <Progress
                      percent={imageLoadProgress}
                      size="tiny"
                      indicating
                    >
                      tiny
                    </Progress>
                  </div>
                ) : hunt.imageURL ? (
                  <div>
                    <Image src={hunt.imageURL} size="medium" bordered />
                  </div>
                ) : (
                  ""
                )}
                <br />
                <Button size="small" onClick={this.replaceImage}>
                  Replace Image
                </Button>
              </div>
            </Form.Field>
          </Form>
          <Divider hidden />
          <Button primary size="medium" onClick={this.save}>
            Save
          </Button>
        </div>
      );
    };

    let body;

    if (loading) {
      body = renderLoading();
    } else {
      body = renderEditInfo();
    }

    return (
      <div>
        <Button primary size="small" floated="right" onClick={this.save}>
          Save
        </Button>
        <Header as="h2">Hunt Details</Header>
        {body}
      </div>
    );
  }
}

ManageDetails.propTypes = {
  match: PropTypes.object.isRequired,
  loadHunt: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired,
  saveHuntDetails: PropTypes.func.isRequired,
  replaceHuntLogo: PropTypes.func.isRequired,
  hunt: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  joinHuntURL: PropTypes.string.isRequired,
  formData: PropTypes.object,
  change: PropTypes.func,
  imageLoadProgress: PropTypes.number,
  imageLoading: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  hunt: selectCurrentHunt,
  loading: makeSelectLoading(),
  error: makeSelectError(),
  initialValues: selectHuntDetailsFormInitialValues,
  formData: selectHuntDetailsFormValues,
  joinHuntURL: selectJoinHuntURL,
  imageLoading: selectImageLoading,
  imageLoadProgress: selectImageLoadingProgress
});

const mapDispatchToProps = dispatch => ({
  loadHunt: huntId => dispatch(loadHunt(huntId)),
  saveHuntDetails: (hunt, huntDetails) =>
    dispatch(saveHuntDetails(hunt, huntDetails)),
  changeField: changeAction => dispatch(changeAction),
  replaceHuntLogo: (hunt, file) => dispatch(uploadAndSetLogo(file, hunt))
});

const ReduxManageDetailsForm = reduxForm({
  form: MANAGE_HUNT_DETAILS_FORM_NAME
})(ManageDetails);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReduxManageDetailsForm)
);
