import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import qs from "qs";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Header,
  Divider,
  Grid,
  Placeholder,
  Image,
  Dropdown
} from "semantic-ui-react";
import { Switch, Route, Redirect } from "react-router-dom";
import { MediaContextProvider, Media } from "components/Responsive/Media";

import { loadHunt, huntPurchased } from "redux/actionCreators";
import ManageDetails from "./sections/ManageDetails";
import ManageFeed from "../Feed";
import ManageChallenges from "containers/Challenges/ManageChallenges";
import ManageJudging from "./sections/Judging";
import ManageLeaderboard from "./sections/Leaderboard";
import ManageParticipants from "./sections/Participants";
import ManageManagers from "./sections/ManageManagers";
import ManageDownload from "./sections/Download";
import ManageSettings from "./sections/Settings";
import ManageStatus from "./sections/ManageStatus";
import ManageHuntMenu, { manageSections } from "./sections/ManageHuntMenu";
import HuntStatusSteps from "./sections/HuntStatusSteps";

import { capitalizeFirstLetter } from "utils/string";
import { selectCurrentHunt } from "redux/selectors";
import "./manage-hunt.css";

// import log from "loglevel";

class ManageHunt extends React.PureComponent {
  UNSAFE_componentWillMount() {
    const { match, location } = this.props;
    this.props.loadHunt(match.params.huntId);

    if (location.search) {
      let queryParams = qs.parse(location.search, {
        ignoreQueryPrefix: true
      });
      if (queryParams.session_id) {
        this.props.huntPurchased(match.params.huntId, queryParams.session_id);
      }
    }
  }

  handleSectionClick = sectionName => {
    this.props.history.push(
      "/manage/" + this.props.match.params.huntId + "/" + sectionName
    );
  };

  render() {
    var huntStatus = "";
    if (this.props.hunt) {
      huntStatus = capitalizeFirstLetter(this.props.hunt.status);
    }

    const ManageMenu = ({ ...rest }) => (
      <ManageHuntMenu
        handleSectionClick={this.handleSectionClick}
        {...rest}
        huntStatus={huntStatus}
      />
    );

    const DropdownManageMenu = params => {
      log.debug("dropdown params: ", params);
      var options = [
        {
          key: "status",
          value: "status",
          text: `Hunt Status - ${capitalizeFirstLetter(params.status)}`
        }
      ];
      manageSections().forEach(val => options.push(val));
      return (
        <Dropdown
          text=""
          icon="sitemap"
          style={{
            float: "right",
            marginRight: 0,
            marginLeft: ".25em"
          }}
          button
          basic
          direction="right"
          options={options}
          onChange={(e, data) => {
            this.handleSectionClick(data.value);
          }}
        />
      );
    };

    const { hunt, match } = this.props;
    const huntId = match.params.huntId;

    return (
      <div className="manage-hunt">
        <Helmet>
          <title>
            Scavr - Manage Hunt - {hunt && hunt.name ? hunt.name : ""}
          </title>
        </Helmet>
        {hunt ? (
          <MediaContextProvider>
            <Media at="sm">
              <DropdownManageMenu status={hunt.status} />
            </Media>
            <Header as="h1">
              <Image
                size="small"
                circular
                src={hunt.imageURL}
                verticalAlign="middle"
              />
              {hunt.name}
            </Header>
          </MediaContextProvider>
        ) : (
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        )}
        <Divider clearing={true} />
        <Grid centered columns={2}>
          <MediaContextProvider>
            <Media greaterThanOrEqual="md">
              <Grid.Column only="computer tablet" width={4}>
                <Route path="/manage/:huntId/:section" render={ManageMenu} />
              </Grid.Column>
            </Media>
          </MediaContextProvider>
          <Grid.Column
            mobile={16}
            tablet={12}
            computer={12}
            largeScreen={12}
            className="manage-hunt-body"
          >
            <Switch>
              <Redirect
                exact
                from="/manage/:huntId"
                to={"/manage/" + huntId + "/challenges"}
              />
              <Route path="/manage/:huntId/status" component={ManageStatus} />
              <Route path="/manage/:huntId/details" component={ManageDetails} />
              <Route
                path="/manage/:huntId/challenges"
                component={ManageChallenges}
              />
              <Route path="/manage/:huntId/judging" component={ManageJudging} />
              <Route
                path="/manage/:huntId/participants"
                component={ManageParticipants}
                // render={() => <ManageParticipants hunt={hunt} />}
              />
              <Route
                path="/manage/:huntId/leaderboard"
                component={ManageLeaderboard}
              />
              <Route path="/manage/:huntId/feed" component={ManageFeed} />
              <Route
                path="/manage/:huntId/download"
                component={ManageDownload}
              />
              <Route
                path="/manage/:huntId/managers"
                component={ManageManagers}
              />
              <Route
                path="/manage/:huntId/settings"
                component={ManageSettings}
              />
            </Switch>
          </Grid.Column>
        </Grid>
        {hunt ? (
          <MediaContextProvider>
            <Media greaterThanOrEqual="md">
              <div className="push" />
              <div className="hunt-footer">
                <HuntStatusSteps hunt={hunt} />
              </div>
            </Media>
          </MediaContextProvider>
        ) : (
          ""
        )}
      </div>
    );
  }
}

ManageHunt.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  hunt: PropTypes.object,
  match: PropTypes.object.isRequired,
  loadHunt: PropTypes.func.isRequired,
  huntPurchased: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  hunt: selectCurrentHunt
});

const mapDispatchToProps = dispatch => ({
  loadHunt: huntId => dispatch(loadHunt(huntId)),
  huntPurchased: (huntId, sessionId) =>
    dispatch(huntPurchased(huntId, sessionId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageHunt)
);
