import { createSelector } from "reselect";

const selectDeleteTeamState = (state) => state.manageHunt.deleteTeam;

const team = (state, props) => props.teamToDelete;

export const selectIsDeleting = createSelector(
  selectDeleteTeamState,
  team,
  (deleteTeamState, team) => {
    return deleteTeamState
      ? deleteTeamState.getIn([team, "isDeleting"], false)
      : false;
  }
);
