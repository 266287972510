import React from "react";
import log from "loglevel"; //eslint-disable-line no-unused-vars
import { Helmet } from "react-helmet";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import PropTypes from "prop-types";
import FirebaseAuth from "containers/FirebaseAuth";
import JoinHunt from "containers/JoinHunt";
import { Loader } from "semantic-ui-react";
import App from "./MainApp";
import { listenForAuthChanges } from "redux/actionCreators";
import { initializeApp } from "redux/actions";

// import "semantic/dist/semantic.min.css";
import "semantic-ui-css/semantic.min.css";

const AuthRoute = ({ component, shouldRender, redirect, path }) => (
  /* eslint-disable indent */
  <Route
    path={path}
    render={() =>
      shouldRender ? (
        component
      ) : (
        <Redirect
          to={{
            pathname: redirect
          }}
        />
      )
    }
  />
);

AuthRoute.propTypes = {
  shouldRender: PropTypes.bool.isRequired,
  redirect: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired
};

class AuthWrapper extends React.PureComponent {
  componentDidMount() {
    this.props.initializeApp();
    this.props.listenForAuthChange();
  }

  render() {
    const { loading, isAuthenticated, history } = this.props;

    log.info("Rendering main application with props: ", this.props);

    let useEmulator = false;

    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === "development") {
      const emulatorURL = process.env.REACT_APP_EMULATOR_AUTH_URL; // eslint-disable-line no-undef
      if (emulatorURL) {
        log.debug("Using emulator for authentication.");
        useEmulator = true;
      }
    }

    const authComponent = <FirebaseAuth useEmulator={useEmulator} />;

    return (
      <ConnectedRouter history={history}>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Scavr</title>
          </Helmet>
          {loading ? (
            <Loader />
          ) : (
            <Switch>
              <Route path="/join" component={JoinHunt} />
              <AuthRoute
                shouldRender={!isAuthenticated}
                path="/login"
                redirect="/"
                component={authComponent}
              />
              <AuthRoute
                shouldRender={!isAuthenticated}
                path="/signup"
                redirect="/"
                component={authComponent}
              />
              <AuthRoute
                shouldRender={!isAuthenticated}
                path="/firebaseAuth"
                redirect="/"
                component={authComponent}
              />
              <AuthRoute
                shouldRender={isAuthenticated}
                path="/"
                redirect="/login"
                component={<App />}
              />
            </Switch>
          )}
        </div>
      </ConnectedRouter>
    );
  }
}

AuthWrapper.propTypes = {
  history: PropTypes.object.isRequired,

  currentUser: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  listenForAuthChange: PropTypes.func.isRequired,
  initializeApp: PropTypes.func.isRequired,
  initialized: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    currentUser: state.firebase.auth,
    isAuthenticated: !state.firebase.auth.isEmpty,
    loading: state.app.get("initializing") || !state.firebase.auth.isLoaded,
    initialized: !state.app.get("initializing")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initializeApp: () => dispatch(initializeApp()),
    listenForAuthChange: () => dispatch(listenForAuthChanges())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthWrapper);
