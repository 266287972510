import { createSelector } from "reselect";

import log from "loglevel"; // eslint-disable-line no-unused-vars

const selectSettingsState = state => state.manageHunt.settings;

const selectIsDeleting = createSelector(selectSettingsState, settings =>
  settings.get("isDeleting")
);

const selectIsCopying = createSelector(selectSettingsState, settings =>
  settings.get("isCopying")
);

const selectIsLoadingSettings = createSelector(selectSettingsState, settings =>
  settings.get("isLoadingSettings")
);

export { selectIsDeleting, selectIsCopying, selectIsLoadingSettings };
