import log from "loglevel"; // eslint-disable-line no-unused-vars
import { createSelector } from "reselect";
import { inflateObject } from "utils/selectors";

const selectData = state => state.data;
const selectSettings = state => state.data.get("huntSettings", null);
const selectChallenges = state => state.data.get("challenges", null);
const selectTeams = state => state.data.get("teams", null);
const selectFeedItems = state => state.data.get("feedItem", null);
const selectCurrentHuntId = (state, props) => props.match.params.huntId;
const selectCurrentHunt = createSelector(
  [selectData, selectCurrentHuntId],
  (data, huntId) => inflateObject(data, huntId, "hunts")
);

const selectCapabilities = state => state.capabilities;

const selectCurrentHuntSettings = createSelector(
  [selectCurrentHuntId, selectSettings],
  (huntId, settingsData) =>
    settingsData && settingsData.get(huntId)
      ? settingsData.get(huntId).toJS()
      : {}
);

const selectHuntCapabilityChallenges = createSelector(
  selectCurrentHuntId,
  selectCapabilities,
  (huntId, caps) => {
    let huntCaps = caps.get(huntId);
    if (huntCaps) {
      return huntCaps.challenges;
    }
    return null;
  }
);

const selectHuntCapabilityParticipants = createSelector(
  selectCurrentHuntId,
  selectCapabilities,
  (huntId, caps) => {
    let huntCaps = caps.get(huntId);
    if (huntCaps) {
      return huntCaps.participants;
    }
    return null;
  }
);

const selectHuntCapabilityChallengeLimit = createSelector(
  selectHuntCapabilityChallenges,
  challengeCaps => (challengeCaps ? challengeCaps.challengeLimit : null)
);

const legacyChallengeTypesDict = {
  basic: ["photo", "password"],
  enhanced: ["photo", "password", "qr", "geopoint", "video"]
};
const selectHuntCapabilityChallengeTypes = createSelector(
  selectHuntCapabilityChallenges,
  selectCurrentHunt,
  (challengeCaps, hunt) => {
    if (challengeCaps) {
      return challengeCaps.challengeTypes;
    } else {
      return legacyChallengeTypesDict[hunt.priceLevel.toLowerCase()];
    }
  }
);

const selectHuntCapabilityTeamLimit = createSelector(
  selectHuntCapabilityParticipants,
  participantCaps => (participantCaps ? participantCaps.teamLimit : null)
);

export {
  selectData,
  selectChallenges,
  selectTeams,
  selectFeedItems,
  selectCurrentHuntId,
  selectCurrentHunt,
  selectCurrentHuntSettings,
  selectHuntCapabilityChallengeLimit,
  selectHuntCapabilityChallengeTypes,
  selectHuntCapabilityTeamLimit
};
