import log from "loglevel"; // eslint-disable-line no-unused-vars
import DataProvider from "DataProvider";
import {
  deleteTeam,
  deleteTeamFailed,
  deleteTeamSucceeded,
  loadHuntAction,
  loadHuntSucceeded,
  loadHuntFailed,
  loadCapabilitiesSucceeded,
  loadCapabilitiesAction,
  initializeRemoteConfig,
  remoteConfigInitialized,
  initializeAppComplete,
  paymentCompleteAction,
  creatingArchive,
  archiveCreated,
  createArchiveFailed,
  stoppedWatchingArchiveData,
  watchingArchiveData,
  watchHuntArchiveData,
  archiveInfoUpdated,
  stopWatchingHuntArchiveData,
  loadHuntSettingsAction,
  loadHuntSettingsSucceeded,
  loadHuntSettingsFailed,
  updateHuntSettingAction,
  updateHuntSettingSucceeded,
  watchHuntSettingsAction,
  stopWatchingHuntSettingsAction,
  watchSettingsStarted,
  watchSettingsStopped,
} from "redux/actions";
import RemoteConfig from "../RemoteConfig";
import { authChangedAction } from "../reducers/AuthReducer";
import { updateUserProfile } from "../utils/auth";

const dataProvider = DataProvider.instance();

const deleteTeamFromHunt = (teamId, huntId) => async (dispatch) => {
  dispatch(deleteTeam(teamId, huntId));

  try {
    let result = await dataProvider.deleteTeam(teamId, huntId);

    log.info("Delete team result: ", result);

    dispatch(deleteTeamSucceeded(teamId));
  } catch (err) {
    dispatch(deleteTeamFailed(err));
  }
};

const loadHunt = (huntId) => async (dispatch) => {
  dispatch(loadHuntAction(huntId));
  dispatch(loadCapabilitiesAction(huntId));
  try {
    let huntObject = await dataProvider.loadHunt(huntId);
    dispatch(loadHuntSucceeded(huntObject));

    let capabilities = await dataProvider.loadHuntCapabilities(huntId);
    dispatch(
      loadCapabilitiesSucceeded({
        huntId,
        capabilities,
      })
    );
  } catch (err) {
    dispatch(loadHuntFailed({ error: err }));
  }
};

const huntPurchased = (huntId, sessionId) => async (dispatch) => {
  // Get the payment info for this hunt/session/sku
  let hunt = await dataProvider.loadHunt(huntId);
  let sku = await dataProvider.loadSKU(hunt.skuId);

  // Emit an event with the correct price paid, mostly for analytics
  dispatch(
    paymentCompleteAction({
      huntId,
      skuShortCode: hunt.skuShortCode,
      skuDisplayName: hunt.skuName,
      skuId: hunt.skuId,
      priceUSD: sku.price / 100.0,
      sessionId,
    })
  );
};

const authChanged = (newUser) => async (dispatch) => {
  log.debug("Auth has changed to: ", newUser);
  if (newUser) {
    dispatch(initializeRemoteConfig());
    await RemoteConfig.initialize(newUser.uid);
    log.debug("remote config initialized.");
    dispatch(remoteConfigInitialized());
    dispatch(authChangedAction(newUser));
  }
  dispatch(initializeAppComplete());
};

const listenForAuthChanges = () => (dispatch) => {
  log.debug("Listening for auth changes.");

  dataProvider.listenForAuthChanges((user) => {
    // dispatch an "Auth did change" action
    dispatch(authChanged(user));

    if (user) {
      const profileInfo = {
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
      };
      updateUserProfile(user.uid, profileInfo);
    }
  });

  // dispatch(
  //   listenForAuthChange(user => {
  //     dispatch(authChanged(user));
  //   })
  // );
};

const createAttemptArchive = (huntId, settings) => async (dispatch) => {
  dispatch(creatingArchive(huntId, settings));

  // Call the thing to create the archive
  log.debug("Creating archive.", huntId, settings);
  try {
    let downloadURL = await dataProvider.createAttemptArchive(huntId, settings);

    log.debug("Created archive: ", downloadURL);
    dispatch(archiveCreated({ huntId, downloadURL }));
  } catch (e) {
    log.error("Couldn't make archive: ", e);
    dispatch(createArchiveFailed(huntId, e));
  }
};

const stopListeningForArchive = (huntId) => (dispatch) => {
  dispatch(stopWatchingHuntArchiveData({ huntId }));

  dispatch(
    stoppedWatchingArchiveData({
      huntId,
    })
  );
};

const listenForHuntArchive = (huntId) => async (dispatch) => {
  dispatch(
    watchHuntArchiveData({ huntId }, () => {
      const listener = dataProvider.watchHuntArchiveInfo(
        huntId,
        (archiveInfo) => {
          log.debug("Got archive info update: ", archiveInfo);
          dispatch(archiveInfoUpdated({ huntId, archiveInfo }));
        }
      );

      dispatch(watchingArchiveData(huntId, listener));

      return listener;
    })
  );
};

const watchHuntSettings = (huntId) => async (dispatch) => {
  dispatch(
    watchHuntSettingsAction({ huntId }, () => {
      let listener = dataProvider.watchHuntSettings(huntId, (settings) => {
        dispatch(loadHuntSettingsSucceeded({ huntId, settings }));
      });

      dispatch(watchSettingsStarted({ huntId }));

      return listener;
    })
  );
};

const stopWatchingHuntSettings = (huntId) => (dispatch) => {
  log.debug("Stopping watching hunt settings: ", huntId);
  dispatch(stopWatchingHuntSettingsAction({ huntId }));
  dispatch(watchSettingsStopped({ huntId }));
};

const loadHuntSettings = (huntId) => async (dispatch) => {
  dispatch(loadHuntSettingsAction({ huntId }));

  try {
    const settings = await dataProvider.loadHuntSettings(huntId);

    dispatch(loadHuntSettingsSucceeded({ huntId, settings }));
  } catch (error) {
    dispatch(loadHuntSettingsFailed({ huntId, error }));
  }
};

const updateHuntSetting = (huntId, settingKey, newValue) => async (
  dispatch
) => {
  dispatch(updateHuntSettingAction({ huntId, settingKey, newValue }));

  await dataProvider.updateHuntSetting(huntId, settingKey, newValue);

  dispatch(updateHuntSettingSucceeded(huntId));

  dispatch(loadHuntSettings(huntId));
};

export {
  deleteTeamFromHunt,
  loadHunt,
  huntPurchased,
  authChanged,
  listenForAuthChanges,
  createAttemptArchive,
  listenForHuntArchive,
  stopListeningForArchive,
  watchHuntSettings,
  stopWatchingHuntSettings,
  loadHuntSettings,
  updateHuntSetting,
};
