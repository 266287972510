import log from "loglevel"; // eslint-disable-line no-unused-vars
import DataProvider from "DataProvider";
import md5 from "md5";

const dataProvider = DataProvider.instance();

export const updateUserProfile = (userId, userProfileInfo) => {
  let photoURL = userProfileInfo.photoURL;
  if (!photoURL) {
    photoURL = generateProfileImage(userProfileInfo.email);
  }
  const profileWithImage = Object.assign(userProfileInfo, { photoURL });
  dataProvider.updateUserProfile(userId, profileWithImage);
};

export const generateProfileImage = (email) => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};
