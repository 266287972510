import skuFunctions from "./skus";
import huntFunctions from "./hunts";
import capabilityFunctions from "./capabilities";
import settingsFunctions from "./settings";
import feedFunctions from "./feed";
import leaderboardFunctions from "./leaderboard";
import challengeAttemptFunctions from "./challengeAttempts";
import * as firebaseFunctions from "./firebaseDataProvider";

const firebase = {
  ...firebaseFunctions,
  ...skuFunctions,
  ...capabilityFunctions,
  ...huntFunctions,
  ...leaderboardFunctions,
  ...challengeAttemptFunctions,
  ...settingsFunctions,
  ...feedFunctions
};

export default firebase;
