import { fromJS, Set } from "immutable";
import {
  APPROVE_ATTEMPT,
  APPROVE_ATTEMPT_FAILED,
  APPROVE_ATTEMPT_SUCCEEDED,
  FILTER_CHANGED,
  JUDGING_FILTER_CHALLENGE,
  JUDGING_FILTER_CHALLENGE_STATUS,
  JUDGING_FILTER_CHALLENGE_TYPE,
  JUDGING_FILTER_TEAM,
  LOADING_JUDGING_DATA,
  REJECT_ATTEMPT,
  REJECT_ATTEMPT_FAILED,
  REJECT_ATTEMPT_SUCCEEDED,
} from "./constants";
import {
  CHALLENGE_ATTEMPT_DIFF_LOADED,
  CHALLENGE_ATTEMPTS_LOADED,
} from "containers/ManageHunt/constants";
import { LOAD_HUNT_CHALLENGES_SUCCESS } from "containers/Challenges/constants";
import { TEAMS_LOADED } from "containers/ManageHunt/constants";

const initialState = fromJS({
  selectedTeamId: null,
  selectedChallengeId: null,
  selectedChallengeStatus: "attempted",
  selectedChallengeType: null,
  loadingTeams: false,
  loadingChallenges: false,
  loadingAttempts: false,
}).set("attemptsBeingJudged", Set());

function judgingReducer(state = initialState, action) {
  switch (action.type) {
    case FILTER_CHANGED:
      switch (action.payload.filterType) {
        case JUDGING_FILTER_CHALLENGE_TYPE:
          return state.set("selectedChallengeType", action.payload.value);
        case JUDGING_FILTER_CHALLENGE:
          return state.set("selectedChallengeId", action.payload.value);
        case JUDGING_FILTER_TEAM:
          return state.set("selectedTeamId", action.payload.value);
        case JUDGING_FILTER_CHALLENGE_STATUS:
          return state.set("selectedChallengeStatus", action.payload.value);
        default:
          break;
      }
      break;
    case LOAD_HUNT_CHALLENGES_SUCCESS:
      return state.set("loadingChallenges", false);
    case TEAMS_LOADED:
      return state.set("loadingTeams", false);
    case CHALLENGE_ATTEMPTS_LOADED:
    case CHALLENGE_ATTEMPT_DIFF_LOADED:
      return state.set("loadingAttempts", false);
    case LOADING_JUDGING_DATA:
      return state
        .set("loadingChallenges", true)
        .set("loadingTeams", true)
        .set("loadingAttempts", true);
    case APPROVE_ATTEMPT:
    case REJECT_ATTEMPT:
      return state.set(
        "attemptsBeingJudged",
        state.get("attemptsBeingJudged").add(action.payload.attemptId)
      );
    case APPROVE_ATTEMPT_SUCCEEDED:
    case APPROVE_ATTEMPT_FAILED:
    case REJECT_ATTEMPT_SUCCEEDED:
    case REJECT_ATTEMPT_FAILED:
      return state.set(
        "attemptsBeingJudged",
        state.get("attemptsBeingJudged").delete(action.payload.attemptId)
      );
    default:
      break;
  }
  return state;
}

export default judgingReducer;
