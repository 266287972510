import { fromJS } from "immutable";

import log from "loglevel";

import {
  CREATE_HUNT_STARTED,
  CREATE_HUNT_PRICING_CHOSEN,
  CREATE_HUNT_TYPE_SELECTED,
  CREATE_HUNT_INFO_SUBMITTED,
  SAVE_HUNT_SUCCEEDED,
  SAVE_HUNT_FAILED,
  CREATE_HUNT_COMPLETED,
  CREATE_HUNT_SKU_SELECTED,
} from "./constants";
import {
  LOAD_HUNT_LEVELS,
  LOAD_HUNT_LEVELS_FAILURE,
  LOAD_HUNT_LEVELS_SUCCESS,
} from "../../redux/constants";

const initialState = fromJS({
  step: "pricing",
  huntType: null,
  huntLevel: null,
  huntSKUId: null,
  loading: false,
  error: null,
  huntId: null,
  loadingSKUs: false,
  skus: [],
});

function createHuntReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_HUNT_STARTED:
      return state
        .set("step", "pricing")
        .set("huntType", null)
        .set("huntSKUId", null)
        .set("loading", false)
        .set("error", null);
    case CREATE_HUNT_PRICING_CHOSEN:
      return state
        .set("step", "type")
        .set("huntLevel", action.payload.huntLevel);
    case CREATE_HUNT_SKU_SELECTED:
      return state
        .set("step", "type")
        .set("huntSKUId", action.payload.skuId)
        .set("loadingSkus", false);
    case CREATE_HUNT_TYPE_SELECTED:
      return state.set("step", "info").set("huntType", action.payload.huntType);
    case CREATE_HUNT_INFO_SUBMITTED:
      return state.set("loading", true).set("error", null);
    case SAVE_HUNT_SUCCEEDED:
      return state.set("huntId", action.payload.result).set("error", null);
    case CREATE_HUNT_COMPLETED:
      return state.set("loading", false).set("step", "complete");
    case SAVE_HUNT_FAILED:
      return state.set("loading", false).set("error", action.error);
    case LOAD_HUNT_LEVELS:
      return state.set("loadingSKUs", true);
    case LOAD_HUNT_LEVELS_SUCCESS:
      log.debug("SKUS: ", action.payload);
      return state
        .set("loadingSKUs", false)
        .set(
          "skus",
          fromJS(action.skuIds.map((id) => action.payload.entities.skus[id]))
        );
    case LOAD_HUNT_LEVELS_FAILURE:
      return state.set("loadingSKUs", false).set("error", action.error);
    default:
      return state;
  }
}

export default createHuntReducer;
