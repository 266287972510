import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Input, Modal } from "semantic-ui-react";
import { updateTeamName } from "../actions";
import log from "loglevel";
import { selectTeamNameLoading } from "../selectors";
import { createStructuredSelector } from "reselect"; // eslint-disable-line no-unused-vars

class EditTeamName extends React.PureComponent {
  state = { teamName: "", modalOpen: false };

  onSave = () => {
    log.info(
      "Updating team name: ",
      this.state.teamName,
      this.props.team.objectId
    );
    this.props.updateTeamName(this.props.team.objectId, this.state.teamName);
    this.setState({ modalOpen: false });
  };

  onNameChanged = (event, data) => {
    this.setState({ teamName: data.value });
  };

  onOpen = () => {
    this.setState({ teamName: this.props.team.name, modalOpen: true });
  };

  onClose = () => this.setState({ modalOpen: false });

  render() {
    let teamName = this.props.team.name;
    let newTeamName = this.state.teamName;

    let trigger = <a>{teamName}</a>;

    if (this.props.loading) {
      return <span>{newTeamName}</span>;
    }

    return (
      <Modal
        trigger={trigger}
        size="mini"
        closeIcon={true}
        onOpen={this.onOpen}
        onClose={this.onClose}
        open={this.state.modalOpen}
      >
        <Modal.Header>Change My Team Name</Modal.Header>
        <Modal.Content>
          <Input
            focus
            fluid
            defaultValue={teamName}
            onChange={this.onNameChanged}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={this.onSave}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

EditTeamName.propTypes = {
  team: PropTypes.object,
  updateTeamName: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: selectTeamNameLoading,
});

function mapDispatchToProps(dispatch) {
  return {
    updateTeamName: (teamId, newTeamName) =>
      dispatch(updateTeamName(teamId, newTeamName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTeamName);
