import log from "loglevel"; // eslint-disable-line no-unused-vars
const providerType = process.env.REACT_APP_DATA_PROVIDER; // eslint-disable-line no-undef

const getInstance = () => {
  let instance;

  function createInstance() {
    let object;
    switch (providerType) {
      // case "parse":
      //   object = require("./parseDataProvider");
      //   break;
      case "firebase":
        object = require("./firebase/index.js").default;
        log.debug("provider: ", object);
        break;
      default:
        object = {};
        break;
    }
    return object;
  }

  return {
    instance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
};

const api = {
  instance: getInstance().instance,
};

export default api;
