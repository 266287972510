/*
 *
 * Signup constants
 *
 */

export const SIGNUP_CHANGE_EMAIL = "app/Signup/CHANGE_EMAIL";
export const SIGNUP_CHANGE_PASSWORD = "app/Signup/CHANGE_PASSWORD";
export const SIGNUP_CHANGE_FIRST_NAME = "app/Signup/CHANGE_FIRST_NAME";
export const SIGNUP_CHANGE_LAST_NAME = "app/Signup/CHANGE_LAST_NAME";
