import {
  LOCATION_CHANGE,
  ANALYTICS_PAGE,
  IDENTIFY_USER,
  ANALYTICS_IDENTIFY,
} from "./constants";

export function locationChange(history, name = "") {
  return {
    type: LOCATION_CHANGE,
    history,
    meta: {
      analytics: {
        method: ANALYTICS_PAGE,
        payload: {
          path: history.location.pathname,
          name,
        },
      },
    },
  };
}

export function identifyUser() {
  return {
    type: IDENTIFY_USER,
    meta: {
      analytics: {
        method: ANALYTICS_IDENTIFY,
      },
    },
  };
}
