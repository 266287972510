import { schema } from "normalizr";
import { HuntSchema } from "./Hunt";

export const ChallengeSchema = new schema.Entity(
  "challenges",
  {
    hunt: HuntSchema,
  },
  {
    idAttribute: "objectId",
  }
);
