// import { normalizeHunts } from "../../models/Hunt";
import { HuntSchema } from "../../models/Hunt";
import { normalize } from "normalizr";
import {
  LOAD_MY_HUNTS,
  LOAD_MY_HUNTS_FAILURE,
  LOAD_MY_HUNTS_SUCCESS,
} from "./constants";

export function loadMyHunts() {
  return {
    type: LOAD_MY_HUNTS,
  };
}

export function loadMyHuntsFailure(error) {
  return {
    type: LOAD_MY_HUNTS_FAILURE,
    error,
  };
}

export function loadMyHuntsSuccess(hunts) {
  return {
    type: LOAD_MY_HUNTS_SUCCESS,
    payload: normalize(hunts, [HuntSchema]),
  };
}
