import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Step, Button, Modal } from "semantic-ui-react";

import {
  HUNT_STATE_FINISHED,
  HUNT_STATE_PUBLISHED,
  HUNT_STATE_UNPUBLISHED,
  HUNT_STATE_ACTIVE,
  HUNT_STATE_PAUSED
} from "models/constants";
import { selectJoinHuntURL } from "containers/ManageHunt/selectors";
import CopyableInput from "components/CopyableInput";

import { updateHuntStatus } from "./actions";
import { selectStatusLoading } from "./selectors";

import log from "loglevel"; // eslint-disable-line no-unused-vars

import "./hunt-footer.css";

class HuntStatusSteps extends React.PureComponent {
  handleClose = () => {
    this.setState({ shareLinkOpen: false });
  };

  handleOpen = () => {
    this.setState({ shareLinkOpen: true });
  };

  constructor(props) {
    super(props);
    this.state = {
      shareLinkOpen: false
    };
  }

  handleStepClick = newState => () => {
    const { hunt } = this.props;
    this.props.updateHuntStatus(hunt, newState);
  };

  stepIsActive = step => {
    const { status } = this.props.hunt;
    switch (step) {
      case "signup":
        return false;
      case "publish":
        return status === HUNT_STATE_UNPUBLISHED;
      case "share":
        return status === HUNT_STATE_PUBLISHED;
      case "start":
        return [
          HUNT_STATE_PUBLISHED,
          HUNT_STATE_PAUSED,
          HUNT_STATE_FINISHED
        ].includes(status);
      case "pause":
        return status === HUNT_STATE_ACTIVE;
      case "finish":
        return status === HUNT_STATE_ACTIVE;
      default:
        return false;
    }
  };

  stepIsCompleted = step => {
    const { status } = this.props.hunt;
    switch (step) {
      case "signup":
        return true;
      case "publish":
        return [
          HUNT_STATE_PUBLISHED,
          HUNT_STATE_ACTIVE,
          HUNT_STATE_PAUSED,
          HUNT_STATE_FINISHED
        ].includes(status);
      case "start":
        return [HUNT_STATE_ACTIVE, HUNT_STATE_FINISHED].includes(status);
      case "pause":
        return status === HUNT_STATE_FINISHED;
      case "share":
        return [
          HUNT_STATE_ACTIVE,
          HUNT_STATE_PAUSED,
          HUNT_STATE_FINISHED
        ].includes(status);
      case "finish":
        return status === HUNT_STATE_FINISHED;
      default:
        return false;
    }
  };

  titleForStep = ({ step, title, nextState, onClickAction }) => {
    const { statusIsLoading } = this.props;
    const active = this.stepIsActive(step);
    const onClick = onClickAction
      ? onClickAction
      : this.handleStepClick(nextState);
    if (active) {
      return (
        <Button
          compact
          size="small"
          primary
          onClick={onClick}
          disabled={statusIsLoading}
          loading={statusIsLoading}
        >
          {title}
        </Button>
      );
    } else {
      return title;
    }
  };

  render() {
    const { status } = this.props.hunt;
    const { registerURL } = this.props;
    const { shareLinkOpen } = this.state;

    const createStep = {
      step: "signup",
      icon: "plus square outline",
      title: "Create",
      description: "Set up the details",
      nextState: HUNT_STATE_UNPUBLISHED
    };

    const publishStep = {
      step: "publish",
      icon: "share square outline",
      title: "Publish",
      description: "Create a sign up link",
      nextState: HUNT_STATE_PUBLISHED
    };

    const shareStep = {
      step: "share",
      icon: "share square outline",
      title: "Share",
      description: "Share your sign-up link",
      onClickAction: this.handleOpen
    };

    const launchStep = {
      step: "start",
      icon: "rocket",
      title:
        status === HUNT_STATE_PAUSED
          ? "Resume"
          : status === HUNT_STATE_FINISHED
          ? "Relaunch"
          : "Launch",
      description:
        status === HUNT_STATE_PAUSED
          ? "Restart the hunt!"
          : status === HUNT_STATE_FINISHED
          ? "Restart the hunt!"
          : "Start the hunt!",
      nextState: HUNT_STATE_ACTIVE
    };

    const pauseStep = {
      step: "pause",
      icon: "pause circle outline",
      title: "Pause",
      description: "Pause the hunt",
      nextState: HUNT_STATE_PAUSED
    };

    const finishStep = {
      step: "finish",
      icon: "flag checkered",
      title: "Complete",
      description: "Finish the hunt and see who won",
      nextState: HUNT_STATE_FINISHED
    };

    let stepData = [createStep];

    if (status === HUNT_STATE_UNPUBLISHED) {
      stepData.push(publishStep);
    } else {
      stepData.push(shareStep);
    }

    if (status === HUNT_STATE_ACTIVE) {
      stepData.push(pauseStep);
    } else {
      stepData.push(launchStep);
    }

    stepData.push(finishStep);

    const steps = stepData.map(stepInfo => ({
      key: stepInfo.step,
      icon: stepInfo.icon,
      description: stepInfo.description,
      title: this.titleForStep(stepInfo),
      disabled: !this.stepIsActive(stepInfo.step),
      completed: this.stepIsCompleted(stepInfo.step)
    }));

    return (
      <div>
        <Step.Group fluid widths={4} items={steps} />
        <Modal size="small" open={shareLinkOpen}>
          <Modal.Header>Share Your Hunt&apos;s Registration Page</Modal.Header>
          <Modal.Content>
            <CopyableInput value={registerURL} label={""} />
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={this.handleClose}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

HuntStatusSteps.propTypes = {
  updateHuntStatus: PropTypes.func.isRequired,

  hunt: PropTypes.object.isRequired,
  statusIsLoading: PropTypes.bool.isRequired,
  registerURL: PropTypes.string
};

const mapStateToProps = createStructuredSelector({
  statusIsLoading: selectStatusLoading,
  registerURL: selectJoinHuntURL
});

const mapDispatchToProps = dispatch => ({
  updateHuntStatus: (hunt, newState) =>
    dispatch(updateHuntStatus(hunt, newState))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HuntStatusSteps)
);
