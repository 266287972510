import React, { createRef } from "react";
import Field from "../Field";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import { DateInput } from "semantic-ui-calendar-react";

import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

class DateField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.fieldRef = createRef();
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = () => {
    if (this.fieldRef.current) {
      this.fieldRef.current.blur();
    }
  };

  render() {
    const { label, name, placeholder, onClear } = this.props;

    return (
      <Field
        label={label}
        name={name}
        placeholder={placeholder}
        iconPosition="left"
        component={DateInput}
        dateFormat="MMM D YYYY"
        onClear={onClear}
        closable={true}
        closeOnMouseLeave={false}
        clearable={true}
        animation="none"
        ref={ref => (this.fieldRef = ref)}
      />
    );
  }
}

DateField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onClear: PropTypes.func
};

export default DateField;
