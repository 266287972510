import { fromJS } from "immutable";
import { LOAD_HUNT_CAPABILITIES_SUCCESS } from "../redux/constants";

const initialState = fromJS({});

export default function reducer(state = initialState, action) {
  if (action.type === LOAD_HUNT_CAPABILITIES_SUCCESS) {
    return state.set(action.payload.huntId, action.payload.capabilities);
  }
  return state;
}
