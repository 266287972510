import log from "loglevel"; //eslint-disable-line no-unused-vars
import DataProvider from "DataProvider";
import { loadingFeedAction, loadingFeedSucceeded, resetFeed } from "./actions";
import {
  watchHuntSettingsAction,
  loadHuntSettingsSucceeded,
  watchSettingsStarted,
} from "redux/actions";

const dataProvider = DataProvider.instance();

const watchFeedSettings = (huntId) => (dispatch) => {
  dispatch(
    watchHuntSettingsAction({ huntId }, () => {
      let listener = dataProvider.watchHuntSettings(
        huntId,
        async (settings) => {
          log.debug("Got settings update: ", settings);
          dispatch(loadHuntSettingsSucceeded({ huntId, settings }));
          log.debug("dispatched the settings update.");
          if (settings) {
            // If settings has loaded or changed
            // and the feed is now enabled
            // Reset the feed and load items
            dispatch(resetFeed({ huntId }));
            await new Promise((r) => setTimeout(r, 1000));
            dispatch(loadMoreFeedItems(huntId, settings));
          }
        }
      );

      dispatch(watchSettingsStarted({ huntId }));

      return listener;
    })
  );
};

const loadMoreFeedItems = (huntId, settings, loadAfterItem) => async (
  dispatch
) => {
  // Dispatch a "Loading feed" action
  dispatch(loadingFeedAction({ huntId }));

  // Load the feed settings
  log.debug("Loading settings when loading feed items.", huntId);
  if (!settings) {
    settings = await dataProvider.loadHuntSettings(huntId);
    dispatch(loadHuntSettingsSucceeded({ huntId, settings }));
  }

  log.debug("Loading feed with settings: ", settings);

  const limit = 15000;

  if (settings.feedEnabled) {
    // load the feed
    const feedItems = await dataProvider.loadFeed(
      huntId,
      settings,
      limit,
      loadAfterItem
    );

    log.debug("Got feed items: ", feedItems);

    let hasMore = true;
    if (feedItems.length < limit) {
      hasMore = false;
    }

    // dispatch a "Feed Loaded" action
    dispatch(loadingFeedSucceeded({ huntId, feedItems, hasMore }));
  }
};

export { watchFeedSettings, loadMoreFeedItems };
