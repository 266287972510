import React from "react";
import PropTypes from "prop-types";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

class GoogleMap extends React.PureComponent {
  render() {
    const { markers, google, zoom, initialCenter, style, ...rest } = this.props;

    return (
      <Map
        google={google}
        zoom={zoom}
        initialCenter={initialCenter}
        style={style}
        {...rest}
      >
        {markers.map((markerData, idx) => <Marker key={idx} {...markerData} />)}
      </Map>
    );
  }
}

GoogleMap.propTypes = {
  google: PropTypes.object,
  zoom: PropTypes.number,
  initialCenter: PropTypes.object,
  markers: PropTypes.array,
  style: PropTypes.object,
};

GoogleMap.defaultProps = {
  zoom: 14,
  initialCenter: {
    lat: 40.854885,
    lng: -88.081807,
  },
  style: {
    width: "100%",
    height: "100%",
    minHeight: "200px",
  },
  markers: [],
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(GoogleMap);
