import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import { Button, Divider, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/functions";

import log from "loglevel";

function functions() {
  const functions = firebase.functions();

  /* eslint-disable */
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_FIREBASE_FUNCTIONS_URL) {
      functions.useFunctionsEmulator(
        process.env.REACT_APP_FIREBASE_FUNCTIONS_URL
      );
    }
  }
  /* eslint-enable */

  return functions;
}

class TestingComponent extends React.PureComponent {
  createCheckoutSession = async () => {
    log.debug("Checking out...");
    let huntId = "3OvGXsiu4oUvLSckbZ3a";

    try {
      const createCheckoutSessionFunc = functions().httpsCallable(
        "createCheckoutSession"
      );
      const result = await createCheckoutSessionFunc({ huntId });
      log.info("Checkout result: ", result);
    } catch (err) {
      log.error("error checing out: ", err);
    }
  };

  render() {
    return (
      <div className="testing">
        <Helmet>
          <title>Scavr - Testing Page</title>
        </Helmet>
        <Header as="h1" floated="left">
          Test: 1, 2, 3...
        </Header>
        <Divider clearing={true} />
        <Button onClick={this.createCheckoutSession}>
          Create Checkout Session{" "}
        </Button>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TestingComponent)
);
