/* eslint-disable no-undef */
import log from "loglevel";
import { compose } from "redux";
import { reactReduxFirebase, firebaseReducer } from "react-redux-firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import "firebase/remote-config";

import {
  loadModelsForQuerySnapshot,
  loadModelForDocumentSnapshot,
  loadDiffOfModelsForQuerySnapshot
} from "./firebaseModels";

import {
  FIREBASE_COLLECTION_CHALLENGES,
  FIREBASE_COLLECTION_HUNTS,
  FIREBASE_COLLECTION_TEAMS
} from "./constants";

import firebaseUtils from "./firebaseUtils";

export function foo() {
  return "firebase";
}

export const initializeDataProvider = () => firebaseUtils.initialize();
const db = () => firebaseUtils.db();
const storage = () => firebaseUtils.storage();
const functions = () => firebaseUtils.functions();
const auth = () => firebaseUtils.auth();
export const remoteConfig = () => firebaseUtils.remoteConfig();

export function getCreateStore(createStore) {
  const rrfConfig = {
    userProfile: "users",
    useFirestoreForProfile: true
  };

  return compose(
    reactReduxFirebase(firebase, rrfConfig) // firebase instance as first argument
    //   reduxFirestore(firebase) // <- needed if using firestore
  )(createStore);
}

export function getReducers() {
  return {
    firebase: firebaseReducer
    //   firestore: firestoreReducer, // <- needed if using firestore
  };
}

// --------- Data Models -------------------
export function getGeoPoint(latitude, longitude) {
  return new firebase.firestore.GeoPoint(latitude, longitude);
}

// --------- Auth ------------
export function listenForAuthChanges(callback) {
  auth().onAuthStateChanged(function(user) {
    callback(user);
  });
}

export function currentUser() {
  return auth().currentUser;
}

export function emailLogIn(email, password) {
  return auth()
    .signInWithEmailAndPassword(email, password)
    .catch(function(error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      log.error("Error logging in", errorCode, errorMessage);
    });
}

export function emailSignup(userData) {
  const { email, password } = userData;
  return auth()
    .createUserWithEmailAndPassword(email, password)
    .catch(function(error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      log.error("Error signing up", errorCode, errorMessage);
    });
}

export function logOut() {
  return auth().signOut();
}

export function updateUserProfile(userId, userProfile) {
  return db()
    .collection("users")
    .doc(userId)
    .set(userProfile, { merge: true })
    .then(result => {
      log.debug("Set Profile result:", result);
    });
}

// -------------- Data ------------------

export function saveObject(object, newData) {
  let docRef = object.docRef;
  if (!docRef) {
    log.error("Trying to save object without a docRef", object);
    throw new { message: "Missing reference to this object." }();
  }

  Object.keys(newData).forEach(
    key => newData[key] === undefined && delete newData[key]
  );

  // log.debug("About to update object: ", docRef.id, newData);
  return docRef
    .set(newData, { merge: true })
    .then(() => docRef.get())
    .then(snapshot => loadModelForDocumentSnapshot(snapshot))
    .catch(function(error) {
      log.error("Failure updating object.", error);
      throw new { message: "Failure saving object.", error }();
    });
}

export function loadHuntChallenges(huntId) {
  return db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId)
    .collection(FIREBASE_COLLECTION_CHALLENGES)
    .get()
    .then(querySnapshot => loadModelsForQuerySnapshot(querySnapshot))
    .catch(error => {
      log.error("Error getting LoadHuntChallenges document:", error);
      return { err: error };
    });
}

export function loadHuntTeams(huntId) {
  const database = db();

  const huntRef = database.collection(FIREBASE_COLLECTION_HUNTS).doc(huntId);

  return database
    .collection(FIREBASE_COLLECTION_TEAMS)
    .where("hunt", "==", huntRef)
    .get()
    .then(querySnapshot => loadModelsForQuerySnapshot(querySnapshot))
    .catch(error => {
      log.error("Error loading teams", error);
      return { err: error };
    });
}

export function createChallenge(huntId, challengeData) {
  const huntDocRef = db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId);

  return db()
    .runTransaction(transaction =>
      transaction.get(huntDocRef).then(huntDoc => {
        if (!huntDoc.exists) {
          throw new { message: "Hunt does not exist!" }();
        }

        const newChallengeCount = huntDoc.data().challengeCount + 1;
        transaction.update(huntDocRef, {
          challengeCount: newChallengeCount
        });

        const newChallengeRef = huntDocRef.collection("challenges").doc();

        const newChallengeData = challengeData;
        newChallengeData["sortIndex"] = newChallengeCount - 1;
        transaction.set(newChallengeRef, newChallengeData);

        return newChallengeRef;
      })
    )
    .then(chalRef => chalRef.get())
    .then(docSnapshot => loadModelForDocumentSnapshot(docSnapshot))
    .catch(error => {
      log.error("Error creating hunt", error);
    });
}

export function deleteChallenge(challenge, huntId) {
  const huntDocRef = db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId);

  const deleteChallengeRef = db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId)
    .collection("challenges")
    .doc(challenge.objectId);

  const challengesToUpdateRef = db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId)
    .collection("challenges")
    .where("sortIndex", ">", challenge.sortIndex);

  return db().runTransaction(transaction =>
    transaction
      .get(huntDocRef)
      .then(huntDoc => {
        const newChallengeCount = huntDoc.data().challengeCount - 1;
        transaction.update(huntDocRef, {
          challengeCount: newChallengeCount
        });

        transaction.delete(deleteChallengeRef);

        return challengesToUpdateRef.get();
      })
      .then(challengesSnapshot => {
        let batch = db().batch();

        _.forEach(challengesSnapshot.docs, c => {
          if (c.data().sortIndex > challenge.sortIndex) {
            batch.update(c.ref, "sortIndex", c.data().sortIndex - 1);
          }
        });

        return batch.commit();
      })
  );
}

export function reorderChallenge(challengeId, huntId, oldIndex, newIndex) {
  // Get all the challenges, then update all their indexes
  const movingUp = newIndex > oldIndex;

  const query = db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId)
    .collection("challenges")
    .where("sortIndex", ">=", movingUp ? oldIndex : newIndex)
    .where("sortIndex", "<=", movingUp ? newIndex : oldIndex)
    .orderBy("sortIndex", "asc");

  return query.get().then(function(querySnapshot) {
    let batch = db().batch();

    _.forEach(querySnapshot.docs, c => {
      if (c.id === challengeId) {
        batch.update(c.ref, "sortIndex", newIndex);
      } else if (movingUp) {
        batch.update(c.ref, "sortIndex", c.data().sortIndex - 1);
      } else {
        batch.update(c.ref, "sortIndex", c.data().sortIndex + 1);
      }
    });

    return batch.commit();
  });
}

export async function deleteTeam(teamId, huntId) {
  log.debug("About to delete team.", teamId, "for hunt: ", huntId);
  const deleteTeamFunc = functions().httpsCallable("deleteTeam");
  try {
    const result = await deleteTeamFunc({ teamId: teamId, huntId: huntId });
    log.debug("Got result from deleting team: ", result);
    return result;
  } catch (e) {
    log.error("Error deleting team: ", e);
    throw e;
  }
}

// ----- Watching Funcitons ------
export function watchChallenges(huntId, onChallengesUpdated) {
  return db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId)
    .collection("challenges")
    .orderBy("sortIndex", "asc")
    .onSnapshot(function(querySnapshot) {
      loadModelsForQuerySnapshot(querySnapshot).then(challenges => {
        onChallengesUpdated(huntId, challenges);
      });
    });
}

export function watchTeams(huntId, onTeamsUpdated) {
  const database = db();

  const huntRef = database.collection(FIREBASE_COLLECTION_HUNTS).doc(huntId);

  return database
    .collection("teams")
    .where("hunt", "==", huntRef)
    .onSnapshot(function(querySnapshot) {
      loadModelsForQuerySnapshot(querySnapshot).then(teams => {
        onTeamsUpdated(huntId, teams);
      });
    });
}

export function watchParticipants(huntId, onTeamsUpdated, onUsersUpdated) {
  const database = db();

  const huntRef = database.collection(FIREBASE_COLLECTION_HUNTS).doc(huntId);

  return database
    .collection("teams")
    .where("hunt", "==", huntRef)
    .onSnapshot(function(querySnapshot) {
      log.debug("Got participant update: ", querySnapshot);
      loadDiffOfModelsForQuerySnapshot(querySnapshot).then(
        async changedModels => {
          log.debug("Changed models: ", changedModels);
          onTeamsUpdated(changedModels);

          const userIds = _.flatMap(querySnapshot.docs, d => {
            const data = d.data();
            return data.members;
          });

          let userSnapshots = await Promise.all(
            userIds.map(id =>
              database
                .collection("users")
                .doc(id)
                .get()
            )
          );

          let userDocs = await Promise.all(
            userSnapshots.map(loadModelForDocumentSnapshot)
          );

          let userResult = {
            added: userDocs,
            deleted: [],
            updated: []
          };

          onUsersUpdated(userResult);
        }
      );
    });
}

export function watchParticipants2(huntId, onParticipantsUpdated) {
  const database = db();

  const huntRef = database.collection(FIREBASE_COLLECTION_HUNTS).doc(huntId);

  return database
    .collection("teams")
    .where("hunt", "==", huntRef)
    .onSnapshot(function(querySnapshot) {
      const userIds = _.flatMap(querySnapshot.docs, d => {
        const data = d.data();
        return data.members;
      });

      const teams = _.map(querySnapshot.docs, d => {
        const data = d.data();
        const members = data.members;
        return {
          objectId: d.id,
          ...data,
          hunt: huntId,
          members
        };
      });

      Promise.all(
        userIds.map(id =>
          database
            .collection("users")
            .doc(id)
            .get()
        )
      ).then(results => {
        const users = _.map(results, d => {
          const data = d.data();
          return {
            objectId: d.id,
            ...data
          };
        });
        onParticipantsUpdated(huntId, { teams, users });
      });
    });
}

export function watchChallengeAttempts(
  huntId,
  teamId,
  challengeId,
  challengeType,
  challengeStatus,
  onChallengeAttemptsUpdated
) {
  const database = db();

  let huntRef = database.collection(FIREBASE_COLLECTION_HUNTS).doc(huntId);
  let challengeAttemptRef = database
    .collection("challengeAttempts")
    .where("hunt", "==", huntRef);

  if (teamId) {
    let teamRef = database.collection("teams").doc(teamId);
    challengeAttemptRef = challengeAttemptRef.where("team", "==", teamRef);
  }

  if (challengeId) {
    let challengeRef = database
      .collection(FIREBASE_COLLECTION_HUNTS)
      .doc(huntId)
      .collection("challenges")
      .doc(challengeId);

    challengeAttemptRef = challengeAttemptRef.where(
      "challenge",
      "==",
      challengeRef
    );
  }

  if (challengeType) {
    challengeAttemptRef = challengeAttemptRef.where(
      "itemType",
      "==",
      challengeType
    );
  }

  if (challengeStatus) {
    challengeAttemptRef = challengeAttemptRef.where(
      "status",
      "==",
      challengeStatus
    );
  }

  return challengeAttemptRef.onSnapshot(function(querySnapshot) {
    loadDiffOfModelsForQuerySnapshot(querySnapshot).then(changedModels => {
      onChallengeAttemptsUpdated(changedModels);
    });
  });
}

export function stopWatching(unsubscribe) {
  unsubscribe();
}

// --- Join Hunt ---
export function createTeamAndJoinHunt(huntId, teamName, password, currentUser) {
  log.debug("Creating team with data", huntId, teamName, password);
  const huntDocRef = db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId);

  if (!currentUser.uid) {
    throw new { message: "You must be signed in to join this hunt" }();
  }

  const teamData = {
    name: teamName,
    password: password ? password : "",
    members: [currentUser.uid],
    hunt: huntDocRef
  };

  return db()
    .collection("teams")
    .where("hunt", "==", huntDocRef)
    .where("name", "==", teamName)
    .get()
    .then(function(querySnapshot) {
      if (querySnapshot.size > 0) {
        throw new Error("A team with that name already exists.");
      }

      return db()
        .collection("teams")
        .add(teamData);
    })
    .then(docRef => docRef.get())
    .then(doc => {
      const teamData = doc.data();
      return {
        ...teamData,
        objectId: doc.id,
        hunt: teamData.hunt.id
      };
    })
    .catch(error => {
      log.error("Error joining hunt", error);
      throw error;
    });
}

export async function updateTeamName(teamId, newTeamName) {
  const teamRef = db()
    .collection("teams")
    .doc(teamId);

  const newTeamData = {
    name: newTeamName
  };

  await teamRef.set(newTeamData, { merge: true });

  let updatedTeamSnapshot = await teamRef.get();

  let teamModel = await loadModelForDocumentSnapshot(updatedTeamSnapshot);

  return teamModel;
}

export function fetchAmIRegistered(userId, huntId) {
  const huntRef = db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId);

  return db()
    .collection("teams")
    .where("hunt", "==", huntRef)
    .where(`members`, "array-contains", userId)
    .get()
    .then(function(querySnapshot) {
      if (querySnapshot.size > 0) {
        const teams = _.map(querySnapshot.docs, d => {
          const data = d.data();
          return {
            objectId: d.id,
            ...data
          };
        });

        return { response: teams[0] };
      } else {
        return { response: null };
      }
    })
    .catch(error => {
      log.error("Error getting registered document:", error);
      return { err: error };
    });
}

// --- Manage Storage ---

const uploadTaskProgressHandler = progressCallback => snapshot => {
  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  progressCallback(progress);
  switch (snapshot.state) {
    case firebase.storage.TaskState.PAUSED: // or 'paused'
      log.debug("Upload is paused");
      break;
    case firebase.storage.TaskState.RUNNING: // or 'running'
      log.debug("Upload is running");
      break;
    default:
      break;
  }
};

const uploadTaskCompletionHandler = (task, callback) => () => {
  task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
    callback(task.snapshot.ref.fullPath, downloadURL);
  });
};

export function uploadFileFromFile(
  file,
  storagePath,
  progressCallback,
  completionCallback
) {
  const ref = storage()
    .ref()
    .child(storagePath);
  const task = ref.put(file);

  task.on(
    "state_changed",
    uploadTaskProgressHandler(progressCallback),
    function(error) {
      // Handle unsuccessful uploads
      log.error("Error uploading from data url", error);
    },
    uploadTaskCompletionHandler(task, completionCallback)
  );
}

export function uploadFileFromDataURL(
  dataURL,
  storagePath,
  progressCallback,
  completionCallback
) {
  const ref = storage()
    .ref()
    .child(storagePath);
  const task = ref.putString(dataURL, "data_url");

  task.on(
    "state_changed",
    uploadTaskProgressHandler(progressCallback),
    function(error) {
      // Handle unsuccessful uploads
      log.error("Error uploading from data url", error);
    },
    uploadTaskCompletionHandler(task, completionCallback)
  );
}
