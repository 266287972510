/*
 *
 * MyHunts reducer
 *
 */

import { fromJS } from "immutable";
import {
  LOAD_MY_HUNTS,
  LOAD_MY_HUNTS_FAILURE,
  LOAD_MY_HUNTS_SUCCESS,
} from "./constants";

const initialState = fromJS({
  loading: false,
  error: null,
  myHunts: [],
});

function myHuntsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_MY_HUNTS:
      return state
        .set("loading", true)
        .set("error", null)
        .set("myHunts", fromJS([]));
    case LOAD_MY_HUNTS_FAILURE:
      return state.set("loading", false).set("error", action.error);
    case LOAD_MY_HUNTS_SUCCESS:
      return state
        .set("loading", false)
        .set("error", null)
        .set("myHunts", fromJS(action.payload.result));
    default:
      return state;
  }
}

export default myHuntsReducer;
