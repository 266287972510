import React from "react";
import { Header } from "semantic-ui-react";
import HuntStatusSteps from "./HuntStatusSteps";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loadHunt } from "redux/actionCreators";
import { selectCurrentHunt } from "redux/selectors";

class ManageStatus extends React.PureComponent {
  render() {
    const { hunt } = this.props;
    return (
      <div>
        <div />
        <Header as="h2">Hunt Status</Header>
        {hunt ? <HuntStatusSteps hunt={hunt} /> : ""}
      </div>
    );
  }
}

ManageStatus.propTypes = {
  hunt: PropTypes.object,
  loadHunt: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  hunt: selectCurrentHunt
});

const mapDispatchToProps = dispatch => ({
  loadHunt: huntId => dispatch(loadHunt(huntId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageStatus)
);
