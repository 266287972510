import log from "loglevel"; // eslint-disable-line no-unused-vars
import firebaseUtils from "./firebaseUtils";
import {
  FIREBASE_COLLECTION_HUNTS,
  FIREBASE_COLLECTION_INTERNAL,
  FIREBASE_DOCID_CAPABILITIES,
} from "./constants";

const loadHuntCapabilities = (huntId) =>
  firebaseUtils
    .db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId)
    .collection(FIREBASE_COLLECTION_INTERNAL)
    .doc(FIREBASE_DOCID_CAPABILITIES)
    .get()
    .then((capabilitySnapshot) => capabilitySnapshot.data());

const api = {
  loadHuntCapabilities,
};

export default api;
