import { createAction } from "redux-actions";
import { normalize } from "normalizr";
import { FeedItemSchema } from "models/FeedItem";

import {
  LOADING_FEED,
  LOADING_FEED_FAILED,
  LOADING_FEED_SUCCEEDED,
  RESET_FEED
} from "./constants";

const loadingFeedAction = createAction(LOADING_FEED);
const loadingFeedSucceeded = createAction(
  LOADING_FEED_SUCCEEDED,
  ({ huntId, feedItems, hasMore }) => {
    const normalizedFeedItems = normalize(feedItems, [FeedItemSchema]);
    return {
      huntId,
      hasMore,
      ...normalizedFeedItems
    };
  }
);
const loadingFeedFailed = createAction(LOADING_FEED_FAILED);

const resetFeed = createAction(RESET_FEED);

export {
  loadingFeedAction,
  loadingFeedFailed,
  loadingFeedSucceeded,
  resetFeed
};
