import { createSelector } from "reselect";
import { inflateObject } from "utils/selectors";

import log from "loglevel"; // eslint-disable-line no-unused-vars

const selectJoinHuntDomain = (state) => state.joinHunt;
const selectData = (state) => state.data;
const selectHuntId = (state, props) => props.match.params.huntId;

export const selectLoading = createSelector(
  selectJoinHuntDomain,
  (state) => state.get("loadingHuntCapabilities") || state.get("loadingHunt")
);
export const selectRegisterLoading = createSelector(
  selectJoinHuntDomain,
  (state) => state.get("registerLoading")
);

export const selectTeamNameLoading = createSelector(
  selectJoinHuntDomain,
  (state) => state.get("teamNameSaving")
);

export const selectError = createSelector(selectJoinHuntDomain, (state) =>
  state.get("error")
);

export const selectMyRegisteredTeam = createSelector(
  selectJoinHuntDomain,
  selectData,
  (domain, data) => {
    const teamId = domain.get("myRegisteredTeam");
    if (teamId) {
      const inflatedTeam = inflateObject(data, teamId, "teams");
      return inflatedTeam;
    }
    return undefined;
  }
);

export const selectRegisteredTeamCount = createSelector(
  selectData,
  selectHuntId,
  (data, huntId) => {
    const teams = data.get("teams");

    if (teams) {
      const huntTeams = teams
        .toArray()
        .filter((team) => team.get("hunt") === huntId);
      return huntTeams.length;
    } else {
      return 0;
    }
  }
);
