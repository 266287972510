// @flow
// import Parse from "parse";
import firebase from "firebase/app";
import "firebase/auth";
import React from "react";
import PropTypes from "prop-types";
import getParamsFromObject from "utils/objectToParam";

import "./facebook.css";

import log from "loglevel";

const getIsMobile = () => {
  let isMobile = false;

  try {
    isMobile = !!(
      (window.navigator && window.navigator.standalone) ||
      navigator.userAgent.match("CriOS") ||
      navigator.userAgent.match(/mobile/i)
    );
  } catch (ex) {
    // continue regardless of error
  }

  return isMobile;
};

// https://www.w3.org/TR/html5/disabled-elements.html#disabled-elements
const _shouldAddDisabledProp = tag =>
  [
    "button",
    "input",
    "select",
    "textarea",
    "optgroup",
    "option",
    "fieldset"
  ].indexOf((tag + "").toLowerCase()) >= 0;

class FacebookLoginButton2 extends React.Component {
  static propTypes = {
    isDisabled: PropTypes.bool,
    callback: PropTypes.func.isRequired,
    appId: PropTypes.string.isRequired,
    xfbml: PropTypes.bool,
    cookie: PropTypes.bool,
    reAuthenticate: PropTypes.bool,
    scope: PropTypes.string,
    returnScopes: PropTypes.bool,
    redirectUri: PropTypes.string,
    textButton: PropTypes.string,
    typeButton: PropTypes.string,
    autoLoad: PropTypes.bool,
    disableMobileRedirect: PropTypes.bool,
    isMobile: PropTypes.bool,
    size: PropTypes.string,
    fields: PropTypes.string,
    cssClass: PropTypes.string,
    version: PropTypes.string,
    icon: PropTypes.any,
    language: PropTypes.string,
    onClick: PropTypes.func,
    containerStyle: PropTypes.object,
    buttonStyle: PropTypes.object,
    children: PropTypes.node,
    tag: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    onFailure: PropTypes.func
  };

  static defaultProps = {
    textButton: "Login with Facebook",
    typeButton: "button",
    redirectUri: typeof window !== "undefined" ? window.location.href : "/",
    scope: "public_profile,email,user_friends",
    returnScopes: false,
    xfbml: false,
    cookie: false,
    reAuthenticate: false,
    size: "metro",
    fields: "name, first_name, last_name, gender, age_range, email, picture",
    cssClass: "kep-login-facebook",
    version: "2.5",
    language: "en_US",
    disableMobileRedirect: false,
    isMobile: getIsMobile(),
    tag: "button",
    onFailure: null
  };

  state = {
    isSdkLoaded: false,
    isProcessing: false,
    loadingButton: true
  };

  componentDidMount() {
    this._isMounted = true;
    if (document.getElementById("facebook-jssdk")) {
      this.sdkLoaded();
      return;
    }
    this.setFbAsyncInit();
    this.loadSdkAsynchronously();
    let fbRoot = document.getElementById("fb-root");
    if (!fbRoot) {
      fbRoot = document.createElement("div");
      fbRoot.id = "fb-root";
      document.body.appendChild(fbRoot);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.isSdkLoaded && nextProps.autoLoad && !this.props.autoLoad) {
      window.FB.getLoginStatus(this.checkLoginAfterRefresh);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setStateIfMounted(state) {
    if (this._isMounted) {
      this.setState(state);
    }
  }

  setFbAsyncInit() {
    const { autoLoad } = this.props;
    window.fbAsyncInit = () => {
      // Parse.FacebookUtils.init({
      //   version: `v${version}`,
      //   appId,
      //   xfbml,
      //   cookie,
      // });
      this.provider = new firebase.auth.FacebookAuthProvider();

      this.setStateIfMounted({ isSdkLoaded: true });
      if (autoLoad || window.location.search.includes("facebookdirect")) {
        window.FB.getLoginStatus(this.checkLoginAfterRefresh);
      }
    };
  }

  sdkLoaded() {
    this.setState({ isSdkLoaded: true });
  }

  loadSdkAsynchronously() {
    const { language, appId } = this.props;
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = `//connect.facebook.net/${language}/sdk.js#xfbml=1&version=v2.8&appId=${appId}`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  responseParse = user => {
    this.checkLoginState(user);
    this.getUserData(user);
  };

  getUserData = user => {
    window.FB.api(
      "/me",
      { locale: this.props.language, fields: this.props.fields },
      me => {
        log.debug("get user data", me);
        this.props.callback(user, me);
      }
    );
  };

  checkLoginState = () => {
    this.setStateIfMounted({ isProcessing: false });
  };

  checkLoginAfterRefresh = response => {
    if (response.status === "connected") {
      this.checkLoginState(response);
    } else {
      // Parse.FacebookUtils.logIn(null, loginResponse =>
      //   this.checkLoginState(loginResponse)
      // );
      firebase
        .auth()
        .signInWithPopup(this.provider)
        .then(function(result) {
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const token = result.credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // ...
          log.debug(token, user);
        })
        .catch(function(error) {
          // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // // The email of the user's account used.
          // const email = error.email;
          // // The firebase.auth.AuthCredential type that was used.
          // const credential = error.credential;
          // ...
          log.error(error);
        });
    }
  };

  click = e => {
    if (
      !this.state.isSdkLoaded ||
      this.state.isProcessing ||
      this.props.isDisabled
    ) {
      return;
    }
    this.setState({ isProcessing: true });
    const {
      scope,
      appId,
      onClick,
      reAuthenticate,
      returnScopes,
      redirectUri,
      disableMobileRedirect
    } = this.props;

    if (typeof onClick === "function") {
      onClick(e);
      if (e.defaultPrevented) {
        return;
      }
    }

    const params = {
      client_id: appId,
      redirect_uri: redirectUri,
      state: "facebookdirect",
      return_scopes: returnScopes,
      scope
    };

    if (reAuthenticate) {
      params.auth_type = "reauthenticate";
    }

    if (this.props.isMobile && !disableMobileRedirect) {
      window.location.href = `//www.facebook.com/dialog/oauth${getParamsFromObject(
        params
      )}`;
    } else {
      // Parse.FacebookUtils.logIn(scope, {
      //   success: this.responseParse,
      //   error: function(user, error) {
      //     log.error(
      //       error,
      //       "User cancelled the Facebook login or did not fully authorize."
      //     );
      //   },
      // });
      firebase
        .auth()
        .signInWithPopup(this.provider)
        .then(function(result) {
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const token = result.credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // ...
          log.debug(token, user);
        })
        .catch(function(error) {
          // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // // The email of the user's account used.
          // const email = error.email;
          // // The firebase.auth.AuthCredential type that was used.
          // const credential = error.credential;
          // ...
          log.error(error);
        });
    }
  };

  // [AdGo] 20.11.2016 - coult not get container class to work
  containerStyle() {
    const style = { transition: "opacity 0.2s" };
    if (
      this.state.isProcessing ||
      !this.state.isSdkLoaded ||
      this.props.isDisabled
    ) {
      style.opacity = 0.6;
    }
    return Object.assign(style, this.props.containerStyle);
  }

  renderOwnButton() {
    const {
      cssClass,
      size,
      icon,
      textButton,
      typeButton,
      buttonStyle
    } = this.props;
    const isIconString = typeof icon === "string";
    const optionalProps = {};
    if (this.props.isDisabled && _shouldAddDisabledProp(this.props.tag)) {
      optionalProps.disabled = true;
    }
    return (
      <span style={this.containerStyle()}>
        {isIconString && (
          <link
            rel="stylesheet"
            href="//maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
          />
        )}
        <this.props.tag
          type={typeButton}
          className={`${cssClass} ${size} loginBtn loginBtn--facebook`}
          style={buttonStyle}
          onClick={this.click}
          {...optionalProps}
        >
          {icon && isIconString && <i className={`fa ${icon}`} />}
          {icon && !isIconString && icon}
          {textButton}
        </this.props.tag>
      </span>
    );
  }

  render() {
    const { children } = this.props;
    return children ? (
      <span onClick={this.click}>{children}</span>
    ) : (
      this.renderOwnButton()
    );
  }
}

export default FacebookLoginButton2;
