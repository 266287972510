import { createSelector } from "reselect";
import { selectCurrentHuntId } from "redux/selectors";

const selectDetailsState = state => {
  return state.manageHunt.editHuntInfo;
};

const selectHuntDetailsState = createSelector(
  selectCurrentHuntId,
  selectDetailsState,
  (huntId, state) => state.get(huntId)
);

const selectImageLoading = createSelector([selectHuntDetailsState], state =>
  state.get("imageReloading")
);

const selectImageLoadingProgress = createSelector(
  [selectHuntDetailsState],
  state => state.get("imageUpdateProgress")
);

export { selectImageLoading, selectImageLoadingProgress };
