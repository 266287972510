export const FIREBASE_FUNCTION_CREATE_HUNT_WITH_SKU = "createHuntWithSKU";
export const FIREBASE_FUNCTION_CREATE_HUNT = "createHunt";
export const FIREBASE_FUNCTION_DELETE_HUNT = "deleteHunt";
export const FIREBASE_FUNCTION_CREATE_CHECKOUT_SESSION =
  "createCheckoutSession";
export const FIREBASE_FUNCTION_CREATE_CHECKOUT_SESSION_WITH_SKU =
  "createCheckoutSessionWithSKU";
export const FIREBASE_FUNCTION_CREATE_ARCHIVE =
  "archive-makeHuntImageAndVideoZip";

export const FIREBASE_COLLECTION_HUNTS = "hunts";
export const FIREBASE_COLLECTION_TEAMS = "teams";
export const FIREBASE_COLLECTION_CHALLENGES = "challenges";
export const FIREBASE_COLLECTION_ATTEMPTS = "challengeAttempts";
export const FIREBASE_COLLECTION_USERS = "users";
export const FIREBASE_COLLECTION_SKUS = "skus";
export const FIREBASE_COLLECTION_INTERNAL = "internal";
export const FIREBASE_COLLECTION_SHARED = "shared";
export const FIREBASE_COLLECTION_FEED = "feed";

export const FIREBASE_DOCID_CAPABILITIES = "capabilities";
export const FIREBASE_DOCID_SETTINGS = "settings";
export const FIREBASE_DOCID_LEADERBOARD = "leaderboard";
export const FIREBASE_DOCID_MANAGERINFO = "managerInfo";

export const FIREBASE_STORAGE_LOGOFOLDER = "default-hunt-logos";
