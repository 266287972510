export const ANALYTICS_IDENTIFY = "identify";
export const ANALYTICS_TRACK = "track";
export const ANALYTICS_PAGE = "page";

export const LOCATION_CHANGE = "scavr/analytics/LOCATION_CHANGE";

export const IDENTIFY_USER = "scavr/analytics/IDENTIFY_USER";

export const USER_FIELDS_TO_IGNORE = [
  "apiKey",
  "appName",
  "isEmpty",
  "isLoaded",
  "redirectEventId",
  "stsTokenManager"
];

export const OBJECT_FIELDS_TO_IGNORE = [
  "challenge.docRef",
  "oldChallenge.docRef",
  "team.docRef",
  "hunt.docRef",
  "hunt.managers"
];
