import { createAction } from "redux-actions";
import {
  createOpenListenerAction,
  createCloseListenerAction
} from "redux/actions";

import {
  STARTED_WATCHING_LEADERBOARD,
  LEADERBOARD_UPDATED,
  STOPPED_WATCHING_LEADERBOARD,
  LOADING_LEADERBOARD_DATA,
  WATCH_LEADERBOARD,
  STOP_WATCHING_LEADERBOARD
} from "redux/constants";

function startLoadingLeaderboardData() {
  return {
    type: LOADING_LEADERBOARD_DATA
  };
}

const watchLeaderboardAction = createOpenListenerAction(
  WATCH_LEADERBOARD,
  "watch-leaderboard"
);

const stopWatchingLeaderboardAction = createCloseListenerAction(
  STOP_WATCHING_LEADERBOARD,
  "watch-leaderboard"
);

const watchLeaderboardStarted = createAction(STARTED_WATCHING_LEADERBOARD);
const watchLeaderboardStopped = createAction(STOPPED_WATCHING_LEADERBOARD);

const leaderboardUpdated = createAction(
  LEADERBOARD_UPDATED,
  (huntId, leaderboardData) => ({
    huntId,
    leaderboardData
  })
);

export {
  startLoadingLeaderboardData,
  watchLeaderboardAction,
  stopWatchingLeaderboardAction,
  watchLeaderboardStarted,
  watchLeaderboardStopped,
  leaderboardUpdated
};
