import { ANALYTICS_TRACK } from "analytics/constants";
import {
  UPDATE_HUNT_STATUS,
  UPDATE_HUNT_STATUS_FAILED,
  UPDATE_HUNT_STATUS_SUCCESS,
} from "./constants";
import { normalize } from "normalizr";
import { HuntSchema } from "models/Hunt";

export function updateHuntStatus(hunt, newStatus) {
  return {
    type: UPDATE_HUNT_STATUS,
    payload: {
      hunt,
      newStatus,
    },
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "ManageHunt.UpdateHuntStatus",
        payload: {
          huntId: hunt.objectId,
          newStatus,
        },
      },
    },
  };
}

export function updateHuntStatusFailed(hunt, error) {
  return {
    type: UPDATE_HUNT_STATUS_FAILED,
    error,
    payload: {
      hunt,
    },
  };
}

export function updateHuntStatusSuccess(updatedHunt) {
  const normalized = normalize(updatedHunt, HuntSchema);
  return {
    type: UPDATE_HUNT_STATUS_SUCCESS,
    payload: normalized,
  };
}
