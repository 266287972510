import React from "react";
import { Table, Placeholder } from "semantic-ui-react";

class PlaceholderTable extends React.PureComponent {
  render() {
    return (
      <Table.Body>
        <Table.Row>
          <Table.Cell />
          <Table.Cell>
            <Placeholder>
              <Placeholder.Line length="medium" />
            </Placeholder>
          </Table.Cell>
          <Table.Cell>
            <Placeholder>
              <Placeholder.Line length="very short" />
            </Placeholder>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell />
          <Table.Cell>
            <Placeholder>
              <Placeholder.Line length="very long" />
            </Placeholder>
          </Table.Cell>
          <Table.Cell>
            <Placeholder>
              <Placeholder.Line length="very short" />
            </Placeholder>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell />
          <Table.Cell>
            <Placeholder>
              <Placeholder.Line length="long" />
            </Placeholder>
          </Table.Cell>
          <Table.Cell>
            <Placeholder>
              <Placeholder.Line length="very short" />
            </Placeholder>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  }
}

export default PlaceholderTable;
