import log from "loglevel"; // eslint-disable-line no-unused-vars
import { createSelector } from "reselect";
import {
  selectHuntChallengeAttempts,
  selectHuntChallenges,
  selectHuntTeams
} from "containers/ManageHunt/selectors";
import { selectCurrentHuntId } from "redux/selectors";

const selectLeaderboardState = state => {
  return state.leaderboard;
};

const selectHuntLeaderboardState = createSelector(
  selectCurrentHuntId,
  selectLeaderboardState,
  (huntId, leaderboardState) => {
    return leaderboardState.get(huntId);
  }
);

const selectLeaderboard = createSelector(
  selectHuntLeaderboardState,
  huntLeaderboardState => {
    let leaderboardData = huntLeaderboardState.get("leaderboardData");

    if (!leaderboardData) {
      return [];
    }

    return leaderboardData
      .sort((a, b) => {
        if (a.get("points") === b.get("points")) {
          if (a.get("lastSubmission") && b.get("lastSubmission")) {
            return a.get("lastSubmission") - b.get("lastSubmission");
          }
        } else {
          return b.get("points") - a.get("points");
        }
        return 0;
      })
      .toJS();

    // log.debug("Sorted data: ", sortedLeaderboardData);

    // return sortedLeaderboardData;
  }
);

export const selectTeamScores = createSelector(
  [selectHuntTeams, selectHuntChallenges, selectHuntChallengeAttempts],
  (teams, challenges, challengeAttempts) => {
    let teamDict = teams.map(team => ({
      name: team.get("name"),
      points: 0,
      attempts: 0
    }));
    // teamDict is now an immutable dict where the teamId is the key, and the new "name"/"points" are the value object

    challengeAttempts
      .filter(attempt => attempt.get("status") === "completed")
      .forEach(attempt => {
        const teamId = attempt.get("team");
        const challengeId = attempt.get("challenge");
        const challenge = challenges.get(challengeId);
        if (challenge) {
          const points = challenge.get("points");
          teamDict = teamDict.update(teamId, teamObj => {
            if (teamObj === undefined) {
              log.debug("teamObj is null for ", teamId);
            } else {
              return {
                name: teamObj.name,
                points: teamObj.points + points,
                attempts: teamObj.attempts + 1
              };
            }
          });
        }
      });

    return teamDict
      .toArray()
      .sort((a, b) => a.points - b.points)
      .reverse();
  }
);

const selectLeaderboardLoading = createSelector(
  [selectHuntLeaderboardState],
  state => state.get("loading")
);

export { selectLeaderboard, selectLeaderboardLoading };
