export const CREATE_EDIT_CHALLENGE_FORM_NAME = "createEditChallenge";

export const WATCHING_CHALLENGES =
  "scavr/ManageChallenges/WATCH_HUNT_CHALLENGES";

export const LOAD_HUNT_CHALLENGES =
  "scavr/ManageChallenges/LOAD_HUNT_CHALLENGES";
export const LOAD_HUNT_CHALLENGES_FAILURE =
  "scavr/ManageChallenges/LOAD_HUNT_CHALLENGES_FAILURE";
export const LOAD_HUNT_CHALLENGES_SUCCESS =
  "scavr/ManageChallenges/LOAD_HUNT_CHALLENGES_SUCCESS";

export const CREATE_CHALLENGE = "scavr/ManageChallenges/CREATE_CHALLENGE";
export const CREATE_CHALLENGE_SUCCESS =
  "scavr/ManageChallenges/CREATE_CHALLENGE_SUCCESS";
export const CREATE_CHALLENGE_FAILURE =
  "scavr/ManageChallenges/CREATE_CHALLENGE_FAILURE";

export const REORDER_CHALLENGE = "scavr/ManageChallenges/REORDER_CHALLENGE";
export const REORDER_CHALLENGE_SUCCESS =
  "scavr/ManageChallenges/REORDER_CHALLENGE_SUCCESS";
export const REORDER_CHALLENGE_FAILURE =
  "scavr/ManageChallenges/REORDER_CHALLENGE_FAILURE";

export const SAVE_CHALLENGE = "scavr/ManageChallenges/SAVE_CHALLENGE";
export const SAVE_CHALLENGE_SUCCESS =
  "scavr/ManageChallenges/SAVE_CHALLENGE_SUCCESS";
export const SAVE_CHALLENGE_FAILURE =
  "scavr/ManageChallenges/SAVE_CHALLENGE_FAILURE";

export const DELETE_CHALLENGE = "scavr/ManageChallenges/DELETE_CHALLENGE";
export const DELETE_CHALLENGE_SUCCESS =
  "scavr/ManageChallenges/DELETE_CHALLENGE_SUCCESS";
export const DELETE_CHALLENGE_FAILURE =
  "scavr/ManageChallenges/DELETE_CHALLENGE_FAILURE";

export const SAVE_CHALLENGES = "scavr/ManageChallenges/SAVE_CHALLENGES";
export const SAVE_CHALLENGES_SUCCESS =
  "scavr/ManageChallenges/SAVE_CHALLENGES_SUCCESS";
export const SAVE_CHALLENGES_FAILURE =
  "scavr/ManageChallenges/SAVE_CHALLENGES_FAIULRE";

export const CHALLENGE_TYPES = [
  {
    key: "photo",
    text: "Photo",
    value: "photo",
    // premiumOnly: false,
    icon: "photo",
    description: "Submit a picture of a particular place or thing.",
  },
  {
    key: "password",
    text: "Password",
    value: "password",
    // premiumOnly: false,
    icon: "lightbulb",
    description: "Guess a password. Answers are not case-sensitive.",
  },
  {
    key: "video",
    text: "Video",
    value: "video",
    // premiumOnly: true,
    icon: "video camera",
    description:
      "Submit a video. Videos are limited to 30 seconds. Only in Enhanced and Professional hunts.",
  },
  {
    key: "qr",
    text: "QR Code",
    value: "qr",
    // premiumOnly: true,
    icon: "qrcode",
    description:
      "Print or digitally share a unique QR code. " +
      "Scan the QR code to complete the challenge. Only in Enhanced and Professional hunts.",
  },
  {
    key: "geo",
    text: "Geo-Location",
    value: "geopoint",
    // premiumOnly: true,
    icon: "marker",
    description:
      "Get within a certain radius of a point in the real world. Only in Enhanced and Professional hunts.",
  },
];
