import { fromJS } from "immutable";
import { ANALYTICS_TRACK } from "analytics/constants";
import {
  APPROVE_ATTEMPT,
  REJECT_ATTEMPT,
  APPROVE_ATTEMPT_FAILED,
  REJECT_ATTEMPT_FAILED,
  FILTER_CHANGED,
  LOADING_JUDGING_DATA,
  APPROVE_ATTEMPT_SUCCEEDED,
  REJECT_ATTEMPT_SUCCEEDED,
} from "./constants";

export function startLoadingJudgingData() {
  return {
    type: LOADING_JUDGING_DATA,
  };
}

export function approveAttempt(attempt) {
  return {
    type: APPROVE_ATTEMPT,
    payload: {
      attempt: fromJS(attempt),
    },
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "Judging.ApproveAttempt",
        payload: {
          challengeAttemptId: attempt.objectId,
          challengeType: attempt.itemType,
          huntId: attempt.hunt,
          challengeId: attempt.challenge.objectId,
          team: attempt.team.objectId,
        },
      },
    },
  };
}

export function approveAttemptSucceeded(attemptId) {
  return {
    type: APPROVE_ATTEMPT_SUCCEEDED,
    payload: {
      attemptId,
    },
  };
}

export function approveAttemptFailed(error, attemptId) {
  return {
    type: APPROVE_ATTEMPT_FAILED,
    error,
    payload: {
      attemptId,
    },
  };
}

export function rejectAttempt(attempt, message) {
  return {
    type: REJECT_ATTEMPT,
    payload: {
      attempt: fromJS(attempt),
      message,
    },
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "Judging.RejectAttempt",
        payload: {
          challengeAttemptId: attempt.objectId,
          huntId: attempt.hunt,
          challengeType: attempt.itemType,
          challengeId: attempt.challenge.objectId,
          team: attempt.team.objectId,
          message,
        },
      },
    },
  };
}

export function rejectAttemptSucceeded(attemptId) {
  return {
    type: REJECT_ATTEMPT_SUCCEEDED,
    payload: {
      attemptId,
    },
  };
}

export function rejectAttemptFailed(error, attemptId) {
  return {
    type: REJECT_ATTEMPT_FAILED,
    error,
    payload: {
      attemptId,
    },
  };
}

export function changeJudgingFilter(filterType, newValue) {
  let val = newValue === "" ? null : newValue;
  return {
    type: FILTER_CHANGED,
    payload: {
      filterType,
      value: val,
    },
    meta: {
      analytics: {
        method: ANALYTICS_TRACK,
        type: "Judging.FilterChanged",
        payload: {
          filter: filterType,
          value: val,
        },
      },
    },
  };
}
