import _ from "lodash";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import React from "react";
import PropTypes from "prop-types";
import { Image, Menu, Accordion, Icon, Button } from "semantic-ui-react";
import logo from "images/scavr-text-logo.png";
import { Link } from "react-router-dom";

class GlobalNavMobile extends React.PureComponent {
  state = { expanded: false };

  handleClick = () => {
    const { expanded } = this.state;

    this.setState({ expanded: !expanded });
  };

  render() {
    const { expanded } = this.state;
    const { leftItems, onSignOut, location } = this.props;
    return (
      <Accordion as={Menu} vertical fluid>
        <Accordion.Title active={expanded} onClick={this.handleClick}>
          <Menu.Item>
            <Icon name="sidebar" size="big" />
            <Image
              size="tiny"
              src={logo}
              verticalAlign="middle"
              spaced="left"
            />
          </Menu.Item>
        </Accordion.Title>
        <Accordion.Content active={expanded}>
          {_.map(leftItems, item => (
            <Menu.Item
              as={Link}
              active={location.pathname === item.to}
              {...item}
            />
          ))}
          <Button onClick={onSignOut} fluid basic primary>
            Log Out
          </Button>
        </Accordion.Content>
      </Accordion>
    );
  }
}

GlobalNavMobile.propTypes = {
  location: PropTypes.object.isRequired,
  leftItems: PropTypes.array.isRequired,
  rightItems: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  onPusherClick: PropTypes.func,
  onSignOut: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
};

export default GlobalNavMobile;
