import { fromJS } from "immutable";
import {
  DELETE_HUNT,
  DELETE_HUNT_SUCCESS,
  DELETE_HUNT_FAILURE,
  COPY_HUNT,
  COPY_HUNT_SUCCESS,
  COPY_HUNT_FAILURE
} from "containers/ManageHunt/constants";

import {
  LOAD_HUNT_SETTINGS,
  LOAD_HUNT_SETTINGS_SUCCESS,
  LOAD_HUNT_SETTINGS_FAILURE
} from "redux/constants";

const initialState = fromJS({
  isDeleting: false,
  isCopying: false,
  isLoadingSettings: false
});

function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_HUNT:
      return state.set("isDeleting", true);
    case DELETE_HUNT_SUCCESS:
      return state.set("isDeleting", false);
    case DELETE_HUNT_FAILURE:
      return state.set("isDeleting", false);
    case COPY_HUNT:
      return state.set("isCopying", true);
    case COPY_HUNT_SUCCESS:
      return state.set("isCopying", false);
    case COPY_HUNT_FAILURE:
      return state.set("isCopying", false);
    case LOAD_HUNT_SETTINGS:
      return state.set("isLoadingSettings", true);
    case LOAD_HUNT_SETTINGS_SUCCESS:
      return state.set("isLoadingSettings", false);
    case LOAD_HUNT_SETTINGS_FAILURE:
      return state.set("isLoadingSettings", false);
    default:
      break;
  }
  return state;
}

export default settingsReducer;
