import log from "loglevel";
import DataProvider from "DataProvider";
import {
  createTeamAndRegisterFailed,
  createTeamAndRegisterSucceeded
} from "./actions";
import { push as pushRoute } from "connected-react-router";
const dataProvider = DataProvider.instance();

const createTeamAndJoinHunt = (
  hunt,
  teamName,
  teamPassword
) => async dispatch => {
  const currentUser = dataProvider.currentUser();
  log.debug(
    "Creating team with data: ",
    currentUser,
    hunt,
    teamName,
    teamPassword
  );

  // dispatch a "creating/registering" action

  try {
    let result = await dataProvider.createTeamAndJoinHunt(
      hunt.objectId,
      teamName,
      teamPassword,
      currentUser
    );

    // dispatch a created successfully
    dispatch(createTeamAndRegisterSucceeded(result, hunt));
    // push to "confirmation" page
    dispatch(pushRoute(`/join/${hunt.objectId}/confirmed`));
  } catch (err) {
    log.error("Couldn't join hunt/create team: ", err);
    dispatch(createTeamAndRegisterFailed(err));
  }
  // catch - dispatch a failure event
};

export { createTeamAndJoinHunt };
