export const HUNT_TYPE_RACE = "race";
export const HUNT_TYPE_SCAVENGER_HUNT = "classic";
export const HUNT_TYPE_TREASURE_HUNT = "treasure";

export const HUNT_LEVEL_BASIC = "basic";
export const HUNT_LEVEL_ENHANCED = "enhanced";
export const HUNT_LEVEL_PRO = "professional";

export const CREATE_HUNT_STARTED = "scavr/CreateHunt/CREATE_HUNT_STARTED";
export const CREATE_HUNT_SKU_SELECTED = "scavr/CraeteHunt/HUNT_SKU_SELECTED";
export const CREATE_HUNT_PRICING_CHOSEN =
  "scavr/CreateHunt/HUNT_LEVEL_SELECTED";
export const CREATE_HUNT_CHANGE_LEVEL =
  "scavr/CreateHunt/CREATE_HUNT_CHANGE_LEVEL";
export const CREATE_HUNT_TYPE_SELECTED =
  "scavr/CreateHunt/CREATE_HUNT_TYPE_SELECTED";
export const CREATE_HUNT_INFO_SUBMITTED =
  "scavr/CreateHunt/CREATE_HUNT_INFO_SUBMITTED";
export const CREATE_HUNT_COMPLETED = "scavr/CreateHunt/CREATE_HUNT_COMPLETED";
export const SAVE_HUNT_SUCCEEDED = "scavr/CreateHunt/SAVE_HUNT_SUCCEEDED";
export const SAVE_HUNT_FAILED = "scavr/CreateHunt/SAVE_HUNT_FAILED";
