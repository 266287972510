import React from "react";
import PropTypes from "prop-types";
import { Map, GoogleApiWrapper, Circle } from "google-maps-react";

import log from "loglevel"; // eslint-disable-line no-unused-vars

class GoogleGeoPointChallengeMap extends React.PureComponent {
  handleRadiusChangedEvent = (mapProps, radiusEvent) => {
    this.props.onRadiusChanged(radiusEvent.radius);
  };

  handleCenterChangedEvent = (mapProps, circleEvent) => {
    this.props.onCenterChanged(
      circleEvent.center.lat(),
      circleEvent.center.lng()
    );
  };

  render() {
    const {
      google,
      style,
      center,
      challengeLocation,
      challengeRadius,
      editable,
      ...rest
    } = this.props;

    const circle = (
      <Circle
        radius={challengeRadius}
        center={challengeLocation}
        editable={editable}
        onRadius_changed={this.handleRadiusChangedEvent}
        onCenter_changed={this.handleCenterChangedEvent}
        strokeColor="#00FF00"
        strokeOpacity={0.8}
        strokeWeight={2}
        fillColor="#00FF00"
        fillOpacity={0.35}
      />
    );

    return (
      <div style={{ height: "340px" }}>
        <Map
          google={google}
          style={style}
          {...rest}
          initialCenter={center}
          center={center}
          // onDblclick={this.handleDblClick}
          gestureHandling={"cooperative"}
          zoom={16}
        >
          {/*<GeoPointCircle />*/}
          {circle}
        </Map>
      </div>
    );
  }
}

GoogleGeoPointChallengeMap.propTypes = {
  center: PropTypes.object,
  challengeLocation: PropTypes.object,
  challengeRadius: PropTypes.number,
  google: PropTypes.object,
  style: PropTypes.object,
  markers: PropTypes.array,
  onCenterChanged: PropTypes.func,
  onRadiusChanged: PropTypes.func,
  editable: PropTypes.bool
};

GoogleGeoPointChallengeMap.defaultProps = {
  zoom: 14,
  style: {
    width: "100%",
    height: "340px",
    marginBottom: "10px",
    position: "relative"
  },
  markers: [],
  challengeRadius: 1000
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY // eslint-disable-line no-undef
})(GoogleGeoPointChallengeMap);
