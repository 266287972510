import log from "loglevel"; // eslint-disable-line no-unused-vars
import firebaseUtils from "./firebaseUtils";
import { loadModelForDocumentSnapshot } from "./firebaseModels";

const watchLeaderboard = (huntId, onLeaderboardUpdate) => {
  return firebaseUtils
    .db()
    .collection("hunts")
    .doc(huntId)
    .collection("shared")
    .doc("leaderboard")
    .onSnapshot(async leaderboardSnapshot => {
      let leaderboard = await loadModelForDocumentSnapshot(leaderboardSnapshot);
      onLeaderboardUpdate(leaderboard);
    });
};

const api = {
  watchLeaderboard
};

export default api;
