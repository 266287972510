import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import { Header, Divider, Loader, Dimmer } from "semantic-ui-react";
import { createStructuredSelector } from "reselect";
import { Route, Switch, Redirect } from "react-router-dom";
import ChooseHuntType from "./steps/ChooseHuntType";
import BasicInfo from "./steps/CreateBasicHuntInfo";
import { loadHuntSKUs } from "./actionCreators";

import {
  huntTypeSelected,
  startCreateHunt,
  huntLevelSelected,
  changeHuntLevel,
  huntSKUSelected
} from "./actions";

import { createHuntAction } from "./actionCreators";

import {
  selectHuntType,
  selectStep,
  selectHuntName,
  // selectHuntLevel,
  selectHuntSKU,
  selectHuntSKUId,
  selectHuntSKUShortCode,
  selectHuntDescription,
  selectHuntStartDateTime,
  selectHuntStartDate,
  selectHuntStartTime,
  selectHuntId,
  selectHuntLocationObject,
  selectHuntImageURL,
  selectHuntImagePath,
  selectCreateHuntDomain
} from "./selectors";
import ChooseHuntSKU from "./steps/ChooseHuntSKU";

class CreateHunt extends React.PureComponent {
  UNSAFE_componentWillMount() {
    this.props.loadHuntSKUs();
    this.props.createHuntStarted();
    this.props.history.replace("/create");
  }

  UNSAFE_componentWillReceiveProps() {
    // if (nextProps.step === "complete" && nextProps.huntId) {
    //   nextProps.history.push("/manage/" + nextProps.huntId);
    // }
  }

  basicInfoSubmitted = () => {
    const {
      huntType,
      // huntLevel,
      huntSKUId,
      huntSKU,
      huntName,
      huntDescription,
      huntImageURL,
      huntImagePath,
      huntStartDate,
      huntStartTime,
      huntLocation
    } = this.props;

    let startTime = null;
    if (huntStartDate) {
      let timeStr = huntStartDate;
      if (huntStartTime) {
        timeStr = timeStr + " " + huntStartTime;
      } else {
        timeStr = timeStr + " 12:00 pm";
      }
      startTime = moment(timeStr).toDate();
    }

    log.debug("Start date/time: ", huntStartDate, huntStartTime, startTime);
    // const startTime = huntStartDateTime ? huntStartDateTime.toDate() : null;

    const data = {
      type: huntType,
      priceLevel: null,
      skuId: huntSKUId,
      skuName: huntSKU.displayName,
      name: huntName,
      startTime,
      description: huntDescription,
      location: huntLocation,
      imageURL: huntImageURL,
      imagePath: huntImagePath
    };

    this.props.handleBasicInfoSubmitted(data);
  };

  handleUpgrade = huntTypeChosen => {
    const currentLevel = null;
    this.props.createHuntChangeLevel(currentLevel, huntTypeChosen);
    this.props.history.push(this.props.match.url + "/pricing");
  };

  handleTypeSelected = huntType => {
    this.props.handleHuntTypeSelected(huntType);
    this.props.history.push(this.props.match.url + "/info");
  };

  // handleLevelSelected = (huntLevel) => {
  //   this.props.handleHuntLevelSelected(huntLevel);
  //   this.props.history.push(this.props.match.url + "/type");
  // };

  handleSKUSelected = (skuId, skuName) => {
    this.props.handleHuntSKUSelected(skuId, skuName);
    this.props.history.push(this.props.match.url + "/type");
  };

  render() {
    // const PricingComponent = ({ ...rest }) => (
    //   <ChooseHuntLevel onSelect={this.handleLevelSelected} {...rest} />
    // );
    const SKUComponent = ({ ...rest }) => (
      <ChooseHuntSKU onSelect={this.handleSKUSelected} {...rest} />
    );
    const HuntTypeComponent = ({ ...rest }) => (
      <ChooseHuntType
        huntSKU={this.props.huntSKU}
        onSelect={this.handleTypeSelected}
        onChangeHuntLevel={this.handleUpgrade}
        huntSKUShortCode={this.props.huntSKUShortCode}
        {...rest}
      />
    );
    const BasicInfoComponent = ({ ...rest }) => (
      <BasicInfo handleNext={this.basicInfoSubmitted} {...rest} />
    );
    return (
      <div>
        <Helmet>
          <title>Scavr - Create a Scavenger Hunt</title>
        </Helmet>
        <Header as="h1">Create a Scavenger Hunt</Header>
        <Divider />
        {/*<Step.Group items={steps} fluid />*/}
        {this.props.createHunt.get("loading") ? (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        ) : null}
        <Switch>
          <Route
            path={this.props.match.path + "/pricing"}
            component={SKUComponent}
          />
          <Route
            path={this.props.match.path + "/type"}
            component={HuntTypeComponent}
          />
          <Route
            path={this.props.match.path + "/info"}
            render={BasicInfoComponent}
          />
          <Redirect
            from={this.props.match.path}
            to={this.props.match.path + "/pricing"}
          />
        </Switch>
      </div>
    );
  }
}

CreateHunt.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  createHunt: PropTypes.object,
  handleHuntTypeSelected: PropTypes.func.isRequired,
  // handleHuntLevelSelected: PropTypes.func.isRequired,
  handleBasicInfoSubmitted: PropTypes.func.isRequired,
  createHuntStarted: PropTypes.func.isRequired,
  createHuntChangeLevel: PropTypes.func.isRequired,
  loadHuntSKUs: PropTypes.func.isRequired,
  handleHuntSKUSelected: PropTypes.func.isRequired,

  step: PropTypes.string,
  huntType: PropTypes.string,
  // huntLevel: PropTypes.string,
  huntSKU: PropTypes.object,
  huntSKUId: PropTypes.string,
  huntSKUShortCode: PropTypes.string,
  huntName: PropTypes.string,
  huntLocation: PropTypes.object,
  huntStartDateTime: PropTypes.object,
  huntStartDate: PropTypes.string,
  huntStartTime: PropTypes.string,
  huntDescription: PropTypes.string,
  huntImageURL: PropTypes.string,
  huntImagePath: PropTypes.string,
  huntId: PropTypes.string
};

const mapStateToProps = createStructuredSelector({
  createHunt: selectCreateHuntDomain,
  step: selectStep,
  huntType: selectHuntType,
  // huntLevel: selectHuntLevel,
  huntSKU: selectHuntSKU,
  huntSKUId: selectHuntSKUId,
  huntSKUShortCode: selectHuntSKUShortCode,
  huntName: selectHuntName,
  huntLocation: selectHuntLocationObject,
  huntStartDateTime: selectHuntStartDateTime,
  huntStartDate: selectHuntStartDate,
  huntStartTime: selectHuntStartTime,
  huntDescription: selectHuntDescription,
  huntImageURL: selectHuntImageURL,
  huntImagePath: selectHuntImagePath,
  huntId: selectHuntId
});

const mapDispatchToProps = dispatch => ({
  loadHuntSKUs: () => dispatch(loadHuntSKUs()),
  handleHuntTypeSelected: huntType => dispatch(huntTypeSelected(huntType)),
  createHuntStarted: () => dispatch(startCreateHunt()),
  createHuntChangeLevel: (currentLevel, huntTypeChosen) =>
    dispatch(changeHuntLevel(currentLevel, huntTypeChosen)),
  handleBasicInfoSubmitted: huntData => dispatch(createHuntAction(huntData)),
  handleHuntLevelSelected: huntLevel => dispatch(huntLevelSelected(huntLevel)),
  handleHuntSKUSelected: (skuId, skuName) =>
    dispatch(huntSKUSelected(skuId, skuName))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateHunt)
);
