import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { Grid, Header, Segment, Message, Divider } from "semantic-ui-react";

import log from "loglevel";

// Absolute imports
import FacebookLoginButton from "components/FacebookLoginButton";
import { FACEBOOK_APP_ID } from "utils/constants";

// Local Imports
import selectLogin, { makeRedirectURL } from "./selectors";
import {
  emailLogin,
  fbLoginSuccess,
  fbSignupSuccess
} from "reducers/AuthReducer";
import EmailLoginForm from "components/EmailLoginForm";

export class Login extends React.PureComponent {
  fbLogin = (user, fbUserData) => {
    log.debug("FB Login callback", user, fbUserData);
    if (!user.existed()) {
      log.debug(
        "User signed up and logged in through Facebook!",
        user,
        fbUserData
      );
      this.props.onFacebookSignup(user, fbUserData);
    } else {
      log.debug("User logged in through Facebook!", user);
      this.props.onFacebookSignup(user, fbUserData);
    }
  };

  // eslint-disable-line react/prefer-stateless-function
  render() {
    log.info("Rendering login view.");
    return (
      <Grid verticalAlign="middle" centered style={{ height: "100vh" }}>
        <Helmet
          title="Login"
          meta={[{ name: "description", content: "Login to Scavr" }]}
        />
        <Grid.Column computer={6} tablet={8} mobile={14}>
          <Header as="h2" textAlign="center">
            <Header.Content>
              <h2>Log In</h2>
            </Header.Content>
          </Header>
          <Segment.Group>
            <Segment.Group horizontal>
              <Segment textAlign="center">
                <EmailLoginForm
                  onSubmit={this.props.onSubmit}
                  login={this.props.login}
                />
                <div>
                  <Divider hidden />
                  <span>- or -</span>
                  <Divider hidden />
                  <FacebookLoginButton
                    callback={this.fbLogin}
                    appId={FACEBOOK_APP_ID}
                  />
                </div>
              </Segment>
            </Segment.Group>
            <Segment>
              <Message.Header>Don&apos;t have an account?</Message.Header>
              <p>
                <Link
                  to={{
                    pathname: "/signup",
                    query: { redirect: this.props.redirectURL }
                  }}
                >
                  Create one
                </Link>{" "}
                now.
              </p>
            </Segment>
          </Segment.Group>
        </Grid.Column>
      </Grid>
    );
  }
}

Login.propTypes = {
  login: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  redirectURL: PropTypes.string,
  onFacebookLogin: PropTypes.func.isRequired,
  onFacebookSignup: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  login: selectLogin,
  redirectURL: makeRedirectURL()
});

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (email, password) => {
      dispatch(emailLogin(email, password));
    },
    onFacebookLogin: user => {
      dispatch(fbLoginSuccess(user));
    },
    onFacebookSignup: (user, fbUserData) => {
      dispatch(fbSignupSuccess(user, fbUserData));
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
