export const FEED_OPTION_ATTEMPTSTOSHOW = "feedAttemptsToShow";
export const FEED_OPTION_ENABLED = "feedEnabled";
export const FEED_OPTION_AUTO_ENABLE = "feedAutoEnableAfterComplete";

export const FEED_OPTION_ATTEMPTSTOSHOW_ALL = "all";
export const FEED_OPTION_ATTEMPTSTOSHOW_COMPLETED = "completed";
export const FEED_OPTION_ATTEMPTSTOSHOW_SHARED = "shared";

export const RESET_FEED = "scavr/Feed/RESET_FEED";
export const LOADING_FEED = "scavr/Feed/LOADING_FEED";
export const LOADING_FEED_SUCCEEDED = "scavr/Feed/LOADING_FEED_SUCCEEDED";
export const LOADING_FEED_FAILED = "scavr/Feed/LOADING_FEED_FAILED";
