import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Header, Icon, Modal } from "semantic-ui-react";

const DeleteHuntButton = ({
  isDeleting,
  deleteModalOpen,
  handleDeleteOpen,
  handleDeleteClose,
  onClickDelete
}) => (
  <Form.Field>
    <Modal
      trigger={
        <Button negative onClick={handleDeleteOpen}>
          Delete Hunt
        </Button>
      }
      open={deleteModalOpen}
      onClose={handleDeleteClose}
      dimmer="blurring"
      closeOnDimmerClick={!isDeleting}
      closeOnEscape={!isDeleting}
      size="small"
      closeIcon
    >
      <Header icon="delete" content="Delete This Hunt" />
      <Modal.Content>
        <p>
          Are you sure you want to permanently delete this hunt?
          <br />
          <small>
            This action cannot be undone. Make sure you have saved any
            participant lists, files, or other information you wish to keep
            before deleting.
          </small>
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          color="black"
          onClick={handleDeleteClose}
          disabled={isDeleting}
        >
          <Icon name="remove" /> No, Keep this Hunt
        </Button>
        <Button
          color="red"
          inverted
          onClick={onClickDelete}
          disabled={isDeleting}
          loading={isDeleting}
        >
          <Icon name="checkmark" /> Delete Hunt
        </Button>
      </Modal.Actions>
    </Modal>
  </Form.Field>
);

DeleteHuntButton.propTypes = {
  isDeleting: PropTypes.bool.isRequired,
  deleteModalOpen: PropTypes.bool.isRequired,
  handleDeleteOpen: PropTypes.func.isRequired,
  handleDeleteClose: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired
};

export default DeleteHuntButton;
