import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import Field from "components/Field";

import log from "loglevel"; // eslint-disable-line no-unused-vars

class AddressFormField extends React.PureComponent {
  render() {
    return (
      <div>
        <Field
          name="location.locationName"
          component={Form.Input}
          placeholder="Enter the location's name"
        />
        <Field
          name="location.streetAddress1"
          component={Form.Input}
          placeholder="Address"
        />
        <Field
          name="location.streetAddress2"
          component={Form.Input}
          placeholder="Address 2"
        />
        <Field name="location.city" component={Form.Input} placeholder="City" />

        <Form.Group widths="equal">
          <Field
            name="location.state"
            component={Form.Input}
            placeholder="State"
          />
          <Field
            name="location.zip"
            component={Form.Input}
            placeholder="Zip/Postal"
          />
        </Form.Group>
        <Field
          name="location.country"
          component={Form.Input}
          placeholder="Country"
        />
      </div>
    );
  }
}

AddressFormField.propTypes = {
  addressObject: PropTypes.object,
};

export default AddressFormField;
