import React from "react";
import PropTypes from "prop-types";
import copy from "copy-to-clipboard";
import { toast, Flip } from "react-toastify";
import { Form } from "semantic-ui-react";

const CopyableInput = ({ value, fluid, label }) => {
  let toastId = null;
  const copyToClipboard = () => {
    copy(value);

    if (!toast.isActive(toastId)) {
      toastId = toast.success("Copied to clipboard!", {
        autoClose: 1600,
        hideProgressBar: true,
        transition: Flip,
      });
    }
  };

  return (
    <Form.Field>
      <label>{label}</label>
      <Form.Input
        fluid={fluid}
        action={{
          color: "blue",
          labelPosition: "right",
          icon: "copy",
          content: "Copy",
          onClick: copyToClipboard,
        }}
        actionPosition="left"
        value={value}
      />
    </Form.Field>
  );
};

CopyableInput.defaultProps = {
  fluid: true,
};

CopyableInput.propTypes = {
  value: PropTypes.string.isRequired,
  fluid: PropTypes.bool,
  label: PropTypes.string,
};

export default CopyableInput;
