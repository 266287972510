import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { deleteTeamFromHunt } from "../../redux/actionCreators";
import { selectIsDeleting } from "./selectors";

class DeleteTeamButton extends React.PureComponent {
  state = { deleteModalOpen: false };

  handleDeleteOpen = () => {
    this.setState({ deleteModalOpen: true });
  };

  handleDeleteClose = () => {
    this.setState({ deleteModalOpen: false });
  };

  onConfirmDelete = () => {
    log.debug(
      "about to delete team: ",
      this.props.teamToDelete,
      this.props.huntId
    );
    this.props.deleteTeam(this.props.teamToDelete, this.props.huntId);
  };

  render() {
    const { deleteModalOpen } = this.state;
    const { isDeleting } = this.props;

    return (
      <Modal
        trigger={
          <Button compact basic size="mini" onClick={this.handleDeleteOpen}>
            <Icon name="trash" />
            Delete Team
          </Button>
        }
        open={deleteModalOpen}
        onClose={this.handleDeleteClose}
        dimmer="blurring"
        closeOnDimmerClick={!isDeleting}
        closeOnEscape={!isDeleting}
        size="small"
        closeIcon
      >
        <Header icon="delete" content="Delete Team" />
        <Modal.Content>
          <p>
            Are you sure you want to delete this team?
            <br />
            <small>
              This action cannot be undone. This will delete the team and any
              challenge submissions they may have made, including images and
              videos.
            </small>
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="black"
            onClick={this.handleDeleteClose}
            disabled={isDeleting}
          >
            <Icon name="remove" /> No, keep this team
          </Button>
          <Button
            color="red"
            inverted
            onClick={this.onConfirmDelete}
            disabled={isDeleting}
            loading={isDeleting}
          >
            <Icon name="checkmark" /> Delete Team
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

DeleteTeamButton.propTypes = {
  teamToDelete: PropTypes.string.isRequired,
  huntId: PropTypes.string.isRequired,

  isDeleting: PropTypes.bool.isRequired,

  deleteTeam: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isDeleting: selectIsDeleting,
});

const mapDispatchToProps = (dispatch) => ({
  deleteTeam: (teamId, huntId) => dispatch(deleteTeamFromHunt(teamId, huntId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeleteTeamButton)
);
