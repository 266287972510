import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import {
  Header,
  Divider,
  Message,
  Segment,
  Container,
  List,
  Placeholder,
  Button,
  Icon
} from "semantic-ui-react";
import ChallengeListItem from "components/ChallengeListItem/index";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import CreateChallengeModal from "../CreateChallengeModal";

const SortableChallengeItemHandle = SortableHandle(() => (
  <Icon
    name="arrows alternate vertical"
    className="move middle aligned right floated"
    size="large"
  />
));

const SortableChallengeItem = SortableElement(({ challenge }) => {
  return (
    <ChallengeListItem
      withModal={true}
      challenge={challenge}
      sortHandle={<SortableChallengeItemHandle />}
    />
  );
});

const SortableChallengeList = SortableContainer(({ items }) => {
  return (
    <List divided size="large" selection verticalAlign="middle">
      {items.map((challenge, index) => (
        <SortableChallengeItem
          challenge={challenge}
          key={`challenge-${index}`}
          index={index}
        />
      ))}
    </List>
  );
});

export default class ChallengesList extends React.PureComponent {
  handleSortEnd = ({ oldIndex, newIndex }) => {
    const challenge = this.props.challenges[oldIndex];
    this.props.handleResortChallenge(challenge.objectId, oldIndex, newIndex);
  };

  render() {
    const { loading, error, challenges } = this.props;
    const border = error || challenges.length === 0;

    const renderLoading = () => {
      return (
        <List divided size="large" verticalAlign="middle">
          <List.Item>
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </List.Item>
          <List.Item>
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </List.Item>
          <List.Item>
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </List.Item>
        </List>
      );
    };

    const renderEmpty = () => {
      const addChallengeButton = (
        <Container textAlign="center">
          <Button primary size="small">
            Add a Challenge
          </Button>
        </Container>
      );
      return (
        <div>
          <Header textAlign="center">
            Get started by creating some challenges!
          </Header>
          <Divider hidden />
          <CreateChallengeModal
            triggerElement={addChallengeButton}
            onCreateChallenge={this.props.handleChallengeCreated}
          />
        </div>
      );
    };

    const renderError = error => {
      return (
        <Message error>
          <Header>Something went wrong</Header>
          <Divider hidden />
          {error}
        </Message>
      );
    };

    const renderList = challenges => {
      return (
        <SortableChallengeList
          helperClass="dragging ui list selection"
          items={challenges}
          onSortEnd={this.handleSortEnd}
          useDragHandle={true}
        />
      );
    };

    let children;

    if (loading) {
      children = renderLoading();
    } else if (error) {
      children = renderError(error);
    } else if (challenges.length === 0) {
      children = renderEmpty();
    } else {
      children = renderList(challenges);
    }

    return (
      <Segment basic={!border} className="challenge-list-segment">
        {children}
      </Segment>
    );
  }
}

ChallengesList.propTypes = {
  challenges: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleResortChallenge: PropTypes.func.isRequired,
  handleChallengeCreated: PropTypes.func.isRequired
};
