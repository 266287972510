/* eslint-disable no-case-declarations */
import { fromJS, Set } from "immutable";
import {
  WATCHING_CHALLENGES,
  LOAD_HUNT_CHALLENGES,
  LOAD_HUNT_CHALLENGES_SUCCESS,
  LOAD_HUNT_CHALLENGES_FAILURE,
  CREATE_CHALLENGE,
  CREATE_CHALLENGE_FAILURE,
  CREATE_CHALLENGE_SUCCESS,
  REORDER_CHALLENGE,
  REORDER_CHALLENGE_FAILURE,
  REORDER_CHALLENGE_SUCCESS,
  DELETE_CHALLENGE,
  DELETE_CHALLENGE_FAILURE,
  DELETE_CHALLENGE_SUCCESS,
} from "../constants";

import log from "loglevel"; // eslint-disable-line no-unused-vars

const initialState = fromJS({
  loading: false,
  error: null,
  huntChallenges: {},
});

function myHuntsReducer(state = initialState, action) {
  let challengeId, huntId;
  switch (action.type) {
    case WATCHING_CHALLENGES:
      return state.set("loading", true).set("error", null);
    case LOAD_HUNT_CHALLENGES:
      return state.set("loading", true).set("error", null);
    case LOAD_HUNT_CHALLENGES_FAILURE:
      return state.set("loading", false).set("error", action.error.message);
    case LOAD_HUNT_CHALLENGES_SUCCESS:
      const challenges = state
        .set("loading", false)
        .set("error", null)
        .getIn(["huntChallenges", action.payload.huntId], Set())
        .union(Set(action.payload.result));
      return state
        .set("loading", false)
        .set("error", null)
        .setIn(["huntChallenges", action.payload.huntId], challenges);
    case CREATE_CHALLENGE:
      return state;
    case CREATE_CHALLENGE_FAILURE:
      return state.set("loading", false).set("error", action.error.message);
    case CREATE_CHALLENGE_SUCCESS:
      challengeId = action.payload.result;
      huntId = action.payload.entities.challenges[challengeId].hunt;
      // log.debug("setting challenge ")
      return state
        .set("loading", false)
        .set("error", null)
        .updateIn(["huntChallenges", huntId], Set(), (set) =>
          set.add(challengeId)
        );
    case DELETE_CHALLENGE:
    case REORDER_CHALLENGE:
      // return state.set("loading", true);
      return state;
    case REORDER_CHALLENGE_FAILURE:
    case DELETE_CHALLENGE_FAILURE:
      return state.set("loading", false).set("error", action.error.message);
    case DELETE_CHALLENGE_SUCCESS:
      challengeId = action.payload.result;
      huntId = action.payload.entities.challenges[challengeId].hunt;
      log.debug("Deleteing hunt challenges", huntId, challengeId, state);
      return state
        .set("loading", false)
        .set("error", null)
        .updateIn(["huntChallenges", huntId], Set(), (set) =>
          set.delete(challengeId)
        );
    case REORDER_CHALLENGE_SUCCESS:
      return state.set("loading", false);
    default:
      return state;
  }
}

export default myHuntsReducer;
