/* eslint-disable indent */
import { createSelector } from "reselect";
import moment from "moment";
import { inflateObject, inflateObjects } from "utils/selectors";
import { MANAGE_HUNT_DETAILS_FORM_NAME } from "./constants";
import { fromJS, Set } from "immutable";
import {
  selectChallenges,
  selectCurrentHunt,
  selectCurrentHuntId,
  selectData,
  selectTeams
} from "../../redux/selectors"; // eslint-disable-line no-unused-vars

export const selectHuntChallenges = createSelector(
  [selectCurrentHuntId, selectChallenges],
  (huntId, challenges) => {
    if (!challenges) {
      return fromJS({});
    }
    return challenges.filter(challenge => challenge.get("hunt") === huntId);
  }
);

const selectChallengeAttemptsArray = state => {
  const data = state.data;
  if (!data.get("challengeAttempts")) {
    return null;
  }

  return data.get("challengeAttempts").toArray();
};

export const selectHuntChallengeAttempts = createSelector(
  [selectCurrentHuntId, selectChallengeAttemptsArray],
  (huntId, attempts) => {
    if (attempts == null) {
      return [];
    }
    return attempts.filter(attempt => attempt.get("hunt") === huntId);
  }
);

export const selectHuntTeams = createSelector(
  [selectCurrentHuntId, selectTeams],
  (huntId, teams) => {
    if (!teams) {
      return fromJS({});
    }
    return teams
      .filter(team => team.get("hunt") === huntId)
      .sort((t1, t2) => t1.get("name") <= t2.get("name"));
  }
);

const selectManageHuntData = state => state.manageHunt.manageHunt;

export const makeSelectError = () =>
  createSelector(selectManageHuntData, manageHunt => manageHunt.get("error"));

export const makeSelectLoading = () =>
  createSelector(
    selectManageHuntData,
    manageHunt =>
      manageHunt.get("loadingHunt") || manageHunt.get("loadingCapabilities")
  );

export const selectHuntParticipants = createSelector(
  [selectData, selectCurrentHuntId, selectTeams],
  (data, huntId, teams) => {
    if (!teams) {
      return [];
    }
    const huntTeams = teams.filter(t => t.get("hunt") === huntId);
    const participantIds = huntTeams.reduce(
      (r, team) => r.union(team.get("members")),
      Set()
    );
    const inflatedUsers = inflateObjects(data, participantIds, "users");
    inflatedUsers.forEach(user => {
      if (user) {
        huntTeams.forEach(team => {
          if (team.get("members").includes(user.objectId)) {
            user["team"] = inflateObject(data, team.get("objectId"), "teams");
          }
        });
      }
    });

    return inflatedUsers;
  }
);

export const selectJoinHuntURL = createSelector(
  selectCurrentHuntId,
  // eslint-disable-next-line no-undef
  id => process.env.REACT_APP_PUBLIC_URL + "/join/" + id
);

// Hunt Details Form
export const selectHuntDetailsForm = state =>
  state.form.get(MANAGE_HUNT_DETAILS_FORM_NAME);

export const selectHuntDetailsFormValues = createSelector(
  selectHuntDetailsForm,
  form => (form ? form.get("values").toJS() : null)
);
export const selectHuntDetailsFormInitialValues = createSelector(
  selectCurrentHunt,
  hunt =>
    hunt
      ? {
          name: hunt.name,
          description: hunt.description,
          longDescription: hunt.longDescription,
          location: hunt.location,
          // priceLevel: capitalizeFirstLetter(hunt.priceLevel),
          // type: capitalizeFirstLetter(hunt.type),
          startTime: hunt.startTime ? moment(hunt.startTime) : null,
          logoURL: hunt.imageURL
        }
      : null
);
