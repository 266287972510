import log from "loglevel"; // eslint-disable-line no-unused-vars
import DataProvider from "DataProvider";
import {
  watchLeaderboardStarted,
  watchLeaderboardStopped,
  leaderboardUpdated,
  watchLeaderboardAction,
  stopWatchingLeaderboardAction
} from "./actions";

const dataProvider = DataProvider.instance();

const watchLeaderboard = huntId => dispatch => {
  dispatch(
    watchLeaderboardAction({ huntId }, () => {
      let listener = dataProvider.watchLeaderboard(huntId, newLeaderboard => {
        dispatch(leaderboardUpdated(huntId, newLeaderboard));
      });

      dispatch(watchLeaderboardStarted({ huntId }));

      return listener;
    })
  );
};

const stopWatchingLeaderboard = huntId => dispatch => {
  dispatch(stopWatchingLeaderboardAction({ huntId }));

  dispatch(watchLeaderboardStopped({ huntId }));
};

export { watchLeaderboard, stopWatchingLeaderboard };
