import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import moment from "moment";
import { withRouter } from "react-router-dom";
import { push as pushRoute } from "connected-react-router";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import {
  Button,
  Card,
  Divider,
  Header,
  Icon,
  Modal,
  Segment,
} from "semantic-ui-react";
import FeedItem from "components/FeedItem";
import { loadMoreFeedItems, watchFeedSettings } from "./actionCreators";
import { resetFeed } from "./actions";
import {
  selectCurrentHunt,
  selectCurrentHuntId,
  selectCurrentHuntSettings,
} from "redux/selectors";
import { HUNT_STATE_PUBLISHED, HUNT_STATE_UNPUBLISHED } from "models/constants";
import FeedList from "./FeedList";
import FeedSettingsSection from "../ManageHunt/sections/Settings/components/feedSettings";
import { selectIsLoadingSettings } from "../ManageHunt/sections/Settings/selectors";
import {
  stopWatchingHuntSettings,
  updateHuntSetting,
} from "redux/actionCreators";
import {
  selectFeedIsLoading,
  selectHuntFeedItems,
  selectHasMoreItems,
} from "./selectors";
import PlaceholderFeedItem from "components/FeedItem/PlaceholderFeedItem";
import {
  FEED_OPTION_ATTEMPTSTOSHOW,
  FEED_OPTION_AUTO_ENABLE,
  FEED_OPTION_ENABLED,
} from "./constants";

import "react-virtualized/styles.css";

class HuntFeed extends React.PureComponent {
  state = {
    settingsModalOpen: false,
  };

  componentDidMount() {
    this.props.watchSettings(this.props.huntId);
    this.props.loadMoreFeedItems(this.props.huntId);
  }

  componentWillUnmount() {
    this.props.stopWatchingHuntSettings(this.props.huntId);
  }

  onClickDownload = () => {
    this.props.routeToDownload(this.props.huntId);
  };

  onClickApproved = () => {
    this.props.routeToJudging(this.props.huntId);
  };

  routeToSettings = () => {
    this.props.routeToSettings(this.props.huntId);
  };

  openSettingsModal = () => {
    this.setState({ settingsModalOpen: true });
  };

  closeSettingsModal = () => {
    this.setState({ settingsModalOpen: false });
  };

  handleSettingUpdated = (key, newValue) => {
    const { huntId } = this.props;
    this.props.updateHuntSetting(huntId, key, newValue);
    // this.props.resetFeed(huntId);
    // this.props.loadMoreFeedItems(huntId);
  };

  render() {
    const { hunt, feedItems, isLoadingFeed, huntSettings } = this.props;

    const header = (
      <div>
        <Button
          animated="vertical"
          size="small"
          basic
          floated="right"
          onClick={this.onClickDownload}
        >
          <Button.Content visible>Download</Button.Content>
          <Button.Content hidden>
            <Icon name="download" />
          </Button.Content>
        </Button>
        <Button
          animated="vertical"
          size="small"
          basic
          floated="right"
          onClick={this.openSettingsModal}
        >
          <Button.Content visible>Settings</Button.Content>
          <Button.Content hidden>
            <Icon name="settings" />
          </Button.Content>
        </Button>
        <Header as="h2">Feed</Header>
      </div>
    );

    if (!hunt) {
      return header;
    }

    const huntUnstarted =
      hunt.status === HUNT_STATE_UNPUBLISHED ||
      hunt.status === HUNT_STATE_PUBLISHED;

    const rowCount = feedItems.length;
    log.info("Num rows in feed: ", rowCount);

    let settingsModal = <div />;
    if (huntSettings) {
      let { isLoadingSettings } = this.props;
      let {
        feedEnabled,
        feedAttemptsToShow,
        feedAutoEnableAfterComplete,
      } = huntSettings;
      const { settingsModalOpen } = this.state;

      settingsModal = (
        <Modal
          onOpen={this.openSettingsModal}
          onClose={this.closeSettingsModal}
          open={settingsModalOpen}
          closeIcon
        >
          <Modal.Header>Feed Settings</Modal.Header>
          <Modal.Content>
            <FeedSettingsSection
              loading={isLoadingSettings}
              feedEnabled={feedEnabled}
              attemptsToShow={feedAttemptsToShow}
              autoEnableAfterComplete={feedAutoEnableAfterComplete}
              onToggleEnabled={() => {
                this.handleSettingUpdated(FEED_OPTION_ENABLED, !feedEnabled);
              }}
              onChangeAttemptsToShow={(e, t) => {
                this.handleSettingUpdated(FEED_OPTION_ATTEMPTSTOSHOW, t.value);
              }}
              onToggleAutoEnable={() => {
                this.handleSettingUpdated(
                  FEED_OPTION_AUTO_ENABLE,
                  !feedAutoEnableAfterComplete
                );
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Close"
              labelPosition="right"
              onClick={() => this.closeSettingsModal()}
            />
          </Modal.Actions>
        </Modal>
      );
    }

    // eslint-disable-next-line no-unused-vars
    const feedList = (
      <div style={{ height: "calc(100vh - 100px)" }}>
        <AutoSizer>
          {({ width, height }) => (
            <FeedList feedItems={feedItems} width={width} height={height} />
          )}
        </AutoSizer>
      </div>
    );

    // eslint-disable-next-line no-unused-vars
    const feedList2 = (
      <Card.Group itemsPerRow={1} centered>
        {feedItems.map((item) => (
          <div key={`p-${item.objectId}`}>
            <p>{item.objectId}</p>
            <FeedItem
              key={item.objectId}
              teamName={item.teamName}
              mediaURL={item.mediaURL}
              challengeName={item.challengeName}
              submittedTime={moment.unix(item.submittedAt.seconds).fromNow()}
              mediaType={item.type}
              attemptId={item.challengeAttempt}
              feedId={item.objectId}
              submissionTimeRaw={moment.unix(item.submittedAt.seconds).format()}
            />
          </div>
        ))}
      </Card.Group>
    );

    const unstartedBody = (
      <Segment textAlign="center">
        <Header>You have not started your hunt yet.</Header>
        <p>
          Once your hunt is active and participants complete challenges,
          you&apos;ll see the feed here as submissions are{" "}
          <a
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={this.onClickApproved}
          >
            approved
          </a>
          !
        </p>
      </Segment>
    );

    let autoEnableFeedAfterComplete = false;
    if (huntSettings) {
      let { autoEnableAfterComplete } = huntSettings;
      autoEnableFeedAfterComplete = autoEnableAfterComplete;
    }

    const feedNotEnabledBody = (
      <Segment textAlign="center">
        <Header>Your Feed is not turned on.</Header>
        {autoEnableFeedAfterComplete ? (
          <p>
            It will automatically be turned on after your hunt is completed.
          </p>
        ) : (
          ""
        )}
        <p>
          <a
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={this.openSettingsModal}
          >
            Edit my feed settings
          </a>
        </p>
      </Segment>
    );

    const feedLoadingBody = (
      <div>
        <Divider />
        <Card.Group itemsPerRow={1} centered>
          <PlaceholderFeedItem />
          <PlaceholderFeedItem />
          <PlaceholderFeedItem />
          <PlaceholderFeedItem />
          <PlaceholderFeedItem />
        </Card.Group>
      </div>
    );

    let body;

    if (huntUnstarted) {
      body = unstartedBody;
    } else if (!huntSettings || !huntSettings.feedEnabled) {
      body = feedNotEnabledBody;
    } else if (isLoadingFeed) {
      log.debug("Loading feed.");
      body = feedLoadingBody;
    } else {
      body = (
        <div>
          <Divider />
          {feedList}
        </div>
      );
    }

    return (
      <div>
        {header}
        {body}
        {settingsModal}
      </div>
    );
  }
}

HuntFeed.propTypes = {
  hunt: PropTypes.object,
  huntId: PropTypes.string.isRequired,
  huntSettings: PropTypes.object,

  feedItems: PropTypes.array.isRequired,
  isLoadingFeed: PropTypes.bool,
  hasMoreItems: PropTypes.bool.isRequired,

  routeToDownload: PropTypes.func.isRequired,
  routeToJudging: PropTypes.func.isRequired,
  routeToSettings: PropTypes.func.isRequired,

  resetFeed: PropTypes.func.isRequired,
  loadMoreFeedItems: PropTypes.func.isRequired,

  watchSettings: PropTypes.func.isRequired,
  stopWatchingHuntSettings: PropTypes.func.isRequired,

  isLoadingSettings: PropTypes.bool.isRequired,
  updateHuntSetting: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  hunt: selectCurrentHunt,
  huntId: selectCurrentHuntId,
  huntSettings: selectCurrentHuntSettings,

  feedItems: selectHuntFeedItems,

  isLoadingSettings: selectIsLoadingSettings,
  isLoadingFeed: selectFeedIsLoading,
  hasMoreItems: selectHasMoreItems,
});

const mapDispatchToProps = (dispatch) => ({
  watchSettings: (huntId) => dispatch(watchFeedSettings(huntId)),
  stopWatchingHuntSettings: (huntId) =>
    dispatch(stopWatchingHuntSettings(huntId)),
  updateHuntSetting: (huntId, key, value) =>
    dispatch(updateHuntSetting(huntId, key, value)),
  routeToDownload: (huntId) =>
    dispatch(pushRoute(`/manage/${huntId}/download`)),
  routeToJudging: (huntId) => dispatch(pushRoute(`/manage/${huntId}/judging`)),
  routeToSettings: (huntId) =>
    dispatch(pushRoute(`/manage/${huntId}/settings`)),
  loadMoreFeedItems: (huntId, loadAfterItem) =>
    dispatch(loadMoreFeedItems(huntId, loadAfterItem)),
  resetFeed: (huntId) => dispatch(resetFeed({ huntId })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HuntFeed)
);
