import log from "loglevel"; //eslint-disable-line no-unused-vars
import { createSelector } from "reselect";
import { inflateObjects } from "utils/selectors";

const selectData = state => state.data;
const selectMyHuntsDomain = state => state.myHunts;

/**
 * Default selector
 */
export const makeSelectMyHunts = () =>
  createSelector(selectData, selectMyHuntsDomain, (data, myHuntsDomain) => {
    const myHuntIds = myHuntsDomain.get("myHunts");
    if (myHuntIds) {
      return inflateObjects(data, myHuntIds, "hunts");
    } else {
      return [];
    }
  });

const selectMyHunts = createSelector(
  selectData,
  selectMyHuntsDomain,
  (data, myHuntsDomain) => {
    const myHuntIds = myHuntsDomain.get("myHunts");
    if (myHuntIds) {
      return inflateObjects(data, myHuntIds, "hunts");
    } else {
      return [];
    }
  }
);

export const makeSelectMyHuntsLoading = () =>
  createSelector(selectMyHuntsDomain, myHunts => myHunts.get("loading"));

export const makeSelectMyHuntsError = () =>
  createSelector(selectMyHuntsDomain, myHunts => myHunts.get("error"));

export { selectMyHunts };
