import { createSelector } from "reselect";
// import { fromJS } from "immutable";

import log from "loglevel"; // eslint-disable-line no-unused-vars

export const selectCurrentUser = createSelector(
  state => state.firebase.auth,
  auth => auth
);

export const selectIsLoggedIn = createSelector(
  selectCurrentUser,
  auth => auth.isLoaded && !auth.isEmpty
);

export const inflateObject = (data, id, dataType) => {
  if (!data) {
    return null;
  }

  const rawObj = data.getIn([dataType, id], null);
  if (!rawObj) {
    return null;
  }
  return rawObj.toJS();
};

export const inflateObjects = (data, ids, dataType) => {
  // log.debug("Inflating objects: ", ids, dataType);
  if (!data) {
    return null;
  }
  const dataTypeObj = data.get(dataType);
  // log.debug("Inflating objects under ", dataTypeObj);
  if (!ids || ids.size === 0 || !dataTypeObj || dataTypeObj.size === 0)
    return [];
  // if (dataTypeObj.constructor.name === "Object") {
  //   return ids.map(id => dataTypeObj[id]);
  // }
  return ids.map(id => dataTypeObj.get(id, null)).toJS();
};
