import log from "loglevel"; // eslint-disable-line no-unused-vars
import firebaseUtils from "./firebaseUtils";
import {
  FIREBASE_COLLECTION_HUNTS,
  FIREBASE_COLLECTION_FEED,
} from "./constants";
import {
  FEED_OPTION_ATTEMPTSTOSHOW,
  FEED_OPTION_ATTEMPTSTOSHOW_COMPLETED,
  FEED_OPTION_ATTEMPTSTOSHOW_SHARED,
} from "containers/Feed/constants";
import { loadModelsForQuerySnapshot } from "./firebaseModels";

const loadFeed = async (huntId, options, limit, loadAfterDoc) => {
  log.debug("Fetching feed");
  let feedQ = firebaseUtils
    .db()
    .collection(FIREBASE_COLLECTION_HUNTS)
    .doc(huntId)
    .collection(FIREBASE_COLLECTION_FEED);

  if (options && options[FEED_OPTION_ATTEMPTSTOSHOW]) {
    switch (options[FEED_OPTION_ATTEMPTSTOSHOW]) {
      case FEED_OPTION_ATTEMPTSTOSHOW_COMPLETED:
        feedQ = feedQ.where("attemptStatus", "==", "completed");
        break;
      case FEED_OPTION_ATTEMPTSTOSHOW_SHARED:
        feedQ = feedQ.where("shared", "==", true);
        break;
      default:
        break;
    }
  } else {
    feedQ = feedQ.where("attemptStatus", "==", "completed");
  }

  feedQ = feedQ.where("type", "in", ["photo", "video"]);

  feedQ = feedQ.orderBy("submittedAt", "desc");

  log.debug("Ignoring limit of ", limit);
  // feedQ = feedQ.limit(limit);

  if (loadAfterDoc) {
    feedQ.startAfter(loadAfterDoc.submittedAt);
  }

  let feedSnap = await feedQ.get();

  log.debug(`Loaded ${feedSnap.size} feed items for hunt ${huntId}`);

  let models = await loadModelsForQuerySnapshot(feedSnap);

  return models;
};

const api = {
  loadFeed,
};

export default api;
