import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import { CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { Card } from "semantic-ui-react";
import FeedItem from "components/FeedItem";
import moment from "moment";

class FeedList extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this._cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 300,
    });
  }

  render() {
    const { width, height, feedItems } = this.props;

    return (
      <List
        deferredMeasurementCache={this._cache}
        height={height}
        width={width}
        overscanRowCount={0}
        rowCount={feedItems.length}
        rowHeight={this._cache.rowHeight}
        rowRenderer={this._rowRenderer}
      />
    );
  }

  _rowRenderer = ({ index, key, parent, style }) => {
    const { feedItems } = this.props;

    log.debug(
      "Feed items in row renderer: ",
      // feedItems,
      feedItems.length,
      // index % feedItems.length,
      // feedItems[index],
      feedItems[index % feedItems.length]
    );

    const feedItem = feedItems[index % feedItems.length];
    // const classNames = getClassName({ columnIndex: 0, rowIndex: index });

    if (feedItem) {
      log.debug("Feed item id: ", feedItem.objectId);
    } else {
      log.warn("No feed item found at index: ", index);
      return <div />;
    }
    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}
      >
        {({ measure, registerChild }) => (
          <div ref={registerChild} style={style}>
            <Card.Group itemsPerRow={1} centered>
              <FeedItem
                key={feedItems[index].objectId}
                teamName={feedItems[index].teamName}
                mediaURL={feedItems[index].mediaURL}
                challengeName={feedItems[index].challengeName}
                challengeDescription={feedItems[index].challengeDescription}
                submittedTime={moment
                  .unix(feedItems[index].submittedAt.seconds)
                  .fromNow()}
                mediaType={feedItems[index].type}
                onLoad={measure}
                attemptId={feedItems[index].attempt}
                feedId={feedItems[index].objectId}
                submissionTimeRaw={moment
                  .unix(feedItems[index].submittedAt.seconds)
                  .format("LLLL")}
              />
            </Card.Group>
            {/*<Divider hidden />*/}
            <div style={{ height: "28px" }} />
          </div>
        )}
      </CellMeasurer>
    );
  };
}

FeedList.propTypes = {
  feedItems: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default FeedList;
