import { fromJS } from "immutable";
import { LOAD_HUNT } from "redux/constants";
import {
  UPDATING_HUNT_LOGO,
  UPDATING_HUNT_LOGO_COMPLETE,
  UPDATING_HUNT_LOGO_PROGRESS
} from "../../constants";

const initialState = fromJS({});

const initialHuntState = fromJS({
  imageReloading: false,
  imageUpdateProgress: 0
});

function editDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_HUNT:
      return state.set(action.payload.huntId, initialHuntState);
    case UPDATING_HUNT_LOGO:
      return state
        .setIn([action.payload.huntId, "imageReloading"], true)
        .setIn([action.payload.huntId, "imageUpdateProgress"], 0);
    case UPDATING_HUNT_LOGO_PROGRESS:
      return state.setIn(
        [action.payload.huntId, "imageUpdateProgress"],
        action.payload.progress
      );
    case UPDATING_HUNT_LOGO_COMPLETE:
      return state.setIn([action.payload.huntId, "imageReloading"], false);
    default:
      return state;
  }
}

export default editDetailsReducer;
