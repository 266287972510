import log from "loglevel"; // eslint-disable-line
const providerType = process.env.REACT_APP_CONFIG_PROVIDER; // eslint-disable-line no-undef
let configProvider = null;

log.debug("Loading provider ", providerType);
switch (providerType) {
  case "split":
    configProvider = require("./split_remote_config").default;
    break;
  case "firebase":
    configProvider = require("./firebase_remote_config").default;
    break;
  case "local":
    configProvider = require("./local_remote_config");
    break;
  default:
    configProvider = {};
    break;
}

export default configProvider;
