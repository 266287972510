export const PUBLISH_HUNT = "scavr/HuntStatusSteps/PUBLISH_HUNT";
export const PUBLISH_HUNT_SUCCESS =
  "scavr/HuntStatusSteps/PUBLISH_HUNT_SUCCESS";
export const PUBLISH_HUNT_FAILED = "scavr/HuntStatusSteps/PUBLISH_HUNT_FAILED";

export const START_HUNT = "scavr/HuntStatusSteps/START_HUNT";
export const START_HUNT_SUCCESS = "scavr/HuntStatusSteps/START_HUNT_SUCCESS";
export const START_HUNT_FAILED = "scavr/HuntStatusSteps/START_HUNT_FAILED";

export const FINISH_HUNT = "scavr/HuntStatusSteps/FINISH_HUNT";
export const FINISH_HUNT_SUCCESS = "scavr/HuntStatusSteps/FINISH_HUNT_SUCCESS";
export const FINISH_HUNT_FAILED = "scavr/HuntStatusSteps/FINISH_HUNT_FAILED";

export const PAUSE_HUNT = "scavr/HuntStatusSteps/PAUSE_HUNT";
export const PAUSE_HUNT_SUCCESS = "scavr/HuntStatusSteps/PAUSE_HUNT_SUCCESS";
export const PAUSE_HUNT_FAILED = "scavr/HuntStatusSteps/PAUSE_HUNT_FAILED";

export const RESUME_HUNT = "scavr/HuntStatusSteps/RESUME_HUNT";
export const RESUME_HUNT_SUCCESS = "scavr/HuntStatusSteps/RESUME_HUNT_SUCCESS";
export const RESUME_HUNT_FAILED = "scavr/HuntStatusSteps/RESUME_HUNT_FAILED";

export const UPDATE_HUNT_STATUS = "scavr/HuntStatusSteps/UPDATE_HUNT_STATUS";
export const UPDATE_HUNT_STATUS_SUCCESS =
  "scavr/HuntStatusSteps/UPDATE_HUNT_STATUS_SUCCESS";
export const UPDATE_HUNT_STATUS_FAILED =
  "scavr/HuntStatusSteps/UPDATE_HUNT_STATUS_FAILED";
