import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import _ from "lodash"; // eslint-disable-line no-unused-vars
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  Loader,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment
} from "semantic-ui-react";
import { createStructuredSelector } from "reselect";
import { loadHunt } from "redux/actionCreators";
import { fetchAmIRegistered, loadRegisteredTeams } from "../actions";
import { createTeamAndJoinHunt } from "../actionCreators";
import { selectCurrentUser, selectIsLoggedIn } from "utils/selectors";
import {
  selectMyRegisteredTeam,
  selectRegisteredTeamCount,
  selectError,
  selectRegisterLoading,
  selectLoading
} from "../selectors";
import {
  fbSignupSuccess,
  logout,
  emailLogin,
  emailSignup
} from "reducers/AuthReducer/index";
import { Media, MediaContextProvider } from "components/Responsive/Media";
import RegisterForHuntModal from "../components/RegisterForHuntModal";
import CreateTeamAndRegister from "../components/CreateTeamAndRegister";
import selectLogin from "containers/Login/selectors";
import selectSignup from "containers/SignUp/selectors";
import AddressLabel from "components/AddressLabel";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import "firebase/auth";
import qs from "qs";
import { BASIC_TEAM_LIMIT, ENHANCED_TEAM_LIMIT } from "utils/constants";
import {
  selectCurrentHunt,
  selectHuntCapabilityTeamLimit
} from "redux/selectors";

import "../index.css";

const HuntHeading = ({ mobile, hunt }) => (
  <div>
    {hunt.imageURL && (
      <Image
        centered
        size="small"
        src={hunt.imageURL}
        style={{ marginBottom: "1em" }}
      />
    )}
    <Header
      as="h1"
      content={hunt.name}
      style={{
        fontSize: mobile ? "2em" : "4em",
        fontWeight: "normal",
        marginBottom: 0
      }}
    />
    {!_.isEmpty(hunt.location) && (
      <Header.Subheader
        content={<AddressLabel locationObject={hunt.location} />}
        style={{
          fontSize: mobile ? "1.2em" : "1.5em",
          fontWeight: "normal",
          marginTop: mobile ? "0.5em" : "1em"
        }}
      />
    )}
    {!!hunt.startTime && (
      <Header
        as="h3"
        content={moment(hunt.startTime).format("dddd, MMMM Do YYYY, h:mm a")}
      />
    )}
  </div>
);

HuntHeading.propTypes = {
  mobile: PropTypes.bool,
  hunt: PropTypes.object.isRequired
};

class JoinHuntHeader extends React.PureComponent {
  render() {
    return (
      <MediaContextProvider>
        <Media at="sm">
          {" "}
          <Segment
            textAlign="center"
            style={{ minHeight: 250, padding: "1em 0em" }}
            vertical
          >
            <HuntHeading mobile={true} hunt={this.props.hunt} />
          </Segment>
        </Media>
        <Media greaterThan="sm">
          <Segment
            textAlign="center"
            style={{ minHeight: 250, padding: "1em 0em" }}
            vertical
          >
            <HuntHeading mobile={false} hunt={this.props.hunt} />
          </Segment>
        </Media>
      </MediaContextProvider>
    );
  }
}

JoinHuntHeader.propTypes = {
  hunt: PropTypes.object.isRequired
};

const JoinHuntDetails = ({ hunt }) => (
  <Container>
    <Header as="h4">DETAILS</Header>
    <p
      style={{
        whiteSpace: "pre-wrap"
      }}
    >
      {hunt.description}
    </p>
  </Container>
);

JoinHuntDetails.propTypes = {
  hunt: PropTypes.object.isRequired
};

class JoinHuntLanding extends React.PureComponent {
  state = {
    modalOpen: false
  };

  // Configure FirebaseUI.
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "redirect",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: function(args) {
    //   log.debug("sign in scucess args: ", args);
    // },
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => {}
    },
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      {
        provider: "apple.com",
        providerName: "Apple",
        buttonColor: "#000000",
        iconUrl:
          process.env.REACT_APP_PUBLIC_URL + "/images/Apple%20White%20Logo.png", // eslint-disable-line no-undef
        scopes: ["name", "email"]
      }
    ]
  };

  UNSAFE_componentWillMount() {
    const { match } = this.props;

    const huntId = match.params.huntId;

    this.props.loadHunt(huntId);
    if (this.props.loggedIn) {
      this.props.fetchAmIRegistered(huntId);
    }
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(user => this.setState({ isSignedIn: !!user }));

    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    if (query.mode === "select") {
      this.handleModalOpen();
    }
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const huntId = this.props.match.params.huntId;
    if (nextProps.loggedIn && !this.props.loggedIn) {
      this.props.fetchAmIRegistered(huntId);
    }
    if (!nextProps.hunt) {
      this.props.loadHunt(huntId);
      this.props.loadTeamCount(huntId);
    }
    if (nextProps.loggedIn && nextProps.myTeam && !this.props.myTeam) {
      this.handleModalClose();
    }
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => this.setState({ modalOpen: false });

  render() {
    const { hunt, registeredTeamCount, teamLimit } = this.props;
    if (!hunt) {
      return (
        <Grid
          centered
          style={{
            marginTop: "4em"
          }}
        >
          <Grid.Column width={10}>
            <Loader size="large" />
          </Grid.Column>
        </Grid>
      );
    }

    let huntIsFull;
    if (teamLimit) {
      huntIsFull = registeredTeamCount >= teamLimit;
    } else {
      if (hunt.priceLevel === "basic") {
        huntIsFull = registeredTeamCount >= BASIC_TEAM_LIMIT;
      } else if (hunt.priceLevel === "enhanced") {
        huntIsFull = registeredTeamCount >= ENHANCED_TEAM_LIMIT;
      } else {
        huntIsFull = registeredTeamCount >= 10;
      }
    }

    /*
      Any hunt's join page should have three responsive sections:
      * A header, which includes:
      *   * (optional) hunt header image
      *   * Hunt date
      *   * Hunt name
      *   * hunt host?
      *   * Hunt price...
      * An Action section, which includes:
      *   * A share button?
      *   * A "register" CTA type button
      * A Details section, which includes:
      *   * Long description
      *   * Date & Time
      *   * Location
    */
    const isLoggedIn =
      this.props.currentUser.isLoaded && !this.props.currentUser.isEmpty;
    let joinHuntModalContent = <div />;
    if (this.props.loggedIn) {
      joinHuntModalContent = (
        <CreateTeamAndRegister
          user={this.props.currentUser}
          onLogout={this.props.onLogOut}
          hunt={this.props.hunt}
          onCreateTeamAndRegister={this.props.onCreateTeamAndRegister}
          loading={this.props.loading}
          registerError={this.props.error}
        />
      );
    } else {
      joinHuntModalContent = (
        <StyledFirebaseAuth
          uiConfig={this.uiConfig}
          firebaseAuth={firebase.auth()}
        />
      );
    }

    const joinHuntContent = [
      <JoinHuntHeader hunt={hunt} key="joinHuntHeader" />,
      <Divider clearing key="joinHuntHeaderDivider" />,
      <RegisterForHuntModal
        hunt={hunt}
        handleClose={this.handleModalClose}
        handleOpen={this.handleModalOpen}
        modalOpen={this.state.modalOpen}
        loggedIn={isLoggedIn}
        onLogout={this.props.onLogOut}
        registerLoading={this.props.registerLoading}
        team={this.props.myTeam}
        loading={this.props.loading}
        error={this.props.error}
        huntIsFull={huntIsFull}
        key="joinHuntModal"
      >
        {joinHuntModalContent}
      </RegisterForHuntModal>,
      <div key="detailsSection">
        {!!hunt.description && (
          <div>
            <Divider clearing key="joinHuntActionsDivider" />
            <JoinHuntDetails hunt={hunt} key="joinHuntDetails" />
          </div>
        )}
      </div>
    ];

    return (
      <div
        style={{
          color: "#3d3d3d",
          backgroundColor: "#333333",
          width: "100%",
          height: "100%",
          position: "absolute",
          x: "0",
          y: "0"
        }}
      >
        <Helmet
          title={hunt.name + " - on Scavr"}
          meta={[{ name: "description", content: "Join a hunt." }]}
        />
        <MediaContextProvider>
          <Media lessThan="lg">
            {" "}
            <div
              style={{
                color: "#3d3d3d",
                backgroundColor: "#FFFF",
                width: "100%",
                height: "100%",
                position: "absolute",
                x: "0",
                y: "0"
              }}
            >
              {joinHuntContent}
            </div>
          </Media>
          <Media greaterThanOrEqual="lg">
            <Grid
              centered
              style={{
                marginTop: "4em"
              }}
            >
              <Grid.Column width={10}>
                <Segment padded={false}>{joinHuntContent}</Segment>
              </Grid.Column>
            </Grid>
          </Media>
        </MediaContextProvider>
      </div>
    );
  }
}

JoinHuntLanding.propTypes = {
  loadHunt: PropTypes.func.isRequired,
  fetchAmIRegistered: PropTypes.func.isRequired,
  loadTeamCount: PropTypes.func.isRequired,
  teamLimit: PropTypes.number,
  hunt: PropTypes.object,
  currentUser: PropTypes.object,
  match: PropTypes.object.isRequired,
  myTeam: PropTypes.object,
  onEmailSignup: PropTypes.func.isRequired,
  onEmailLogin: PropTypes.func.isRequired,
  onFacebookSignup: PropTypes.func.isRequired,
  onLogOut: PropTypes.func.isRequired,
  onCreateTeamAndRegister: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  registerLoading: PropTypes.bool,
  error: PropTypes.string,
  loginState: PropTypes.object,
  signupState: PropTypes.object,
  registeredTeamCount: PropTypes.number.isRequired,
  loggedIn: PropTypes.bool,
  location: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  hunt: selectCurrentHunt,
  currentUser: selectCurrentUser,
  loggedIn: selectIsLoggedIn,
  myTeam: selectMyRegisteredTeam,
  loading: selectLoading,
  registerLoading: selectRegisterLoading,
  error: selectError,
  loginState: selectLogin,
  signupState: selectSignup,
  registeredTeamCount: selectRegisteredTeamCount,
  teamLimit: selectHuntCapabilityTeamLimit
});

function mapDispatchToProps(dispatch) {
  return {
    loadHunt: huntId => dispatch(loadHunt(huntId)),
    loadTeamCount: huntId => dispatch(loadRegisteredTeams(huntId)),
    fetchAmIRegistered: huntId => dispatch(fetchAmIRegistered(huntId)),
    onFacebookSignup: (user, fbUserData) => {
      dispatch(fbSignupSuccess(user, fbUserData));
    },
    onEmailLogin: (email, password) => {
      dispatch(emailLogin(email, password));
    },
    onEmailSignup: (email, password, userData) => {
      dispatch(emailSignup(email, password, userData));
    },
    onCreateTeamAndRegister: (hunt, teamName, teamPassword, teamInvites) => {
      dispatch(
        createTeamAndJoinHunt(hunt, teamName, teamPassword, teamInvites)
      );
    },
    onLogOut: () => {
      dispatch(logout());
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JoinHuntLanding)
);
