import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import { Menu } from "semantic-ui-react";
import RemoteConfig from "RemoteConfig";
import { RC_DOWNLOAD_TAB, RC_FEED_FEATURE } from "RemoteConfig/constants";

const manageSections = () => {
  const downloadTabConfig = RemoteConfig.getStringValue(RC_DOWNLOAD_TAB);
  const showDownloadTab = downloadTabConfig === "on";

  const feedTabConfig = RemoteConfig.getStringValue(RC_FEED_FEATURE);
  const showFeedTab = feedTabConfig === "on";

  let sections = [];
  sections.push({
    key: "details",
    value: "details",
    text: "Edit Details"
  });
  sections.push({
    key: "challenges",
    value: "challenges",
    text: "Challenges"
  });
  sections.push({
    key: "judging",
    value: "judging",
    text: "Judging"
  });
  sections.push({
    key: "participants",
    value: "participants",
    text: "Participants"
  });
  sections.push({
    key: "leaderboard",
    value: "leaderboard",
    text: "Leaderboard"
  });
  if (showFeedTab) {
    sections.push({
      key: "feed",
      value: "feed",
      text: "Feed"
    });
  }
  if (showDownloadTab) {
    sections.push({
      key: "download",
      value: "download",
      text: "Download"
    });
  }
  sections.push({
    key: "settings",
    value: "settings",
    text: "Settings"
  });

  return sections;
};

class ManageHuntMenu extends React.PureComponent {
  render() {
    const { match, handleSectionClick } = this.props;

    const activeItem = match.params.section;
    const sections = manageSections();
    const defaultArray = sections.map(section => (
      <Menu.Item
        key={section.key}
        name={section.text}
        active={activeItem === section.key}
        onClick={() => handleSectionClick(section.key)}
      />
    ));

    return (
      <Menu secondary pointing vertical fluid>
        {defaultArray}
      </Menu>
    );
  }
}

ManageHuntMenu.propTypes = {
  handleSectionClick: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
};

export default ManageHuntMenu;

export { manageSections };
