/* eslint react/prop-types: 0 */
import React from "react";
import { Form, Label } from "semantic-ui-react";
import { Field as ReduxFormField } from "redux-form/immutable";

import log from "loglevel"; // eslint-disable-line no-unused-vars

const renderField = ({ input, meta, fieldType, label, ...rest }) => {
  const FieldType = fieldType ? fieldType : Form.Field;
  const hasErrorField = fieldType !== "textarea";
  // log.debug("renderField", meta, hasErrorField);
  const showError = meta.error && meta.touched && !meta.active;
  return (
    <div className={"field " + (showError ? "error" : "")}>
      {label && <label>{label}</label>}
      <FieldType
        {...input}
        {...rest}
        error={hasErrorField ? showError : undefined}
        onFocus={evt => input.onFocus(evt)}
        onBlur={evt => input.onBlur(evt)}
        onChange={(evt, data) => {
          if (data) {
            input.onChange(data.value);
          } else {
            input.onChange(evt);
          }
        }}
      />
      {/*{showError && <Message error header="Error" content={meta.error} />}*/}
      {showError && (
        <Label basic color="red" pointing>
          {meta.error}
        </Label>
      )}
    </div>
  );
};

const ScavrField = ({ component, ...rest }) => (
  <ReduxFormField component={renderField} {...rest} fieldType={component} />
);

export default ScavrField;
