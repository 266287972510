import * as Sentry from "@sentry/browser";

const sentryMiddleware = () => next => action => {
  const result = next(action);

  Sentry.addBreadcrumb({
    message: "Redux Action: " + action.type,
    level: Sentry.Severity.Info
    // data: action.,
  });

  return result;
};

export default sentryMiddleware;
