import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router";
import JoinHuntLanding from "./containers/JoinHuntLanding";
import JoinHuntConfirmation from "./containers/JoinHuntConfirmation";
import FourOhFour from "containers/404";

class JoinHunt extends React.PureComponent {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Switch>
        <Route
          path={`${url}/:huntId/confirmed`}
          component={JoinHuntConfirmation}
        />
        <Route path={`${url}/:huntId`} component={JoinHuntLanding} />
        <Route component={FourOhFour} />
      </Switch>
    );
  }
}

JoinHunt.propTypes = {
  match: PropTypes.object.isRequired,
};

export default JoinHunt;
