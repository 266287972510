import { createAction } from "redux-actions";
import { normalize } from "normalizr";
import {
  MODELS_UPDATED,
  DELETE_TEAM,
  DELETE_TEAM_FAILED,
  DELETE_TEAM_SUCCESS,
  LOAD_HUNT,
  LOAD_HUNT_FAILURE,
  LOAD_HUNT_SUCCESS,
  LOAD_HUNT_CAPABILITIES,
  LOAD_HUNT_CAPABILITIES_SUCCESS,
  LOAD_HUNT_CAPABILITIES_FAILURE,
  LOAD_HUNT_SETTINGS,
  LOAD_HUNT_SETTINGS_SUCCESS,
  LOAD_HUNT_LEVELS_FAILURE,
  LOAD_REMOTE_CONFIG,
  LOAD_REMOTE_CONFIG_SUCCESS,
  INITIALIZE_APP,
  INITIALIZE_APP_COMPLETE,
  PAYMENT_COMPLETE,
  LOADING_HUNT_ARCHIVE,
  HUNT_ARCHIVE_LOADED,
  HUNT_ARCHIVE_FAILED,
  HUNT_ARCHIVE_REQUESTED,
  WATCHING_HUNT_ARCHIVE_INFO,
  STOPPED_WATCHING_HUNT_ARCHIVE_INFO,
  ARCHIVE_INFO_UPDATED,
  WATCH_HUNT_ARCHIVE_INFO,
  STOP_WATCHING_HUNT_ARCHIVE_INFO,
  UPDATE_HUNT_SETTING,
  UPDATE_HUNT_SETTING_SUCCESS,
  UPDATE_HUNT_SETTING_FAILURE,
  WATCH_HUNT_SETTINGS,
  STOP_WATCHING_HUNT_SETTINGS,
  STARTED_WATCHING_SETTINGS,
  STOPPED_WATCHING_SETTINGS
} from "redux/constants";
import { ANALYTICS_TRACK } from "analytics/constants";
import { HuntSchema } from "../models/Hunt";
import {
  LISTENER_ACTION_CLOSE,
  LISTENER_ACTION_OPEN
} from "./listeners/constants";

const createOpenListenerAction = (actionType, listenerId) => (
  payload,
  openListenerFunc
) => ({
  type: actionType,
  listenerAction: LISTENER_ACTION_OPEN,
  openFunction: openListenerFunc,
  listenerId,
  payload
});

const createCloseListenerAction = (actionType, listenerId) => payload => ({
  type: actionType,
  listenerAction: LISTENER_ACTION_CLOSE,
  listenerId,
  payload
});

const modelsUpdated = createAction(MODELS_UPDATED, (modelDiff, schema) => {
  const addedNormalized = normalize(modelDiff.added, [schema]);
  const removedNormalized = normalize(modelDiff.deleted, [schema]);
  const updatedNormalized = normalize(modelDiff.updated, [schema]);

  return {
    added: addedNormalized,
    removed: removedNormalized,
    updated: updatedNormalized
  };
});

const deleteTeam = createAction(
  DELETE_TEAM,
  (teamId, huntId) => ({ huntId, teamId }),
  (teamId, huntId) => ({
    analytics: {
      method: ANALYTICS_TRACK,
      type: "Manage.DeleteTeam",
      payload: {
        huntId,
        teamId
      }
    }
  })
);

const deleteTeamSucceeded = createAction(DELETE_TEAM_SUCCESS, teamId => ({
  teamId
}));
const deleteTeamFailed = createAction(DELETE_TEAM_FAILED, teamId => ({
  teamId
}));

const loadHuntAction = createAction(LOAD_HUNT, huntId => ({ huntId }));
const loadHuntSucceeded = createAction(LOAD_HUNT_SUCCESS, huntObject =>
  normalize(huntObject, HuntSchema)
);
const loadHuntFailed = createAction(LOAD_HUNT_FAILURE);

const loadCapabilitiesAction = createAction(LOAD_HUNT_CAPABILITIES, huntId => ({
  huntId
}));
const loadCapabilitiesSucceeded = createAction(LOAD_HUNT_CAPABILITIES_SUCCESS);
const loadCapabilitiesFailed = createAction(LOAD_HUNT_CAPABILITIES_FAILURE);

const watchHuntSettingsAction = createOpenListenerAction(
  WATCH_HUNT_SETTINGS,
  "watch-hunt-settings"
);

const stopWatchingHuntSettingsAction = createCloseListenerAction(
  STOP_WATCHING_HUNT_SETTINGS,
  "watch-hunt-settings"
);

const watchSettingsStarted = createAction(STARTED_WATCHING_SETTINGS);
const watchSettingsStopped = createAction(STOPPED_WATCHING_SETTINGS);

const loadHuntSettingsAction = createAction(LOAD_HUNT_SETTINGS);
const loadHuntSettingsSucceeded = createAction(
  LOAD_HUNT_SETTINGS_SUCCESS,
  ({ huntId, settings }) => ({
    huntId,
    settings,
    entities: {
      huntSettings: {
        [huntId]: settings
      }
    }
  })
);
const loadHuntSettingsFailed = createAction(LOAD_HUNT_LEVELS_FAILURE);

const updateHuntSettingAction = createAction(
  UPDATE_HUNT_SETTING,
  obj => obj,
  obj => ({
    analytics: {
      method: ANALYTICS_TRACK,
      type: "Manage.UpdateHuntSetting",
      payload: {
        ...obj
      }
    }
  })
);
const updateHuntSettingSucceeded = createAction(UPDATE_HUNT_SETTING_SUCCESS);
const updateHuntSettingFailed = createAction(UPDATE_HUNT_SETTING_FAILURE);

const initializeApp = createAction(INITIALIZE_APP);
const initializeAppComplete = createAction(INITIALIZE_APP_COMPLETE);

const initializeRemoteConfig = createAction(LOAD_REMOTE_CONFIG);
const remoteConfigInitialized = createAction(LOAD_REMOTE_CONFIG_SUCCESS);

const paymentCompleteAction = createAction(
  PAYMENT_COMPLETE,
  ({ huntId, skuShortCode, skuId, priceUSD }) => ({
    huntId: huntId,
    huntType: skuShortCode,
    skuId: skuId,
    price: priceUSD
  }),
  ({ huntId, skuShortCode, skuDisplayName, skuId, priceUSD, sessionId }) => ({
    analytics: {
      method: ANALYTICS_TRACK,
      type: "Order Completed",
      payload: {
        huntId,
        sessionId,
        huntType: skuShortCode,
        skuId: skuId,
        price: priceUSD,

        // Official e-commerce spec
        checkout_id: sessionId,
        order_id: huntId,
        revenue: priceUSD,
        currency: "USD",
        products: [
          {
            sku: skuId,
            name: skuDisplayName,
            price: priceUSD
          }
        ]
      }
    }
  })
);

const creatingArchive = createAction(
  LOADING_HUNT_ARCHIVE,
  (huntId, settings) => ({ huntId, settings }),
  (huntId, settings) => ({
    analytics: {
      method: ANALYTICS_TRACK,
      type: "Manage.CreateArchiveRequested",
      payload: {
        huntId,
        settings
      }
    }
  })
);
const archiveRequested = createAction(HUNT_ARCHIVE_REQUESTED);
const archiveCreated = createAction(HUNT_ARCHIVE_LOADED);
const createArchiveFailed = createAction(HUNT_ARCHIVE_FAILED);

const watchHuntArchiveData = createOpenListenerAction(
  WATCH_HUNT_ARCHIVE_INFO,
  "watch-hunt-archive-data"
);

const stopWatchingHuntArchiveData = createCloseListenerAction(
  STOP_WATCHING_HUNT_ARCHIVE_INFO,
  "watch-hunt-archive-data"
);

const watchingArchiveData = createAction(WATCHING_HUNT_ARCHIVE_INFO);
const stoppedWatchingArchiveData = createAction(
  STOPPED_WATCHING_HUNT_ARCHIVE_INFO
);
const archiveInfoUpdated = createAction(ARCHIVE_INFO_UPDATED);

export {
  createOpenListenerAction,
  createCloseListenerAction,
  modelsUpdated,
  deleteTeam,
  deleteTeamFailed,
  deleteTeamSucceeded,
  loadHuntAction,
  loadHuntFailed,
  loadHuntSucceeded,
  loadCapabilitiesAction,
  loadCapabilitiesSucceeded,
  loadCapabilitiesFailed,
  watchHuntSettingsAction,
  stopWatchingHuntSettingsAction,
  watchSettingsStarted,
  watchSettingsStopped,
  loadHuntSettingsAction,
  loadHuntSettingsSucceeded,
  loadHuntSettingsFailed,
  updateHuntSettingAction,
  updateHuntSettingSucceeded,
  updateHuntSettingFailed,
  initializeRemoteConfig,
  remoteConfigInitialized,
  initializeApp,
  initializeAppComplete,
  paymentCompleteAction,
  creatingArchive,
  archiveRequested,
  archiveCreated,
  createArchiveFailed,
  watchHuntArchiveData,
  stopWatchingHuntArchiveData,
  watchingArchiveData,
  stoppedWatchingArchiveData,
  archiveInfoUpdated
};
