/*
 *
 * Login reducer
 *
 */

import { fromJS } from "immutable";

import {
  EMAIL_LOGIN,
  EMAIL_LOGIN_SUCCESS,
  EMAIL_LOGIN_FAILURE,
} from "reducers/AuthReducer";

const initialState = fromJS({
  loading: false,
  error: false,
});

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case EMAIL_LOGIN:
      return state.set("loading", true).set("error", false);
    case EMAIL_LOGIN_FAILURE:
      return state
        .set("loading", false)
        .set("error", action.error)
        .set("password", "");
    case EMAIL_LOGIN_SUCCESS:
      return state
        .set("loading", false)
        .set("error", false)
        .set("email", "")
        .set("password", "");
    default:
      return state;
  }
}

export default loginReducer;
