/**
 * Create the store with asynchronously loaded reducers
 */

import log from "loglevel"; // eslint-disable-line no-unused-vars
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import createSagaMiddleware from "redux-saga";
import scavrReducer from "./reducers";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { authSaga } from "reducers/AuthReducer";
import { dataSaga } from "reducers/DataReducer";
import analyticsMiddleware from "analytics/middleware";
import sentryMiddleware from "analytics/sentry-middleware";
import DataProvider from "DataProvider";
import { callAPIMiddleware } from "./middleware";
import listenerMiddleware from "./listeners/middleware";

export default function configureStore(history) {
  const sagaMiddleware = createSagaMiddleware();

  // Create the store with middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  const middlewares = [
    sagaMiddleware,
    thunk,
    callAPIMiddleware,
    analyticsMiddleware,
    sentryMiddleware,
    routerMiddleware(history),
    listenerMiddleware
  ];

  const scavrReducers = scavrReducer();
  const dataReducers = DataProvider.instance().getReducers();
  const reducers = {
    ...scavrReducers,
    ...dataReducers,
    router: connectRouter(history)
  };

  const createDataStore = DataProvider.instance().getCreateStore(createStore);

  const store = createDataStore(
    combineReducers(reducers),
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  // Run Sagas
  sagaMiddleware.run(authSaga);
  sagaMiddleware.run(dataSaga);

  // Extensions
  store.runSaga = sagaMiddleware.run;
  // store.asyncReducers = {}; // Async reducer registry
  //
  // // Make reducers hot reloadable, see http://mxs.is/googmo
  // /* istanbul ignore next */
  // if (module.hot) {
  //   module.hot.accept("./reducers", () => {
  //     import("./reducers").then((reducerModule) => {
  //       const nextReducers = reducerModule.default;
  //       // const nextReducers = createReducers(store.asyncReducers);
  //
  //       store.replaceReducer(nextReducers);
  //     });
  //   });
  // }

  return store;
}
