import { schema } from "normalizr";
import { HuntSchema } from "./Hunt";
import { TeamSchema } from "./Team";
import { ChallengeSchema } from "./Challenge";
import { ChallengeAttemptSchema } from "./ChallengeAttempt";

export const FeedItemSchema = new schema.Entity(
  "feedItem",
  {
    hunt: HuntSchema,
    team: TeamSchema,
    challenge: ChallengeSchema,
    attempt: ChallengeAttemptSchema
  },
  {
    idAttribute: "objectId"
  }
);
