import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Card, Image } from "semantic-ui-react";

// import log from "loglevel";

export default class HuntListItem extends React.PureComponent {
  render() {
    const { hunt, onClick } = this.props;
    const startTime = hunt.startTime ? moment(hunt.startTime).calendar() : null;
    return (
      <Card link onClick={onClick}>
        <Card.Content>
          <Image floated="right" avatar src={hunt.imageURL} />
          <Card.Header>{hunt.name}</Card.Header>
          <Card.Meta>{startTime}</Card.Meta>
          <Card.Description>{hunt.description}</Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

/**
 *
 *         <Link to={"/manage/" + this.props.hunt.objectId}>
 <Header>{this.props.hunt.name}</Header>
 <p>{this.props.hunt.objectId}</p>
 </Link>

 * @type {{hunt: shim}}
 */
HuntListItem.propTypes = {
  hunt: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
