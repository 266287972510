import React from "react";
import PropTypes from "prop-types";
import log from "loglevel"; // eslint-disable-line no-unused-vars
import { Modal, Button, Icon, Confirm } from "semantic-ui-react";
import { ChallengeListItem } from "components/ChallengeListItem";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";

import ViewChallengeDetails from "../ViewChallengeDetails";
import EditChallenge from "../CreateEditChallengeForm";

import { updateChallenge, deleteChallenge } from "../actions";
import { selectValues } from "../selectors";

class ViewChallengeModal extends React.PureComponent {
  state = { modalOpen: false, isEditing: false, deleteConfirmOpen: false };
  handleClose = () => {
    this.setState({ modalOpen: false });
  };
  handleOpen = () => {
    this.setState({ modalOpen: true });
  };
  handleEditClicked = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };
  handleCancelClicked = () => {
    this.setState({ isEditing: false });
  };
  handleSaveClicked = () => {
    this.props.updateChallenge(
      this.props.challenge,
      this.props.formData.toJS()
    );

    this.setState({ isEditing: false });
  };
  handleDeleteClicked = () => {
    this.setState({ deleteConfirmOpen: true });
  };
  handleDeleteCanceled = () => {
    this.setState({ deleteConfirmOpen: false });
  };
  handleDeleteConfirmed = () => {
    log.debug("confirmed delete", this.props.challenge);
    this.props.deleteChallenge(this.props.challenge);
    this.setState({ deleteConfirmOpen: false, modalOpen: false });
  };

  render() {
    const { isEditing } = this.state;
    return (
      <Modal
        trigger={
          <ChallengeListItem
            challenge={this.props.challenge}
            sortHandle={this.props.sortHandle}
          />
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        size="small"
      >
        <Modal.Header>
          {!isEditing && [
            <Button
              floated="right"
              basic
              size="small"
              onClick={this.handleDeleteClicked}
              key="deleteChallengeBtn"
              negative
            >
              <Icon name="trash" />
              Delete
            </Button>,
            <Button
              floated="right"
              basic
              size="small"
              onClick={this.handleEditClicked}
              key="editChallengeBtn"
              primary
            >
              <Icon name="pencil" />
              Edit
            </Button>,
            <Confirm
              key="deleteChallengeConfirm"
              open={this.state.deleteConfirmOpen}
              cancelButton="Cancel"
              confirmButton="Delete Challenge"
              content="Are you sure you want to delete this challenge? This will also delete any submissions for this challenge and update the leaderboard to remove points from this challenge."
              onCancel={this.handleDeleteCanceled}
              onConfirm={this.handleDeleteConfirmed}
            />
          ]}
          Challenge Details
        </Modal.Header>
        <Modal.Content scrolling>
          {isEditing ? (
            <EditChallenge challenge={this.props.challenge} />
          ) : (
            <ViewChallengeDetails challenge={this.props.challenge} />
          )}
        </Modal.Content>
        <Modal.Actions>
          {isEditing ? (
            [
              <Button
                key="cancelChallengeEdit"
                onClick={this.handleCancelClicked}
              >
                Cancel
              </Button>,
              <Button
                key="saveChallengeEdits"
                onClick={this.handleSaveClicked}
                primary
              >
                Save Changes
              </Button>
            ]
          ) : (
            <Button onClick={this.handleClose}>Close</Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

ViewChallengeModal.propTypes = {
  updateChallenge: PropTypes.func.isRequired,
  deleteChallenge: PropTypes.func.isRequired,

  formData: PropTypes.object,

  challenge: PropTypes.object.isRequired,
  sortHandle: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  formData: selectValues
});

const mapDispatchToProps = dispatch => ({
  updateChallenge: (challenge, newChallengeData) =>
    dispatch(updateChallenge(challenge, newChallengeData)),
  deleteChallenge: challenge => dispatch(deleteChallenge(challenge))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewChallengeModal)
);
