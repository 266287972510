import React from "react";

const FourOhFourPage = () => (
  <p>
    Page not found...{" "}
    <span role="img" aria-label="Crying">
      😢
    </span>
  </p>
);

export default FourOhFourPage;
