import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { Image, Menu, Dropdown } from "semantic-ui-react";

import logo from "images/scavr-text-logo.png";

import "./globalNavDesktop.css";

const GlobalNavDesktop = ({ leftItems, location, userInfo, onSignOut }) => (
  <Menu className="global-nav-desktop">
    <Menu.Item>
      <Image size="tiny" src={logo} />
    </Menu.Item>
    {_.map(leftItems, item => (
      <Menu.Item as={Link} active={location.pathname === item.to} {...item} />
    ))}
    <Menu.Menu position="right">
      <Menu.Item>
        {userInfo.avatar ? <Image src={userInfo.avatar} avatar /> : ""}
        <Dropdown item text={userInfo.name}>
          <Dropdown.Menu>
            <Dropdown.Item onClick={onSignOut}>Log Out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </Menu.Menu>
  </Menu>
);

GlobalNavDesktop.propTypes = {
  location: PropTypes.object.isRequired,
  leftItems: PropTypes.array.isRequired,
  rightItems: PropTypes.array.isRequired,
  userInfo: PropTypes.object.isRequired,
  onSignOut: PropTypes.func.isRequired,
};

export default GlobalNavDesktop;
